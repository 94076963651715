import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {LIMIT_SCROLL_CITY} from '../constants/constants';
const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token");
  return token;
};

const baseUrl = process.env.REACT_APP_UTILITIES_DNS_NAME as string;

export const utilityApi = createApi({
  reducerPath: "utilityApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (build) => ({
    getCountries: build.query({
      query: (data) => ({
        url: `/api/utility/countries?languageCode=${data.lang}`,
      }),
    }),
    getCountryCodes: build.query({
      query: (lang) => ({
        url: `/api/Utility/CountryPhoneCodes?languageCode=${lang || "eng"}`,
      }),
      transformResponse: (response: any[]) => {
        const data = response.map((item) => {
          return {
            id: item.id,
            name: `${item.code} (${item.country})`,
            code: item.code,
          };
        });
        return data;
      },
    }),
    getCountryById: build.query({
      query: (data) => ({
        url: `/api/utility/countries/${data.countryId}?languageCode=${
          data.lang || "eng"
        }`,
      }),
    }),
    getCountryTranslateById: build.query({
      query: (data) => ({
        url: `/api/utility/countries/countriesTranslate?${data.countryIds}&languageCode=${data.lang || "EN"}`,
      }),
    }),
    getCitiesTranslateById: build.query({
      query: (data) => ({
        url: `/api/utility/cities/citiesTranslate?${data.citiesIds}&languageCode=${data.lang || "EN"}`,
      }),
    }),
    getReligionsTranslateById: build.query({
      query: (data) => ({
        url: `/api/religions/religionsTranslate?${data.religionIds}&languageCode=${data.lang || "EN"}`,
      }),
    }),

    getCities: build.query({
      query: (data) => ({
        url: `/api/utility/countries/${data.countryIsoCode}/cities?start=0&limit=40&languageCode=${data.lang}`,
      }),
    }),
    getCityById: build.query({
      query: (data) => ({
        url: `/api/utility/cities/${data.cityId}?languageCode=${data.lang}`,
      }),
    }),
    getRegionById: build.query({
      query: (data) => ({
        url: `/api/utility/regions/${data.regionId}?languageCode=${data.lang}`,
      }),
    }),

    searchCities: build.query({
      query: (data) => ({
        url: `/api/utility/Countries/${data.countryId}/cities?languageCode=${data.lang}&searchQuery=${data.search}&regionCode=${data.region ? data.region : ''}&start=${data.start ? data.start : 0}&limit=${LIMIT_SCROLL_CITY}`,
      }),
    }),
    searchCountries: build.query({
      query: (data) => ({
        url: `/api/Utility/Countries?languageCode=${data.lang}&searchQuery=${data.search}`,
      }),
    }),
    searchRegions: build.query({
      query: (data) => ({
        url: `/api/Utility/Countries/${data.countryId}/regions?languageCode=${data.lang}&searchQuery=${data.search}&start=${data.start ? data.start : 0}&limit=${LIMIT_SCROLL_CITY}`,
      }),
    }),
    getReligions: build.query({
      query: (lang) => ({
        url: `/api/religions?languageCode=${lang}`,
      }),
    }),
    getReligionById: build.query({
      query: (data) => ({
        url: `/api/religions/${data.religionId}?languageCode=${data.lang}`,
      }),
    }),
    getReligionsBySearch: build.query({
      query: (data) => ({
        url: `/api/religions/search?languageCode=${data.lang || "en"}${data.search ? `&searchQuery=${data.search}` : ''}`,
      }),
    }),
    getCoordinateInformation: build.query({
      query: (data) => ({
        url: `/api/Utility/GeoCoding/CoordinateInformation?coordinates=${data.coords}&languageCode=${data.lang || "en"}`,
      }),
      
    }),
    getFullUrl: build.query({
      query: (url) => ({
        url: `/api/Utility/urlshortener/Get/${url}`,
      }),
      
    }),

  }),
});
export const {
  useGetCountriesQuery,
  useGetReligionByIdQuery,
  useGetReligionsBySearchQuery,
  useGetCitiesQuery,
  useGetCityByIdQuery,
  useGetRegionByIdQuery,
  useGetCountryByIdQuery,
  useGetReligionsQuery,
  useGetCountryCodesQuery,
  useSearchCitiesQuery,
  useSearchCountriesQuery,
  useSearchRegionsQuery,
  useGetCoordinateInformationQuery,
  useGetFullUrlQuery,
  useGetCountryTranslateByIdQuery,
  useGetCitiesTranslateByIdQuery,
  useGetReligionsTranslateByIdQuery,
} = utilityApi;
