import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorContact: '',
};

export const userContactErrorSlice = createSlice({
  name: "errorContactSlice",
  initialState,
  reducers: {
    setErrorContact: (state, action) => {
      state.errorContact = action.payload;
    },
  },
});
export const { setErrorContact } =
  userContactErrorSlice.actions;
export default userContactErrorSlice.reducer;
