import { useState, useEffect } from "react";

import { useMsal } from "@azure/msal-react";
import { b2cPolicies, protectedResources } from "../authConfig";
import { useSelector } from "react-redux";

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */

const useFetchWithMsal = (type, isAuthenticated) => {
  const { instance, accounts, inProgress } = useMsal();
  const [skip, setSkip] = useState(
    isAuthenticated === null ? true : isAuthenticated
  );
  const [skipSearch, setSkipSearch] = useState(
    isAuthenticated === null ? true : isAuthenticated
  );
  const [skipUser, setSkipUser] = useState(
    isAuthenticated === null ? true : isAuthenticated
  );
  const [skipPayment, setSkipPayment] = useState(
    isAuthenticated === null ? true : isAuthenticated
  );
  const [skipOrder, setSkipOrder] = useState(
    isAuthenticated === null ? true : isAuthenticated
  );
  const [skipMedia, setSkipMedia] = useState(
    isAuthenticated === null ? true : isAuthenticated
  );
  const [skipComplaint, setSkipComplaint] = useState(
    isAuthenticated === null ? true : isAuthenticated
  );
  
  const { language } = useSelector((state) => state.language);
  const getScope = () => {
    switch (type) {
      case "profile":
        return [...protectedResources.api.scopes.profile];
      case "search":
        return [...protectedResources.api.scopes.search];
      case "payment":
        return [...protectedResources.api.scopes.payment];
      case "order":
        return [...protectedResources.api.scopes.order];
      case "media":
        return [...protectedResources.api.scopes.media];
      case "complaint":
        return [...protectedResources.api.scopes.complaint];
      default:
        return [...protectedResources.api.scopes.read];
    }
  };
  const scope = getScope();

  const refreshToken = () => {
    instance
      .acquireTokenSilent({
        account: accounts[0],
        scopes: scope,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
      })
      .then((response) => {
        localStorage.setItem(`token-${type}`, response?.accessToken);
        switch (type) {
          case "profile":
            setSkip(false);
            break;
          case "search":
            setSkipSearch(false);
            break;
          case "payment":
            setSkipPayment(false);
            break;
          case "order":
            setSkipOrder(false);
            break;
          case "media":
            setSkipMedia(false);
            break;
          case "complaint":
            setSkipComplaint(false);
            break;
          default:
            setSkipUser(false);
        }
      })
      .catch((e) => {
        localStorage.clear(); 
        localStorage.setItem("lang",language );
        let signUpSignInFlowRequest = {
          authority: b2cPolicies.authorities.signUpSignIn.authority,
          scopes: [...protectedResources.api.scopes.read],
          extraQueryParameters: { ui_locales: language },
        };
        instance
          .loginRedirect(signUpSignInFlowRequest)
          .catch((error) => console.log(error));
      });
  };

  useEffect(() => {
    if (inProgress === "none") {
      if (isAuthenticated) {
        refreshToken();
      } else if(isAuthenticated !== null){
        setSkip(false);
        setSkipSearch(false);
        setSkipPayment(false);
        setSkipOrder(false);
        setSkipMedia(false);
        setSkipComplaint(false);
        setSkipUser(false);
      }
    }
  }, [inProgress, type, isAuthenticated]);

  return {
    skip,
    skipSearch,
    skipUser,
    skipPayment,
    skipOrder,
    skipMedia,
    skipComplaint,
  };
};

export default useFetchWithMsal;
