import {
  useLayoutEffect,
  useRef,
  useState
} from "react";
import "./PaymentForm.scss";
import { useTranslation } from "react-i18next";
import { SwitchCheckbox } from "../../shared/components/switchCheckbox/SwitchCheckbox";
import useIsAuthenticated from "../../utils/useIsAuthenticated";
import { Loader } from '../../shared/components/loader/Loader';
import { PaymentStatus } from "../../shared/components/paymentStatus/PaymentStatus";

interface PaymentFormProps {
  onClick?: (token: string, method: string, isDefault: boolean) => void;
  disabled: boolean;
  buttonText?: string;
}

interface MollieChangeEvent {
  dirty: boolean;
  touched: boolean;
  error: string;
  valid: boolean;
}

const molliekey = process.env.REACT_APP_MOLLIE_KEY as string;
const testModeEvn = process.env.REACT_APP_TEST_MODE as string;

const PaymentForm = ({ onClick, disabled, buttonText }: PaymentFormProps) => {
  const {isAuthenticated} =  useIsAuthenticated();
  const [paymentError, setPaymentError] = useState<boolean>(false);

  const cardNumberElementRef = useRef<HTMLDivElement>(null);
  const cardHolderElementRef = useRef<HTMLDivElement>(null);
  const expiryDateElementRef = useRef<HTMLDivElement>(null);
  const verificationCodeElementRef = useRef<HTMLDivElement>(null);
  const isFirst = useRef(true);
  const mollieInstanceRef = useRef<any>(null);
  const mollieCardNumberRef = useRef<any>(null);
  const mollieCardHolderRef = useRef<any>(null);
  const mollieExpiryDateRef = useRef<any>(null);
  const mollieVerificationCodeRef = useRef<any>(null);
  const { t } = useTranslation();

  const [cardHolderEvent, setCardHolderEvent] = useState<MollieChangeEvent>();
  const [cardNumberEvent, setCardNumberEvent] = useState<MollieChangeEvent>();
  const [expiryDateEvent, setExpiryDateEvent] = useState<MollieChangeEvent>();
  const [verificationCodeEvent, setVerificationCodeEvent] = useState<MollieChangeEvent>();
  const [isDefault, setIsDefault] = useState<boolean>(!!isAuthenticated)
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadMollie = async () => {
    try {
      const script = document.createElement("script");
      script.src = "https://js.mollie.com/v1/mollie.js";
      script.async = true;

      script.onload = () => {
        const testmode = testModeEvn === "true";
        const mollieInstance = (window as any).Mollie(molliekey, {
          locale: "ru_RU",
          testmode: testmode,
        });
        mollieInstanceRef.current = mollieInstance;
        if (mollieInstance && mollieInstance.createToken) {

          var options = {
            styles: {
              base: {
                fontSize: "19px",
                color: "#162941",
                '::placeholder' : {
                  color: 'rgba(68, 68, 68, 0)',
                  fontSize: "19px",
                  fontWeight: '500'
                }
              },
            },
          };
          mollieCardNumberRef.current = mollieInstance.createComponent(
              "cardNumber",
              options
          );
          mollieCardHolderRef.current = mollieInstance.createComponent(
              "cardHolder",
              options
          );
          mollieExpiryDateRef.current = mollieInstance.createComponent(
              "expiryDate",
              options
          );
          mollieVerificationCodeRef.current = mollieInstance.createComponent(
              "verificationCode",
              options
          );

          mollieCardHolderRef.current.mount(cardHolderElementRef.current!);
          mollieCardNumberRef.current.mount(cardNumberElementRef.current!);
          mollieExpiryDateRef.current.mount(expiryDateElementRef.current!);
          mollieVerificationCodeRef.current.mount(verificationCodeElementRef.current!);

          mollieCardHolderRef.current.addEventListener('change', onChangeMollieField(setCardHolderEvent));
          mollieCardNumberRef.current.addEventListener('change', onChangeMollieField(setCardNumberEvent));
          mollieExpiryDateRef.current.addEventListener('change', onChangeMollieField(setExpiryDateEvent));
          mollieVerificationCodeRef.current.addEventListener('change', onChangeMollieField(setVerificationCodeEvent));

          setIsLoading(true);
        } else {
          console.error("Mollie.js initialization failed.");
        }
      };

      document.head.appendChild(script);
    } catch (error) {
      console.error("Error loading Mollie.js:", error);
    }
  };

  useLayoutEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      loadMollie();
    }

    return () => {
      if (mollieCardNumberRef.current) {
        mollieCardNumberRef.current.unmount();
        mollieCardHolderRef.current.unmount();
        mollieExpiryDateRef.current.unmount();
        mollieVerificationCodeRef.current.unmount();

        mollieCardHolderRef.current.removeEventListener('change', onChangeMollieField(setCardHolderEvent));
        mollieCardNumberRef.current.removeEventListener('change', onChangeMollieField(setCardNumberEvent));
        mollieExpiryDateRef.current.removeEventListener('change', onChangeMollieField(setExpiryDateEvent));
        mollieVerificationCodeRef.current.removeEventListener('change', onChangeMollieField(setVerificationCodeEvent));
      }
    };
  }, []);

  const onChangeMollieField = (callBack: (value: MollieChangeEvent) => void) => {
    return (event: MollieChangeEvent) => {
      callBack(event);
    }
  }

  const handleSubmit = async () => {
    try {
      const { token, error } = await mollieInstanceRef.current.createToken();
      if(!error) {
        onClick && onClick(token, 'creditcard', isDefault);
      } else{
        setPaymentError(true)
      }

    } catch (error) {
      console.error("Error tokenizing card:", error);
    }
  };

  const formIsValid = (): boolean => {
    return !!cardNumberEvent?.valid && !!cardHolderEvent?.valid && !!expiryDateEvent?.valid && !!verificationCodeEvent?.valid
  }

  return (
      <div>
        {!isLoading && <div>
          <Loader/>
        </div>}
        <div className={"mollie-custom-component"}>
          {isLoading && <div className={`mollie-custom-placeholder ${cardHolderEvent?.dirty ? 'hidden' : ''}`}>{t("payment.cardHolder")}</div>}
          <div ref={cardHolderElementRef}></div>
          {isLoading && (
            <div className={`mollie-custom-error ${!cardHolderEvent || (!cardHolderEvent.error && cardHolderEvent.valid) ? 'hidden' : ''}`}>
            { !!cardHolderEvent?.dirty ? t("payment.cardHolderInvalid") : t("payment.cardHolderEmpty") }
          </div>
          )}
        </div>
        <div className={"mollie-custom-component"}>
          {isLoading && <div
            className={`mollie-custom-placeholder ${cardNumberEvent?.dirty ? 'hidden' : ''}`}>
            {t("payment.cardNumber")}
          </div>}
          <div ref={cardNumberElementRef}></div>
          {isLoading && (
            <div className={`mollie-custom-error ${!cardNumberEvent || (!cardNumberEvent.error && cardNumberEvent.valid) ? 'hidden' : ''}`}>
              {!!cardNumberEvent?.dirty ? t("payment.cardNumberInvalid") : t("payment.cardNumberEmpty")}
            </div>
          )}
        </div>

        <div className={"mollie-custom-components"}>
          <div className={"mollie-custom-component"}>
            {isLoading && (
              <div className={`mollie-custom-placeholder ${expiryDateEvent?.dirty ? 'hidden' : ''}`}>{t("payment.expiryDateFormat")}</div>
            )}
            <div ref={expiryDateElementRef}></div>
            {isLoading && (
              <div className={`mollie-custom-error ${!expiryDateEvent || (!expiryDateEvent.error && expiryDateEvent.valid) ? 'hidden' : ''}`}>
                {!!expiryDateEvent?.dirty ? t("payment.expiryDateInvalid") : t("payment.expiryDateEmpty")}
              </div>
            )}

          </div>
          <div className={"mollie-custom-component"}>
            {isLoading &&
              <>
                <div className={`mollie-custom-placeholder ${verificationCodeEvent?.dirty ? 'hidden' : ''}`}>{t("payment.verificationCode")}</div>
                <div className={`mollie-custom-placeholder ${verificationCodeEvent?.dirty ? 'hidden' : ''}`}>{t("payment.verificationCode")}</div>
              </>
            }
            <div ref={verificationCodeElementRef}></div>
            {isLoading && (
              <div className={`mollie-custom-error ${!verificationCodeEvent || (!verificationCodeEvent.error && verificationCodeEvent.valid) ? 'hidden' : ''}`}>
                {!!verificationCodeEvent?.dirty ? t("payment.verificationCodeInvalid") : t("payment.verificationCodeEmpty")}
              </div>
            )}
          </div>
        </div>

        {isAuthenticated && isLoading && (
          <div className={"molly-checkbox-box"}>
            <label className={"description"}>{t("payment.setDefault")}</label>
            <SwitchCheckbox isChecked={isDefault} onChange={(value: boolean) => {setIsDefault(value)}}/>
          </div>
        )}

        {isLoading && (
          <button
            onClick={handleSubmit}
            className={"paymentButton"}
            disabled={disabled || !formIsValid()}
          >
            {!!buttonText ? buttonText : t("common.pay")}
          </button>
        )}
          <PaymentStatus type={"plate"} paymentError={paymentError}  onClose={()=>setPaymentError(false)}/>
      </div>
  );
};

export default PaymentForm;
