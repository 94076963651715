import { FC, FocusEvent, FormEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import styles from "./CodeValidationInput.module.scss";
 
type InputOrNull = HTMLInputElement | null;

interface Props {
  length?: number;
  onSend : (value:string)=>void;
  setIsCodeComplete?: ( value: boolean ) => void;
}

export const CodeValidationInput: FC<Props> = ({ length = 4 , onSend, setIsCodeComplete }) => {
  const [code, setCode] = useState<string[]>(Array(length).fill(""));
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(()=>{
    const isEveryNonEmpty = code.every(value => value.trim() !== '');
    if (isEveryNonEmpty) {
      onSend(code.join(''));
    }

    if (setIsCodeComplete) {
      setIsCodeComplete(isEveryNonEmpty);
    }
  },[code]);

  const update = useCallback((index: number, val: string) => {
    return setCode((prevState) => {
      const slice = prevState.slice();
      slice[index] = val;
      return slice;
    });
  }, []);

  function handleKeyDown(evt: KeyboardEvent<HTMLInputElement>) {
    const index = parseInt(evt.currentTarget.dataset.index as string);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; 

    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prevInput: InputOrNull = form.querySelector(`.input-${prevIndex}`);
    const nextInput: InputOrNull = form.querySelector(`.input-${nextIndex}`);
    switch (evt.key) {
      case "Backspace":
        if (code[index]) update(index, "");
        else if (prevInput) prevInput.select();
        break;
      case "ArrowRight":
        evt.preventDefault();
        if (nextInput) nextInput.focus();
        break;
      case "ArrowLeft":
        evt.preventDefault();
        if (prevInput) prevInput.focus();
    }
  }

  function handleChange(evt: FormEvent<HTMLInputElement>) {
    const value = evt.currentTarget.value;
    const index = parseInt(evt.currentTarget.dataset.index as string);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; 

    let nextIndex = index + 1;
    let nextInput: InputOrNull = form.querySelector(`.input-${nextIndex}`);

    update(index, value[0] || "");
    if (value.length === 1) nextInput?.focus();
    else if (index < length - 1) {
      const split = value.slice(index + 1, length).split("");
      split.forEach((val) => {
        update(nextIndex, val);
        nextInput?.focus();
        nextIndex++;
        nextInput = form.querySelector(`.input-${nextIndex}`);
      });
    }
  }

  function handleFocus(evt: FocusEvent<HTMLInputElement>) {
    evt.currentTarget.select();
  }

  return (
    <form
      ref={formRef}
    >
      <div
      className={styles.codeValidationInput}
      >
        {code.map((value, i) => (
          <input
            key={i}
            value={value}
            className={`input-${i}`}
            aria-label={`Number ${i + 1}`}
            data-index={i}
            pattern="[0-9]*"
            type="numeric"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
          />
        ))}
      </div>
    </form>
  );
};