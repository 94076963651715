import styles from "./MobileInfoButton.module.scss";
import { ReactComponent as InfoIcon } from "../../image/postsIcon/infoIcon.svg";
import { TouchEvent, useState } from "react";
import deleteIcon from "../../image/delete.svg";

type PostItemProps = {
  isShowDelete: boolean;
  isShowInfo: boolean;
  children: JSX.Element;
  onDelete: () => void;
  onComplain: () => void;
  childrenHaveBorder?: boolean;
};

export const MobileInfoButton = ({
  isShowDelete,
  isShowInfo,
  children,
  onDelete,
  onComplain,
  childrenHaveBorder
}: PostItemProps) => {
  const [swiped, setSwiped] = useState(false);
  let startX = 0;

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    startX = e.changedTouches[0].clientX;
  };

  const handleTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
    const deltaX = e.changedTouches[0].clientX - startX;

    if (deltaX < -50 && (isShowDelete || isShowInfo)) {
      setSwiped(true);
    } else if (deltaX > 50 && swiped) {
      setSwiped(false);
    }
  };

  return (
    <div className={styles.buttonsMobileWrapper}>
      <div
        className={`${styles.buttonsMobileContainer} ${
          swiped ? styles.swiped : ""
        }`}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        style={{
          marginLeft: swiped
            ? isShowDelete && isShowInfo
              ? "-60px"
              : "-30px"
            : 0,
        }}
      >
        {children}
      </div>
      {swiped && (
        <div className={`${styles.buttonsMobile} ${!!childrenHaveBorder ? styles.borderDelete : ''}`}>
          {isShowDelete && (
            <div className={styles.deleteButtonMobile} onClick={onDelete}>
              <img src={deleteIcon} alt="" />
            </div>
          )}
          {isShowInfo && (
            <div className={styles.infoButtonMobile} onClick={onComplain}>
              <InfoIcon />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
