import { useTranslation } from "react-i18next";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { RootState } from "../../../store";
import styles from "./QrCodeWizardComponent.module.scss";
import {
    Model,
    Material,
    Mounting,
    Color
} from "../../../types/PlateTypes";
import { QrCodeItem } from "../../../shared/components/qrCodeItem/QrCodeItem";
import {
    setPlateChangesDate,
    setPlateChangesDescription,
    setPlateChangesDescriptionOriginal,
    setPlateChangesImage,
    setPlateChangesName,
    setPlateChangesNameOriginal,
    setPlateColor,
    setPlateCount,
    setPlateMaterial,
    setPlateMounting,
    setPlateWizard
} from "../../../store/slices/OrderSlice";
import { ModalWindow } from "../../modalWindow/ModalWindow";
import { QrCodeRequest } from "../qrCodeRequest/QrCodeRequest";
import { useGetPlateWizardQuery } from "../../../api/PlateService";
import {
    useEffect,
    useRef,
    useState
} from "react";
import { QrCodeWizardView } from "./qrCodeWizardView/QrCodeWizardView";
import { useGetQRCodeQuery } from "../../../api/ProfileService";
import  dayjs from "dayjs";

import { QrCodeModelAliasName } from "../../../constants/ModelsPlateWizard.constant";
import ModalWindowHeader from "../../../shared/components/modalWindowHeader/ModalWindowHeader";
import { Dropdown } from "../../../shared/components/dropdown/Dropdown";
import { DropdownData } from "../../../types/CommonTypes";
import { Mandate } from "../../../types/PaymentTypes";
import {domToBlob, domToPng} from "modern-screenshot";
import { ReactComponent as EditIcon } from "../../../shared/image/editIcon.svg";

type QrCodeWizardComponentProps = {
    onClose: (token: string, paymentMethod: string, orderId: string, isDefault?: boolean, mandate?: Mandate) => void;
}

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const QrCodeWizardComponent = ({onClose}: QrCodeWizardComponentProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { plate, selectedProfiles } = useSelector((state: RootState) => state.orderSlice);
    const { language } = useSelector((state: RootState) => state.language);

    const [isShowQrCodeRequest, setIsShowQrCodeRequest] = useState<boolean>(false);
    const [model, setModel] = useState<Model>()
    const [mountings, setMountings] = useState<Array<Mounting>>([]);
    const [colors, setColors] = useState<Array<Color>>([]);
    const [avatarFile, setAvatarFile] = useState<Blob>();
    const [plateFile, setPlateFile] = useState<string>("");
    const [isEdit, setIdEdit] = useState<boolean>(false);
    const [isShowEditTooltip, setIsShowEditTooltip] = useState<boolean>(true);

    const blockForImage = useRef<HTMLDivElement>(null);
    const blockForPlate = useRef<HTMLDivElement>(null);

    const { data: plateWizard, isSuccess: isSuccessWizard } = useGetPlateWizardQuery({});

    const { data: qr } = useGetQRCodeQuery(selectedProfiles[0].profileId);

    useEffect(() => {
        setDefaultTemplateValue();
        hideTooltip();
    }, []);

    useEffect(() => {
        if (plate.changes.descriptionOriginal === plate.changes.description.text) {
            dispatch(setPlateChangesDescription({
                html: t("qrCode.defaultDescription"),
                text: t("qrCode.defaultDescription")
            }))
            dispatch(setPlateChangesDescriptionOriginal(t("qrCode.defaultDescription")));
        }

        if (plate.changes.nameOriginal === plate.changes.name.text) {
            switch (language) {
                case "en-US": {
                    dispatch(setPlateChangesName({
                        html: selectedProfiles[0].profileFullNameEn,
                        text: selectedProfiles[0].profileFullNameEn
                    }));
                    dispatch(setPlateChangesNameOriginal(selectedProfiles[0].profileFullNameEn));
                    break;
                }
                case "ru-RU": {
                    dispatch(setPlateChangesName({
                        html: selectedProfiles[0].profileFullName,
                        text: selectedProfiles[0].profileFullName
                    }));
                    dispatch(setPlateChangesNameOriginal(selectedProfiles[0].profileFullName));

                }

            }

        }
    }, [language]);

    useEffect(() => {
        if (isSuccessWizard) {
            const selectedMaterial = plateWizard.materials.find(material => material.id === plate.selectedMaterial);
            if (selectedMaterial) {
                setMountings(selectedMaterial.mountingTypes);
                setColors(selectedMaterial.colors);
            }
        }
    }, [isSuccessWizard]);

    useEffect(() => {
        if (!!colors.length) {
            const index = colors.findIndex(color => color.id === plate.selectedColor);
            if (index > -1) {
                const selectedModel = colors[index].models.find(item => item.modelId === plate.selectedModel);
                setModel(selectedModel);
            } else {
                dispatch(setPlateColor(colors[0].id));
            }
        }
    }, [colors]);

    useEffect(() => {
        if (plate.selectedMaterial) {
            const selectedMaterial = plateWizard?.materials.find(material => material.id === plate.selectedMaterial);
            if (selectedMaterial) {
                setMountings(selectedMaterial.mountingTypes);
                const mounting = selectedMaterial.mountingTypes.find(item => item.id === plate.selectedMounting);
                if (!mounting) {
                    dispatch(setPlateMounting(selectedMaterial.mountingTypes[0].id));
                }
                setColors(selectedMaterial.colors);
            }
        }

    }, [plate.selectedMaterial]);

    useEffect(() => {
        if (!!plate.selectedColor) {
            const selectedColor = colors.find(item => item.id === plate.selectedColor);
            if (!!selectedColor) {
                const selectedModel = selectedColor.models.find(item => item.modelId === plate.selectedModel);
                setModel(selectedModel);
            }
        }
    }, [plate.selectedColor]);

    const setDefaultTemplateValue = (): void => {
        dispatch(setPlateChangesName({
            html: language === "ru-RU" ? selectedProfiles[0].profileFullName : selectedProfiles[0].profileFullNameEn,
            text: language === "ru-RU" ? selectedProfiles[0].profileFullName : selectedProfiles[0].profileFullNameEn
        }));
        dispatch(setPlateChangesNameOriginal(language === "ru-RU" ? selectedProfiles[0].profileFullName : selectedProfiles[0].profileFullNameEn));

        dispatch(setPlateChangesDescription({
            html: t("qrCode.defaultDescription"),
            text: t("qrCode.defaultDescription")
        }));
        dispatch(setPlateChangesDescriptionOriginal(t("qrCode.defaultDescription")));

        const date = `${getDate(selectedProfiles[0].dateOfBirth)} - ${getDate(selectedProfiles[0].dateOfDeath)}`
        dispatch(setPlateChangesDate({
            html: date,
            text: date
        }));

        if (!!selectedProfiles[0].avatarUrl) {
            dispatch(setPlateChangesImage(url + selectedProfiles[0].avatarUrl));
        } else {
            dispatch(setPlateChangesImage(""));
        }
    }

    const hideTooltip = (): void => {
        setTimeout(() => {
            setIsShowEditTooltip(false);
        }, 5000)
    }

    const getDate = (date: string) => {
        if (date) {
            return dayjs(date).format("DD.MM.YYYY");
        }
        return t("common.unknown");
    };

    const backClick = () => {
        dispatch(setPlateWizard(false));
    }

    const onMaterialClick = (id: string) => {
        dispatch(setPlateMaterial(id));
    };

    const onMountingClick = (id: string) => {
        dispatch(setPlateMounting(id));
    }

    const onColorClick = (id: string) => {
        dispatch(setPlateColor(id));
    }

    const addCountPlate = (): void => {
        if (plate.count < 20) {
            dispatch(setPlateCount(plate.count + 1));
        }
    }

    const removeCountPlate = (): void => {
        if (plate.count > 1) {
            dispatch(setPlateCount(plate.count - 1));
        }
    }

    const onPaymentClick = (token: string, paymentMethod: string, orderId: string, isDefault?: boolean, mandate?: Mandate) => {
        setIsShowQrCodeRequest(false);
        onClose(token, paymentMethod, orderId, isDefault, mandate);
    };

    const getPrice = (): string => {
        if (!!model) {
            const mounting = mountings.find(item => item.id === plate.selectedMounting)
            return ((model.cost + (mounting?.cost || 0)) * selectedProfiles.length * plate.count).toFixed(2);
        } else {
            return '0';
        }
    }

    const getMinMaterialPrice = (material: Material): number => {
        const prices: Array<number> = [];
        material.colors.forEach(color => {
            color.models.forEach(model => prices.push(model.cost))
        });
        return Math.min(...prices)
    }

    const orderClick = async () => {
        if (blockForPlate.current) {
            const blockPlate = blockForPlate.current;
            const parentBlock = blockPlate.parentNode as HTMLDivElement;
            const parentTransform = parentBlock.style.transform;
            parentBlock.style.transform = "unset";
            domToPng(blockPlate, { quality: 1 }).then((plateFileResult) => {
                setPlateFile(plateFileResult);
                setIsShowQrCodeRequest(true);
                parentBlock.style.transform = parentTransform;
            }, (error) => {
                console.error(error);
            });

            if (blockForImage.current) {
                const blockImage = blockForImage.current;
                domToBlob(blockImage, { quality: 1 }).then((imageFileResult) => {
                    setAvatarFile(imageFileResult);
                }, (error) => {
                    console.error(error);
                });
            }
        }
    }

    const editClick = (): void => {
        setIdEdit(!isEdit);
    }

    const hideEditButton = (): boolean => {
        return model?.aliasName === QrCodeModelAliasName.ONE_TWO;
    }

    const isBlack = (): boolean => {
        const selectedColor = colors.find(item => item.id === plate.selectedColor);
        return selectedColor?.name === "Black";
    }

    const getPlateDropdownData = (): DropdownData[] => {
        const result: Array<DropdownData> = [];
        for (let i = 1; i <= 20; i++) {
            result.push({id: i, name: i.toString()});
        }
        return result;
    }

    const changeCountMobile = (value: number): void => {
        dispatch(setPlateCount(value));
    }

    return (
      <div className={styles.mainContainer}>
          <div className={styles.modalWindowHeaderContainer}>
              <ModalWindowHeader title={isEdit ? t("common.edit") : t("qrCode.placingAnOrder")} onClick={backClick}/>
              <div className={styles.editHeaderButton} onClick={editClick}>
                  <EditIcon />
              </div>
          </div>
          <div className={styles.back} onClick={backClick}>{t("common.back")}</div>
          <div className={styles.container}>
              <div className={styles.wizardContainer}>
                  <div className={styles.title}>
                      {isEdit ? t("common.edit") : t("qrCode.placingAnOrder")}
                  </div>
                  <QrCodeWizardView isBlack={isBlack()}
                                    isEdit={isEdit}
                                    model={model}
                                    qr={qr}
                                    blockForPlate={blockForPlate}
                                    blockForImage={blockForImage}/>
                  {!hideEditButton() && (
                    <div className={styles.wizardButton}>
                        <button className={styles.editButton} onClick={editClick}>
                            {isEdit ? t("common.save") : t("common.edit")}
                        </button>
                        {isShowEditTooltip && (
                          <div className={styles.tooltip}>
                              {t("qrCode.editTooltip")}
                          </div>
                        )}
                    </div>
                  )}
              </div>
              <div className={styles.optionsContainer}>
                  <div className={styles.title}>
                      {t("qrCode.additionally")}
                  </div>
                  <div className={styles.additionallyContainer}>
                      <div className={styles.subTitle}>{t("qrCode.material")}</div>
                      <div className={styles.additionallyGridContainer}>
                          <div className={styles.additionallyGrid}>
                              {plateWizard?.materials?.map((item: Material) => {
                                  return (
                                    <QrCodeItem
                                      key={item.id}
                                      selected={plate.selectedMaterial === item.id}
                                      description={t(`qrCode.materials.${item.name}`)}
                                      type="material"
                                      onClick={onMaterialClick}
                                      id={item.id}
                                      img={item.mediaUrl}
                                      price={getMinMaterialPrice(item)}
                                      currency="€"
                                    />
                                  );
                              })}
                          </div>
                      </div>
                      <div className={styles.subTitle}>{t("qrCode.mounting")}</div>
                      <div className={styles.additionallyGridContainer}>
                          <div className={`${styles.additionallyGrid} ${styles.mounting}`}>
                              {
                                  mountings.map(mounting => (
                                    <QrCodeItem
                                      key={mounting.id}
                                      selected={plate.selectedMounting === mounting.id}
                                      description={t(`qrCode.glues.${mounting.name}`)}
                                      type="mounting"
                                      onClick={onMountingClick}
                                      id={mounting.id}
                                      price={mounting.cost}
                                      currency="€"
                                    />
                                  ))
                              }
                          </div>
                      </div>

                      {colors.length > 1 && (<>
                          <div className={styles.subTitle}>{t("qrCode.color")}</div>
                          <div className={styles.additionallyGridContainer}>
                              <div className={`${styles.additionallyGrid} ${styles.mounting}`}>
                                  {colors.map(color => (
                                    <QrCodeItem
                                      key={color.id}
                                      selected={plate.selectedColor === color.id}
                                      description={t(`qrCode.colors.${color.name}`)}
                                      type="mounting"
                                      onClick={onColorClick}
                                      id={color.id}
                                    />
                                  ))}
                              </div>
                          </div>
                      </>)}
                      <div className={styles.mobileCountContainer}>
                          <div className={styles.description}>
                              {t("qrCode.count")}:
                          </div>
                          <div className={styles.dropdown}>
                              <Dropdown
                                isTop
                                data={getPlateDropdownData()}
                                value={plate.count}
                                onChange={changeCountMobile}
                                type="date"
                                height="200px"
                              />
                          </div>
                      </div>
                      <div className={styles.countContainer}>
                          <div className={styles.description}>
                              {t("qrCode.count")}:
                          </div>
                          <div className={styles.value}>
                              {plate.count}
                          </div>
                          <div className={styles.buttons}>
                              <div className={styles.customBut} onClick={addCountPlate}>+</div>
                              <div className={styles.line}></div>
                              <div className={styles.customBut} onClick={removeCountPlate}>-</div>
                          </div>
                      </div>
                  </div>
                  <div className={styles.bottomContainer}>
                      <div className={styles.price}>{getPrice()} €</div>
                      <div className={`${styles.buttonContainer} ${hideEditButton() ? styles.hide : ""}`}>
                          <button className={styles.editButton} onClick={editClick}>
                              {isEdit ? t("common.save") : t("common.edit")}
                          </button>
                          <button className={styles.orderButton}
                                  disabled={isEdit}
                                  onClick={orderClick}>
                              {t("qrCode.payment.checkout")}
                          </button>
                          {!hideEditButton() && isShowEditTooltip && (
                            <div className={styles.tooltip}>
                                {t("qrCode.editTooltip")}
                            </div>
                          )}
                      </div>
                  </div>
              </div>
          </div>
          {isShowQrCodeRequest && (
            <ModalWindow
              onClose={() => setIsShowQrCodeRequest(false)}
              isShow={isShowQrCodeRequest}
              headerText={t("qrCode.payment.checkout")}
              children={
                  <QrCodeRequest
                    onClose={onPaymentClick}
                    price={getPrice()}
                    plateFile={plateFile}
                    avatarFile={avatarFile}/>
              }
            ></ModalWindow>
          )}
      </div>
    );
};
