import { useTranslation } from "react-i18next";
import styles from "./Gallery.module.scss";
import { useEffect, useState } from "react";
import { ImageViewer } from "../../imageViewer/ImageViewer";
import { ImageItem } from "../../../types/CommonTypes";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

type PropsType = {
  gallery: ImageItem[];
  deletePhoto: (photoId: string, filteredGallery: ImageItem[]) => void;
  isBurialPlaceGallery?: boolean;
  isShowDelete:boolean
};

export const Gallery = ({
  gallery,
  deletePhoto,
  isBurialPlaceGallery,
  isShowDelete
}: PropsType) => {
  const { t } = useTranslation();
  const [imgCount, setImgCount] = useState(5);
  const [imgWidth, setImgWidth] = useState(126);

  const [isShowGallery, setIsShowGallery] = useState(false);
  const [selectedImg, setSelectedImg] = useState<number>(0);
  const { photoHash } = useSelector((state: RootState) => state.profileSlice);

  const handleResize = () => {
    const gallery = document.getElementsByClassName(
      styles.galleryGrid
    ) as HTMLCollectionOf<HTMLElement>;
      if (gallery.length) {
        const firstChild = gallery[0]?.childNodes[0];
        const firstElementWidth = firstChild instanceof HTMLElement ? firstChild.offsetWidth : 0;
        setImgWidth(firstElementWidth + 6);
      }
   
    if (gallery[0]?.offsetWidth < 530) {
      setImgCount(3);
    } else if (gallery[0]?.offsetWidth  < 656) {
      setImgCount(4);
    } else {
      setImgCount(5);
    }
  };
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [gallery]);

  const onDelete = (value: number) => {
    const filteredGallery = gallery.filter((item, index) => index !== value);
    deletePhoto(gallery[value]?.id as string, filteredGallery);

    if (!filteredGallery[value]) {
      if (filteredGallery.length === 0) {
        setIsShowGallery(false);
      } else {
        setSelectedImg(value - 1);
      }
    } else {
      setSelectedImg(value);
    }
  };
  const onImageClick = (index: number) => {
    setSelectedImg(index);
    setIsShowGallery(true);
  };
  const onViewAllClick = (index?: number) => {
    setSelectedImg(index ? index : 0);
    setIsShowGallery(true);
  };
  
  return (
    <>
      {!isBurialPlaceGallery && (
        <div className={styles.headerBlock}>
          <h3 className={styles.header}> {t("profile.gallery")}</h3>
          <p onClick={() => onViewAllClick()}>{t("profile.viewAll")}</p>
        </div>
      )}
      <div className={styles.galleryGrid}>
        {gallery.slice(0, imgCount).map((item, index) => {

          return (
            <div className={item.description === 'video' ? styles.imgContainerVideo : styles.imgContainer} key={index}  onClick={() => onImageClick(index)}>
              <LazyLoadImage
                src={item.description === 'video' ? item.thumbnail :  `${item.thumbnail}?${photoHash}`}
                alt=""
                className={styles.galleryImage}
              />
            </div>
          );
        })}
        {gallery?.length > imgCount && (
          <span
            style={{ left: `calc(${imgWidth}px * ${imgCount - 1})` , width:`calc(${imgWidth}px - 6px`}}
            onClick={() => onViewAllClick(imgCount - 1)}
          >{`+${gallery?.length - imgCount}`}</span>
        )}
      </div>
      {isShowGallery && (
        <ImageViewer
          isShow={isShowGallery}
          data={gallery}
          isShowDelete={isShowDelete}
          onClose={() => setIsShowGallery(false)}
          onDelete={onDelete}
          startIndex={selectedImg}
        />
      )}
    </>
  );
};
