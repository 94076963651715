import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styles from "./Carousel.module.scss";
import backArrow from "../../shared/image/backArrow.svg";
import { useGetAllFramesQuery } from "../../api/ProfileService";
import useFetchWithMsal from "../../utils/useFetchWithMsal";

const initItems = [
  <div className={`${styles.carouselItem} `}></div>,
  <div className={`${styles.carouselItem} ${styles.main}`}></div>,
  <div className={`${styles.carouselItem}`}></div>,
];
type CustomCarouselProps = {
  setFrame: (value: string) => void;
  value?: string;
  isHidden:boolean
};
const CustomCarousel = ({ setFrame, value,isHidden }: CustomCarouselProps) => {
  const { skip } = useFetchWithMsal("profile", true);
  const { data } = useGetAllFramesQuery("", { skip });
  const [currentIndex, setCurrentIndex] = useState(1);
  const [items, setItems] = useState(initItems);

  useEffect(() => {
    if (value && data) {
      const selected = data.findIndex((item: {id:string, color:string}) => item.id === value);
      setCurrentIndex(selected);
    }
  }, [value, data]);

  useEffect(() => {
    if (data) {
      const frames = data?.map((item: any) => {
        return (
          <div
            className={`${styles.carouselItem}`}
            style={{ borderColor: item.color }}
          ></div>
        );
      });
      setItems(frames);
    }
  }, [data]);

  const handleNext = () => {
    setFrame(data?.[(currentIndex + 1) % items.length]?.id);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };
  const handlePrev = () => {
    setFrame(data?.[(currentIndex - 1 + items.length) % items.length]?.id);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
  };
  const handlers = useSwipeable({
    onSwipedLeft: handlePrev,
    onSwipedRight: handleNext,
    trackMouse: true,
  });

  const orderedItems = [
    items[(currentIndex - 1 + items.length) % items.length],
    items[currentIndex],
    items[(currentIndex + 1) % items.length],
  ];

  return (
    <div className={isHidden?styles.carouselWrapperHidden :styles.carouselWrapper} {...handlers}>
      <div className={styles.carouselContainer}>
        {orderedItems.map((item, index) => (
          <div
            key={index}
            className={`${
              index === 1
                ? styles.selectedItemContainer
                : styles.carouselItemContainer
            }`}
          >
            {item}
          </div>
        ))}
      </div>
      <button className={styles.prevButton} onClick={handlePrev}>
        <img src={backArrow} alt="" draggable={false} loading="lazy"/>
      </button>
      <button className={styles.nextButton} onClick={handleNext}>
        <img src={backArrow} alt="" draggable={false} loading="lazy"/>
      </button>
    </div>
  );
};

export default CustomCarousel;
