import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import useFetchWithMsal from '../../../utils/useFetchWithMsal';
import { useSendComplaintMutation } from '../../../api/ComplaintService';
import { ModalWindow } from '../../modalWindow/ModalWindow';
import { ComplainList } from './ComplainList';
import styles from './Complaints.module.scss';
import infoResultIcon from '../../../shared/image/info.svg'
import { ModalWithTextarea } from '../../../shared/components/ModalWithTextarea/ModalWithTextarea';
import { SwipeDownIndicator } from '../../../shared/components/SwipeDownIndicator/SwipeDownIndicator';
import { useDispatch } from 'react-redux';
import { setShowSpinner } from '../../../store/slices/SpinnerSlice';

type ComplaintsType = {
  onClose: () => void;
  entityType: string;
  entityId: string;
  textContent?: string;
  mediaContentUrls?: string[];
}

export const ComplainPopup = ({ onClose, entityType, entityId, textContent, mediaContentUrls }: ComplaintsType) => {
  const { t } = useTranslation();
  const { skipComplaint } = useFetchWithMsal("complaint",  true);
  const [ sendComplaint, {isSuccess} ] = useSendComplaintMutation();
  const profileUrl = window.location.href;
  const [complaintConfirmationModal, setComplaintConfirmationModal] = useState<boolean>(false)
  const [openTextareaForComplaint, setOpenTextareaForComplaint] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      setComplaintConfirmationModal(true)
      dispatch(setShowSpinner(false));

    }
  }, [isSuccess])

  const handleComplainClick = (complaintType: string) => {
    if (complaintType === 'Other') {
      setOpenTextareaForComplaint(true)
    } else {
      sendComplain(complaintType)
      dispatch(setShowSpinner(true));

    }
  };

  const onSubmitComplaintText = (text?: string) => {
    sendComplain('Other', text);
    setOpenTextareaForComplaint(false);
    dispatch(setShowSpinner(true));

  }

  const sendComplain = (complaintType: string, text?: string) => {
    if (!skipComplaint) {
      sendComplaint({
        body: {
          complaintType: complaintType,
          entityType: entityType,
          entityId: entityId,
          profileUrl: profileUrl ? profileUrl : "",
          additionalText: text ? text : "",
          complaintDetails: {
            textContent: textContent ? textContent : "",
            mediaContentUrls: mediaContentUrls ? mediaContentUrls : []
          }
        },
      })
    }
  };

  const onCloseConfirmationModal = () => {
    setComplaintConfirmationModal(false);
    onClose();
  }

  return (
    <>
      <ModalWindow
        onClose={onClose}
        isShow={true}
        headerText={t("complaints.complain")}
        isMobile
        children={
          <ComplainList
            handleComplainClick={handleComplainClick}
            onClose={onClose}
          />
        }
      ></ModalWindow>

      {complaintConfirmationModal && (
        <ModalWindow
          onClose={onCloseConfirmationModal}
          isShow={complaintConfirmationModal}
          headerText={t("complaints.complain")}
          isMobile
          isComplain
          children={
          <>
            <SwipeDownIndicator
              onClose={onClose}
            />
            <div className={styles.flex}>
              <div>
                <p className={styles.subHeaderConfirmation}>{t("complaints.sentComplaint")}</p>
                <p className={styles.descriptionConfirmation}>{t("complaints.thankForInforming")}</p>
              </div>
              <div>
                <img className={styles.infoIcon} src={infoResultIcon} alt={""}/>
                <button className={styles.buttonConfirmModal} onClick={onClose}>{t("common.close")}</button>
              </div>
            </div>
          </>
        }
        ></ModalWindow>
      )}
      {openTextareaForComplaint && (
        <ModalWithTextarea
          onClose={() => setOpenTextareaForComplaint(false)}
          isShow={openTextareaForComplaint}
          headerText={t("complaints.complain")}
          placeholder={t("complaints.textComplaint")}
          idTextarea="addComplaint"
          maxLength={2048}
          onSubmit={onSubmitComplaintText}
          submitButtonName={t("common.send")}
        />
      )}
    </>
  )
}