import styles from "./Input.module.scss";
import icon from "../../image/cleanUp.svg";

type InputProps = {
  setValue: (value: string) => void;
  value: string
  label?: string;
  placeholder?: string;
  error?: string;
  disabled?:boolean;
  maxlength?: number
};

export const InputWithButton = ({
  label,
  placeholder,
  error,
  setValue,
  value,
  disabled,
  maxlength
}: InputProps) => {

  const onMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setValue("");
  };
  return (
    <div className={styles.inputContainer}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.inputWithCleanUp}>
        <input
          className={`${styles.input}  ${error ? styles.error : error}`}
          type='text'
          placeholder={placeholder}
          autoComplete={"none"}
          value={value}
          disabled={disabled}
          maxLength={maxlength}
          onChange={(e)=>setValue(e.target?.value)}
          style={{ pointerEvents: disabled?'none' : 'inherit'}}
        />
        <span className={styles.icon} onMouseDown={onMouseDown}>
          <img src={icon} alt="" />
        </span>
      </div>

      {error && <p className={styles.errorMessage}> {error}</p>}
    </div>
  );
};
