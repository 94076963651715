import styles from "./Menu.module.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FavoriteIcon } from "../../shared/image/menu/favorite.svg";
import { ReactComponent as ChatIcon } from "../../shared/image/menu/chat.svg";
import { ReactComponent as UserIcon } from "../../shared/image/userIcon.svg";
import { ReactComponent as AddIcon } from "../../shared/image/addIcon.svg";
import { ReactComponent as HomeIcon } from "../../shared/image/menu/home.svg";
import { ReactComponent as FeelingIcon } from "../../shared/image/menu/feeling_web.svg";
import { ReactComponent as CandleIcon } from "../../shared/image/menu/candle.svg";
import { ReactComponent as QRIcon } from "../../shared/image/menu/qrcode.svg";
import { ReactComponent as MenuIcon } from "../../shared/image/menu/menu.svg";
import { ReactComponent as InfoIcon } from "../../shared/image/menu/InfoIcon.svg";
import { ReactComponent as CrossIcon } from "../../shared/image/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useState } from "react";
import { UnAuthenticatedPopup } from "../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";
import { useGetFreeCandleCountQuery } from "../../api/OrderService";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import useIsAuthenticated from "../../utils/useIsAuthenticated";
import { PopupBackground } from "../../shared/components/popupBackground/PopupBackground";
import { deleteState } from "../../store/slices/OrderSlice";
import { setActiveStep } from "../../store/slices/CreateProfileSlice";
import { ModalWindow } from "../modalWindow/ModalWindow";
import { ContactUsForm } from "../contactUsForm/ContactUsForm";
import { useTranslation } from "react-i18next";

export const Menu = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { language } = useSelector((state: RootState) => state.language);
  const { activeStep } = useSelector((state: RootState) => state.createProfile);

  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const [isShowContactUsForm, setIsShowContactUsForm] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { instance } = useMsal();
  const { isAuthenticated } = useIsAuthenticated();
  const [
    isShowNotAuthenticatedModalWindow,
    setIsShowNotAuthenticatedModalWindow,
  ] = useState<boolean>(false);

  const { skipOrder } = useFetchWithMsal("order", isAuthenticated);
  const { data } = useGetFreeCandleCountQuery("", {
    skip: skipOrder || !isAuthenticated,
  });

  const redirect = (type: string) => {
    if (!isAuthenticated) {
      if (type === "home") {
        navigate("/profiles");
      } else if (type === "/createProfile" || type === "/favoriteProfiles") {
        setIsShowNotAuthenticatedModalWindow(true);
      } else {
        loginRequest.extraQueryParameters = { ui_locales: language };
        instance
          .loginRedirect(loginRequest)
          .catch((error) => console.log(error));
      }
    } else {
      if (type === "home") {
        navigate("/");
      } else {
        navigate(type);
      }
    }
  };
  const onAddButtonClick = () => {
    if (activeStep === 6) {
      dispatch(setActiveStep(0));
    } else {
      redirect("/createProfile");
    }
  };
  return (
    <div
      className={`${styles.menuContainer} ${
        isShowContactUsForm ? styles.zIndex : ""
      }`}
    >
      <div className={styles.iconContainer}>
        <div
          onClick={() => redirect("home")}
          className={`${styles.home}`}
          draggable={false}
          title="Home"
        >
          <HomeIcon
            className={`${styles.menuIcon} ${styles.favoriteIcon} ${
              location.pathname === "/" || location.pathname === "/profiles"
                ? styles.selectedIcon
                : ""
            } `}
          />
        </div>
        <div
          onClick={() => redirect("/favoriteProfiles")}
          className={`${styles.favorite}`}
          draggable={false}
          title="Favorite"
        >
          <FavoriteIcon
            className={`${styles.menuIcon} ${styles.favoriteIcon}  ${
              location.pathname === "/favoriteProfiles"
                ? styles.selectedIcon
                : ""
            }`}
          />
        </div>
        <div
          onClick={() => redirect("/")}
          className={`${styles.chat}`}
          draggable={false}
        >
          <ChatIcon className={`${styles.menuIcon} ${styles.chatIcon}`} />
        </div>
        <div
          onClick={() => {
            dispatch(deleteState());
            navigate("/candles");
          }}
          className={`${
            location.pathname === "/candles"
              ? styles.candleSelected
              : styles.candle
          }`}
          draggable={false}
        >
          <CandleIcon
            className={`${styles.menuIcon} ${styles.candleIcon}  ${
              location.pathname === "/candles" ? styles.selectedIcon : ""
            }`}
          />
          <span className={styles.candleCount}>{!!data && `x ${data}`}</span>
        </div>
        <div
          onClick={() => {
            dispatch(deleteState());
            navigate("/feelings");
          }}
          className={`${styles.feeling}`}
          draggable={false}
        >
          <FeelingIcon
            className={`${styles.menuIcon} ${styles.feelingIcon}  ${
              location.pathname === "/feelings" ? styles.selectedIcon : ""
            }`}
          />
        </div>
        <div
          onClick={() => {
            dispatch(deleteState());
            navigate("/qrCodes");
          }}
          className={`${styles.qr}`}
          draggable={false}
        >
          <QRIcon
            className={`${styles.menuIcon} ${styles.qrIcon} ${
              location.pathname === "/qrCodes" ? styles.selectedIcon : ""
            }`}
          />
        </div>
        <div
          draggable={false}
          className={`${styles.qr}`}
          onClick={() => setIsShowContactUsForm(true)}
        >
          <InfoIcon
            className={`${styles.menuIcon} ${styles.infoIcon} ${
              isShowContactUsForm ? styles.selectedIcon : ""
            }`}
          />
        </div>
        <div className={styles.addButton} onClick={onAddButtonClick}>
          <div draggable={false}>
            <AddIcon />
          </div>
        </div>
        <div className={`${styles.menu}`} draggable={false}>
          {isShowMenu && (
            <>
              <PopupBackground onClose={() => setIsShowMenu(false)} />
              <div
                className={`${styles.mobileMenuContainer}`}
                draggable={false}
              >
                <div
                  onClick={() => navigate("/qrCodes")}
                  className={`${styles.qrMobile}`}
                  draggable={false}
                >
                  <QRIcon
                    className={`${styles.menuIcon} ${styles.qrIcon} ${
                      location.pathname === "/qrCodes"
                        ? styles.selectedIcon
                        : ""
                    }`}
                  />
                </div>
                <div
                  onClick={() => navigate("/feelings")}
                  className={`${styles.feelingMobile}`}
                  draggable={false}
                >
                  <FeelingIcon
                    className={`${styles.menuIcon} ${
                      styles.feelingMobileIcon
                    }  ${
                      location.pathname === "/feelings"
                        ? styles.selectedIcon
                        : ""
                    }`}
                  />
                </div>
                <div
                  onClick={() => redirect("/userProfile")}
                  className={`${styles.feelingMobile}`}
                  draggable={false}
                >
                  <UserIcon
                    className={`${styles.menuIcon} ${styles.userIcon} ${
                      location.pathname === "/userProfile"
                        ? styles.selectedIcon
                        : ""
                    } `}
                  />
                </div>
                <div
                  draggable={false}
                  onClick={() => setIsShowContactUsForm(true)}
                >
                  <InfoIcon
                    className={`${styles.menuIcon} ${styles.infoIcon} ${
                      isShowContactUsForm ? styles.selectedIcon : ""
                    }`}
                  />
                </div>
                <div
                  onClick={() => setIsShowMenu(false)}
                  className={`${styles.crossMobile}`}
                  draggable={false}
                >
                  <CrossIcon
                    className={`${styles.menuIcon} ${styles.crossMobileIcon}`}
                  />
                </div>
              </div>
            </>
          )}
          <MenuIcon
            className={`${styles.menuIcon} ${styles.menuIcon}`}
            onClick={() => setIsShowMenu(!isShowMenu)}
          />
        </div>
      </div>
      {isShowNotAuthenticatedModalWindow && (
        <UnAuthenticatedPopup
          onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
        ></UnAuthenticatedPopup>
      )}
      {isShowContactUsForm && (
        <ModalWindow
          onClose={() => setIsShowContactUsForm(false)}
          isShow={isShowContactUsForm}
          isContactUs
          headerText={t("common.contactUs")}
          children={<ContactUsForm onClose={() => setIsShowContactUsForm(false)}/>}
        ></ModalWindow>
      )}
    </div>
  );
};
