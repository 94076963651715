import styles from "./SettingListItem.module.scss";
import arrowIcon from "../../image/arrowIcon.svg";
import selectedIcon from "../../image/selected.svg";

type SettingListItemProps = {
  classNameType: string;
  isStatic?: boolean;
  icon?: string;
  text: string;
  caption?: string;
  placeholder?: string;
  description?: string;
  wide?: boolean;
  isHidden?: boolean;
  isLanguage?: boolean;
  onClick?: () => void;
  subText?: string;
  smallSize?: boolean;
  isDisabled?: boolean;
  classNameImg?: string;
  isSelected?: boolean
};

export const SettingListItem = ({
  classNameType,
  isStatic,
  onClick,
  icon,
  text,
  wide,
  isHidden,
  caption,
  placeholder,
  description,
  isLanguage,
  subText,
  smallSize,
  isDisabled,
  isSelected
}: SettingListItemProps) => {
  const getClassName = () => {
    switch (classNameType) {
      case "firstSelected":
        return styles.firstSelected;
      case "afterSelected":
        return styles.afterSelected;
      case "afterSelectedHidden":
        return styles.afterSelectedHidden;
      case "selected":
        return styles.selected;
      case "selectedSingle":
        return styles.selectedSingle;
      case "afterHidden":
        return styles.afterHidden;
      case "afterLanguage":
        return styles.afterLanguage;
      case "single":
        return styles.single;
      case "profileSingle":
        return styles.profileSingle;
      case "request":
        return styles.request;
      case "request-border":
        return `${styles.request} ${styles.withoutBorder}`;
      case "filterCountriesItems":
        return styles.filterCountriesItems;
      case "filterReligionItems":
        return styles.filterReligionItems;
      case "borderBottomNone":
        return styles.borderBottomNone;
      case "filterCountriesBorderTopNone":
        return styles.filterCountriesBorderTopNone;
      case "freeBg":
        return styles.freeBg;
      case "filterCities":
        return styles.filterCities;
      default:
        return "";
    }
  };
  const handleClick = () => {
    if (!isDisabled && onClick) {
      onClick();
    }
  };
  
  return (
    <li
      className={`
        ${styles.settingListItem} ${getClassName()}
        ${isHidden ? styles.hidden : isLanguage ? styles.language : ""}
        ${!isStatic && text.trim() && !subText && styles.fullContainer}
        ${((text && caption) || wide) && styles.wide}
        ${smallSize && styles.small}
        ${isDisabled ? styles.disabled : ""}
        ${isSelected ? styles.selected : ""}
      `}
      onClick={handleClick}
    >
      <div className={styles.imgContainer}>
        {icon && <img src={icon} alt="" />}
      </div>
      <div className={styles.textContainer}>
        <div>
          {text && caption && <p className={styles.caption}> {caption}</p>}
          <p className={text.trim() ? "" : styles.placeholder}>
            {" "}
            {text.trim() || placeholder || caption}
          </p>
        </div>
        {description && <p className={styles.description}> {description}</p>}
      </div>
      <div className={`${subText ? styles.subText : ""}`}>
        {subText && <span>{subText}</span>}
        <img src={isSelected ? selectedIcon : arrowIcon} alt="" />
      </div>
    </li>
  );
};
