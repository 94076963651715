import { MouseEvent, useEffect, useRef, useState } from "react";
import { PopupBackground } from "../popupBackground/PopupBackground";

import styles from "./Dropdown.module.scss";
import arrowIcon from "../../image/arrowIcon.svg";

type DropdownData = {
  id: number;
  name: string;
  code?: string;
};

type DropdownProps = {
  value: number | string;
  onChange: (value: any) => void;
  data: DropdownData[];
  type?: string;
  isError?: boolean;
  height?: string;
  placeholder?: string;
  smallSize?: boolean;
  isTop?: boolean;
};

export const Dropdown = ({
  value,
  onChange,
  data,
  type,
  isError,
  height,
  placeholder,
  smallSize,
  isTop
}: DropdownProps) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<number | string | null>(value);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownScrollContainerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleWheel = (event: any) => {
      const element = dropdownScrollContainerRef.current;
      if (element) {
        const { scrollTop, scrollHeight, clientHeight } = element;
        const atTop = scrollTop === 0;
        const atBottom = scrollTop + clientHeight + 2 > scrollHeight;

        if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
          event.preventDefault();
        }
      }
    };

    dropdownRef.current?.addEventListener("wheel", handleWheel);

    return () => {
      dropdownRef.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    scrollSelectedIntoView();
  }, [isShow]);

  const handleClickOutside = (e: Event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsShow(false);
    }
  };

  const scrollSelectedIntoView = () => {
    const selectedElement = dropdownRef?.current?.querySelector(
      `[data-selected="true"]`
    );
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth", block: "nearest",
        inline: "nearest"
      });
    }
  };

  const onOpen = () => {
    setIsShow(!isShow);
    setSelected(value)
  };
  const onClick = (id: number | string) => (e: MouseEvent<HTMLLIElement>) => {
    onChange(id);
    setIsShow(false);
  };
  const getValue = () => {
    const selectedItem = data?.find(item => {
      return type ? item.id === value : item.code === value;
    });
    return selectedItem?.name || "";
  };

  return (
    <>
      {isShow && <PopupBackground/>}
      <div
        className={`${getValue() && type !== 'candle' ? styles.dropdownContainerFilled : styles.dropdownContainer} ${
          type ? styles.separateDropDown : styles.phoneDropDown
        } ${isShow ? styles.showDropDown : ""} ${isError ? styles.error : ""} ${smallSize && styles.smallSize}`}
        ref={dropdownRef}
        onClick={onOpen}
        style={{ pointerEvents: isShow ? 'none' : 'auto' }}
      >
        <div className={styles.dropdownValueContainer}>
          <p className={styles.dropdownValue}> {getValue()}</p>
          {!getValue() && placeholder && <p className={styles.dropdownPlaceholder}> {placeholder}</p>}

        </div>

        <img src={arrowIcon} alt="" onClick={onOpen}/>
        {isShow && (
          <div style={{maxHeight: height}}
               className={`${styles.dropdownList} ${type ? styles.dropdownListWithoutButton : ""
               } ${!!isTop && styles.top || ""}`}
          >
            <ul
              ref={dropdownScrollContainerRef}
              style={{ pointerEvents: isShow ? 'auto' : 'none' }}>
              {data?.map((item) => {
                return (
                  <li
                    key={item.id}
                    data-selected={item.id === selected ? "true" : "false"}
                    className={`${
                      type ? styles.smallItem : styles.dropdownItem
                    } ${(item.id === selected) ? styles.selected : ""}`}
                    onClick={onClick(type ? item.id : item.code as string)}
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};