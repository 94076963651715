import {   useEffect, useState } from "react";
import styles from "./ProfilesSetting.module.scss";
import map from "../../../../shared/image/map.png";
import list from "../../../../shared/image/list.png";

import { ProfileCard } from "../../../../shared/components/profileCard/ProfileCard";
import { SearchInput } from "../../../../shared/components/search/SearchInput";
import Map from "../../../map/Map";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type ProfilesSettingProps = {
  header: string;
  data: any;
 listView:boolean
};

export const ProfilesSetting = ({ header, data,listView }: ProfilesSettingProps) => {
  const [profiles, setProfiles] = useState<Array<any>>(data);
  const [searchTerm, setSearchTerm] = useState("");
  const [isListView, setIsListView] = useState(listView);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setProfiles(data);
  }, [data]);
  useEffect(() => {
    setIsListView(listView);
  }, [listView]);

  const onChange = (term: string) => {
    setSearchTerm(term);

    const filtered = data.filter((profile: any) => {
      const fullName = `${profile.firstName || ''} ${profile.middleName || ''} ${profile.lastName || ''}`;
      const englishFullName = `${profile.inEnglish.firstName || ''} ${profile.inEnglish.middleName || ''} ${profile.inEnglish.lastName || ''}`;

      return (
        fullName?.toLowerCase().includes(term.toLowerCase()) ||
        englishFullName?.toLowerCase().includes(term.toLowerCase()) ||
        String(new Date(profile.dateOfBirth).getFullYear()).includes(term) ||
        String(new Date(profile.dateOfDeath).getFullYear()).includes(term)
      );
    });
    setProfiles(filtered);
  };

  return (
    <div className={styles.profilesSettingContainer}>
      <div className={styles.profilesSettingHeader}>
        <h3>{header}</h3>
      </div>
      <div className={styles.profilesSettingSearchContainer}>
      <SearchInput onChange={onChange} value={searchTerm} />

        <img className={styles.switchIcon} src={isListView? map : list} alt="" onClick={()=>setIsListView(!isListView)}/>
      </div>
      <div className={styles.profilesSettingBody}>
        {isListView? ( <div className={styles.profilesGrid}>
          {profiles?.map((item: any, index: number) => {
            return (
              <ProfileCard
              mainPictureId={item.mainPictureId}
              isCreatedByCurrentUser={item.isCreatedByCurrentUser}
              key={index}
                id={item.id}
                name={`${item.lastName || ""} ${item.firstName || ""} ${
                  item.middleName || ""
                }`}
                subName={`${item.inEnglish.lastName || ""} ${
                  item.inEnglish.firstName || ""
                } ${item.inEnglish.middleName || ""}`}
                dateOfBirth={item.dateOfBirth}
                dateOfDeath={item.dateOfDeath}
                country={item.burialPlace.countryId}
                img={item.avatarPreviewUrl}
                isFavorite={item.isFavorite}
              />
            );
          })}
        </div>) :(
          <div className={styles.mapContainer}> <Map  markers ={profiles}/></div>
         
        )}
         {!profiles?.length && isListView && (
              <div className={styles.emptyContainer}>
                <p className={styles.emptyPageText}>{t('userProfile.createdProfiles')} </p>
                <button className={styles.createProfileButton}
                        onClick={() => navigate('/createProfile')}>{t('profile.createProfile')}</button>
              </div>
            )}

          {profiles?.length > 0 && isListView && (
              <div className={styles.emptyContainer}>
                <button
                  className={styles.createProfileButton}
                  onClick={() => navigate("/createProfile")}
                >
                  {t('userProfile.editProfile.addProfile')}
                </button>
              </div>
            )}
            
       
      </div>
    </div>
  );
};
