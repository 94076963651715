import { useTranslation } from "react-i18next";
import styles from './PaymentMethods.module.scss';
import { SettingListItem } from '../../shared/components/settingListItem/SettingListItem';
import cardIcon from '../../shared/image/IconCard.svg'
import payPalIcon from '../../shared/image/IconPayPal.svg'
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import { useGetPaymentMethodsQuery } from "../../api/PaymentService";
import {
  useEffect,
  useState
} from "react";
import { Mandate } from "../../types/PaymentTypes";
import { generateRandomString } from '../../utils/generateRandomString';
import { setPaymentSessionId } from '../../store/slices/PaymentSessionSlice';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { RootState } from '../../store';
import { getCardImage } from "../../utils/paymentUtils";
import useIsAuthenticated from "../../utils/useIsAuthenticated";
import { setShowSpinner } from "../../store/slices/SpinnerSlice";

interface PaymentMethodsProps {
  onPayCreditCard: () => void;
  onPayPaypal: () => void;
  onPay: (mandate: Mandate) => void;
}

const PaymentMethods = ({onPayPaypal, onPayCreditCard, onPay}: PaymentMethodsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {isAuthenticated }=  useIsAuthenticated();
  const { skipPayment } = useFetchWithMsal("payment", isAuthenticated);
  const { candles } = useSelector((state: RootState) => state.orderSlice);
  const [selectedCard, setSelectedCard] = useState<Mandate | null>(null);
  const { data, isLoading: isLoadingGetMandate} = useGetPaymentMethodsQuery("",{ skip: skipPayment || !isAuthenticated });

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/json';
    script.setAttribute('fncls', 'fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99');
    const sessionId = generateRandomString();
    script.text = JSON.stringify({
      "s": "JPEGSQU6FGMYU_checkout-page",
      "f": sessionId
    });
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = 'https://c.paypal.com/da/r/fb.js';
    document.head.appendChild(script2);
    dispatch(setPaymentSessionId(sessionId))

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(script2);
    };
  }, []);

  useEffect(() => {
    if (!!data?.length) {
      const findItem = data.find(item => item.isDefault);
      if (findItem) {
        setSelectedCard(findItem);
      }
    }
  }, [data]);

  useEffect(() => {
    dispatch(setShowSpinner(isLoadingGetMandate));
  }, [isLoadingGetMandate]);

  const payClick = (): void => {
    if (!!selectedCard) {
      onPay(selectedCard);
    }
  }

  return (
    <>
      <ul className={styles.settingList}>
        <SettingListItem
          text={t("paymentMethods.card")}
          classNameType={"freeBg"}
          onClick={onPayCreditCard}
          icon={cardIcon}
        />
        {data?.map(mandate => {
          return (
            <SettingListItem
              key={mandate.id}
              classNameType={"freeBg"}
              isSelected={selectedCard?.id === mandate.id}
              text={`**** ${mandate.cardNumber}`}
              icon={getCardImage(mandate.cardLabel)}
              onClick={() => setSelectedCard(mandate)}
            />
          )
        })}
        {!candles?.selectedPrice?.isSubscription && (
          <SettingListItem
            text={t("paymentMethods.paypal")}
            classNameType={"freeBg"}
            onClick={onPayPaypal}
            icon={payPalIcon}
          />
        )}
      </ul>
      {!!data?.length && !!selectedCard && (
        <div className={styles.buttons}>
          <button onClick={payClick}>
            {t("common.pay")}
          </button>
        </div>
      )}
    </>
  );
};

export default PaymentMethods;
