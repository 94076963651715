import styles from "./WizardThreeTwoView.module.scss";
import { QrCodeWizardViewProps } from "../QrCodeWizardView";
import { WizardNameBlock } from "../shared/wizardNameBlock/WizardNameBlock";
import { WizardDateBlock } from "../shared/wizardDateBlock/WizardDateBlock";
import { WizardImageBlock } from "../shared/wizardImageBlock/WizardImageBlock";
import { WizardQrBlock } from "../shared/wizardQrBlock/WizardQrBlock";
import { WizardDescriptionBlock } from "../shared/wizardDescriptionBlock/WizardDescriptionBlock";
import { ImageItem } from "../../../../../types/CommonTypes";
import { RefObject } from "react";

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

interface WizardThreeTwoProps extends QrCodeWizardViewProps {
  setImageData: (items: ImageItem[], showSelectButton?: boolean) => void;
}

export const WizardThreeTwoView = ({ model, isBlack, qr, blockForImage, blockForPlate, isEdit, setImageData }: WizardThreeTwoProps) => {

  const getTemplateUrl = (): string => {
    return !!model?.templateUrl ? url + model.templateUrl : "";
  }

  return (
    <div className={styles.container}
         ref={blockForPlate}>
      <div className={styles.background} style={{backgroundImage: `url(${getTemplateUrl()})`}}></div>
      <div className={styles.leftContainer}>
        <WizardImageBlock blockForImage={blockForImage as RefObject<HTMLDivElement>} setImageData={setImageData} isEdit={isEdit} borderRadius={'0 0 22px 0'} width={263} height={284}/>
        <WizardDescriptionBlock isBlack={isBlack} isEdit={isEdit} height={"calc(100% - 284px)"}/>
      </div>
      <div className={styles.rightContainer}>
        <WizardNameBlock isBlack={isBlack} isEdit={isEdit}/>
        <div className={styles.marginNameDate}></div>
        <WizardDateBlock isBlack={isBlack} isEdit={isEdit}/>
        <div className={styles.marginDateQr}></div>
        <WizardQrBlock isBlack={isBlack} widthAndHeight={"137px"} qr={qr}/>
      </div>
    </div>
  );
};
