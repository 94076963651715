import { LandingPage } from "../landing/LandingPage";
import { ProfilesPage } from "../profilesPage/ProfilesPage";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

export const HomePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const isEditPhoneNumder = localStorage.getItem("isEditPhoneNumder");
    if (isEditPhoneNumder) {
      localStorage.setItem("isEditPhoneNumder", "");
      navigate("/userProfile");
    }
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <ProfilesPage type="search" />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LandingPage />
      </UnauthenticatedTemplate>
    </>
  );
};
