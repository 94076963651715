import styles from "./PaymentStatusBody.module.scss";
import { useTranslation } from "react-i18next";

type PropsType = {
    status: string;
    onClick: ()=> void;
    type?: string;
}

export const PaymentStatusBody = ({ status, onClick, type }: PropsType) => {
  const { t } = useTranslation();
  return(
      <div className={`${styles.paymentStatusContainer} ${type === 'card' ? styles.card : ""}`}>
          <h3 className={styles.header}>{status !== 'paid' ? t("payment.checkCard") : t("payment.successfulPayment")}</h3>
          <p className={styles.description}>
              {status !== 'paid' ? t("payment.errorDescription") : (t("payment.successfulPaymentDescription") + ' ' + t("payment.checkEmail"))}
          </p>
          {
              status === 'paid' && type === 'plate' && (
                  <p className={styles.description}>
                      {t("qrCode.payment.successfulPaymentDescription")}
                  </p>
              )
          }

          <div className={styles.spin}>
              <div className={styles.backgroundImage}></div>
          </div>
          <button className={styles.button} onClick={onClick}>
            { type === 'card' ? t("common.ok") : t("common.close")}
          </button>
      </div>
  )
};
