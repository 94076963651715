import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainInfo: {
    firstName: "",
    lastName: "",
    middleName: "",
    gender: 1,
    dateOfBirth: "",
    dateOfDeath: "",
    religionId: null,
    countryId: "",
    isFavorite: true,
    isUserContactForProfile: true,
    deathReason: "",
    burialPlace: {
      cityId: "",
      countryId: "",
      longitude: 0,
      latitude: 0,
    },
    inEnglish: {
      firstName: "",
      lastName: "",
      middleName: "",
    },
  },
  burialPhoto: '',
  isUnknownDateOfDeath:false,
  isUnknownDateOfBirth:false,
  isUnknownBurialPlace:false,
  activeStep: 0
};

const createProfileSlice = createSlice({
  name: "createProfileSlice",
  initialState,
  reducers: {
    setNameData: (state, action) => {
      const { firstName, lastName, middleName, gender } = action.payload;
      state.mainInfo.firstName = firstName;
      state.mainInfo.lastName = lastName;
      state.mainInfo.middleName = middleName;
      state.mainInfo.gender = gender;
    },
    setEnglishNameData: (state, action) => {
      state.mainInfo.inEnglish = action.payload;
    },
    setReligion: (state, action) => {
      state.mainInfo.religionId = action.payload;
    },
    setLocation: (state, action) => {
      state.mainInfo.burialPlace = action.payload?.marker?.burialPlace;
      state.mainInfo.countryId = action.payload?.marker?.burialPlace?.countryId;
      state.isUnknownBurialPlace=action.payload?.isUnknownBurialPlace

    },
    setDate: (state, action) => {
      state.mainInfo.dateOfBirth = action.payload.birthDate;
      state.mainInfo.dateOfDeath = action.payload.deathDate;
      state.mainInfo.deathReason = action.payload.deathReason;
      state.isUnknownDateOfBirth =action.payload.isUnknownDateOfBirth
      state.isUnknownDateOfDeath =action.payload.isUnknownDateOfDeath

    },
    deleteState: (state) => {
      return initialState;
    },
    setIsUserContactForProfile: (state) => {
      state.mainInfo.isUserContactForProfile = !state.mainInfo.isUserContactForProfile;
    },
    setBurialPhoto: (state, action) => {
      state.burialPhoto =  action.payload
    },
    setActiveStep: (state, action) => {
      state.activeStep =  action.payload
    },
  },
});

export const {
  setNameData,
  setEnglishNameData,
  setReligion,
  setDate,
  deleteState,
  setLocation,
  setIsUserContactForProfile,
  setBurialPhoto,
  setActiveStep
} = createProfileSlice.actions;
export default createProfileSlice.reducer;
