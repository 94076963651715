import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./OrderHistory.module.scss";
import { MemorialTypes } from "../../../constants/Memorial.constant";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setCheckedTab } from "../../../store/slices/OrderHistorySlice";
import { MemorialHistoryTab } from "./memorialHistoryTab/MemorialHistoryTab";
import { PlatesHistoryTab } from "./platesHistoryTab/PlatesHistoryTab";


export const OrderHistory = React.memo(() => {
  const { checkedTab, candlesData, platesData } = useSelector(
    (state: RootState) => state.orderHistorySlice
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tabs = [
    MemorialTypes.candles,
    MemorialTypes.qrCode,
  ];

  useEffect(() => {
    dispatch(setCheckedTab(MemorialTypes.candles));
  }, []);
  
  const renderTabs = (tabs: string[], checkedButton: any) => {
    return tabs.map((tab: string, index: number) => {
      return (
        <button
          key={index}
          className={`${styles.tab} ${
            checkedButton === tab ? styles.activeTab : ""
          } `}
          onClick={() => dispatch(setCheckedTab(tab))}
        >
          {t(`memorial.${tab}`)}
        </button>
      );
    });
  };

  return (
    <div className={styles.orderHistoryContainer}>
      <div className={styles.orderHistoryHeader}>
        <h3>{t("userProfile.orderHistory.header")}</h3>
      </div>
      <div className={styles.orderHistoryTabs}>
        {renderTabs(tabs, checkedTab)}
      </div>
      <div className={styles.tabContent}>
        {checkedTab === MemorialTypes.candles ? (
          <MemorialHistoryTab data={candlesData} />
        ) : (
          <PlatesHistoryTab data={platesData} />
        )}
      </div>
    </div>
  );
});
