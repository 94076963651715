import { useTranslation } from "react-i18next";
import { ModalWindow } from "../modalWindow/ModalWindow";
import styles from "./SharePopup.module.scss";
import { SettingListItem } from "../../shared/components/settingListItem/SettingListItem";
import messages from "../../shared/image/share/messages.svg";
import facebook from "../../shared/image/share/facebook.svg";
import whatsApp from "../../shared/image/share/whatsApp.svg";
import email from "../../shared/image/share/email.svg";
import link from "../../shared/image/share/link.svg";
import { ClipboardNotification } from "../../shared/components/clipboardNotification/ClipboardNotification";
import { useState } from "react";

type SharePopupType = {
  onClose: () => void;
  id: string;
  isProfile?: boolean;
};

export const SharePopup = ({ onClose, id, isProfile }: SharePopupType) => {
  const { t } = useTranslation();
  const shareList = t("share.shareList", { returnObjects: true }) as string[];
  const imgList = [messages, facebook, whatsApp, email, link];
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleComplainClick = (type: string) => {
    const profileUrl = window.location.href.split("?")[0];
    const url = isProfile ? `${profileUrl}` : `${profileUrl}?postId=${id}`;

    switch (type) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url
          )}`,
          "_blank"
        );
        break;
      case "whatsApp":
        window.open(
          `https://web.whatsapp.com/send?text=${encodeURIComponent(url)}`,
          "_blank"
        );
        break;
      case "email":
        window.open(`mailto:?body=${encodeURIComponent(url)}`, "_blank");
        break;
      case "link":
        handleCopyClick(url)
        break;
      default:
        break;
    }
    type !=="link" && onClose();
  };

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (url:string) => {
    copyTextToClipboard(url)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
          onClose()
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <ModalWindow
        onClose={onClose}
        isShow={true}
        headerText={t("share.shareHeader")}
        isShowClipboard={isCopied}
        children={
          <div>
            <ul className={styles.settingList}>
              {Object.entries(shareList).map(
                ([complaintType, value], index) => {
                  if (index) {
                    return (
                      <SettingListItem
                        key={index}
                        isStatic
                        classNameType=""
                        text={value}
                        onClick={() => handleComplainClick(complaintType)}
                        icon={imgList[index]}
                      />
                    );
                  }
                  return <></>;
                }
              )}
            </ul>
            <button className={styles.closeButton} onClick={onClose}>
              {t("common.close")}
            </button>
    
          </div>
        }
      ></ModalWindow>

    </>
  );
};
