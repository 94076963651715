import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LocationDataType } from '../../../../types/CommonTypes';

import styles from './PopupLocation.module.scss';
import { PopupBackground } from '../../../../shared/components/popupBackground/PopupBackground';

type PopupProps = {
  data: LocationDataType[];
  onClose: () => void;
  onClick: (item: LocationDataType) => void;
  type?: string;
  value?: string;
  handleScroll?: (e: any) => void;
};

export const PopupLocation = ({ data, onClose, onClick, type, value, handleScroll }: PopupProps) => {
  const popupRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleWheel = (event:WheelEvent) => {
      const element = popupRef.current;
      if (element) {
        const { scrollTop, scrollHeight, clientHeight } = element;
        const atTop = scrollTop === 0;
        const atBottom = scrollTop + clientHeight + 2 > scrollHeight;

        if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
          event.preventDefault();
        }
      }
    };

    popupRef.current?.addEventListener("wheel", handleWheel);

    return () => {
      popupRef.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);
  const getClassName = () => {
    switch (type) {
      case 'searchCity':
      case "search":
        return styles.searchPopup;
      default:
        return styles.popupContainer;
    }
  };

  return (
    <>
      <PopupBackground onClose={ onClose}/>
      <div className={getClassName()}>
        <ul ref={popupRef} className={`${styles.popupList}`} onScroll={handleScroll} >
          {data?.map((item: LocationDataType, index) => {
            return (
              <li
                key={index}
                className={`${styles.listItem} ${styles.popupItem} ${item.id === value ? styles.selected : ""}`}
                onClick={() => onClick(item)}
              >
                { type === 'searchCity' && item.region?.name ? `${item.name}, ${item.region?.name}`: item.name}
              </li>
            );
          })}
        </ul>
        <button className={styles.closeButton} onClick={() => onClose()}>
          {t("common.close")}
        </button>
      </div>
    </>
  );
};
