import styles from "./ProfileMenu.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as FavoriteIcon } from "../../../shared/image/favoriteFilled.svg";
import { ReactComponent as InfoIcon } from "../../../shared/image/infoProfile.svg";
import { ReactComponent as ShareIcon } from "../../../shared/image/postsIcon/shareIcon.svg";

import arrow from "../../../shared/image/backArrow.svg";

import { useEffect, useState, MouseEventHandler } from "react";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import {
  useDeleteFavoriteProfileMutation,
  useDeleteProfileMutation,
  useGetProfileQuery,
  usePutFavoriteProfileMutation,
} from "../../../api/ProfileService";
import { useNavigate } from "react-router-dom";
import { Popup } from "../../../shared/components/popup/Popup";
import { ReactComponent as QRIcon } from "../../../shared/image/menu/qrcode.svg";
import { QRPopup } from "../QRPopup/QRPopup";
import { ModalWindow } from "../../modalWindow/ModalWindow";
import { QRModalWindow } from "../QRModalWindow/QRModalWindow";
import { PopupData } from "../../../types/CommonTypes";
import { DeleteProfilePopup } from "../deleteProfilePopup/DeleteProfilePopup";
import { UnAuthenticatedPopup } from "../../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";
import { QrCodeTab } from "../../qrCode/qrCodeTab/QrCodeTab";
import { deleteState, setPlateProfile } from "../../../store/slices/OrderSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { ComplainPopup } from "../../userProfile/complaints/ComplainPopup";
import useIsAuthenticated from "../../../utils/useIsAuthenticated";
import { SharePopup } from "../../share/SharePopup";
import { getLanguageByLocale } from "../../../utils/localeUtils";

type PropsType = {
  id: string;
};

export const ProfileMenu = ({ id }: PropsType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated } = useIsAuthenticated();

  const { skip } = useFetchWithMsal("profile", isAuthenticated);
  const { language } = useSelector((state: RootState) => state.language);

  const { data } = useGetProfileQuery({id, lang:getLanguageByLocale(language)}, { skip });
  const [isFavoriteProfile, setIsFavoriteProfile] = useState<boolean>(
    data?.isFavorite
  );
  const [deleteFavorite, { isLoading }] = useDeleteFavoriteProfileMutation();
  const [deleteProfile] = useDeleteProfileMutation();

  const [putFavorite, { isLoading: isPutLoading }] =
    usePutFavoriteProfileMutation();
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowQRCodePopup, setIsShowQRCodePopup] = useState<boolean>(false);
  const [modalWindow, setModalWindow] = useState<string>("");
  const [isShowDeleteModalWindow, setIsShowDeleteModalWindow] =
    useState<boolean>(false);
  const [
    isShowNotAuthenticatedModalWindow,
    setIsShowNotAuthenticatedModalWindow,
  ] = useState<boolean>(false);
  const [isShowOrderPlatePopup, setIsShowOrderPlatePopup] =
    useState<boolean>(false);
  const [isShowComplainPopup, setIsShowComplainPopup] =
    useState<boolean>(false);
  const [isShowSharePopup, setIsShowSharePopup] = useState<boolean>(false);

  const popupData = t(
    isAuthenticated && data?.isCreatedByCurrentUser
      ? "profile.ownerMenuPopup"
      : "profile.menuPopup",
    {
      returnObjects: true,
    }
  ) as PopupData[];

  useEffect(() => {
    setIsFavoriteProfile(data?.isFavorite);
  }, [data?.isFavorite]);

  const onPopupClick = (type: string) => {
    switch (type) {
      case "edit":
        navigate(`/editProfile/${id}`);
        break;
      case "delete":
        setIsShowDeleteModalWindow(true);
        break;
      case "complaint":
        setIsShowComplainPopup(true);
        break;
      default:
        break;
    }
    setIsShowPopup(false)
  };
  const onDeleteProfileClick = () => {
    deleteProfile(id);
    navigate("/");
  };
  const onQRPopupClick = (type: string) => {
    switch (type) {
      case "download":
      case "print":
        setIsShowQRCodePopup(false);
        setModalWindow("true");
        break;
      case "order":
        setIsShowQRCodePopup(false);
        dispatch(
          setPlateProfile({
            profileId: data.id,
            profileFullName: `${data.lastName || ""} ${data.firstName || ""} ${
              data.middleName || ""
            }`,
            profileFullNameEn: `${data.inEnglish.lastName || ""} ${
              data.inEnglish.firstName || ""
            } ${data.inEnglish.middleName || ""}`,
            dateOfBirth: data.dateOfBirth,
            dateOfDeath: data.dateOfDeath,
            avatarUrl: data.avatarUrl,
          })
        );
        setIsShowOrderPlatePopup(true);
        break;
      default:
        break;
    }
  };
  const onPopupClose = () => {
    setIsShowPopup(false);
  };
  const onClick = () => {
    if (isAuthenticated) {
      if (!isPutLoading && !isLoading) {
        if (data?.isFavorite) {
          deleteFavorite(id);
        } else {
          putFavorite(id);
        }
        setIsFavoriteProfile(!isFavoriteProfile);
      }
    } else {
      setIsShowNotAuthenticatedModalWindow(true);
    }
  };
  const onBackClick = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };
  const onInfoClick: MouseEventHandler<SVGSVGElement> = (event) => {
    if (!isAuthenticated) {
      setIsShowNotAuthenticatedModalWindow(true);
    } else {
      event.stopPropagation();
      setIsShowPopup(!isShowPopup);
    }
  };
  const onQRClick: MouseEventHandler<SVGSVGElement> = (event) => {
    event.stopPropagation();
    setIsShowQRCodePopup(!isShowQRCodePopup);
  };

  const onCloseCandleWindow = () => {
    dispatch(deleteState());
    setIsShowOrderPlatePopup(false);
  };
  const onShareClick = () => {
    const isMobile =
      /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
        navigator.userAgent
      );
    const isPWA = "standalone" in window.navigator;
    const profileUrl = window.location.href;
    const url = `${profileUrl}?postId=${data.id}`;
    if (isMobile && isPWA && navigator && navigator.canShare({ text: url })) {
      navigator.share({ text: url });
    } else {
      setIsShowSharePopup(true);
    }
  };

  return (
    <div className={styles.profilePageButtons}>
      <div>
        <div
          // onClick={() => redirect("/")}
          className={isShowQRCodePopup ? styles.qrCodeSelected : styles.qrCode}
          draggable={false}
        >
          <div className={styles.qrCodeIconcontainer}>
            <QRIcon className={styles.qrCodeIcon} onClick={onQRClick} />
          </div>

          {isShowQRCodePopup && (
            <QRPopup
              onClose={() => setIsShowQRCodePopup(false)}
              onClick={(type) => onQRPopupClick(type)}
            ></QRPopup>
          )}
        </div>
      </div>
      <div className={styles.iconContainer}>
        <img
          src={arrow}
          alt=""
          onClick={onBackClick}
          className={styles.backArrow}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.iconContainer}>
          <FavoriteIcon
            onClick={onClick}
            className={`${
              isFavoriteProfile ? styles.favoriteIcon : styles.menuFavoriteIcon
            } `}
          />
        </div>

        <div className={isShowPopup ? styles.infoIcon : styles.iconContainer}>
          <InfoIcon className={`${styles.menuIcon}  `} onClick={onInfoClick} />
        </div>
        <div className={styles.shareIconContainer} onClick={onShareClick}>
          <ShareIcon />
        </div>
        {isShowPopup && (
          <Popup
            data={popupData}
            onClose={onPopupClose}
            onClick={onPopupClick}
            type={"profileMenu"}
          ></Popup>
        )}
        {!!modalWindow && (
          <ModalWindow
            onClose={() => setModalWindow("")}
            isShow={!!modalWindow}
            headerText={t("profile.print")}
            children={
              <QRModalWindow onClose={() => setModalWindow("")} id={id} />
            }
          ></ModalWindow>
        )}
        {isShowDeleteModalWindow && (
          <DeleteProfilePopup
            onClose={() => setIsShowDeleteModalWindow(false)}
            isShow={isShowDeleteModalWindow}
            onApprove={onDeleteProfileClick}
          ></DeleteProfilePopup>
        )}
        {isShowNotAuthenticatedModalWindow && (
          <UnAuthenticatedPopup
            onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
          ></UnAuthenticatedPopup>
        )}
        {isShowOrderPlatePopup && (
          <ModalWindow
            onClose={onCloseCandleWindow}
            isShow={isShowOrderPlatePopup}
            headerText={t("qrCode.title")}
            children={<QrCodeTab isModalWindow />}
            isOrder
          ></ModalWindow>
        )}
        {isShowComplainPopup && (
          <ComplainPopup
            onClose={() => setIsShowComplainPopup(false)}
            entityType={"Profile"}
            entityId={id}
          />
        )}
        {isShowSharePopup && (
          <SharePopup
            onClose={() => setIsShowSharePopup(false)}
            id={data.id}
            isProfile
          />
        )}
      </div>
    </div>
  );
};
