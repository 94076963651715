import { useTranslation } from "react-i18next";
import styles from "./AddPostField.module.scss";
import imgIcon from "../../../../../shared/image/imgIcon.png";
import arrowIcon from "../../../../../shared/image/postsIcon/arrowIcon.png";
import video from "../../../../../shared/image/video.png";
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { ErrorImages, ImageItem } from "../../../../../types/CommonTypes";
import PhotoForGallery from "../../../../../shared/components/photoForGallery/PhotoForGallery";
import ImageDrop from "../../../../dropArea/DropArea";
import { useAddPostMutation } from "../../../../../api/PostProfileService";
import { useParams } from "react-router-dom";
import { useUploadPostMediaMutation } from "../../../../../api/MediaService";
import { PopupBackground } from "../../../../../shared/components/popupBackground/PopupBackground";
import { FileError } from "../../../../../shared/components/fileError/FileError";
import { ImageViewer } from "../../../../imageViewer/ImageViewer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { addProfilePost } from "../../../../../store/slices/ProfileSlice";
import { ModalWindow } from "../../../../modalWindow/ModalWindow";
import { SimpleTextarea } from "../../../../../shared/components/simpleTextarea/SimpleTextarea";
import { setShowSpinner } from "../../../../../store/slices/SpinnerSlice";
import { UnAuthenticatedPopup } from "../../../../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";
import {
  acceptFormats,
  allowedImageTypes,
  allowedVideoTypes,
} from "../../../../../constants/Media.constant";
import useIsAuthenticated from "../../../../../utils/useIsAuthenticated";
import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../../../../utils/tokenRequest";
import { setShowWarningModal } from '../../../../../store/slices/WarningModalSlice';

type AddPostFieldProps = {
  onFocus: (value: boolean) => void;
};

const maxImageSize = !!process.env.MAX_IMAGE_SIZE
  ? +process.env.MAX_IMAGE_SIZE
  : 15 * 1024 * 1024;
const maxVideoSize = !!process.env.MAX_VIDEO_SIZE
  ? +process.env.MAX_VIDEO_SIZE
  : 100 * 1024 * 1024;

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const AddPostField = ({ onFocus }: AddPostFieldProps) => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();
  const { isAuthenticated } = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();

  const [
    isShowNotAuthenticatedModalWindow,
    setIsShowNotAuthenticatedModalWindow,
  ] = useState<boolean>(false);
  const [isShowImageAria, setIsShowImageAria] = useState<boolean>(false);
  const [images, setImages] = useState<ImageItem[]>([]);
  const [text, setText] = useState<string>("");
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(false);
  const [selectedImg, setSelectedImg] = useState<number>(0);
  const [errorProps, setErrorProps] = useState<ErrorImages>({
    showVideoError: false,
    showImageError: false,
    showFormatError: false,
    filesName: [],
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [addPost, { isSuccess, isLoading, data, isError, error }] = useAddPostMutation();
  const [
    uploadPostMedia,
    { isSuccess: isUploadPostMediaSuccess, isLoading: isMediaLoading },
  ] = useUploadPostMediaMutation();
  const {
    id: userId,
    username,
    photo,
  } = useSelector((state: RootState) => state.userPhotoSlice);
  const {
    hasPreviousPosts
  } = useSelector((state: RootState) => state.profileSlice);
  const reset = () => {
    setImages([]);
    setText("");
    setIsShowImageAria(false);
    onFocus(false);
  };

  const createPost = () => {
    const newPost = {
      id: data,
      text: text.trim(),
      profileId: id,
      creationDateTime: new Date().toISOString(),
      owner: {
        id: userId,
        profiles: "",
        username: username,
        avatarUrl: "",
        avatarPreviewUrl: photo.replace(url, ""),
      },
      likesCount: 0,
      commentsCount: 0,
      isLikedByUser: false,
      isCommentedByUser: false,
    };

    dispatch(setShowSpinner(false));
    if(!hasPreviousPosts){
      dispatch(addProfilePost(newPost));
    }
    reset();
  };

  useEffect(() => {
    if (data && images.length) {
      const formData = new FormData();
      images.forEach((imageItem: ImageItem) => {
        formData.append(`files`, imageItem.original as File);
      });
      uploadPostMedia({ profileId: id, postId: data, body: formData });
    } else if (isSuccess) {
      createPost();
    } else if (isError && error &&
      "status" in error &&
      error.status === 406) {
      dispatch(setShowSpinner(false));
      dispatch(setShowWarningModal(true));
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isUploadPostMediaSuccess) {
      createPost();
    }
  }, [isUploadPostMediaSuccess]);

  const handleDrop = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const mediaArray = [] as ImageItem[];
    const files = Array.from(e.target.files as FileList);

    const errorProps: ErrorImages = {
      filesName: [],
      showImageError: false,
      showVideoError: false,
      showFormatError: false,
    };
    const remainingSlots = 5 - images.length;
    const filesToAdd = files.slice(0, remainingSlots);

    if (filesToAdd) {
      onFocus(true);

      [...filesToAdd].forEach((file) => {
        if (!!file) {
          const fileType = file.type as string;
          if (allowedImageTypes.includes(fileType)) {
            if (file.size < maxImageSize) {
              const newItem: ImageItem = {
                original: file,
                thumbnail: URL.createObjectURL(file as Blob),
                description: "image",
                size: file.size,
                type: fileType,
              };
              mediaArray.push(newItem);
            } else {
              errorProps.showImageError = true;
              errorProps.filesName.push(file.name);
            }
          } else if (allowedVideoTypes.includes(fileType)) {
            if (file.size < maxVideoSize) {
              const newItem: ImageItem = {
                original: file,
                thumbnail: video,
                description: "video",
                size: file.size,
                type: fileType,
              };
              mediaArray.push(newItem);
            } else {
              errorProps.showVideoError = true;
              errorProps.filesName.push(file.name);
            }
          } else {
            errorProps.showFormatError = true;
            errorProps.filesName.push(file.name);
          }
        }
      });
    }
    if (inputRef?.current) {
      inputRef.current.value = "";
    }
    setErrorProps(errorProps);
    setImages(mediaArray);
    setIsShowImageAria(true);
  };

  const onDelete = (value: number) => {
    const filteredGallery = images.filter((item, index) => index !== value);
    setImages(filteredGallery);

    if (!filteredGallery[value]) {
      if (filteredGallery.length === 0) {
        setIsGalleryVisible(false);
      } else {
        setSelectedImg(value - 1);
      }
    } else {
      setSelectedImg(value);
    }
  };
  const onRotate = (value: number, img: File) => {
    const newImages = images.map((item, index) => {
      if (index === value) {
        item.original = img;
        item.thumbnail = URL.createObjectURL(img as Blob)
      }
      return item
    });
  
    setImages(newImages);
  };
  const onImageClick = (index: number) => {
    setIsGalleryVisible(true);
    setSelectedImg(index);
  };

  const setToGallery = (value: ImageItem[], errorValue: ErrorImages) => {
    setErrorProps(errorValue);
    const remainingSlots = 5 - images.length;
    const filesToAdd = value.slice(0, remainingSlots);

    if (filesToAdd) {
      setImages([...images, ...filesToAdd]);
    }
  };
  const handleAddPost = () => {
    addPost({ profileId: id, text: { text: text.trim() } });
    dispatch(setShowSpinner(true));
  };
  const onSubmit = () => {
    if (text.trim() || images.length) {
      tokenRequest("profile", instance, accounts, handleAddPost, () =>
        setIsShowNotAuthenticatedModalWindow(true)
      );
    }
  };
  const onClose = () => {
    reset();
    setIsShowPopup(false);
  };
  const handleFocus = () => {
    if (isAuthenticated) {
      setIsShowImageAria(true);
      onFocus(true);
    }
  };
  const onBlur = () => {
    if (text || images.length) {
      setIsShowPopup(true);
    } else {
      setIsShowImageAria(false);
      onFocus(false);
    }
  };
  const onFieldClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isAuthenticated) {
      e.stopPropagation();

      onFocus(true);
      setIsShowNotAuthenticatedModalWindow(true);
    }
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const isTouchScreen =window.matchMedia("(pointer: coarse)").matches
    if (e.key === "Enter" && !e.shiftKey && !isTouchScreen) {
      e.preventDefault();
      if (onSubmit) {
        const textarea = e.target as HTMLTextAreaElement;
        textarea && textarea.blur();
        onSubmit();
      }
    } 
  };
  return (
    <>
      {isShowImageAria && <PopupBackground onClose={onBlur} />}

      <div
        className={
          isShowImageAria
            ? styles.addPostFieldFocus
            : styles.addPostFieldContainer
        }
        ref={containerRef}
        onClick={onFieldClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}

      >
        <div className={styles.inputContainer}>
          <SimpleTextarea
            onChange={(value) => setText(value)}
            value={text}
            classType={isShowImageAria ? "outline" : ""}
            isInitHeight={!isShowImageAria}
            placeholder={t("posts.addPost")}
            id="addPostField"
            onFocus={handleFocus}
            maxLength={2048}
            disabled={!isAuthenticated}
          />
          {isShowImageAria ? (
            <button
              className={styles.button}
              onClick={onSubmit}
              disabled={
                isLoading ||
                (images.length !== 0 && isMediaLoading) ||
                (!images.length && !text.trim().length)
              }
            >
              <img src={arrowIcon} alt="" />
            </button>
          ) : (
            <div
              className={styles.iconContainer}
              onClick={() => isAuthenticated && inputRef.current?.click()}
            >
              <img src={imgIcon} alt="" />
              <input
                accept={acceptFormats}
                id="icon-button-file"
                multiple
                type="file"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={handleDrop}
              />
            </div>
          )}
        </div>

        {isShowImageAria && (
          <div
            className={styles.mediaGrid}
            style={{
              gridTemplateColumns: `repeat(${Math.min(
                images.length + 1,
                5
              )}, 1fr)`,
            }}
          >
            {images.map((item, index) => (
              <PhotoForGallery
                key={index}
                isBig
                item={item}
                onDelete={() => onDelete(index)}
                onImageClick={() => onImageClick(index)}
              />
            ))}
            {images.length < 5 && (
              <div className={styles.dropContainer}>
                <ImageDrop
                  onDrop={setToGallery}
                  type="mapSmall"
                  text={t("createProfile.mapPhoto")}
                />
              </div>
            )}
          </div>
        )}
        {isGalleryVisible && (
          <ImageViewer
            isShow={isGalleryVisible}
            isShowRotate
            data={images}
            isShowDelete
            onClose={() => setIsGalleryVisible(false)}
            onDelete={onDelete}
            onRotate={onRotate}
            startIndex={selectedImg}
          />
        )}
        {(errorProps.showVideoError ||
          errorProps.showImageError ||
          errorProps.showFormatError) && (
          <FileError
            onClose={() =>
              setErrorProps({
                filesName: [],
                showImageError: false,
                showVideoError: false,
                showFormatError: false,
              })
            }
            showVideoError={errorProps.showVideoError}
            showImageError={errorProps.showImageError}
            showFormatError={errorProps.showFormatError}
            errorFileNames={errorProps.filesName}
          />
        )}
        {isShowPopup && (
          <ModalWindow
            onClose={() => setIsShowPopup(false)}
            isShow={isShowPopup}
            headerText={t("posts.publishPost")}
            backdropModal={true}
            children={
              <div>
                <p className={styles.popupDescription}>
                  {t("posts.publishPostDescription")}
                </p>
                <button
                  className={styles.popupButton}
                  onClick={() => setIsShowPopup(false)}
                >
                  {t("common.cancel")}
                </button>
                <button className={styles.popupCloseButton} onClick={onClose}>
                  {t("common.close")}
                </button>
              </div>
            }
          ></ModalWindow>
        )}
      </div>
      {isShowNotAuthenticatedModalWindow && (
        <UnAuthenticatedPopup
          onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
        ></UnAuthenticatedPopup>
      )}
    </>
  );
};
