import { LogLevel } from "@azure/msal-browser";
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SignUpOrSignInWithPhoneOrEmail",
    signUp: "B2C_1A_SignUpWithPhone",
    editPhoneNumber: "B2C_1A_EditPhoneNumber",
    forgotPassword: "B2C_1A_PasswordResetEmailAndPhone",
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_SUSI || '',
    },
    signUp: {
      authority: process.env.REACT_APP_SU || '',
    },
    editPhoneNumber: {
      authority: process.env.REACT_APP_EDIT || '',
    },
    forgotPassword: {
      authority: process.env.REACT_APP_PASSWORD || '',
    },
  },
  authorityDomain: process.env.REACT_APP_DOMAIN || '',
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || '', // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    validateAuthority: true,
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const protectedResources = {
  api: {
    scopes: {
      openId: ["openid", "profile"],
      read: [process.env.REACT_APP_AZURE_READ || ''],
      profile: [process.env.REACT_APP_AZURE_PROFILE || ''],
      search: [process.env.REACT_APP_AZURE_SEARCH || ''],
      payment: [process.env.REACT_APP_AZURE_PAYMENT || ''],
      order: [process.env.REACT_APP_AZURE_ORDER || ''],
      media: [process.env.REACT_APP_AZURE_MEDIA || ''],
      complaint: [process.env.REACT_APP_AZURE_COMPLAINT || ''],
    },
  },
};

export const loginRequest = {
  scopes: [...protectedResources.api.scopes.read],
  extraQueryParameters: { ui_locales: "en-US" },
};

