import { useTranslation } from "react-i18next";
import styles from "./ProfileInfo.module.scss";
import { useGetFrameQuery, useGetProfileQuery } from "../../api/ProfileService";
import { useGetCandleProfileQuery } from "../../api/GiftService";
import { useEffect, useState } from "react";
import { ReactComponent as UserIcon } from "../../shared/image/userIcon.svg";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import calculateAgeAndLifeSpan from "../../utils/calculateAgeAndLifeSpan";
import { ProfileTabsContainer } from "./profileTabs/ProfileTabsContainer";
import { LinksInfo } from "./links/LinksInfo";
import { ImageViewer } from "../imageViewer/ImageViewer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { PersonalPhotosGallery } from "./personalPhotosGallery/PersonalPhotosGallery";
import { ProfileLocation } from "./location/ProfileLocation";
import { ImageItem } from "../../types/CommonTypes";
import {
  deleteState,
  setCandle,
  setCandleMessage,
  setProfiles,
} from "../../store/slices/OrderSlice";
import { ModalWindow } from "../modalWindow/ModalWindow";
import { SilentLetterModal } from "../modalWindow/SilentLetterModal";
import { MemorialTab } from "../memorial/memorialTab/MemorialTab";
import { PaymentStatus } from "../../shared/components/paymentStatus/PaymentStatus";
import { SuccessStatusPopup } from "../../shared/components/successStatusPopup/SuccessStatusPopup";
import { AvatarProfileCandle } from "./avatarProfileCandle/AvatarProfileCandle";
import { MemorialMobileGallery } from "./memorialMobile/MemorialMobileGallery";
import { MemorialTypes } from "../../constants/Memorial.constant";
import { ProfileWall } from "./profileWall/ProfileWall";
import { setIsCreatedByCurrentUser } from "../../store/slices/ProfileSlice";
import { PostsMobile } from "./postsMobile/PostsMobile";
import useIsAuthenticated from "../../utils/useIsAuthenticated";
import { getLanguageByLocale } from "../../utils/localeUtils";

type PropsType = {
  id: string;
  isShowWall:boolean
  refetchData: (type: string) => void;
  fetchNewPageData: (type: string) => void;
  fetchPreviousPosts: () => void;
};
const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const ProfileInfo = ({
  id,
  isShowWall,
  refetchData,
  fetchNewPageData,
  fetchPreviousPosts
}: PropsType) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const {isAuthenticated} = useIsAuthenticated();
  const { skip } = useFetchWithMsal("profile", isAuthenticated);

  const { selectedProfiles, isPaymentLoading } = useSelector(
    (state: RootState) => state.orderSlice
  );

  const [isShowSilentLetterModal, setIsShowSilentLetterModal] = useState(false);
  
  const onSendSilentLetter = (message: string) => {
    console.log("Silent letter sent:", message);
    setIsShowSilentLetterModal(false);
  };

  const [isFree, setIsFree] = useState<boolean>(false);
  const [type, setType] = useState<string>(MemorialTypes.candles);

  const [isShowGallery, setIsShowGallery] = useState(false);
  const [isShowCandleModalWindow, setIsShowCandleModalWindow] =
    useState<boolean>(false);
  const [isShowMobileWall, setIsShowMobileWall] = useState<boolean>(false);
  const [isShowAddPost, setIsShowAddPost] = useState<boolean>(false);
  const { language } = useSelector((state: RootState) => state.language);
  const { photoHash } = useSelector((state: RootState) => state.profileSlice);

  const { data, isSuccess, refetch } = useGetProfileQuery({id, lang:getLanguageByLocale(language)}, {
    skip,
    refetchOnMountOrArgChange: true,
  });
  const { data: frame } = useGetFrameQuery(data?.profilePictureFrameId, {
    skip: !data?.profilePictureFrameId,
  });
  const { data: avatarCandle, refetch: refetchAvatarCandle } =
    useGetCandleProfileQuery(id, {
      skip,
    });

  useEffect(() => {
    dispatch(deleteState());
  }, []);
 
  useEffect(() => {
    if(isShowWall && window.innerWidth < 1150){

      setIsShowMobileWall(true)
    }
  }, [isShowWall]);

  const createProfileData = () => {
    if (data && !selectedProfiles.length) {
      const profileInfo = {
        profileId: data?.id,
        profileFullName: `${data?.lastName || ""} ${data?.firstName || ""} ${
          data?.middleName || ""
        }`,
        profileFullNameEn: `${data?.inEnglish.lastName || ""} ${
          data?.inEnglish.firstName || ""
        } ${data?.inEnglish.middleName || ""}`,
      };
      dispatch(setProfiles(profileInfo));
    }
  };

  useEffect(() => {
    if (data) {
      createProfileData();
      dispatch(setIsCreatedByCurrentUser(data.isCreatedByCurrentUser));
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess && !data) {
      window.location.href = "/#/profiles";
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isFree) {
      const timeoutIdRefetch = setTimeout(() => {
        refetchData(type);
      }, 2000);
      const timeoutIdIsFree = setTimeout(() => {
        setIsFree(false);
      }, 4000);
      return () => {
        clearTimeout(timeoutIdRefetch);
        clearTimeout(timeoutIdIsFree);
      };
    }
  }, [isFree]);

  const onAddMemorialItemClick = (type?: string) => {
    type && setType(type);
    createProfileData();
    setIsShowMobileWall(false);
    setIsShowCandleModalWindow(true);
  };
  const onCloseCandleWindow = () => {
    dispatch(setCandleMessage(""));
    dispatch(setCandle(""));
    setIsShowCandleModalWindow(false);
  };
  const onFreeOrderHandle = (type: string) => {
    setType(type);
    setIsShowCandleModalWindow(false);
    refetch();
    refetchAvatarCandle();
    setIsFree(true);
  };
  const { ageInYears, lifeSpan } = calculateAgeAndLifeSpan(
    data?.dateOfBirth,
    data?.dateOfDeath,
    t("common.unknown")
  );
  const onAddPostClick = () => {
    setIsShowAddPost(true);
    setIsShowMobileWall(true);
  };
  const onCloseMobileindow = () => {
    setIsShowMobileWall(false);
    setIsShowAddPost(false);
  };
  return (
    <div className={styles.profileInfo}>
      <div className={styles.profileAvatarCurrentPhoto}>
        <div
          className={styles.frame}
          style={ { borderColor: !!data?.profilePictureFrameId ? frame?.color : "#F7F8F9" }}
        >
          <div className={styles.photoContainer}>
            { !!data?.avatarPreviewUrl
              ? (<img
                alt=""
                className={styles.bigPhoto}
                src={`${url}${data?.avatarPreviewUrl}?${photoHash}`}
                onClick={() => {
                  setIsShowGallery(!!data?.avatarPreviewUrl);
                }}
              />)
              : (
                <div className={styles.bigPhoto}>
                  <UserIcon onClick={() => {
                    setIsShowGallery(!!data?.thumbnail);
                  }}/>
                </div>
              )
            }
          </div>
          {!!avatarCandle?.mediaUrls.length && (
            <AvatarProfileCandle avatarCandlesUrl={avatarCandle?.mediaUrls} />
          )}
        </div>
      </div>
      <div className={styles.profileName}>
        <h3>{`${data?.lastName || ""} ${data?.firstName || ""} ${
          data?.middleName || ""
        }`}</h3>
      </div>
      <div className={styles.profileAge}>
        <p>
          <span>{lifeSpan}</span>{" "}
          <span className={styles.ageInfo}>{`(${ageInYears} ${t(
            "common.years"
          )})`}</span>
        </p>
      </div>
      {data?.deathReason && (
        <p className={styles.deathReason}>{`${t("profile.deathReason")} ${
          data?.deathReason
        }`}</p>
      )}
      <button
        className={styles.addButton}
        onClick={() => onAddMemorialItemClick()}
        disabled={!data}
      >
        {t("profile.addCandle")}
      </button>

      <button
        className={styles.addButton}
        onClick={() => setIsShowSilentLetterModal(true)}
        disabled={!data}
      >
        {t("profile.quietLetter")}
      </button>

      <MemorialMobileGallery
        type={MemorialTypes.candles}
        fetchNewPageData={fetchNewPageData}
        onViewAllClick={() => setIsShowMobileWall(true)}
      />
      <MemorialMobileGallery
        type={MemorialTypes.feelings}
        fetchNewPageData={fetchNewPageData}
        onViewAllClick={() => setIsShowMobileWall(true)}
      />

      <ProfileLocation
        id={id}
        skip={skip}
        isCreatedByCurrentUser={data?.isCreatedByCurrentUser}
      />
      <PostsMobile
        onViewAllClick={() => setIsShowMobileWall(true)}
        onAddNewPostClick={onAddPostClick}
      />
      <LinksInfo id={id} skip={skip} />
      <PersonalPhotosGallery
        id={id}
        isAuthenticated={!!isAuthenticated}
        isCreatedByCurrentUser={data?.isCreatedByCurrentUser}
      />
      <ProfileTabsContainer id={id} />
      {isShowGallery && (
        <ImageViewer
          isShow={isShowGallery}
          data={
            [
              {
                thumbnail: `${url}${data?.avatarPreviewUrl}`,

                url: `${url}${data?.avatarUrl}`,
              },
            ] as ImageItem[]
          }
          onClose={() => setIsShowGallery(false)}
          startIndex={0}
        />
      )}
      {isShowCandleModalWindow && (
        <ModalWindow
          onClose={onCloseCandleWindow}
          isShow={isShowCandleModalWindow && !isPaymentLoading}
          headerText={t("profile.addCandle")}
          children={
            <MemorialTab
              isModalWindow
              onClose={onFreeOrderHandle}
              type={type}
            />
          }
          isOrder
        ></ModalWindow>
      )}
  
      {isShowSilentLetterModal && (
        <SilentLetterModal
          isShow={isShowSilentLetterModal}
          onClose={() => setIsShowSilentLetterModal(false)}
          onSend={onSendSilentLetter}
        />
      )}
      {isShowMobileWall && (
        <ModalWindow
          onClose={onCloseMobileindow}
          isShow={isShowMobileWall && window.innerWidth<1150}
          headerText={t("profile.addCandle")}
          children={
            <ProfileWall
              isShowAddPost={isShowAddPost}
              fetchNewPageData={fetchNewPageData}
              fetchPreviousPosts={fetchPreviousPosts}
              onAddMemorialItemClick={onAddMemorialItemClick}
              onClosePopup={onCloseMobileindow}
            />
          }
        ></ModalWindow>
      )}
      <PaymentStatus isFree={isFree} />
      {isFree && <SuccessStatusPopup />}
    </div>
  );
};
