import { Menu } from "../../components/menu/Menu";
import styles from "./InfoPage.module.scss";

const baseUrl = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const InfoPage = () => {
  return (
    <>
        <video
          width="100%"
          preload="auto"
          controls
          className={styles.video}
          src={
            `${baseUrl}/promo/RIPPlanet-demo.mp4`
          }
        ></video>
      <div className={styles.menuContainer}>
        <Menu />
      </div>
    </>
  );
};
