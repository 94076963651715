import { useTranslation } from "react-i18next";
import styles from "./EditForm.module.scss";
import { setLocation } from "../../../store/slices/CreateProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { SearchInput } from "../../../shared/components/search/SearchInput";
import {UIEvent, useEffect, useRef, useState} from "react";
import Map from "../../../components/map/Map";
import { Checkbox } from "../../../shared/components/checkbox/Checkbox";
import {
  useGetCityByIdQuery,
  useGetCoordinateInformationQuery,
  useGetCountryByIdQuery,
  useGetRegionByIdQuery,
  useSearchCitiesQuery,
  useSearchCountriesQuery,
  useSearchRegionsQuery,
} from "../../../api/UtilityService";
import ImageDrop from "../../../components/dropArea/DropArea";
import { ImageViewer } from "../../../components/imageViewer/ImageViewer";
import { CommonType, CountryType, ErrorImages, ImageItem, LocationDataType } from "../../../types/CommonTypes";
import { MapCenterType, MarkerType } from "../../../types/MapTypes";
import { FileError } from "../../../shared/components/fileError/FileError";
import PhotoForGallery from "../../../shared/components/photoForGallery/PhotoForGallery";
import {getLanguageByLocale} from "../../../utils/localeUtils";

import {LIMIT_SCROLL_CITY} from '../../../constants/constants';
import {PopupLocation} from './PopupCountries/PopupLocation';

type CoordinatesInfoType = {
  cityId: string | null;
  cityName: string | null;
  countryId: string;
  countryIsoCode: string;
  countryName: string;
  regionCode: string | null;
  regionId: string | null;
  regionName: string | null;
}

type MapFormProps = {
  onClick: (value: { burialPlacePhotos: ImageItem[] }) => void;
  burialPlacePhotos?: ImageItem[];
  setIsChangeLastStep: (value: boolean) => void;
};

export const MapForm = ({ onClick, burialPlacePhotos, setIsChangeLastStep }: MapFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    mainInfo: { burialPlace },
    isUnknownBurialPlace,
  } = useSelector((state: RootState) => state.createProfile);
  const { language } = useSelector((state: RootState) => state.language);
  const [city, setCity] = useState<CommonType | null>(null);
  const [country, setCountry] = useState<CommonType | null>(null);
  const [region, setRegion] = useState<CommonType | null>(null);
  const [countryInfo, setCountryInfo] = useState<null | {
    isoCode: string;
    countryId: string;
  }>(null);

  const [regionId, setRegionId] = useState("");
  const [activeSearchType, setActiveSearchType] = useState("");
  const [coords, setCoords] = useState("");
  const [size, setSize] = useState(0);
  const [selectedImg, setSelectedImg] = useState<number>(0);

  const [marker, setMarker] = useState<MarkerType[]>([]);
  const [center, setCenter] = useState<MapCenterType>({
    lat: 0,
    lng:0,
  });
  const [popupData, setPopupData] = useState<any>(null);
  const [popupCountryData, setPopupCountryData] = useState<LocationDataType[] | null>(null);
  const [skipCoords, setSkipCoords] = useState(false);
  const [isInvalidCoords, setIsInvalidCoords] = useState(false);
  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(false);
  const [isUnknown, setIsUnknown] = useState(isUnknownBurialPlace);
  const [showPopup, setShowPopup] = useState("");
  const [images, setImages] = useState<ImageItem[]>([]);
  const [errorProps, setErrorProps] = useState<ErrorImages>({
    showVideoError: false,
    showImageError: false,
    showFormatError:false,
    filesName: [],
  });
  const [isSearch, setIsSearch] = useState(false);
  const [offset, setOffset] = useState<number>(0);
  const isLoadingScroll = useRef<boolean>(false);

  const { data: searchCountriesData, isSuccess: isCountrySuccess } =
    useSearchCountriesQuery(
      { lang: getLanguageByLocale(language), search: country?.name || '' },
      { skip: !isSearch || activeSearchType !== "country", }
    );
  const { data: searchRegionsData, isSuccess: isRegionsSuccess, isFetching: isRegionsLoading } =
    useSearchRegionsQuery(
      { lang: getLanguageByLocale(language),
        countryId: countryInfo?.isoCode,
        search: region?.name || '' ,
        start: offset,
      },
      { skip:  activeSearchType !== "region", }
    );
  const { data: searchCityData, isSuccess: isCitiesSuccess, isFetching: isCitiesLoading } = useSearchCitiesQuery(
    {
      lang: getLanguageByLocale(language),
      search: city?.name || '' ,
      countryId: countryInfo?.isoCode,
      region: regionId,
      start: offset,
    },
    { skip: activeSearchType !== "city", }
  );
  const {
    data: coordsData,
    isSuccess: isCoordinatesValid,
    isError,
  } = useGetCoordinateInformationQuery(
    { lang: getLanguageByLocale(language), coords: coords },
    { skip: skipCoords || !coords, }
  );

  const { data: cityName } = useGetCityByIdQuery(
    {
      cityId: city?.id,
      lang: getLanguageByLocale(language),
    }, { skip: !city?.id, }
  );

  const { data: regionName } = useGetRegionByIdQuery(
    {
      regionId: region?.id,
      lang: getLanguageByLocale(language),
    }, { skip: !region?.id, }
  );

  const { data: countryName } = useGetCountryByIdQuery(
    {
      countryId: country?.id,
      lang: getLanguageByLocale(language),
    }, { skip: !country?.id, }
  );

  useEffect(() => {
    if (countryName) {
      setCountry({ id: countryName.id, name: countryName.country });

      setCountryInfo({
        isoCode: countryName.isoCode,
        countryId: countryName.id
      })
    }
  }, [countryName]);

  useEffect(() => {
    if (cityName) {
      if (cityName.region?.id && region?.id !== cityName.region.id) {
        setRegion({ id: cityName.region.id, name: cityName.region.name });
      }

      setCity({
        id: city?.id || burialPlace?.cityId,
        name: cityName.name,
      });
    }
  }, [cityName]);

  useEffect(() => {
    if (regionName) {
      setRegion({ id: region?.id || "", name: regionName.name })
    }
  }, [regionName]);

  useEffect(() => {
    if(images.length && burialPlacePhotos?.length !== images.length) {
      setIsChangeLastStep(true);
    } else {
      setIsChangeLastStep(false);
    }
  }, [ images.length ])

  useEffect(() => {
    if (burialPlacePhotos?.length) {
      setImages(burialPlacePhotos);
    }
  }, [burialPlacePhotos]);

  useEffect(() => {
    setMarker([{ burialPlace }]);
    if (burialPlace && burialPlace?.latitude && burialPlace?.longitude) {
      setCoords(`${burialPlace?.latitude}, ${burialPlace?.longitude}`);
      setSkipCoords(false);
      setCenter({lat:burialPlace.latitude, lng:burialPlace.longitude})
    }
    if (burialPlace?.countryId) {
      setCountry({ id: burialPlace?.countryId, name: "" });
    }
    if (burialPlace?.cityId) {
      setCity({ id: burialPlace?.cityId, name: "" });
    }
  }, [burialPlace]);

  useEffect(() => {
    if (showPopup === "city") {

      if (searchCityData?.results?.length && !isCitiesLoading && isCitiesSuccess && isSearch) {
        
        if (offset) {
          setPopupData([
            ...(popupData as LocationDataType[]),
            ...searchCityData.results,
          ]);
        } else {
          setPopupData([...searchCityData.results]);
        }
        isLoadingScroll.current = false;
        setIsSearch(false);
      }
    }
  }, [isCitiesSuccess,isCitiesLoading, offset, isSearch]);

  useEffect(() => {
    if (showPopup === "region") {
      if (searchRegionsData?.results?.length && !isRegionsLoading && isRegionsSuccess && isSearch) {
        
        if (offset) {
          setPopupData([
            ...(popupData as LocationDataType[]),
            ...searchRegionsData.results,
          ]);
        } else {
          setPopupData([...searchRegionsData.results]);
        }

        setIsSearch(false);
        isLoadingScroll.current = false;
      }
    }
  }, [isRegionsSuccess,isRegionsLoading, offset,isSearch]);

  useEffect(() => {
    if (isCountrySuccess && searchCountriesData?.length && isSearch) {
      const popupData = searchCountriesData.map((item: CountryType) => {
        return {
          name: item.country,
          type: item.id,
          isoCode: item.isoCode,
          id: item.id,
          code: item.code,
          country: item.country,
        };
      });
      setPopupCountryData(popupData);
      setShowPopup("country");
      setIsSearch(false);
    } else if (isCountrySuccess && searchCountriesData?.length === 0 && isSearch) {
      setPopupCountryData([]);
      setIsSearch(false);
    }
  }, [searchCountriesData]);

  useEffect(() => {
    if (isError) {
      setIsInvalidCoords(true);
      setSkipCoords(true);
    }
  }, [isError]);

  useEffect(() => {
    if (isCoordinatesValid && coordsData) {
      deleteSearchState();
      setMarker([
        {
          burialPlace: {
            longitude: coordsData.longitude,
            latitude: coordsData.latitude,
            cityId: coordsData.coordinatesInfo?.cityId,
            countryId: coordsData.coordinatesInfo?.countryId,
          },
        },
      ]);
      setCenter({ lat: coordsData.latitude, lng: coordsData.longitude });
      if (coordsData.coordinatesInfo?.cityName) {
        setCity({
          id: coordsData.coordinatesInfo.cityId,
          name: coordsData.coordinatesInfo.cityName,
        });
      }
      if (coordsData.coordinatesInfo?.countryName) {
        setCountry({
          id: coordsData.coordinatesInfo.countryId,
          name: coordsData.coordinatesInfo.countryName,
        });
      }
      if (coordsData.coordinatesInfo?.regionName) {
        setRegion({
          id: coordsData.coordinatesInfo.regionId,
          name: coordsData.coordinatesInfo.regionName,
        });
      }
      if (coordsData.coordinatesInfo?.regionCode) {
        setRegionId(coordsData.coordinatesInfo.regionCode);
      }
      if (coordsData.coordinatesInfo?.countryId) {
        setCountryInfo({
          countryId: coordsData.coordinatesInfo.countryId,
          isoCode: coordsData.coordinatesInfo.countryIsoCode,
        });
      }
      setSkipCoords(true);
    }
  }, [coordsData]);

  const onSubmit = () => {
    dispatch(
      setLocation({ marker: marker[0], isUnknownBurialPlace: isUnknown })
    );
    onClick({ burialPlacePhotos: images });
  };

  const onCityChange = (value: string) => {
    setActiveSearchType("city");
    setCity({ id: "", name: value });
    setCoords("");
    setMarker([]);
    setOffset(0);
    setIsSearch(true);
  };

  const onCountryChange = (value: string) => {
    setActiveSearchType("country");
    setCountryInfo(null);
    setCountry({ id: "", name: value });
    setCountryInfo(null);
    setRegionId("");
    setRegion(null);
    setCity(null);
    setCoords("");
    setMarker([]);
    setIsSearch(true);
    setOffset(0);
  };
  const onRegionChange = (value: string) => {
    setOffset(0);
    setActiveSearchType("region");
    setRegion({ id: "", name: value });
    setRegionId("");
    setCity(null);
    setCoords("");
    setMarker([]);
    setIsSearch(true);
  };
  const onPopupCityClick = (city: LocationDataType) => {
    if (city) {
      setActiveSearchType("");
      setCoords(`${city?.latitude}, ${city?.longitude}`);
      setIsUnknown(false);
      setMarker([
        {
          burialPlace: {
            longitude: city.longitude,
            latitude: city.latitude,
            cityId: city.id,
            countryId: countryInfo?.countryId,
          },
        },
      ]);

      setCity({ id: city.id, name: city.name });
      setRegion({ id: city.region?.id, name: city.region?.name || "" });
      setRegionId(city.region?.code || '');
      setCenter({ lat: city?.latitude, lng: city?.longitude });
      setShowPopup("");
      setOffset(0);
      setPopupData([]);
    }
  };
  const onPopupCountryClick = (item: LocationDataType) => {
    if (item) {
      setActiveSearchType("");
      setIsUnknown(false);
      setCountry({ id: item.id, name: item.country as string });
      setCountryInfo({ isoCode: item.isoCode as string, countryId: item.id as string});
      setShowPopup("");
      setPopupCountryData([]);
    }
  };
  const onPopupRegionClick = (region: LocationDataType) => {
    if (region) {
      setActiveSearchType("");
      setIsUnknown(false);
      setRegionId(region.adminFirstCode);
      setRegion({ id: region.id, name: region.name });
      setShowPopup("");
      setOffset(0);
      setPopupData([]);
    }
  };
  const onPopupClose = () => {
    setActiveSearchType("");
    setPopupData([]);
    setShowPopup("");
    setOffset(0);
  };

  const onSearchBlur = (type: string) => {
    switch (type) {
      case "country":
        if (!countryInfo?.countryId) {
          setCountry(null);
        }
        break;
      case "region":
        if (!regionId) {
          setRegion(null);
        }
        break;
      case "city":
        if (!coords) {
          setCity(null);
        }
        break;
      default:
        break;
    }
  };
  const deleteSearchState = () => {
    setCity(null);
    setCountry(null);
    setRegion(null);
    setRegionId("");
    setCountryInfo(null);
  };
  const onMarkerClick = (value: MarkerType) => {
    setMarker([value]);
    setCoords(
      `${value?.burialPlace.latitude}, ${value?.burialPlace.longitude}`
    );
    setIsUnknown(false);
    setSkipCoords(false);
  };
  const searchByCoords = () => {
    setIsUnknown(false);
    setSkipCoords(false);
  };
  const onDelete = (value: number) => {
    const filteredGallery = images.filter((item, index) => index !== value);
    setSize((prev) => prev + images[value]?.original?.size);
    setImages(filteredGallery);

    if (!filteredGallery[value]) {
      if (filteredGallery.length === 0) {
        setIsGalleryVisible(false);
      } else {
        setSelectedImg(value - 1);
      }
    } else {
      setSelectedImg(value);
    }
  };
  const onImageClick = (index: number) => {
    setIsGalleryVisible(true);
    setSelectedImg(index);
  };

  const setToGallery = (value: ImageItem[], errorValue: ErrorImages) => {
    setErrorProps(errorValue);
    let totalSize = size;
    const remainingSlots = 3 - images.length;
    const filesToAdd = value.slice(0, remainingSlots);

    if (filesToAdd) {
      filesToAdd.forEach((item) => (totalSize += item.original.size));

      if (totalSize <= 200e6) {
        setImages([...images, ...filesToAdd]);
        setSize(totalSize);
      }
    }
  };
  const onCheckboxClick = () => {
    setIsUnknown(!isUnknown);
    deleteSearchState();
    setCity(null);
    setCoords("");
    setMarker([]);
  };

  const fieldInFocus = (type: string) => {
    setActiveSearchType(type);
    setIsSearch(true);
    setShowPopup(type);
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (showPopup === "region" || showPopup === "city") {
      const divComponent = e.target as HTMLDivElement;
      if (divComponent.offsetHeight + divComponent.scrollTop >= divComponent.scrollHeight - 100) {
        if ((showPopup === "region" ? !isRegionsLoading : !isCitiesLoading)
          && (showPopup === "region" ? searchRegionsData?.hasNext : searchCityData?.hasNext)
          && !isLoadingScroll.current) {
          isLoadingScroll.current = true;
          setIsSearch(true);
          setOffset(offset + LIMIT_SCROLL_CITY);
        }
      }
    }
  };
  const onRotate = (value: number, img: File) => {
    const newImages = images.map((item, index) => {
      if (index === value) {
        item.original = img;
        item.thumbnail = URL.createObjectURL(img as Blob)
      }
      return item
    });
  
    setImages(newImages);
  };
  return (
    <div className={styles.formContainer}>
      <h3 className={styles.formHeader}>{t("createProfile.location")}</h3>
      <div className={styles.searchContainer}>
        <SearchInput
          onChange={onCountryChange}
          value={country?.name || ''}
          placeholder={t("createProfile.country")}
          type="search"
          onBlur={() => onSearchBlur("country")}
          onFocus={() => fieldInFocus("country")}
        />
        {showPopup === "country" && !!popupCountryData?.length && (
          <PopupLocation
            type="search"
            data={popupCountryData}
            onClose={onPopupClose}
            onClick={onPopupCountryClick}
          ></PopupLocation>
        )}
      </div>
      <div className={styles.searchContainer}>
        <SearchInput
          onChange={onRegionChange}
          value={region?.name || ''}
          placeholder={t("createProfile.region")}
          type="search"
          isDisabled={!countryInfo}
          onBlur={() => onSearchBlur("region")}
          onFocus={() => fieldInFocus("region")}
        />
        {showPopup === "region" && !!popupData?.length && (
          <PopupLocation
            type="search"
            data={popupData}
            onClose={onPopupClose}
            onClick={onPopupRegionClick}
            handleScroll={handleScroll}
          ></PopupLocation>
        )}
      </div>
      <div className={styles.searchContainer}>
        <SearchInput
          onChange={onCityChange}
          value={city?.name || ''}
          placeholder={t("createProfile.city")}
          type="search"
          isDisabled={!countryInfo}
          onBlur={() => onSearchBlur("city")}
          onFocus={() => fieldInFocus("city")}
        />
        {showPopup === "city" && !!popupData?.length && (
          <PopupLocation
            type="search"
            data={popupData}
            onClose={onPopupClose}
            onClick={onPopupCityClick}
            handleScroll={handleScroll}
          ></PopupLocation>
        )}
      </div>
      <SearchInput
        onChange={(value) => {
          setCoords(value);
          setIsInvalidCoords(false);
        }}
        value={coords}
        placeholder={t("createProfile.coords")}
        type="coords"
        error={
          isInvalidCoords && !isUnknown ? t("createProfile.dateValidation") : ""
        }
        onClick={searchByCoords}
        isDisabledButton={!coords}
      />
      <div className={styles.mapContainer}>
        <Map
          markers={marker}
          onMarkerClick={onMarkerClick}
          centerPoint={center.lat === 0 && burialPlace.latitude === 0 ? null : center }
          type="create"
        />
      </div>
      <div className={styles.mapMediaContainer}>
        {images.length ? (
          <div className={styles.scrollContainer}>
            <div className={styles.profileMediaGrid}>
              {images.map((item, index) => (
                <PhotoForGallery key={index}
                  item={item}
                  onDelete={() => onDelete(index)}
                  onImageClick={() => onImageClick(index)}
                />
              ))}
              <div className={styles.mapDropContainer}>
                <ImageDrop
                  onDrop={setToGallery}
                  type="mapSmall"
                  text={t("createProfile.mapPhoto")}
                />
              </div>
            </div>
          </div>
        ) : (
          <ImageDrop
            onDrop={setToGallery}
            type="map"
            text={t("createProfile.mapPhoto")}
          />
        )}
      </div>
      {isGalleryVisible && (
        <ImageViewer
          isShow={isGalleryVisible}
          isShowRotate
          data={images}
          isShowDelete
          onClose={() => setIsGalleryVisible(false)}
          onDelete={onDelete}
          onRotate={onRotate}
          startIndex={selectedImg}
        />
      )}
      {(errorProps.showVideoError ||
        errorProps.showImageError  || errorProps.showFormatError )&& (
          <FileError
            onClose={() =>
              setErrorProps({
                filesName: [],
                showImageError: false,
                showVideoError: false,
                showFormatError:false
              })
            }
            showVideoError={errorProps.showVideoError}
            showImageError={errorProps.showImageError}
            showFormatError={errorProps.showFormatError }
            errorFileNames={errorProps.filesName}
          />
        )}
      <div className={styles.mapCheckboxContainer}>
        <Checkbox
          isChecked={isUnknown}
          onChange={onCheckboxClick}
          label={t("createProfile.unknown")}
        />
      </div>
      <button
        className={styles.formMapButton}
        onClick={onSubmit}
        disabled={
          !isUnknown &&
          !marker[0]?.burialPlace?.latitude &&
          !marker[0]?.burialPlace?.longitude
        }
      >
        {t("common.next")}
      </button>
    </div>
  );
};
