import { useForm, FieldValues } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Input } from "../../../shared/components/input/Input";
import styles from "./EditForm.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { setEnglishNameData } from "../../../store/slices/CreateProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Checkbox } from "../../../shared/components/checkbox/Checkbox";
import { useEffect, useState } from "react";
import useUpdateErrors from "../../../utils/useUpdateErrors";
import {MAX_NAME_LENGTH} from '../../../constants/constants';
import {handleSpecificCharsKeyDown} from '../../../utils/handleSpecificCharsKeyDown';

type EngNameFormProps = {
  onClick: () => void;
};

export const EngNameForm = ({ onClick }: EngNameFormProps) => {
  const { inEnglish, firstName, middleName, lastName } = useSelector(
    (state: RootState) => state.createProfile.mainInfo
  );
  const { t } = useTranslation();
  const { language } = useSelector((state: RootState) => state.language);
  const [isDuplicateName, setIsDuplicateName] = useState<boolean>(false);

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9\s'.,()-]+$/, t("createProfile.nameValidation"))
      .required(t("common.requiredField")),
    lastName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9\s'.,()-]+$/, t("createProfile.nameValidation"))
      .required(t("common.requiredField")),
    middleName: yup.string().trim().test({
      test: (value) => !value || /^[a-zA-Z0-9\s'.,()-]+$/.test(value),
      message: t("createProfile.nameValidation"),
    }),
  }) as yup.ObjectSchema<FieldValues>;

  const resolver = yupResolver(schema);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    watch,
  } = useForm<FieldValues>({
    resolver,
    defaultValues: inEnglish,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDuplicateName) {
      setValue("lastName", lastName);
      setValue("firstName", firstName);
      setValue("middleName", middleName);
    }
  }, [isDuplicateName]);

  const onSubmit = (data: FieldValues) => {
    dispatch(setEnglishNameData(data));
    onClick();
  };
  useUpdateErrors(handleSubmit(onSubmit), !!Object.keys(dirtyFields).length);

  const watchFields = watch();
  
  useEffect(() => {
    if (
      watchFields.lastName !== lastName ||
      watchFields.firstName !== firstName ||
      watchFields.middleName !== middleName
    ) {
      setIsDuplicateName(false);
    } else {
      setIsDuplicateName(true);
    }
  }, [watchFields]);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <h3 className={styles.formHeader}>
          {t("createProfile.personalInformation")}
        </h3>
        <Input
          register={register}
          setValue={setValue}
          name="lastName"
          type="text"
          placeholder={`${t("createProfile.lastNameEng")}*`}
          error={errors.lastName?.message as string}
          maxLength={MAX_NAME_LENGTH}
          handleKeyDown={handleSpecificCharsKeyDown}
        />
        <Input
          register={register}
          setValue={setValue}
          name="firstName"
          type="text"
          placeholder={`${t("createProfile.firstNameEng")}*`}
          error={errors.firstName?.message as string}
          maxLength={MAX_NAME_LENGTH}
          handleKeyDown={handleSpecificCharsKeyDown}
        />

        <Input
          register={register}
          setValue={setValue}
          name="middleName"
          type="text"
          placeholder={`${t("createProfile.middleNameEng")}`}
          error={errors.middleName?.message as string}
          maxLength={MAX_NAME_LENGTH}
          handleKeyDown={handleSpecificCharsKeyDown}
        />
        {language.includes("en") && (
          <div className={styles.checkboxContainer}>
            <Checkbox
              isChecked={isDuplicateName}
              onChange={() => setIsDuplicateName(!isDuplicateName)}
              label={"Same as original Full Name"}
            />
          </div>
        )}
         <button
        className={styles.formButton}
        type="submit"
        disabled={
          !watchFields.firstName &&
          !watchFields.lastName &&
          !watchFields.middleName
        }
      >
        {t("common.next")}
      </button>
      </div>
     
    </form>
  );
};
