import styles from "./Textarea.module.scss";
import { UseFormRegister, FieldValues, UseFormSetValue } from "react-hook-form";
import icon from "../../image/cleanUp.svg";
import { ChangeEvent, useEffect } from "react";

type InputProps = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  label?: string;
  placeholder?: string;
  classType?: string;
  error?: string;
  isInitHeight?: boolean;
  isStatic?: boolean;
  maxLength?: number;
};

export const Textarea = ({
  name,
  label,
  placeholder,
  classType,
  error,
  isInitHeight,
  register,
  setValue,
  maxLength,
  isStatic
}: InputProps) => {
  const onMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setValue(name, "");
  };

  useEffect(() => {
    if (isInitHeight) {
      const textarea = document.getElementById(name) as HTMLTextAreaElement;
      textarea.style.height = "56px";
      textarea.style.overflowY = "hidden";
    }
  }, [isInitHeight]);

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if(!isStatic){
      e.currentTarget.style.height = "auto";
      e.currentTarget.style.overflowY = "auto";
      e.currentTarget.style.height = `${e.currentTarget.scrollHeight+3}px`;
    }
  };
 
  return (
    <div className={styles.inputContainer}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={styles.inputWithCleanUp}>
        <textarea
          className={`${styles.input} ${classType ? styles.groupItem : ""} ${
            error ? styles.error : error
          } ${isStatic ? styles.wide:""}`}
          id={name}
          placeholder={placeholder}
          {...register(name)}
          autoComplete="off"
          maxLength={maxLength}
          onInput={handleInput}
        />
        <span className={styles.icon} onMouseDown={onMouseDown}>
          <img src={icon} alt="" />
        </span>
      </div>

      {error && <p className={styles.errorMessage}> {error}</p>}
    </div>
  );
};
