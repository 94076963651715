import visaIcon from "../shared/image/visa.svg";
import masterCardIcon from "../shared/image/masterCard.svg";
import cardIcon from "../shared/image/IconCard.svg";

export const getCardImage = (nameCard: string): string => {
  switch (nameCard.toLowerCase()) {
    case "visa":
      return visaIcon;
    case "mastercard":
      return masterCardIcon;
    default:
      return cardIcon;
  }
}