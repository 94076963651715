import { useForm, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "../../../../shared/components/input/Input";
import styles from "./EditForm.module.scss";
import useUpdateErrors from "../../../../utils/useUpdateErrors";
import {MAX_NAME_LENGTH} from '../../../../constants/constants';
import {handleSpecificCharsKeyDown} from '../../../../utils/handleSpecificCharsKeyDown';

type EditNameWindowProps = {
  onClose: () => void;
  data: FieldValues;
  onSave: (data: FieldValues) => void;
  required?: boolean
};

export const EditNameWindow = ({ onClose, data, onSave, required }: EditNameWindowProps) => {
  const { t } = useTranslation();

  const { register, handleSubmit, setValue, formState: { isValid, dirtyFields} } = useForm({ defaultValues: data, mode: "onChange" });
  const onSubmit = (data: FieldValues) => {
    const updateData = {
      ...data,
      firstName: data.firstName ? data.firstName.replace(/\s+/g, ' ').trim() : '',
      lastName: data.lastName ? data.lastName.replace(/\s+/g, ' ').trim() : '',
      middleName: data.middleName ? data.middleName.replace(/\s+/g, ' ').trim() : '',
    }
    onClose();
    onSave(updateData);
  };
  useUpdateErrors(handleSubmit(onSubmit), !!Object.keys(dirtyFields).length);
  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <Input
          required={required}
          setValue={setValue}
          register={register}
          name="firstName"
          type="text"
          label={t("common.firstName")}
          placeholder={t("userProfile.editProfile.enterName")}
          maxLength={MAX_NAME_LENGTH}
          handleKeyDown={handleSpecificCharsKeyDown}
        />
        <Input
          required={required}
          register={register}
          setValue={setValue}
          name="lastName"
          type="text"
          label={t("common.lastName")}
          placeholder={t("userProfile.editProfile.enterLastName")}
          maxLength={MAX_NAME_LENGTH}
          handleKeyDown={handleSpecificCharsKeyDown}
        />
        <Input
          register={register}
          setValue={setValue}
          name="middleName"
          type="text"
          label={t("common.middleName")}
          placeholder={t("userProfile.editProfile.enterMiddleName")}
          maxLength={MAX_NAME_LENGTH}
          handleKeyDown={handleSpecificCharsKeyDown}
        />
      </div>
      <button className={styles.formButton} type="submit" disabled={!isValid}>
        {t("common.save")}
      </button>
    </form>
  );
};
