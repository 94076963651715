import { createSlice } from "@reduxjs/toolkit";
import {ListType} from '../../components/filter/FilterByLocationAndReligion/AlphabeticalLocationBlock';

export type SelectedCountryType = {
  id: string;
  isoCode: string;
  name: string;
  cities: ListType[]
}

export type ReligionDataType = {
  id: string;
  code: number;
  name: string;
}

export interface initialStateFilterType {
  birthDate: Date | null;
  deathDate: Date | null;
  searchValue: string;
  gender: number | null,
  ageFrom: string;
  ageTo: string;
  country: {id: string; isoCode: string; name: string; cities: ListType[]}[];
  selectedCountryData: SelectedCountryType[];
  selectedReligionData: ReligionDataType[];
  offsetSearch: number;
  filterModified: boolean;
  filterTrigger: boolean;
  countryIds: string[];
  cityIds: string[];
  religionIds: string[];
  scrollCityData: any;
  offset: number;
  isLoadingNewCities: boolean;
  currentLang: string;
}

type Date = {
  year: number;
  month: number;
  day: number;
}

const initialState: initialStateFilterType = {
  searchValue: '',
  gender: null,
  birthDate: null,
  deathDate: null,
  ageFrom: '',
  ageTo: '',
  country: [{id: '', isoCode: '', name: '', cities: []}],
  selectedCountryData: [],
  selectedReligionData: [],
  offsetSearch: 0,
  filterModified: false,
  filterTrigger: false,
  countryIds: [],
  cityIds: [],
  religionIds: [],
  scrollCityData: [],
  offset: 0,
  isLoadingNewCities: false,
  currentLang: '',
};

export const filtersFormSlice = createSlice({
  name: "filtersFormSlice",
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setOffset: (state, action) => {
      state.offsetSearch = action.payload;
    },
    updateFilter: (state, action) => {
      state.gender = action.payload.gender;
      state.ageFrom = action.payload.ageFrom;
      state.ageTo = action.payload.ageTo;
      state.countryIds = action.payload.countryIds;
      state.cityIds = action.payload.cityIds;
      state.religionIds = action.payload.religionIds;
      state.birthDate = action.payload.birthDate;
      state.deathDate = action.payload.deathDate;
    },
    updateFilterSelectedCountryData: (state, action) => {
      state.selectedCountryData = action.payload;
    },
    updateFilterReligionData: (state, action) => {
      state.selectedReligionData = action.payload;
    },
    updateFilterTrigger: (state, action) => {
      state.filterTrigger = action.payload;
    },
    updateFilterCityData: (state, action) => {
      state.scrollCityData = action.payload;
    },
    updateOffsetCityData: (state, action) => {
      state.offset = action.payload;
    },
    updateIsLoadingNewCities: (state, action) => {
      state.isLoadingNewCities = action.payload;
    },
    setFilterLanguage: (state, action) => {
      state.currentLang = action.payload;
    },
    cleaningTheFilter: (state) => {
      return {
        ...initialState,
        searchValue: state.searchValue,
      };
    },
    deleteState: () => {
      return initialState;
    },
  },
});
export const {
  setSearchValue,
  setOffset,
  updateFilter,
  deleteState,
  updateFilterTrigger,
  updateFilterReligionData,
  updateFilterCityData,
  updateOffsetCityData,
  updateIsLoadingNewCities,
  updateFilterSelectedCountryData,
  setFilterLanguage,
  cleaningTheFilter,
} =
  filtersFormSlice.actions;
export default filtersFormSlice.reducer;
