import styles from "./EditForm.module.scss";
import { setReligion } from "../../../store/slices/CreateProfileSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGetReligionsQuery } from "../../../api/UtilityService";
import { useState } from "react";
import { Loader } from "../../../shared/components/loader/Loader";
import { RootState } from "../../../store";
import { Religions } from "../../../components/religionBlock/Religions";
import { getLanguageByLocale } from "../../../utils/localeUtils";

type ReligionFormProps = {
  onClick: () => void;
};

export const ReligionForm = ({ onClick }: ReligionFormProps) => {
  const { language } = useSelector((state: RootState) => state.language);

  const { data } = useGetReligionsQuery(getLanguageByLocale(language));
  const { religionId } = useSelector(
    (state: RootState) => state.createProfile.mainInfo
  );

  const [selected, setSelected] = useState<string>(
    religionId ? religionId : ""
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (isSkip:boolean) => {
    dispatch(setReligion(isSkip ? null : selected ));
    onClick();
  };

  const onItemClick = (selectedItem: any) => {
    if (selectedItem.id === selected) {
      setSelected("");
    } else {
      setSelected(selectedItem.id);
    }
  };
  return (
    <div className={styles.form}>
      <div className={styles.formContainer}>
        <h3 className={styles.formHeader}>{t("createProfile.religion")}</h3>
        {data ? (
          <Religions data={data} selected={selected} onClick={onItemClick} />
        ) : (
          <Loader height={220} />
        )}
        <p className={styles.skipButton} onClick={() => onSubmit(true)}>
          {t("common.skip")}
        </p>
        <button
          className={styles.formButtonWithSkip}
          onClick={() => onSubmit(false)}
          disabled={!selected}
        >
          {t("common.next")}
        </button>
      </div>
    </div>
  );
};
