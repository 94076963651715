import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from "./NotificationPopup.module.scss";
import { useTranslation } from "react-i18next";
import {ModalWindow} from '../../../components/modalWindow/ModalWindow';


export const NotificationPopup = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setShow(true), 600000);
    };
    const handleActivity = () => {
      resetTimeout();
    };
    const handleScroll = () => {
      resetTimeout();
    };
    resetTimeout();
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const remindLater = () => {
    setShow(false);
    setTimeout(() => setShow(true), 600000);
  };

  const onClose = () => {
    setShow(false);
  }

  const onRedirect = () => {
    navigate("/createProfile");
    setShow(false);
  }

  return (
    <>
      {
        show && (
          <ModalWindow
            backdropModal={true}
            onClose={onClose}
            isShow={ show }
            headerText={t("common.notification.createProfile")}
            children={
              <>
                <p className={styles.description}>
                  {t("common.notification.createProfileDescription")
                    .split('\n')
                    .map((line: string, index: number) => <Fragment key={index}>{line}<br/></Fragment>)}
                </p>
                <button className={styles.remindButton}
                        onClick={remindLater}>{t("common.remindLater")}</button>
                <button className={styles.createButton}
                        onClick={onRedirect}>{t("profile.createProfile")}</button>
              </>
            }
          ></ModalWindow>
        )
      }
    </>
  );
};
