import { useForm, FieldValues } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../shared/components/input/Input";
import styles from "./EditForm.module.scss";
import {
  usePutUserNameMutation,
  useValidateUserNameQuery,
} from "../../../../api/UserService";
import { useEffect, useState } from "react";
import {MAX_USERNAME_LENGTH, MIN_USERNAME_LENGTH} from '../../../../constants/constants';
import useUpdateErrors from "../../../../utils/useUpdateErrors";

type EditEmailFormProps = {
  onClose: () => void;
  data: FieldValues;
};

export const EditUserNameForm = ({ onClose, data }: EditEmailFormProps) => {
  const [userName, setUserName] = useState(null);
  const [updateUserName] = usePutUserNameMutation();
  const {
    data: validationData,
    isLoading,
  } = useValidateUserNameQuery(userName, { skip: !userName, refetchOnMountOrArgChange:true});
  const { t } = useTranslation();

  const schema = yup
    .object({
      username: yup
        .string()
        .matches(
          /^[\w\-'a-zA-ZäöüßÄÖÜẞа-яА-ЯёЁёа-зй-шы-яЁА-ЗЙ-ШЫІіЎўа-ъьюяА-ЪЬЮЯáéíñóúüÁÉÍÑÓÚÜZæøåÆØÅąćęłńóśźżĄĆĘŁŃÓŚŹŻĂÂÎȘȚăâîșțàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒZäöåÄÖ.]+$/,
          t("userProfile.editProfile.userNameValidation")
        )
        .min(MIN_USERNAME_LENGTH, t("userProfile.editProfile.userNameTooShort"))
        .max(MAX_USERNAME_LENGTH, t("userProfile.editProfile.userNameTooLong")),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields },
    clearErrors,
  } = useForm({ defaultValues: data, resolver: yupResolver(schema) });

  const username = watch('username');

  const onSubmit = (data: FieldValues) => {
    setUserName(data.username);
    // refetch()
  };
  useUpdateErrors(handleSubmit(onSubmit), !!Object.keys(dirtyFields).length);


  useEffect(() => {
    if (!username) {
      clearErrors('username')
    }
  }, [username]);

  useEffect(() => {
    if (validationData?.isValid) {
      updateUserName(userName);
      onClose();
    }
  }, [validationData]);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <Input
          setValue={setValue}
          register={register}
          name="username"
          type="text"
          error={
            (errors.username?.message as string) ||
            (!validationData
              ? ""
              : validationData.isValid
              ? ""
              : t("userProfile.editProfile.usernameValidation"))
          }
        />
      </div>
      <button className={styles.formButton} type="submit" disabled={isLoading || !username || username === data.username}>
        {t("common.save")}
      </button>
    </form>
  );
};
