import styles from "./CommentWrapper.module.scss";
import { useGetReplysMutation } from "../../../../../../api/PostProfileService";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { CommentItem } from "../commentItem/CommentItem";
import { t } from "i18next";
import { CommentType } from "../../../../../../types/ProfileType";

type CommentWrapperProps = {
  postId: string;
  data: CommentType;
  onCommentDelete: (id: string) => void;
  onReplyDelete: (id: string) => void;
  onAddReply : (id: string, count?: number)=>void
};

export const CommentWrapper = ({
  postId,
  data,
  onCommentDelete,
  onReplyDelete,
  onAddReply
}: CommentWrapperProps) => {
  const { id } = useParams() as { id: string };
  const [getReplies, { data: replyData }] = useGetReplysMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<CommentType[]>([]);
  const [showPageCount, setShowPageCount] = useState<number>(0);
  const [loadedPageCount, setloadedPageCount] = useState<number>(0);
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const [addedComments, setAddedComments] = useState<CommentType[]>([]);
  const commentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (data) {
      setCommentsCount(data.replyCount as number);
    }
  }, [data]);

  useEffect(() => {
    if (loadedPageCount) {
      setIsLoading(true);
      getReplies({
        profileId: id,
        postId,
        commentId: data.id,
        pageNumber: loadedPageCount,
      });
    }
  }, [loadedPageCount]);

  useEffect(() => {
    if (replyData) {
      if (loadedPageCount === 1) {
        setComments([...replyData.items]);
      } else {
        setComments([...comments, ...replyData.items]);
      }
      if (addedComments.length) {
        const filteredArray = addedComments.filter(
          (obj1) =>
            !replyData.items.some((obj2: CommentType) => obj1.id === obj2.id)
        );
        setAddedComments(filteredArray);
      }
      setIsLoading(false);

      if(replyData && data.replyCount !== replyData.totalCount){
        onAddReply(data.id, replyData.totalCount)
      }
    }
  }, [replyData]);

  const onShowButtonClick = () => {
    if (!isLoading) {
      if (
        showPageCount + 1 === replyData?.totalPages &&
        showPageCount + 1 === loadedPageCount &&
        addedComments.length
      ) {
        setComments([...comments, ...addedComments]);
        setAddedComments([]);
        onAddReply(data.id,( data.replyCount || 0 )+ addedComments.length)
      }
      if (loadedPageCount <= showPageCount) {
        setloadedPageCount((prev) => prev + 1);
      }
      setShowPageCount((prev) => prev + 1);
    }
  };
  const onItemDelete = (id: string) => {
    setAddedComments([])
    setComments([])
    onCommentDelete(id)
  };
  const onDelete = (id: string) => {
    const filteredComments = comments.filter((item) => item.id !== id);
    setComments(filteredComments);
    onReplyDelete(data.id);
    setCommentsCount(commentsCount - 1);
  };
  const onAddedRepliesDelete = (id: string) => {
    const filteredComments = addedComments.filter((item) => item.id !== id);
    setAddedComments(filteredComments);
    onReplyDelete(data.id);
    setCommentsCount(commentsCount - 1);
  };
  const addComment = (value: CommentType) => {
    if (replyData?.totalPages === showPageCount) {
      setComments([...comments, value]);
      onAddReply(data.id,( data.replyCount || 0 )+ 1)
    } else {
      setAddedComments([...addedComments, value]);
    }
  };
  const hideReplies = () => {
    setShowPageCount(0)
    commentRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const hideAddedComments = () => {
    if (replyData?.totalPages === loadedPageCount) {
      setComments([...comments, ...addedComments]);
    }
    onAddReply(data.id,( data.replyCount || 0 )+ addedComments.length)

    setAddedComments([]);
    setShowPageCount(0)
    commentRef?.current?.scrollIntoView({ behavior: 'smooth' });

  };
  return (
    <div className={styles.commentsContainer} ref={commentRef}>
      {!!data && (
        <CommentItem
          data={data}
          postId={postId}
          onItemDelete={onItemDelete}
          setIsShowReplies={() => onShowButtonClick()}
          isShowRepliesButton={!showPageCount && !!commentsCount && !(!replyData?.totalCount && commentsCount ===1  && !!addedComments.length)}
          addNewComment={addComment}
          commentsCount={commentsCount}
        />
      )}

      {!!showPageCount && (
        <div className={styles.repliesContainer}>
          {comments.slice(0, 10 * showPageCount).map((item: any) => (
            <CommentItem
              data={item}
              postId={postId}
              onItemDelete={onDelete}
              key={item.id}
              addNewComment={addComment}
            />
          ))}
        </div>
      )}
      {!!commentsCount && !!showPageCount && (
        <div className={styles.actionContainer}>
          <span />
          {commentsCount > showPageCount * 10 && !!showPageCount ? (
            <p onClick={onShowButtonClick}>{`${t(
              "posts.showReplies"
            )} (${Math.min(commentsCount - showPageCount * 10, 10)})`}</p>
          ) : (
            <p onClick={hideReplies}>{t("posts.hideReplies")}</p>
          )}
          <span />
        </div>
      )}
      {!!addedComments.length && (
        <div className={styles.repliesContainer}>
          {addedComments.map((comment: CommentType) => (
             <CommentItem
             data={comment}
             postId={postId}
             onItemDelete={onAddedRepliesDelete}
             key={comment.id}
             addNewComment={addComment}
           />
          ))}
        </div>
      )}
      {!!addedComments.length && (
         <div className={styles.actionContainer}>
          <span />
       <p onClick={hideAddedComments}>{t("posts.hideReplies")}</p>
       </div>
      )}
    </div>
  );
};
