import React from 'react';
import { useTranslation } from "react-i18next";

import styles from "./ModalWindowHeader.module.scss";
import arrow from "../../image/backArrow.svg";
import map from "../../image/map.png";
import list from "../../image/list.png";

type PageControlProps = {
  title: string;
  onClick: () => void;
  onReset?: () => void;
  width?: string;
  padding?: string;
  type?: string;
  setIsListView?: () => void;
  isListView?: boolean;

};

const ModalWindowHeader = ({
  title,
  onClick,
  onReset,
  width,
  padding,
  type,
  isListView,
  setIsListView,
}: PageControlProps) => {
  const { t } = useTranslation();

  const onImageClick = () => {
    if (setIsListView) {
      setIsListView();
    }
  };
  const handleReset = () => {
    if(onReset) {
      onReset()
    }
  };

  return (
    <div
      className={
        (type === "ownedProfile" || type === "filter")
          ? styles.headerProfileContainer
          : styles.headerContainer
      }
      style={{ maxWidth: width, padding: padding }}
    >
      <img src={arrow} alt="" onClick={onClick} />

      <p>{title}</p>
      {type === "ownedProfile" && (
        <img src={isListView ? map : list} alt="" onClick={onImageClick} />
      )}
      {type === "filter" && (
        <div
          className={styles.headerSearchReset}
          onClick={handleReset}
        >
          {t("filter.reset")}
        </div>
      )}
    </div>
  );
};

export default ModalWindowHeader;
