import { useTranslation } from "react-i18next";
import styles from "./SearchInput.module.scss";
import { ReactComponent as SearchIcon } from "../../image/searchIcon.svg";
import { ReactComponent as SearchCoords } from "../../image/searchCoords.svg";
import { ReactComponent as ArrowIcon } from "../../image/arrowIcon.svg";
import { ReactComponent as FilterIcon } from "../../image/filterIcon.svg";

import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";

type PropsType = {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  type?: string;
  onClick?: () => void;
  toggleFilter?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: string;
  isDisabledButton?: boolean;
  isDisabled?: boolean;
  isHistory?: boolean;
  hideBorder?: boolean;
  maxLength?: number;
  isShowFilter?: boolean;
  filterTrigger?: boolean;
  caption?: string;
  positionStyle?: boolean;
};

export const SearchInput = ({
  value,
  onChange,
  placeholder,
  type,
  onClick,
  toggleFilter,
  error,
  isDisabledButton,
  isDisabled,
  onBlur,
  hideBorder,
  maxLength,
  isShowFilter,
  filterTrigger,
  caption,
  isHistory,
  onFocus,
  positionStyle,
}: PropsType) => {
  const { t } = useTranslation();
  const [isOnFocus, setIsOnFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const mainSearchStyle =
    type === "main_search" ? styles.mainSearchContainer : "";

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (isOnFocus) {
        event.preventDefault();
      }
    };
    inputRef.current?.addEventListener("wheel", handleWheel);

    return () => {
      inputRef.current?.removeEventListener("wheel", handleWheel);
    };
  }, [isOnFocus]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const onSearchClick = () => {
    if (onClick && !isDisabledButton) {
      onClick();
    }
  };
  const onFilterClick = () => {
    if (toggleFilter) {
      toggleFilter();
    }
  };
  const onInputBlur = () => {
    setIsOnFocus(false);
    if (onBlur) {
      onBlur();
    }
  };
  const onInputFocus = () => {
    setIsOnFocus(true);
    if (onFocus) {
      onFocus();
    }
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onClick) {
      e.stopPropagation();
      e.preventDefault();
      onClick();
    }
  };

  const getImage = () => {
    switch (type) {
      case "coords":
        return <SearchCoords />;
      case "arrow":
        return <ArrowIcon />;
      case "empty":
        return "";
      default:
        return (
          <SearchIcon
            className={`${
              !isDisabledButton ? styles.activeField : styles.inactiveField
            }`}
          />
        );
    }
  };
  const getClassName = () => {
    if (error) {
      return styles.searchContainerError;
    }
    if (isDisabled) {
      return styles.searchContainerDisabled;
    }
    if (isHistory) {
      return styles.searchContainerHistory;
    }
    if (positionStyle) {
      return styles.positionContainerOpen;
    }
    return styles.searchContainer;
  };
  return (
    <>
      <div
        className={`
                ${getClassName()}
                ${mainSearchStyle}
                ${value && styles.fullContainer}
                ${type === "search" ? styles.searchCityContainer : ""}
                ${hideBorder ? styles.hideBorder : ""}
                ${!!caption ? styles.captionContainer : ""}
            `}
        ref={inputRef}
      >
        <div className={styles.inputContainer}>
          <div>
            {!!value && !!caption && (
              <p className={styles.caption}> {caption}</p>
            )}
          </div>
          <input
            className={`${!!caption ? styles.captionInput :''} ${isHistory ? styles.historyInput : ''} }`}
            onChange={onInputChange}
            value={value}
            maxLength={maxLength}
            placeholder={placeholder || t("common.search")}
            autoComplete="nope"
            disabled={isDisabled || isHistory}
            onBlur={onInputBlur}
            onKeyDown={handleKeyDown}
            onFocus={onInputFocus}
          />
        </div>
        <div
          className={
            type === "main_search"
              ? styles.mainSearchImgContainer
              : styles.imgContainer
          }
          onClick={onSearchClick}
        >
          {getImage()}
        </div>
        {type === "main_search" && (
          <div
            className={`${styles.filterImgContainer}`}
            onClick={onFilterClick}
          >
            <div className={`${isShowFilter ? styles.filterActive : ""}`}>
              <FilterIcon
                className={`${
                  isShowFilter || filterTrigger ? styles.activeField : ""
                }`}
              />
            </div>
          </div>
        )}
      </div>
      {error && <p className={styles.errorMessage}> {error}</p>}
    </>
  );
};
