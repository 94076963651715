import styles from "./DateDropdown.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown } from "../dropdown/Dropdown";

type DropdownData = {
  id: number;
  name: string;
};
type ValueType = {
  year: number;
  month: number;
  day: number;
};
type DropdownProps = {
  onChange: (value: ValueType) => void;
  error?: string;
  value: ValueType;
  isUnknown?: boolean;
  smallSize?: boolean;
};

export const DateDropdown = ({
  onChange,
  error,
  isUnknown,
  value,
  smallSize,
}: DropdownProps) => {
  const [month, setMonth] = useState<number>(-1);
  const [day, setDay] = useState<number>(0);
  const [year, setYear] = useState<number>(-1);
  const [allDays, setAllDays] = useState<DropdownData[]>([]);
  const { t } = useTranslation();

  const months = t("createProfile.months", {
    returnObjects: true,
  }) as DropdownData[];

  useEffect(() => {
    if (value) {
      setDay(value.day);
      setMonth(value.month);
      setYear(value.year);
    }
  }, [value]);

  useEffect(() => {
    const newAllDays = getDaysObject();
    setAllDays(newAllDays);

    if (!newAllDays[day]) {
      setDay(newAllDays[newAllDays.length - 1]?.id);
    }
  }, [month, year]);

  useEffect(() => {
    if (isUnknown) {
      setDay(0);
      setMonth(-1);
      setYear(-1);
    }
  }, [isUnknown]);

  const range = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
      return { id: start + i * step, name: String(start + i * step) };
    });

  const getAllDaysInMonth = () => {

    const date = new Date(year === -1 ? 1900 : year,month-1 ,1);
    date.setFullYear(year === -1 ? 1900 : year,month-1 ,1);
    const dates = [];

    while (date.getMonth() + 1 === month) {
      dates.push(new Date(date).getDate());
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };

  const getDaysObject = () => {
    let arr: DropdownData[] = [];

    if (month === -1) {
      arr = range(1, 31, 1);
      return arr;
    }

    const allDays = getAllDaysInMonth();
    arr = range(allDays[0], allDays[allDays.length - 1], 1);
    return arr;
  };
  const changeDay = (value: number) => {
    setDay(value);
    onChange({ year, month, day :value });
  };
  const changeMonth = (value: number) => {
    setMonth(value);
    onChange({ year, month: value, day });
  };
  const changeYear = (value: number) => {
    setYear(value);
    onChange({ year:value, month, day });
  };
  return (
    <div>
      <div className={styles.datePickerContainer}>
        <Dropdown
          data={allDays}
          value={day}
          onChange={changeDay}
          type="date"
          isError={!!error}
          height="270px"
          placeholder={t('common.day')}
          smallSize={!!smallSize}
        />
        <Dropdown
          data={months}
          value={month}
          onChange={changeMonth}
          type="date"
          isError={!!error}
          height="270px"
          placeholder={t('common.month')}
          smallSize={!!smallSize}
        />
        <Dropdown
          data={range(1, new Date().getFullYear(), 1).reverse()}
          value={year}
          onChange={changeYear}
          type="date"
          isError={!!error}
          height="270px"
          placeholder={t('common.year')}
          smallSize={!!smallSize}
        />
      </div>

      {error && <p className={styles.errorMessage}> {error}</p>}
    </div>
  );
};
