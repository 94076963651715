import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


const initialState = {
  userPhoto: "",
};

const baseUrl = process.env.REACT_APP_MEDIA_DNS_NAME as string;

export const fetchPhoto = createAsyncThunk(
  "mediaSlice/fetchPhoto",
  async (mediaId: string) => {
    const response = await fetch(`${baseUrl}/api/media/${mediaId}`);
    const fileData = await response.blob();

      return await URL.createObjectURL(fileData);
  }
);

export const mediaSlice = createSlice({
  name: "mediaSlice",
  initialState,
  reducers: {
    deleteImage: (state) => {
      state.userPhoto = "";
    },
    setImage: (state,  action) => {
      state.userPhoto = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhoto.fulfilled, (state, action) => {
        state.userPhoto = action.payload;
      })
  },
});


export const {
  deleteImage,
  setImage
} = mediaSlice.actions;
export default mediaSlice.reducer;
