import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token-order");
  return token;
};

const baseUrl = process.env.REACT_APP_ORDER_DNS_NAME as string;

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        // include token in req header
        headers.set(`authorization`, `Bearer ${token}`);
        // headers.set('Access-Control-Allow-Origin', 'no-cors')
        return headers;
      }
    },
  }),
  endpoints: (build) => ({
    createCandleOrder: build.mutation({
      query: (body) => ({
        url: `/api/order/candle`,
        method: "POST",
        body: body,
      }),
    }),
    getPrice: build.mutation({
      query: ({
        currency = "EUR",
        pageNumber = 1,
        pageSize = 15,
        orderBy = "PaidAt",
        orderDirection = 1,
      }) => ({
        url: `/api/order/candle/prices`,
        params: { pageNumber, pageSize, orderBy, orderDirection, currency },
      }),
    }),
    getFreeCandleCount: build.query({
      query: () => ({
        url: `/api/order/candle/free/count`,
      }),
    }),
    getOrderHistoryCandle: build.query({
      query: ({ pageNumber = 1, pageSize = 15, orderDirection = 1 }) => ({
        url: `/api/order/candle`,
        params: { pageNumber, pageSize, orderDirection },
      }),
    }),
    getOrderHistoryPlates: build.query({
      query: ({ pageNumber = 1, pageSize = 15, orderDirection = 1 }) => ({
        url: `/api/order/plate`,
        params: { pageNumber, pageSize, orderDirection },
      }),
    }),
    createFeelingsOrder: build.mutation({
      query: (body) => ({
        url: `/api/order/feeling`,
        method: "POST",
        body: body,
      }),
    }),
    getFeelings: build.mutation({
      query: ({
        currency = "EUR",
        pageNumber = 1,
        pageSize = 15,
        orderBy = "PaidAt",
        orderDirection = 1,
      }) => ({
        url: `/api/order/feeling/prices`,
        params: { pageNumber, pageSize, orderBy, orderDirection, currency },
      }),
    }),
    deleteSubscription: build.mutation({
      query: (body) => ({
        url: `/api/order/candle/disable-subscription`,
        method: "DELETE",
        body: body,
      }),
    }),
  }),
});
export const {
  useCreateCandleOrderMutation,
  useGetPriceMutation,
  useGetFreeCandleCountQuery,
  useGetOrderHistoryCandleQuery,
  useGetOrderHistoryPlatesQuery,
  useCreateFeelingsOrderMutation,
  useGetFeelingsMutation,
  useDeleteSubscriptionMutation,
} = orderApi;
