import styles from "./SwitchCheckbox.module.scss";

type SwitchCheckboxProps = {
  isChecked: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

export const SwitchCheckbox = ({ onChange, isChecked, disabled }: SwitchCheckboxProps) => {
  return (
    <label className={styles.checkboxIos}>
      <input type="checkbox"
             onChange={() => onChange(!isChecked)}
             checked={isChecked}
             disabled={disabled}
      />
      <span className={styles.checkboxIosSwitch}></span>
    </label>
  );
};
