import React, { useState } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./OrderStatusPage.module.scss";
import smallArrow from "../../shared/image/arrowIcon.svg";
import { InputInitial } from '../../shared/components/input/InputInitial';
import { useGetPlateStatusQuery } from '../../api/PlateService';
import { splitIntoParagraphs } from '../../utils/splitIntoParagraph';
import { LoadingPage } from '../loadingPage/LoadingPage';

export const OrderStatusPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderNo } = useParams();
  const [ orderNumber, setOrderNumber ] = useState<string>(orderNo ? orderNo : '');
  const [ checkOrderStatus, setCheckOrderStatus ] = useState<boolean>(false);
  const { data: orderStatus, refetch, error, isFetching } = useGetPlateStatusQuery({orderNo: orderNumber}, {skip: !checkOrderStatus});

  const checkStatus = () => {
    setCheckOrderStatus(true);
    if (orderNumber && orderNumber.trim() !== '') {
      navigate(`/paymentStatus/${orderNumber}`);
    }
  };

  const handleInput = (value: string) => {
    setCheckOrderStatus(false);
    setOrderNumber(value)
  };

  const resetOrderNo = () => {
    navigate('/paymentStatus');
    setOrderNumber('')
    setCheckOrderStatus(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          <div
            className={styles.breadcrumbsContainer}
            onClick={() => navigate("/")}
          >
            <img src={smallArrow} alt="" />
            <p>{t("common.breadcrumbs.homePage")}</p>
          </div>
          <div>
            <h1 className={styles.header}>
              {t("order.orderStatus")}
            </h1>
            <div className={styles.description}>
              {splitIntoParagraphs(t("order.descriptionOrderPage")).map(
                (paragraph, index) => (
                  <p key={index}>
                    {paragraph}<br/>
                  </p>
                )
              )}
            </div>
          </div>
        </div>
        <div className={styles.enterOrderNumberBlock}>
          <div>
            <p className={styles.label}>{t("order.enterOrderNumber")}</p>
            <InputInitial
              name="paymentStatus"
              value={orderNumber}
              setValue={handleInput}
              inputStyle={styles.inputStyle}
              placeholder={t('order.orderNumber')}
            />
            <button
              className={styles.checkStatusButton}
              onClick={checkStatus}
              disabled={(orderStatus && orderNumber && checkOrderStatus) || (error && orderNumber) || !orderNumber}>
              {t('order.check')}
            </button>
            { isFetching ? <LoadingPage type={'status'}/> :
              orderStatus?.orderNo === Number(orderNumber)
              && checkOrderStatus && orderStatus && orderNumber && !error && (
                <div className={styles.response}>
                  <span>{t('order.yourOrder')}:</span>
                  <p className={styles.status}>{t(`order.status.${orderStatus.status}`)}</p>
                </div>
              )
            }
            {(error && orderNumber) && (
              <div className={styles.responseError}>
                <span>{t(`order.orderNotFound`)}</span>
              </div>
            )}
          </div>
          <div>
            {
              checkOrderStatus && orderNumber && (orderStatus || error) && (
                <div className={styles.flexCol}>
                  <button className={styles.updateButton} onClick={() => refetch()}>{t('order.update')}</button>
                  <button className={styles.checkStatusButton} onClick={resetOrderNo}>{t('order.checkAnotherNumber')}</button>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};
