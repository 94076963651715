import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSpinner: false,
};

export const spinnerSlice = createSlice({
  name: "spinnerSlice",
  initialState,
  reducers: {
    setShowSpinner: (state, action) => {
      state.showSpinner = action.payload;
    },
  },
});
export const { setShowSpinner } =
    spinnerSlice.actions;
export default spinnerSlice.reducer;
