import {useEffect, useState} from "react";

import styles from "../ProfileInfo.module.scss";


type AvaCandlePropsType = {
  avatarCandlesUrl: string[];
}
const url = process.env.REACT_APP_BLOB_DNS_NAME as string;
export const AvatarProfileCandle = ({ avatarCandlesUrl }: AvaCandlePropsType) => {
  const [avatarCandleImgChangeIndex, setAvatarCandleImgChangeIndex] = useState(0);
  const shouldAnimate = avatarCandlesUrl.length > 1;

  useEffect(() => {
    if (!!avatarCandlesUrl && avatarCandlesUrl.length > 1) {
      const intervalId = setInterval(() => {
        setAvatarCandleImgChangeIndex((prevIndex) => (prevIndex + 1) % avatarCandlesUrl.length);
      }, 7000);
      return () => {
        clearInterval(intervalId);
      }
    }
  }, [avatarCandlesUrl]);

  return (
    <>
      {!!avatarCandlesUrl.length && (
        <div className={`${styles.candleContainer} ${shouldAnimate ? styles.candleContainerAnimated : ''}`}>
          {avatarCandlesUrl && avatarCandlesUrl.map((candle: string, i: number) => (
            <img
              key={i}
              src={`${url}${candle}`}
              alt=""
              className={`${styles.candle} ${shouldAnimate ? styles.candleAnimate : ''} ${(i === avatarCandleImgChangeIndex && shouldAnimate) ? styles.visible : ''}`}
            />
          ))}
        </div>
      )}
    </>
  );
};
