import styles from "./FileError.module.scss";
import { ModalWindow } from "../../../components/modalWindow/ModalWindow";
import { useTranslation } from "react-i18next";

type FileErrorProps = {
    onClose: () => void;
    errorFileNames?: Array<string>;
    showImageError: boolean;
    showVideoError: boolean;
    showFormatError:boolean
};

const maxImageSize = !!process.env.MAX_IMAGE_SIZE ? (+process.env.MAX_IMAGE_SIZE / 1024 / 1024) : 15;
const maxVideoSize = !!process.env.MAX_VIDEO_SIZE ? (+process.env.MAX_VIDEO_SIZE / 1024 / 1024) : 100;

export const FileError = ({onClose, errorFileNames, showVideoError, showImageError, showFormatError}: FileErrorProps) => {
    const { t } = useTranslation();

    return (
          <ModalWindow
              onClose={onClose}
              isShow={true}
              isError={true}
              headerText={t("common.error")}
              children={
                  <div className={styles.container}>
                      <div className={styles.title}>{showFormatError? t("common.fileError.descriptionFormat"):t("common.fileError.title")}</div>
                      {
                          showImageError &&
                          <div className={styles.description}>
                              {`${t("common.fileError.descriptionImage")} ${maxImageSize} ${t("common.fileError.mb")}`}
                          </div>
                      }
                      {
                          showVideoError &&
                          <div className={styles.description}>
                              {`${t("common.fileError.descriptionVideo")} ${maxVideoSize} ${t("common.fileError.mb")}`}
                          </div>
                      }
                      {
                          !!errorFileNames && !!errorFileNames.length &&
                          <div className={styles.description}>
                              {`${t("common.fileError.invalidFiles")} ${errorFileNames.join(", ")}`}
                          </div>
                      }
                      <button className={styles.button} onClick={onClose}> {t("common.close")}</button>
                  </div>

              }
          ></ModalWindow>
    );
}