import { useEffect, useState } from "react";
import styles from "./CookiePopup.module.scss";
import { useTranslation } from "react-i18next";
import { ModalWindow } from "../modalWindow/ModalWindow";
import { CookieSettings } from "./cookieSettings/CookieSettings";
import Cookies from "js-cookie";

export const CookiePopup = () => {
  const { t } = useTranslation();
  const [isShowSettings, setIsShowSettings] = useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(true);

  useEffect(() => {
    const storedConsent = Cookies.get("cookieConsent");
    if (!storedConsent) {
      setConsent(false);
    }
  }, []);
  function highlightLastWord(text: string) {
    const words = text.split(" ");
    const lastWord = words.pop();
    return (
      <>
        {words.join(" ")}{" "}
        <span
          className={styles.setting}
          onClick={() => setIsShowSettings(true)}
        >
          {lastWord}
        </span>
        .
      </>
    );
  }

  const onAcceptCookie = () => {
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setConsent(true);
  };

  if (consent) {
    return null;
  }
  return (
    <>
      <div className={styles.cookieContainer}>
        <p className={styles.description}>
          {highlightLastWord(t("cookie.description"))}
        </p>
        <button className={styles.button} onClick={onAcceptCookie}>
          {t("cookie.accept")}
        </button>
      </div>
      {isShowSettings && (
        <ModalWindow
          onClose={() => setIsShowSettings(false)}
          isShow={isShowSettings}
          headerText={"Cookie"}
          isOrder
          children={<CookieSettings onAcceptCookie={onAcceptCookie}/>}
        ></ModalWindow>
      )}
    </>
  );
};
