import styles from "./SpinnerPage.module.scss";
import { useTranslation } from "react-i18next";

export const SpinnerPage = () => {
  const { t } = useTranslation();
  return (
      <div className={styles.spinnerPageWrapper}>
          <div className={styles.spinnerPageSpin}></div>
          <div className={styles.description}>
              {t("common.loading")}
          </div>
      </div>
  );
};
