import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showWarningModal: false,
};

export const warningModalSlice = createSlice({
  name: "warningModalSlice",
  initialState,
  reducers: {
    setShowWarningModal: (state, action) => {
      state.showWarningModal = action.payload;
    },
  },
});
export const { setShowWarningModal } =
  warningModalSlice.actions;
export default warningModalSlice.reducer;
