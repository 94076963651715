import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {setErrorContact} from '../store/slices/UserContactErrorSlice';
const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token-user");
  return token;
};

const baseUrl = process.env.REACT_APP_USER_DNS_NAME as string;

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  tagTypes: ["Client", "Clients"],
  endpoints: (build) => ({
    getUserInfoById: build.query({
      query: (userId) => ({
        url: `/api/user/Info/${userId}`,
      }),

    }),
    getAuthorizedUserInfo: build.query({
      query: () => ({
        url: `/api/users/me/Info`,
      }),
    }),
    deleteUser: build.mutation({
      query: (username) => ({
        url: `/api/users/me/Info`,
        method: "DELETE",
      }),
     
    }),
    putUser: build.mutation({
      query: (body) => ({
        url: `/api/users/me/Info`,
        method: "PUT",
        body: body,
      }),
      async onQueryStarted({example}, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            userApi.util.upsertQueryData("getAuthorizedUserInfo", "", data)
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    putUserName: build.mutation({
      query: (username) => ({
        url: `/api/users/me/Username/${username}`,
        method: "PUT",
      }),
      async onQueryStarted(username, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            userApi.util.updateQueryData("getAuthorizedUserInfo", "", (draft) => {
              draft.username = username
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    validateUserName: build.query({
      query: (username) => ({
        url: `/api/users/Username/validate?username=${username}`,
        method: "GET",
      }),
    }),
    changePassword: build.mutation({
      query: (body) => ({
        url: `/api/Password/Me/Change`,
        method: "PATCH",
        body: body,
      }),
    }),
    
    getUserContacts: build.query({
      query: () => ({
        url: `/api/users/me/Contacts`,
      }),
    }),
    postUserContacts: build.mutation({
      query: (body) => ({
        url: `/api/users/me/Contacts`,
        method: "POST",
        body: body,
      }),
      
    }),
    putContact: build.mutation({
      query: (body) => ({
        url: `/api/users/me/Contacts/${body.contactId}`,
        method: "PUT",
        body: body.data,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData("getUserContacts", "", (draft) => {
             const item = (draft as any[]).find(item=> item.id === body.contactId)
             if (item.countryCode) {
               item.countryCode = data.countryCode
             }
            })
          );
        } catch (e: any) {
          dispatch(setErrorContact(e.error.data))
        }
      },
    }),
    sendVerificationCode: build.mutation({
      query: (contactId) => ({
        url: `/api/users/me/Contacts/${contactId}/sendVerificationCode`,
        method: "POST",
      }),
    
    }),
    checkCode: build.mutation({
      query: (body) => ({
        url: `/api/users/me/Contacts/${body.contactId}/verify?verificationCode=${body.code}`,
        method: "PATCH",
      }),
     
    }),
    deleteUserContacts: build.mutation({
      query: (contactId) => ({
        url: `/api/users/me/Contacts/${contactId}`,
        method: "DELETE",
      }),
    
    }),
    putImage: build.mutation({
      query: (data) => ({
        url: `/api/users/me/Info/Picture`,
        method: "PUT",
        body: data,
       
      }),
      async onQueryStarted(body, { dispatch,queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData("getAuthorizedUserInfo", "", (draft) => {
             draft.profilePictureId = data
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deleteImage: build.mutation({
      query: () => ({
        url: `/api/users/me/Info/Picture`,
        method: "DELETE",
       
      }),
      async onQueryStarted(body, { dispatch }) {
        try {
          dispatch(
            userApi.util.updateQueryData("getAuthorizedUserInfo", "", (draft) => {
             draft.profilePictureId = null
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
  }),
  
});
export const {
  useDeleteUserMutation,
  useGetUserInfoByIdQuery,
  useGetAuthorizedUserInfoQuery,
  usePutUserMutation,
  usePutUserNameMutation,
  useChangePasswordMutation,
  useValidateUserNameQuery,
  useGetUserContactsQuery,
  useSendVerificationCodeMutation,
  useCheckCodeMutation,
  usePutContactMutation,
  useDeleteUserContactsMutation,
  usePutImageMutation,
  useDeleteImageMutation
} = userApi;
