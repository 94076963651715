import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import styles from "./EditForm.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { DateDropdown } from "../../../shared/components/dateDropdown/DateDropdown";
import { Checkbox } from "../../../shared/components/checkbox/Checkbox";
import { setDate } from "../../../store/slices/CreateProfileSlice";
import { RootState } from "../../../store";
import { DateType } from "../../../types/CommonTypes";
import useUpdateErrors from "../../../utils/useUpdateErrors";

type DateFormProps = {
  onClick: () => void;
};

export const DateForm = ({ onClick }: DateFormProps) => {
  const {
    mainInfo: { dateOfBirth, dateOfDeath, deathReason },
    isUnknownDateOfBirth: isUnknownBirth,
    isUnknownDateOfDeath: isUnknownDeath,
  } = useSelector((state: RootState) => state.createProfile);
  const { t } = useTranslation();
  const [birthValue, setBirthValue] = useState<DateType>({
    year: -1,
    month: -1,
    day: 0,
  });
  const [deathValue, setDeathValue] = useState<DateType>({
    year: -1,
    month: -1,
    day: 0,
  });
  const [isDirty, setIsDirty] = useState(false);

  const dispatch = useDispatch();
  const [reasonOfDeath, setReasonOfDeath] = useState(deathReason);
  const [errorDateOfDeath, setErrorDateOfDeath] = useState("");
  const [errorDateOfBirth, setErrorDateOfBirth] = useState("");
  const [isUnknownDateOfDeath, setIsUnknownDateOfDeath] =
    useState(isUnknownDeath);
  const [isUnknownDateOfBirth, setIsUnknownDateOfBirth] =
    useState(isUnknownBirth);
  const errorMessage = t("createProfile.dateValidation");

  useEffect(() => {
    if (dateOfBirth) {
      setBirthValue({
        day: dayjs(dateOfBirth, "YYYY-MM-DD").date(),
        month: dayjs(dateOfBirth, "YYYY-MM-DD").month() + 1,
        year: dayjs(dateOfBirth, "YYYY-MM-DD").year(),
      });
    }
    if (dateOfDeath) {
      setDeathValue({
        day: dayjs(dateOfDeath, "YYYY-MM-DD").date(),
        month: dayjs(dateOfDeath, "YYYY-MM-DD").month() + 1,
        year: dayjs(dateOfDeath, "YYYY-MM-DD").year(),
      });
    }
  }, [dateOfBirth, dateOfDeath]);

  const validateDateOfDeath = () => {
    const birth = birthValue as DateType;
    const death = deathValue as DateType;

    if (isUnknownDateOfDeath && isUnknownDateOfBirth) return true;
    const emptyBirthDate = Object.values(birth).some((item) => item === -1);
    const emptyDeathDate = Object.values(death).some((item) => item === -1);

    const isDateBirthValid =
      (emptyBirthDate && isUnknownDateOfBirth) ||
      (!emptyBirthDate && !isUnknownDateOfBirth);
    const isDateDeathValid =
      (emptyDeathDate && isUnknownDateOfDeath) ||
      (!emptyDeathDate && !isUnknownDateOfDeath);

    if (!isDateBirthValid) {
      setErrorDateOfBirth(errorMessage);
    }
    if (!isDateDeathValid) {
      setErrorDateOfDeath(errorMessage);
    }
    const dateOfBirthObj = new Date(birth.year, birth.month-1, birth.day);
    dateOfBirthObj.setFullYear(birth.year);
    const dateOfDeathObj = new Date(death.year, death.month-1, death.day);
    dateOfDeathObj.setFullYear(death.year);

    const isCorrectDeathDate =
    (dateOfDeathObj >= dateOfBirthObj && dateOfDeathObj <= new Date())||
    (isUnknownDateOfBirth && dateOfDeathObj <= new Date())||
    isUnknownDateOfDeath;

    const isCorrectBirthDate =isUnknownDateOfBirth ||  dateOfBirthObj <= new Date()

    if (!isCorrectDeathDate) {
      setErrorDateOfDeath(errorMessage);
    }
    if (!isCorrectBirthDate) {
      setErrorDateOfBirth(errorMessage);
    }
    return isCorrectDeathDate && isDateBirthValid && isDateDeathValid && isCorrectBirthDate ;
  };
  const onSubmit = () => {
    setIsDirty(true)
    if (!validateDateOfDeath()) {
      return;
    }
    const birth = birthValue as DateType;
    const death = deathValue as DateType;

    let birthDate;
    if (birth.day) {
      birthDate = `${
        birth.year < 100
          ? "00" + birth.year
          : birth.year < 1000
          ? "0" + birth.year
          : birth.year
      }-${birth.month}-${birth.day}`;
    }

    let deathDate;
    if (death.day) {
      deathDate = `${
        death.year < 100
          ? "00" + death.year
          : death.year < 1000
          ? "0" + death.year
          : death.year
      }-${death.month}-${death.day}`;
    }
    dispatch(
      setDate({
        birthDate: birthDate,
        deathDate: deathDate || "",
        deathReason: reasonOfDeath.trim(),
        isUnknownDateOfBirth,
        isUnknownDateOfDeath,
      })
    );
    onClick();
  };

  const onDateChange = useCallback((date: DateType, type: string) => {
    if (type === "birth") {
      setBirthValue(date);

      if (date.day > 0 || date.month > 0 || date.year > 0) {
        setIsUnknownDateOfBirth(false);
      }
    } else {
      setDeathValue(date);
      if (date.day > 0 || date.month > 0 || date.year > 0) {
        setIsUnknownDateOfDeath(false);
      }
    }
  }, []);

  const onCheckboxChange = useCallback((value: boolean, type: string) => {
    if (type === "birth") {
      if (value) {
        setBirthValue({
          year: -1,
          month: -1,
          day: 0,
        });
      }
      setIsUnknownDateOfBirth(value);
    } else {
      if (value) {
        setDeathValue({
          year: -1,
          month: -1,
          day: 0,
        });
      }
      setIsUnknownDateOfDeath(value);
    }
  }, []);
  const checkIsDisabled = () => {
    const isBirthDateFilled =
      birthValue.day && birthValue.year > -1 && birthValue.month > -1;
    const isDeathDateFilled =
      deathValue.day && deathValue.year > -1 && deathValue.month > -1;
    return (
      (!isUnknownDateOfBirth && !isBirthDateFilled) ||
      (!isUnknownDateOfDeath && !isDeathDateFilled)
    );
  };
  useUpdateErrors(onSubmit, isDirty);

  return (
    <>
      <div className={styles.formContainer}>
        <h3 className={styles.formHeader}>{t("createProfile.birthDate")}</h3>
        <DateDropdown
          value={birthValue as DateType}
          onChange={(value) => onDateChange(value, "birth")}
          error={errorDateOfBirth}
          isUnknown={isUnknownDateOfBirth}
        />
        <div className={styles.checkboxContainer}>
          <Checkbox
            isChecked={isUnknownDateOfBirth}
            onChange={(value) => onCheckboxChange(value, "birth")}
            label={t("createProfile.unknown")}
          />
        </div>

        <h3 className={styles.formHeader}>{t("createProfile.deathDate")}</h3>
        <DateDropdown
          value={deathValue as DateType}
          onChange={(value) => onDateChange(value, "death")}
          error={errorDateOfDeath}
          isUnknown={isUnknownDateOfDeath}
        />
        <div className={styles.checkboxContainer}>
          <Checkbox
            isChecked={isUnknownDateOfDeath}
            onChange={(value) => onCheckboxChange(value, "death")}
            label={t("createProfile.unknown")}
          />
        </div>
        <h3 className={styles.formHeader}>
          {t("createProfile.reasonOfDeath")}
        </h3>
        <input
          value={reasonOfDeath}
          onChange={(e) => setReasonOfDeath(e.target.value)}
          className={`${styles.input}`}
          placeholder={t("createProfile.reasonOfDeathPlaceholder")}
          maxLength={64}
        />
        <button
          className={`${styles.formButton} ${styles.formDateButton}`}
          type="submit"
          onClick={onSubmit}
          disabled={checkIsDisabled()}
        >
          {t("common.next")}
        </button>
      </div>
    </>
  );
};
