import { useEffect, useState } from "react";
import styles from "./MemorialHistoryItem.module.scss";

import { useTranslation } from "react-i18next";
import { OrderHistoryType } from "../../../../../types/OrderHistoryType";
import { pluralize } from "../../../../../utils/pluralizeUtils";
import  dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { Link } from "react-router-dom";
import { ModalWindow } from "../../../../modalWindow/ModalWindow";
import { useDeleteSubscriptionMutation } from "../../../../../api/OrderService";
import { useDispatch } from "react-redux";
import { deleteSubscriptionId } from "../../../../../store/slices/OrderHistorySlice";
import { setShowSpinner } from "../../../../../store/slices/SpinnerSlice";

type MemorialHistoryItemProps = {
  data: OrderHistoryType;
};
dayjs.extend(utc);

export const MemorialHistoryItem = ({ data }: MemorialHistoryItemProps) => {
  const { t } = useTranslation();
  const [isShowModalWindow, setIsShowModalWindow] = useState<boolean>(false);
  const [deleteSubscription, { isSuccess }] = useDeleteSubscriptionMutation();
  const url = process.env.REACT_APP_BLOB_DNS_NAME as string;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(deleteSubscriptionId(data.id));
      dispatch(setShowSpinner(false));
    }
  }, [isSuccess]);
  
  const onPopupClick = () => {
    deleteSubscription({ subscriptionId: data?.subscriptionId });
    setIsShowModalWindow(false);
    dispatch(setShowSpinner(true));

  };
  function getTime(endTime: string | Date, startTime?: string | Date) {
    const endTimeLocal = dayjs.utc(endTime).local();
    const startTimeLocal = startTime ? dayjs.utc(startTime).local() : dayjs();
    const timeDifference = Math.abs(
      endTimeLocal.diff(startTimeLocal, "seconds")
    );

    const seconds = Math.floor(timeDifference);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (startTimeLocal > endTimeLocal) {
      return 0;
    }
    if (days > 0) {
      return pluralize(
        days,
        t(`memorial.candleBurningData.Day.one`),
        t(`memorial.candleBurningData.Day.few`),
        t(`memorial.candleBurningData.Day.many`)
      );
    } else if (hours > 0) {
      return pluralize(
        hours,
        t(`memorial.candleBurningData.Hour.one`),
        t(`memorial.candleBurningData.Hour.few`),
        t(`memorial.candleBurningData.Hour.many`)
      );
    } else if (minutes > 0) {
      return pluralize(
        minutes,
        t(`memorial.candleBurningData.Minute.one`),
        t(`memorial.candleBurningData.Minute.few`),
        t(`memorial.candleBurningData.Minute.many`)
      );
    } else {
      return 0;
    }
  }
  return (
    <div className={styles.memorialHistoryItemContainer}>
      {!!data?.subscriptionId && (
        <div className={styles.subscriptionHeader}>
          <h3>{t("userProfile.orderHistory.automaticCandle")}</h3>
          <span onClick={() => setIsShowModalWindow(true)}>
            {t("userProfile.editProfile.change")}
          </span>
        </div>
      )}
      <div className={styles.memorialHistoryItemCard}>
        <div className={styles.imgContainer}>
          <img src={`${url}${data?.mediaUrl}`} alt="" />
        </div>
        <div className={styles.memorialHistoryItemBody}>
          <Link className={styles.link} to={`/profile/${data?.profileId}`}>
            <h4 className={styles.name}>{data?.profileFullName}</h4>
          </Link>
          <p className={styles.price}>{data?.cost} $</p>
          <p className={styles.burningTime}>{`${t(
            "userProfile.orderHistory.burningTime"
          )} ${getTime(data?.burnUpAt, data?.createdAt)}.`}</p>
          <p className={styles.residual}>{`${t(
            "userProfile.orderHistory.residual"
          )} ${getTime(data?.burnUpAt)}.`}</p>
        </div>
      </div>
      {isShowModalWindow && (
        <ModalWindow
          onClose={() => setIsShowModalWindow(false)}
          isShow={isShowModalWindow}
          backdropModal={true}
          headerText={t("userProfile.editProfile.change")}
          children={
            <div>
              <p className={styles.popupDescription}>
                {t("userProfile.orderHistory.automaticCandlePopup")}
              </p>
              <button
                className={styles.popupButton}
                onClick={() => setIsShowModalWindow(false)}
              >
                {t("common.close")}
              </button>
              <button
                className={styles.popupCloseButton}
                onClick={onPopupClick}
              >
                {t("userProfile.orderHistory.cancelAutomaticCandle")}
              </button>
            </div>
          }
        ></ModalWindow>
      )}
    </div>
  );
};
