import { PopupData } from "../../../types/CommonTypes";
import styles from "./ListItem.module.scss";

type PopupProps = {
  item: PopupData;
  onClick: (type: string) => void;
  type?: string;
  isSelected?: boolean;
  isWide?:boolean
};

export const ListItem = ({ item, onClick, isSelected,isWide,type }: PopupProps) => {
  return (
    <li
      className={`${styles.listItem} ${
        (item.type === "delete" || item.type === "complaint") ? styles.delete : ""
      } ${type === "popup" ? styles.popupItem : ""
      } ${isSelected ? styles.selected : ""} ${isWide? styles.wide :''} `}
      onClick={() => onClick(item.type)}
    >
      <p className={styles.description}>{item.description}</p>
      {item.name}
    </li>
  );
};
