import styles from "./Avatar.module.scss";
import { ReactComponent as UserIcon } from "../../image/userIcon.svg";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import {
  acceptImageFormats,
  allowedImageTypes,
} from "../../../constants/Media.constant";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  setFullImage,
  setPreviewImage,
} from "../../../store/slices/UserPhotoSlice";
import { setShowSpinner } from "../../../store/slices/SpinnerSlice";
import {
  useDeleteMediaFileMutation,
  usePostUserMainPhotoMutation,
} from "../../../api/MediaService";
import { FileError } from "../fileError/FileError";
import { Popup } from "../popup/Popup";
import { ImageItem, PopupData } from "../../../types/CommonTypes";
import { useTranslation } from "react-i18next";
import { useGetAuthorizedUserInfoQuery } from "../../../api/UserService";
import { ImageViewer } from "../../../components/imageViewer/ImageViewer";
import { useNavigate } from "react-router-dom";
import useIsAuthenticated from "../../../utils/useIsAuthenticated";
import { transformImage } from "../../../utils/transformImage";

type AvatarProps = {
  size?: string;
  isUserProfile?: boolean;
};
const maxImageSize = !!process.env.MAX_IMAGE_SIZE
  ? +process.env.MAX_IMAGE_SIZE
  : 15 * 1024 * 1024;
  const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const Avatar = ({ size, isUserProfile }: AvatarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { isAuthenticated } = useIsAuthenticated();
  const { skipUser } = useFetchWithMsal("user", true);
  const { skipMedia } = useFetchWithMsal("media", true);
  const dispatch = useDispatch<AppDispatch>();
  const { photo, fullPhoto } = useSelector(
    (state: RootState) => state.userPhotoSlice
  );
  const { data } = useGetAuthorizedUserInfoQuery("", {
    skip: skipUser || !isAuthenticated,
  });
  const [postMainPhoto, { isSuccess: isSuccessLoadPhoto, data: newPhoto }] =
    usePostUserMainPhotoMutation();
  const [deleteMediaFile, { isSuccess: isSuccessDeletePhoto }] =
    useDeleteMediaFileMutation();

  const [showFileErrorModal, setShowFileErrorModel] = useState<boolean>(false);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowGallery, setIsShowGallery] = useState<boolean>(false);
  const [editedPhoto, setEditedPhoto] = useState<File | null>(null);

  const popupData = (t("photoPopup", { returnObjects: true }) as PopupData[]).filter(item =>item.type != 'edit') ;
  const popupDataWithoutAvatar = t("photoPopupWithoutAvatar", {
    returnObjects: true,
  }) as PopupData[];

  useEffect(() => {
    if (isSuccessLoadPhoto) {
      dispatch(setShowSpinner(false));
      if(newPhoto.avatarPreviewUrl){
        dispatch(setPreviewImage(url + newPhoto.avatarPreviewUrl));
        dispatch(setFullImage(url + newPhoto.avatarUrl));
      }
    }
  }, [isSuccessLoadPhoto]);

  useEffect(() => {
    if (isSuccessDeletePhoto) {
      dispatch(setShowSpinner(false));
    }
  }, [isSuccessDeletePhoto]);

  const onPopupClick = (type: string) => {
    switch (type) {
      case "load":
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
        break;
      case "delete":
        deleteMediaFile(data?.avatarUrl);
        dispatch(setPreviewImage(""));
        dispatch(setFullImage(""));
        dispatch(setShowSpinner(true));
        break;
    
      case "open":
        if (photo) {
          setIsShowGallery(true);
        }
        break;
      default:
        break;
    }
    setIsShowPopup(false);
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!!file) {
      const fileType = file.type;
      if (allowedImageTypes.includes(fileType)) {
        if (file.size < maxImageSize) {
          if (!skipMedia) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            const newItem={original: file}
            transformImage(newItem as ImageItem)
            fileInputRef.current!.value = "";
            const formData = new FormData();
            formData.append("file", newItem.original);
            postMainPhoto(formData);
            dispatch(setShowSpinner(true));
          }
        } else {
          fileInputRef.current!.value = "";
          setShowFileErrorModel(true);
        }
      }
    }
  };
  const handleIconClick = (event: MouseEvent<HTMLDivElement>) => {
    if (size) {
      navigate("/userProfile");
    } else {
      event.stopPropagation();
      setIsShowPopup(true);
    }
  };
  const onRotate = (value: number, img: File) => {
    setEditedPhoto(img);
  };
  const onCloseViewer = () => {
    if (editedPhoto) {
      const formData = new FormData();
      formData.append("file", editedPhoto);
      postMainPhoto(formData);
      dispatch(setShowSpinner(true));
      setEditedPhoto(null);
    }

    setIsShowGallery(false);
  };
  return (
    <>
      <div
        className={`${styles.photoContainer} ${
          size ? styles.small : styles.big
        } ${photo ? styles.withoutBorder : ""}  ${
          isShowPopup ? styles.selected : ""
        }`}
        onClick={handleIconClick}
      >
        {!!photo ? (
          <img
            className={styles.bigPhoto}
            src={photo}
            alt=""
            draggable={false}
          />
        ) : (
          <div className={styles.bigPhoto}>
            <UserIcon />
          </div>
        )}

        <input
          accept={acceptImageFormats}
          id="icon-button-file"
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
      </div>
      {showFileErrorModal && (
        <FileError
          onClose={() => setShowFileErrorModel(false)}
          showImageError={true}
          showVideoError={false}
          showFormatError={false}
        />
      )}
      {isShowPopup && (
        <Popup
          data={photo ? popupData : popupDataWithoutAvatar}
          onClose={() => setIsShowPopup(false)}
          onClick={(type) => onPopupClick(type)}
          type={isUserProfile ? "userProfileAvatar" : "userAvatar"}
        ></Popup>
      )}
      {isShowGallery && (
        <ImageViewer
          isShow={isShowGallery}
          data={[
            {
              url: fullPhoto,
              thumbnail: photo,
              description: "image",
              original: editedPhoto || undefined,
            } as ImageItem,
          ]}
          isShowRotate
          onClose={onCloseViewer}
          onRotate={onRotate}
          startIndex={0}
        />
      )}
    </>
  );
};
