import { PopupBackground } from "../popupBackground/PopupBackground";
import styles from "./SuccessStatusPopup.module.scss";
import succsesPayment from '../../image/successPayment.png'

export const SuccessStatusPopup = () => {
  return (
    <>
      <PopupBackground />
      <div className={styles.succsesPaymentWrapper}>
       <img src={succsesPayment} alt=''/>
      </div>
    </>
  );
};
