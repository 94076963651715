import { useForm, FieldValues } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./EditForm.module.scss";
import { useChangePasswordMutation } from "../../../../api/UserService";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import { useEffect } from "react";
import { PasswordInput } from "../../../../shared/components/passwordInput/PasswordInput";
import useUpdateErrors from "../../../../utils/useUpdateErrors";

type EditPasswordFormProps = {
  onClose: () => void;
};




export const EditPasswordForm = ({ onClose }: EditPasswordFormProps) => {
  const { t } = useTranslation();
  const {skipUser} = useFetchWithMsal('user',  true);
  const [changePassword, { data ,isLoading}] = useChangePasswordMutation();
  
  const schemaEmail = yup
  .object({
    value: yup
      .string()
      .required( t('userProfile.editProfile.requiredPassword'))
      .min(8,  t('userProfile.editProfile.passwordMinValudation'))
      .max(64, t('userProfile.editProfile.passwordMaxValudation'))
      .test(
        'value',
        t('userProfile.editProfile.passwordValudation'),
        (value) => {
          const conditions = [
            /[a-z]/, // строчная буква
            /[A-Z]/, // прописная буква
            /[а-яё]/i,
            /[äöüß]/i,
            /\d/, // цифра
            /[!@#$%^&*()_+-]/, // спецсимвол
          ];
  
          const fulfilledConditions = conditions.reduce(
            (count, condition) => (condition.test(value) ? count + 1 : count),
            0
          );
  
          return fulfilledConditions >= 3;
        }
      ),
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+]+$/,
      //   t('userProfile.editProfile.passwordValudation')
      // ),
     
  })
  .required() as yup.ObjectSchema<FieldValues>;
  const {
    register,
    handleSubmit,
    formState: { errors,dirtyFields },
  } = useForm({ resolver: yupResolver(schemaEmail), mode: "onChange" });

  const onSubmit = (data: FieldValues) => {
    if (!skipUser) {
    changePassword({ newPassword: data.value });
    }
  };
  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data]);
  useUpdateErrors(handleSubmit(onSubmit), !!Object.keys(dirtyFields).length);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <PasswordInput
          register={register}
          name="value"
          type="text"
          placeholder={t('userProfile.editProfile.newPassword')}
          error={errors.value?.message as string}
        />
      </div>
      <button className={styles.formButton} type="submit" disabled={isLoading}>
      { t('common.save')}
      </button>
    </form>
  );
};
