import { useTranslation } from "react-i18next";
import styles from "./ReligionTab.module.scss";
import { useGetReligionByIdQuery } from "../../../../../api/UtilityService";
import  dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { getLanguageByLocale } from "../../../../../utils/localeUtils";

type PropsType = {
  religionId: string;
  birthDate: string;
  deathDate: string;
};
dayjs.extend(utc);

export const ReligionTab = ({
  religionId,
  birthDate,
  deathDate,
}: PropsType) => {
  const { language } = useSelector((state: RootState) => state.language);
  const { data } = useGetReligionByIdQuery({religionId, lang: getLanguageByLocale(language)},{ skip: !religionId});
  const { t } = useTranslation();
  const getDate = (date: string) => {
    if (date) {
      return  dayjs.utc(date).format("DD.MM.YYYY");
    }
    return t("common.unknown");
  };
  return (
    <div className={styles.religionTab}>
      <p className={styles.religion}>{data?.name}</p>
      <span className={styles.divider} />
      <div className={styles.dateContainer}>
        <div className={styles.dateGroup}>
          <p className={styles.dateHeader}>
            {t("createProfile.birthDate").toLowerCase()}
          </p>
          <p className={styles.date}>{getDate(birthDate)}</p>
        </div>
        <div className={styles.dateGroup}>
          <p className={styles.dateHeader}>
            {t("createProfile.deathDate").toLowerCase()}
          </p>
          <p className={styles.date}>{getDate(deathDate)}</p>
        </div>
      </div>
    </div>
  );
};
