import styles from "./MemorialHistoryTab.module.scss";
import { OrderHistoryType } from "../../../../types/OrderHistoryType";
import { MemorialHistoryItem } from "./memorialHistoryItem/MemorialHistoryItem";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

type MemorialHistoryTabProps = {
  data: OrderHistoryType[];
};

export const MemorialHistoryTab = ({ data }: MemorialHistoryTabProps) => {
  const { t } = useTranslation();
  const {
    isOrderHistoryLoading,
  } = useSelector((state: RootState) => state.orderHistorySlice);
  return (
    <div
      className={
        !data.length
          ? styles.memorialHistoryTabEmpty
          : styles.memorialHistoryTab
      }
    >
      {data?.map((item: OrderHistoryType, index: number) => {
        return (
          <div key={index} className={styles.itemContainer}>
            <MemorialHistoryItem data={item} />
          </div>
        );
      })}
      {!data.length && !isOrderHistoryLoading && (
        <div>
          <p className={styles.text} >{t("userProfile.orderHistory.emptyCandles")}</p>
           <Link className={styles.button} to={`/candles`}>
           {t("userProfile.orderHistory.orderCandle")}
          </Link>
        </div>
      )}
    </div>
  );
};
