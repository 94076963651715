import { useTranslation } from "react-i18next";
import styles from "./AddPostMobile.module.scss";

import { useEffect, useRef, useState } from "react";
import { ErrorImages, ImageItem } from "../../../../../types/CommonTypes";
import PhotoForGallery from "../../../../../shared/components/photoForGallery/PhotoForGallery";
import ImageDrop from "../../../../dropArea/DropArea";
import { useAddPostMutation } from "../../../../../api/PostProfileService";
import { useParams } from "react-router-dom";
import { useUploadPostMediaMutation } from "../../../../../api/MediaService";
import { FileError } from "../../../../../shared/components/fileError/FileError";
import { ImageViewer } from "../../../../imageViewer/ImageViewer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { addProfilePost } from "../../../../../store/slices/ProfileSlice";
import { SimpleTextarea } from "../../../../../shared/components/simpleTextarea/SimpleTextarea";
import { setShowSpinner } from "../../../../../store/slices/SpinnerSlice";

type AddPostMobileProps = {
  onClose: () => void;
  setIsDirtyNewPost: (value: boolean) => void;
};
const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const AddPostMobile = ({
  onClose,
  setIsDirtyNewPost,
}: AddPostMobileProps) => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();
  const [images, setImages] = useState<ImageItem[]>([]);
  const [text, setText] = useState<string>("");

  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(false);
  const [selectedImg, setSelectedImg] = useState<number>(0);
  const [errorProps, setErrorProps] = useState<ErrorImages>({
    showVideoError: false,
    showImageError: false,
    showFormatError: false,
    filesName: [],
  });
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [addPost, { isSuccess, isLoading, data }] = useAddPostMutation();
  const [
    uploadPostMedia,
    { isSuccess: isUploadPostMediaSuccess, isLoading: isMediaLoading },
  ] = useUploadPostMediaMutation();
  const {
    id: userId,
    username,
    photo,
  } = useSelector((state: RootState) => state.userPhotoSlice);
  const { hasPreviousPosts } = useSelector(
    (state: RootState) => state.profileSlice
  );
  const dispatch = useDispatch();

  const reset = () => {
    setIsDirtyNewPost(false);
    setImages([]);
    setText("");
  };

  useEffect(() => {
    if (text) {
      setIsDirtyNewPost(true);
    } else if (!images.length) {
      setIsDirtyNewPost(false);
    }
  }, [text]);
  useEffect(() => {
    if (isSuccess) {
      if (data && images.length) {
        const formData = new FormData();
        images.forEach((imageItem: ImageItem) => {
          formData.append(`files`, imageItem.original as File);
        });
        uploadPostMedia({ profileId: id, postId: data, body: formData });
      } else {
        reset();
        onClose();
        dispatch(setShowSpinner(false));
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess && (images.length === 0 || isUploadPostMediaSuccess)) {
      const newPost = {
        id: data,
        text,
        profileId: id,
        creationDateTime: new Date().toISOString(),
        owner: {
          id: userId,
          profiles: "",
          username: username,
          avatarUrl: "",
          avatarPreviewUrl: photo.replace(url, ""),
        },
        likesCount: 0,
        commentsCount: 0,
        isLikedByUser: false,
        isCommentedByUser: false,
      };
      if (!hasPreviousPosts) {
        dispatch(addProfilePost(newPost));
      }
      reset();
      onClose();
      dispatch(setShowSpinner(false));
    }
  }, [isUploadPostMediaSuccess, isSuccess]);

  const onDelete = (value: number) => {
    const filteredGallery = images.filter((item, index) => index !== value);
    setImages(filteredGallery);

    if (!filteredGallery[value]) {
      if (filteredGallery.length === 0) {
        setIsGalleryVisible(false);
      } else {
        setSelectedImg(value - 1);
      }
    } else {
      setSelectedImg(value);
    }
    if (!filteredGallery.length && !text) {
      setIsDirtyNewPost(false);
    }
  };
  const onImageClick = (index: number) => {
    setIsGalleryVisible(true);
    setSelectedImg(index);
  };

  const setToGallery = (value: ImageItem[], errorValue: ErrorImages) => {
    setErrorProps(errorValue);
    const remainingSlots = 5 - images.length;
    const filesToAdd = value.slice(0, remainingSlots);
    setIsDirtyNewPost(true);
    if (filesToAdd) {
      setImages([...images, ...filesToAdd]);
    }
  };

  const onSubmit = () => {
    addPost({ profileId: id, text: { text } });
    dispatch(setShowSpinner(true));

  };

  return (
    <>
      <div className={styles.addPostMobileContainer} ref={containerRef}>
        <div className={styles.contentContainer}>
          <SimpleTextarea
            classType="outline"
            onChange={(value) => setText(value)}
            value={text}
            isInitHeight={!text}
            placeholder={t("posts.addPost")}
            id="addPostMobileField"
            maxLength={2048}
          />
          <div className={styles.mediaContainer}>
            {!!images.length ? (
              <div className={styles.mediaGrid}>
                {images.map((item, index) => (
                  <PhotoForGallery
                    key={index}
                    isBig
                    item={item}
                    onDelete={() => onDelete(index)}
                    onImageClick={() => onImageClick(index)}
                  />
                ))}
                {images.length < 5 && (
                  <div className={styles.dropContainer}>
                    <ImageDrop
                      onDrop={setToGallery}
                      type="mapSmall"
                      text={t("createProfile.mapPhoto")}
                    />
                  </div>
                )}
              </div>
            ) : (
              <ImageDrop
                onDrop={setToGallery}
                type="mobile"
                text={t("posts.addMedia")}
              />
            )}
          </div>
        </div>

        <button
          className={styles.button}
          onClick={onSubmit}
          disabled={
            isLoading ||
            (images.length !== 0 && isMediaLoading) ||
            (!images.length && !text.length)
          }
        >
          {t("posts.publish")}
        </button>

        {isGalleryVisible && (
          <ImageViewer
            isShow={isGalleryVisible}
            data={images}
            isShowDelete
            onClose={() => setIsGalleryVisible(false)}
            onDelete={onDelete}
            startIndex={selectedImg}
          />
        )}
        {(errorProps.showVideoError ||
          errorProps.showImageError ||
          errorProps.showFormatError) && (
          <FileError
            onClose={() =>
              setErrorProps({
                filesName: [],
                showImageError: false,
                showVideoError: false,
                showFormatError: false,
              })
            }
            showVideoError={errorProps.showVideoError}
            showImageError={errorProps.showImageError}
            showFormatError={errorProps.showFormatError}
            errorFileNames={errorProps.filesName}
          />
        )}
      </div>
    </>
  );
};
