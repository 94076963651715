import { ReactComponent as User } from "../../../shared/image/userIcon.svg";
import { Marker as GoogleMarker, OverlayView } from "@react-google-maps/api";
import styles from "./Marker.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface CustomMarkerProps {
  position: google.maps.LatLngLiteral;
  title: string;
  id?: string;
  zoom: number;
  mainPictureId?: string;
  withLink?: boolean;
}
const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

const Marker = ({
  position,
  title,
  id,
  zoom,
  mainPictureId,
  withLink,
}: CustomMarkerProps) => {
  const { photoHash } = useSelector((state: RootState) => state.profileSlice);
  const navigate = useNavigate();
  const onClick = () => {
    if (withLink) {
      navigate(`/profile/${id}`);
    }
  };
  
  return (
    <>
      {zoom > 15 ? (
        <OverlayView
          position={position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(width, height) => ({
            x: -width / 2,
            y: -height,
          })}
        >
          <div className={styles.marker} onClick={onClick}>
            {!!mainPictureId ?
              (<img src={`${url}${mainPictureId}?${photoHash}`} alt="" loading="lazy"/>) :
              (<User />)
            }

          </div>
        </OverlayView>
      ) : (
        <GoogleMarker position={position} title={title} onClick={onClick} />
      )}
    </>
  );
};

export default Marker;
