import styles from "./ModalWindowError.module.scss";

type ModalWindowProps = {
  isShow: boolean;
  headerText?: string;
  description?: string;
  onClose: () => void;
};

export const ModalWindowError = ({
  isShow,
  onClose,
  headerText,
  description,
}: ModalWindowProps) => {
  return (
    <div className={`${styles.modalWindowWrapper} ${!isShow && styles.hidden}`}>
      <div className={styles.modalWindowContainer}>
        <div className={styles.modalWindowHeader}>
          <p>{headerText}</p>
        </div>
        <p className={styles.description}>{description}
        </p>
        <p className={styles.modalWindowButton} onClick={()=>onClose()}>
          Ok
        </p>
      </div>
    </div>
  );
};
