import {
  Link,
  useNavigate
} from "react-router-dom";
import styles from "./ProfileCreatedPage.module.scss";
import { useTranslation } from "react-i18next";
import smallArrow from "../../../../shared/image/arrowIcon.svg";
import close from "../../../../shared/image/cross.svg";

type PropTypes = {
  id: string;
};
export const ProfileCreatedPage = ({ id }: PropTypes) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.profileCreatedWrapper}>
      <Link to="/">
        <img src={close} alt="" className={styles.closeButton} />
      </Link>

      <h3 className={styles.profileCreatedHeader}>
        {t("createProfile.newProfileCreated")}
      </h3>
      <div className={styles.profileCreatedContainer}>
      <div
            className={styles.breadcrumbsContainer}
            onClick={() => navigate(-1)}
          >
            <img src={smallArrow} alt="" />
            <p>{t("common.back")}</p>
          </div>
        <p className={styles.description}>
          {t("createProfile.newProfileDescription1")}
        </p>
        <p className={styles.description}>
          {t("createProfile.newProfileDescription2")}
        </p>
      </div>
      <Link to={`/profile/${id}`}>
        <p className={styles.link}>{t("createProfile.goToProfile")}</p>
      </Link>
      <Link to={`/editProfile/${id}`}
        className={styles.button}
        //  onClick={()=>onButtonClick()}
      >
        
        {t("createProfile.add")}
      </Link>
    </div>
  );
};
