import styles from "./LinksTab.module.scss";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  useGetLinksQuery,
  useUpdateLinksMutation,
} from "../../../api/ProfileService";
import { FieldValues, useForm } from "react-hook-form";
import { Input } from "../../../shared/components/input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { ReactComponent as InstagramIcon } from "../../../shared/image/links/instagram.svg";
import { ReactComponent as TiktokIcon } from "../../../shared/image/links/tiktok.svg";
import { ReactComponent as FacebookIcon } from "../../../shared/image/links/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../shared/image/links/twitter.svg";
import { ReactComponent as VkIcon } from "../../../shared/image/links/vk.svg";
import { ReactComponent as YoutubeIcon } from "../../../shared/image/links/youtube.svg";
import { ReactComponent as OkIcon } from "../../../shared/image/links/ok.svg";
import { ReactComponent as WikiIcon } from "../../../shared/image/links/wiki.svg";

type PropsType = {
  id: string;
};

type LinkType = {
  type: number;
  link: string;
};
type LinkData = {
  name: string;
  type: string;
};
const iconData = [
  <InstagramIcon />,
  <TiktokIcon />,
  <FacebookIcon />,
  <TwitterIcon />,
  <VkIcon />,
  <YoutubeIcon />,
  <OkIcon />,
  <WikiIcon />,
];
export const LinksTab = ({ id }: PropsType) => {
  const { skip } = useFetchWithMsal("profile",  true);
  const { t } = useTranslation();
  const { data } = useGetLinksQuery(id, { skip });
  const [isDirty, setIsDirty] = useState(false);

  const [updateLink] = useUpdateLinksMutation();

  const linksData = t("editProfile.linksData", {
    returnObjects: true,
  }) as LinkData[];

  const schema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        link: yup
          .string()
          .test(
            "is-valid-link",
            t("editProfile.validateLink"),
            function (value) {
              const { type } = this.parent;
              if (!value) {
                return true;
              }
              switch (type) {
                case "1":
                  return value?.includes("www.facebook.com/");
                case "2":
                  return value?.includes("www.instagram.com/");
                case "3":
                  return value?.includes("ok.ru/");
                case "4":
                  return value?.includes("www.tiktok.com/");
                case "5":
                  return value?.includes("x.com/");
                case "6":
                  return value?.includes("vk.com/");
                case "7":
                  return value?.includes("wikipedia.org/");
                case "8":
                  return value?.includes("www.youtube.com/");
                default:
                  return false;
              }
            }
          ),
      })
    ),
  });

  const resolver = yupResolver(schema);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FieldValues>({
    resolver,
    defaultValues: {
      items: linksData,
    },
  });

  useEffect(() => {
    setIsDirty(!!Object.keys(dirtyFields).length);
  }, [dirtyFields.items]);

  useEffect(() => {
    if (data) {
      data.forEach((item: LinkType) => {
        const { type, link } = item;
        const index = control._defaultValues.items.findIndex(
          (i: LinkType) => i.type == type
        );

        if (index !== -1) {
          setValue(`items.${index}.link`, link);
        }
      });
    }
  }, [data]);

  const errorsRef = errors.items;

  const onSubmit = (data: FieldValues) => {
    const newData = data.items.filter((item: LinkType) => item.link);
    updateLink({ profileId: id, body: newData });
    reset(data);
  };
  const handleChange = (name: string, value: string) => {
    setValue(name, value);
    setIsDirty(true);
  };
  return (
    <div className={styles.linksTabContainer}>
      <h3>{t(`editProfile.links`)}</h3>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {linksData?.map((link, index) => {
          const linkError = (errorsRef as any)?.[index]?.link?.message;
          return (
            <Input
              img={iconData[index]}
              key={index}
              register={register}
              setValue={handleChange}
              name={`items.${index}.link`}
              type="text"
              autocomplete="off"
              placeholder={link.name}
              error={linkError as string}
            />
          );
        })}

        <button className={styles.formButton} type="submit" disabled={!isDirty}>
          {t("common.save")}
        </button>
      </form>
    </div>
  );
};
