import { useTranslation } from "react-i18next";
import styles from "./EditForm.module.scss";
import { useDispatch, useSelector } from "react-redux";
import ImageDrop from "../../../components/dropArea/DropArea";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { ImageViewer } from "../../../components/imageViewer/ImageViewer";
import { Popup } from "../../../shared/components/popup/Popup";
import video from "../../../shared/image/video.png";
import {
  useCreateProfileMutation,
  usePostFrameMutation,
} from "../../../api/ProfileService";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { Checkbox } from "../../../shared/components/checkbox/Checkbox";
import { RootState } from "../../../store";
import {
  deleteState,
  setActiveStep,
  setIsUserContactForProfile,
} from "../../../store/slices/CreateProfileSlice";
import CustomCarousel from "../../../components/carousel/Carousel";
import { ImageCropper } from "../../../components/imageCropper/ImageCropper";
import { ReactComponent as AddIcon } from "../../../shared/image/addIcon.svg";
import { DeletePopup } from "../../../shared/components/deletePopup/DeletePopup";
import {
  CoordsType,
  ErrorImages,
  ImageItem,
  PopupData,
} from "../../../types/CommonTypes";
import {
  usePostBurialPlacePhotoMutation,
  usePostGalleryPhotoMutation,
  usePostMainPhotoMutation,
} from "../../../api/MediaService";
import { FileError } from "../../../shared/components/fileError/FileError";
import PhotoForGallery from "../../../shared/components/photoForGallery/PhotoForGallery";
import {
  acceptFormats,
  acceptImageFormats,
  allowedImageTypes,
  allowedVideoTypes,
} from "../../../constants/Media.constant";
import { getLanguageByLocale } from "../../../utils/localeUtils";
import { transformImage } from "../../../utils/transformImage";

type MediaFormProps = {
  onClick: (value?: { profileId: string }) => void;
  burialPlacePhotos?: ImageItem[];
  setIsCreating: (value: boolean) => void;
  setIsChangeLastStep: (value: boolean) => void;
};

const maxImageSize = !!process.env.MAX_IMAGE_SIZE
  ? +process.env.MAX_IMAGE_SIZE
  : 15 * 1024 * 1024;
const maxVideoSize = !!process.env.MAX_VIDEO_SIZE
  ? +process.env.MAX_VIDEO_SIZE
  : 100 * 1024 * 1024;

export const MediaForm = ({
  onClick,
  burialPlacePhotos,
  setIsCreating,
  setIsChangeLastStep,
}: MediaFormProps) => {
  const { skipMedia } = useFetchWithMsal("media", true);
  const { language } = useSelector((state: RootState) => state.language);
  const { mainInfo: profile } = useSelector(
    (state: RootState) => state.createProfile
  );

  const [createProfile, { isSuccess, data, isLoading }] =
    useCreateProfileMutation();
  const [postMainPhoto, { isLoading: isMainPhotoLoading }] =
    usePostMainPhotoMutation();
  const [postFrame, { isLoading: isFrameUploadLoading }] =
    usePostFrameMutation();
  const [postGalleryPhoto, { isLoading: isMediaLoading }] =
    usePostGalleryPhotoMutation();
  const [postBurialPlacePhoto, { isLoading: isBurialPlacePhotoLoading }] =
    usePostBurialPlacePhotoMutation();
  const dispatch = useDispatch();
  const [galleryVisible, setGalleryVisible] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [cropperData, setCropperData] = useState<ImageItem | null>(null);
  const [cropCoords, setCropCoords] = useState<CoordsType>();
  const [isShowDeletePopup, setIsShowDeletePopup] = useState(false);

  const [activTab, setActivTab] = useState("all");
  const [avatar, setAvatar] = useState<ImageItem | null>(null);
  const [images, setImages] = useState<ImageItem[]>([]);
  const [media, setMedia] = useState<ImageItem[]>([]);
  const [videos, setVideos] = useState<ImageItem[]>([]);

  const [selectedImg, setSelectedImg] = useState<number>(0);

  const [frame, setFrame] = useState<string>("");
  const [errorProps, setErrorProps] = useState<ErrorImages>({
    showVideoError: false,
    showImageError: false,
    showFormatError: false,
    filesName: [],
  });

  const avatarInputRef = useRef<HTMLInputElement | null>(null);
  const galleryInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const popupData = t("photoPopup", { returnObjects: true }) as PopupData[];

  const onSubmit = () => {
    const newProfile = {
      ...profile,
      firstName: profile.firstName
        ? profile.firstName.replace(/\s+/g, " ").trim()
        : "",
      lastName: profile.lastName
        ? profile.lastName.replace(/\s+/g, " ").trim()
        : "",
      middleName: profile.middleName
        ? profile.middleName.replace(/\s+/g, " ").trim()
        : "",
      deathReason: profile.deathReason
        ? profile.deathReason.replace(/\s+/g, " ").trim()
        : "",
    };
    createProfile(newProfile);
  };

  useEffect(() => {
    if (avatar || images.length || !!frame || media.length || videos.length) {
      setIsChangeLastStep(true);
    } else {
      setIsChangeLastStep(false);
    }
  }, [avatar, images.length, frame, media.length, videos.length]);

  useEffect(() => {
    if (data && data.id) {
      if (burialPlacePhotos && burialPlacePhotos.length) {
        const formData = new FormData();

        burialPlacePhotos.forEach((imageItem: ImageItem) => {
          formData.append(`files`, imageItem.original as File);
        });
        formData.append("ProfileId", data.id);
        postBurialPlacePhoto(formData);
      }
      if (avatar || media.length || frame) {
        const formData = new FormData();

        formData.append("ProfileId", data.id);
        if (avatar) {
          const formAvatarData = new FormData();

          formAvatarData.append("ProfileId", data.id);
          formAvatarData.append("file", avatar.original as File);

          if (cropCoords) {
            formAvatarData.append("Left", `${Math.floor(cropCoords.left)}`);
            formAvatarData.append("Top", `${Math.floor(cropCoords.top)}`);
            formAvatarData.append("Right", `${Math.floor(cropCoords?.right)}`);
            formAvatarData.append(
              "Bottom",
              `${Math.floor(cropCoords?.bottom)}`
            );
          }

          postMainPhoto({body:formAvatarData, lang:getLanguageByLocale(language)});
        }
        if (frame) {
          postFrame({
            profileId: data.id,
            frameGuid: frame,
          });
        }
        if (media.length && !skipMedia) {
          const formGalleryData = new FormData();
          formGalleryData.append("ProfileId", data.id);

          media.forEach((imageItem: ImageItem) => {
            formGalleryData.append(`files`, imageItem.original as File);
          });
          postGalleryPhoto(formGalleryData);
        }
      } else {
        onClick({ profileId: data.id });
        setIsCreating(false);
        dispatch(deleteState());
        dispatch(setActiveStep(6));
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (
      !isBurialPlacePhotoLoading &&
      !isMediaLoading &&
      !isFrameUploadLoading &&
      !isMainPhotoLoading &&
      isSuccess
    ) {
      onClick({ profileId: data.id });
      setIsCreating(false);
      dispatch(deleteState());
      dispatch(setActiveStep(6));
    }
  }, [
    isBurialPlacePhotoLoading,
    isFrameUploadLoading,
    isMediaLoading,
    isMainPhotoLoading,
  ]);

  useEffect(() => {
    if (isLoading) {
      setIsCreating(true);
    }
  }, [isLoading]);

  const setImage = (value: ImageItem[], errorValue: ErrorImages) => {
    setErrorProps(errorValue);
    if (!!value[0]) {
      setAvatar(value[0]);
    }
  };

  const setToGallery = (value: ImageItem[], errorValue: ErrorImages) => {
    setErrorProps(errorValue);
    value.forEach((item) => {
      setMedia((prevImages) => [...prevImages, item]);
      item.isNew = true;
      if (item.description.includes("video")) {
        setVideos((prevImages) => [...prevImages, item]);
      } else {
        setImages((prevImages) => [...prevImages, item]);
      }
    });
  };
  const getTabsItem = () => {
    switch (activTab) {
      case "video":
        return videos;
      case "photo":
        return images;
      default:
        return media;
    }
  };
  const onPopupClick = (type: string) => {
    switch (type) {
      case "load":
        if (avatarInputRef.current) {
          avatarInputRef.current.click();
        }
        break;
      case "open":
        setGalleryVisible("avatar");
        break;
      case "edit":
        setCropperData(avatar);
        break;
      case "delete":
        setIsShowDeletePopup(true);
        break;
      default:
        break;
    }
    setShowPopup(false);
  };
  const onDeletePopupClick = (value: string) => {
    switch (value) {
      case "delete":
        setAvatar(null);
        setIsShowDeletePopup(false);

        break;

      default:
        setIsShowDeletePopup(false);
    }
  };
  const onAvatarClick = (event: MouseEvent) => {
    event.stopPropagation();
    setShowPopup(true);
    setSelectedImg(0);
  };

  const onCrop = (value?: CoordsType, img?: ImageItem) => {
    if (!!value && !!img) {
      setAvatar(img);
      setCropCoords(value);
    }
    setCropperData(null);
  };
  const onImageClick = (index: number) => {
    setSelectedImg(index);
    setGalleryVisible("gallery");
  };
  const onDelete = (value: number) => {
    let filteredGallery;
    switch (activTab) {
      case "photo":
        filteredGallery = images.filter((item, index) => index !== value);
        const filteredMedia = media.filter(
          (item) => item.original !== images[value].original
        );
        setMedia(filteredMedia);
        setImages(filteredGallery);
        break;
      case "video":
        filteredGallery = videos.filter((item, index) => index !== value);
        const filteredAllMedia = media.filter(
          (item) => item.original !== videos[value].original
        );
        setMedia(filteredAllMedia);
        setVideos(filteredGallery);
        break;
      default:
        filteredGallery = media.filter((item, index) => index !== value);
        if (media[value]?.description === "video") {
          const filteredVideos = videos.filter(
            (item) => item.original !== media[value].original
          );
          setVideos(filteredVideos);
        } else {
          const filteredImages = images.filter(
            (item) => item.original !== media[value].original
          );
          setImages(filteredImages);
        }
        setMedia(filteredGallery);
        break;
    }

    if (!filteredGallery[selectedImg]) {
      if (filteredGallery.length === 0) {
        setGalleryVisible("");
      } else {
        setSelectedImg(value - 1);
      }
    } else {
      setSelectedImg(value);
    }
  };
  const onRotate = (value: number, img: File) => {
    if (galleryVisible === "avatar") {
      const newAvatar = {
        ...avatar,
        original: img,
        thumbnail: URL.createObjectURL(img as Blob),
      } as ImageItem;
      setAvatar(newAvatar);
    } else {
      const newImages = images.map((item, index) => {
        if (index === value) {
          item.original = img;
          item.thumbnail = URL.createObjectURL(img as Blob);
        }
        return item;
      });
      const newMedia = media.map((item, index) => {
        if (index === value) {
          item.original = img;
          item.thumbnail = URL.createObjectURL(img as Blob);
        }
        return item;
      });
      setMedia(newMedia);
      setImages(newImages);
    }
  };
  const handleImageUpload =
    (type: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (files) {
        const imagesArray = Array.from(files);

        imagesArray.forEach((file) => {
          if (!!file) {
            const fileType = file.type;
            const newItem: ImageItem = {
              original: file,
              thumbnail: allowedVideoTypes.includes(file.type)
                ? video
                : URL.createObjectURL(file as Blob),
              description: allowedVideoTypes.includes(file.type)
                ? "video"
                : "image",
              type: file.type,
            };
            allowedImageTypes.includes(file.type) &&  transformImage(newItem as ImageItem)
            if (allowedImageTypes.includes(fileType)) {
              if (file.size < maxImageSize) {
                if (type === "avatar") {
                  setCropperData(newItem);
                } else {
                  setImages((prevItems) => [...prevItems, newItem]);
                  setMedia((prevImages) => [...prevImages, newItem]);
                }
              } else {
                setErrorProps((prev) => ({
                  showImageError: true,
                  showVideoError: prev.showVideoError,
                  showFormatError: prev.showFormatError,
                  filesName: [...prev.filesName, file.name],
                }));
              }
            } else if (allowedVideoTypes.includes(fileType)) {
              if (file.size < maxVideoSize) {
                setVideos((prevImages) => [...prevImages, newItem]);
                setMedia((prevImages) => [...prevImages, newItem]);
              } else {
                setErrorProps((prev) => ({
                  showImageError: prev.showImageError,
                  showFormatError: prev.showFormatError,
                  showVideoError: true,
                  filesName: [...prev.filesName, file.name],
                }));
              }
            } else {
              setErrorProps((prev) => ({
                showImageError: prev.showImageError,
                showFormatError: true,
                showVideoError: prev.showVideoError,
                filesName: [...prev.filesName, file.name],
              }));
            }
          }
        });
        if (avatarInputRef?.current) {
          avatarInputRef.current.value = "";
        }
        if (galleryInputRef?.current) {
          galleryInputRef.current.value = "";
        }
      }
    };

  return (
    <div className={styles.form}>
      <div className={styles.formContainer}>
        <div className={styles.headerContainer}>
          <h3 className={styles.formHeader}>{t("createProfile.photo")} </h3>

          {!!avatar ? (
            <h3 className={styles.formHeaderDiscription}>
              &nbsp;{t("createProfile.photoDiscription")}
            </h3>
          ) : (
            ""
          )}
        </div>
        <div className={styles.profileAvatarContainer}>
          <CustomCarousel
            setFrame={(value) => setFrame(value)}
            isHidden={!avatar}
          />
          <div
            className={
              !avatar ? styles.photoBackground : styles.photoBackgroundHidden
            }
          ></div>
          <div className={styles.profileAvatarCurrentPhoto}>
            <div className={styles.photoContainer}>
              {avatar ? (
                <img
                  src={avatar.thumbnail}
                  alt="Dropped"
                  className={styles.profileImage}
                  onClick={onAvatarClick}
                />
              ) : (
                <div
                  className={styles.profileImageAddContainer}
                  onClick={() => avatarInputRef.current?.click()}
                >
                  <ImageDrop
                    onDrop={setImage}
                    type="avatar"
                    text={t("createProfile.dropAreaAvatarDescription")}
                  />
                </div>
              )}
              <input
                accept={acceptImageFormats}
                id="icon-button-file"
                type="file"
                ref={avatarInputRef}
                style={{ display: "none" }}
                onChange={handleImageUpload("avatar")}
              />
            </div>
          </div>
        </div>
        <div className={styles.profileMediaContainer}>
          <div className={styles.profileMediaTools}>
            <div
              className={styles.addButton}
              onClick={() => galleryInputRef.current?.click()}
            >
              <AddIcon />
            </div>
            <input
              accept={acceptFormats}
              id="icon-button-file"
              type="file"
              multiple
              ref={galleryInputRef}
              style={{ display: "none" }}
              onChange={handleImageUpload("gallery")}
            />
            <div
              className={`${
                activTab === "all" ? styles.activTab : styles.mediaTab
              }`}
              onClick={() => setActivTab("all")}
            >
              {t("createProfile.all")}
              <span className={styles.count}>
                {images.length + videos.length}
              </span>
            </div>
            <div
              className={`${
                activTab === "photo" ? styles.activTab : styles.mediaTab
              }`}
              onClick={() => setActivTab("photo")}
            >
              {t("createProfile.photo")}
              <span className={styles.count}>{images.length}</span>
            </div>
            <div
              className={`${
                activTab === "video" ? styles.activTab : styles.mediaTab
              }`}
              onClick={() => setActivTab("video")}
            >
              {t("createProfile.video")}
              <span className={styles.count}>{videos.length}</span>
            </div>
          </div>
          {(media.length && activTab === "all") ||
          (images.length && activTab === "photo") ||
          (videos.length && activTab === "video") ? (
            <div className={styles.scrollContainer}>
              <div className={styles.profileMediaGrid}>
                {getTabsItem().map((item, index) => (
                  <PhotoForGallery
                    key={index}
                    item={item}
                    onDelete={() => onDelete(index)}
                    onImageClick={() => onImageClick(index)}
                  />
                ))}
                <div className={styles.dropContainer}>
                  <ImageDrop
                    onDrop={setToGallery}
                    type="mapSmall"
                    text={t("createProfile.mapPhoto")}
                  />
                </div>
              </div>
            </div>
          ) : (
            <ImageDrop onDrop={setToGallery} type="all" />
          )}
        </div>
        <Checkbox
          isChecked={profile.isUserContactForProfile}
          onChange={() => dispatch(setIsUserContactForProfile())}
          label={t("createProfile.contactPerson")}
        />
      </div>
      {galleryVisible && (
        <ImageViewer
          isShow={!!galleryVisible}
          data={
            galleryVisible === "avatar"
              ? ([avatar] as ImageItem[])
              : getTabsItem()
          }
          isShowDelete={galleryVisible === "gallery"}
          onClose={() => setGalleryVisible("")}
          onDelete={onDelete}
          onRotate={onRotate}
          isShowRotate
          startIndex={selectedImg}
        />
      )}
      {!!cropperData && (
        <ImageCropper
          isShow={!!cropperData}
          data={cropperData}
          onClose={onCrop}
        />
      )}
      {(errorProps.showVideoError ||
        errorProps.showImageError ||
        errorProps.showFormatError) && (
        <FileError
          onClose={() =>
            setErrorProps({
              filesName: [],
              showImageError: false,
              showVideoError: false,
              showFormatError: false,
            })
          }
          showVideoError={errorProps.showVideoError}
          showImageError={errorProps.showImageError}
          showFormatError={errorProps.showFormatError}
          errorFileNames={errorProps.filesName}
        />
      )}
      {showPopup && (
        <Popup
          type="avatar"
          data={popupData}
          onClose={() => setShowPopup(false)}
          onClick={(type) => onPopupClick(type)}
        ></Popup>
      )}
      {isShowDeletePopup && (
        <DeletePopup
          onClick={onDeletePopupClick}
          text={t("common.deletePhoto")}
        />
      )}
      <button
        className={styles.createButton}
        onClick={() => onSubmit()}
        disabled={isLoading || isMediaLoading || isBurialPlacePhotoLoading}
      >
        {t("createProfile.createProfileButton")}
      </button>
    </div>
  );
};
