import { useTranslation } from "react-i18next";
import styles from "./ProfileWall.module.scss";
import { ProfileCandles } from "./candles/ProfileCandles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setCheckedTab } from "../../../store/slices/ProfileSlice";
import { MemorialTypes } from "../../../constants/Memorial.constant";
import { MouseEventHandler, UIEvent, useState } from "react";
import { ProfilePosts } from "./posts/ProfilePosts";
import { ModalWindow } from "../../modalWindow/ModalWindow";
import { AddPostMobile } from "./posts/addPostMobile/AddPostMobile";
import { useMsal } from "@azure/msal-react";
import { UnAuthenticatedPopup } from "../../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";

type PropsType = {
  fetchNewPageData: (type: string) => void;
  fetchPreviousPosts?: () => void;
  onAddMemorialItemClick?: (type?: string) => void;
  isShowAddPost?: boolean;
  onClosePopup?: () => void;
};

export const ProfileWall = ({
  fetchNewPageData,
  fetchPreviousPosts,
  onAddMemorialItemClick,
  isShowAddPost,
  onClosePopup,
}: PropsType) => {
  const { instance } = useMsal();
  const isAuthenticated = !!instance.getActiveAccount();
  const [
    isShowNotAuthenticatedModalWindow,
    setIsShowNotAuthenticatedModalWindow,
  ] = useState<boolean>(false);
  const { checkedTab, candlesData, feelingsData, postsData, isWallLoading } = useSelector(
    (state: RootState) => state.profileSlice
  );
  const [isShowPopup, setIsShowPopup] = useState<boolean>(
    isShowAddPost || false
  );
  const [isShowWarningPopup, setIsShowWarningPopup] = useState<boolean>(false);
  const [isDirtyNewPost, setIsDirtyNewPost] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tabs = [
    MemorialTypes.candles,
    MemorialTypes.feelings,
    MemorialTypes.posts,
  ];

  const renderTabs = (tabs: string[], checkedButton: any) => {
    return tabs.map((tab: string, index: number) => {
      return (
        <button
          key={index}
          className={`${styles.tab} ${
            checkedButton === tab ? styles.activeTab : ""
          } `}
          onClick={() => dispatch(setCheckedTab(tab))}
        >
          {t(`memorial.${tab}`)}
        </button>
      );
    });
  };
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const divComponent = e.target as HTMLDivElement;
    if (
      divComponent.offsetHeight + divComponent.scrollTop >=
      divComponent.scrollHeight - 200
    ) {
      fetchNewPageData(checkedTab);
    }
    if (divComponent.scrollTop === 0 && fetchPreviousPosts) {
      fetchPreviousPosts();
    }
  };
  const onButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (checkedTab === MemorialTypes.posts) {
      if (isAuthenticated) {
        setIsShowPopup(true);
      } else {
        e.stopPropagation();
        setIsShowNotAuthenticatedModalWindow(true);
      }
    } else if (onAddMemorialItemClick) {
      onAddMemorialItemClick(checkedTab);
    }
  };
  const getButtonText = () => {
    switch (checkedTab) {
      case MemorialTypes.candles:
        return t("memorial.sendCandles");
      case MemorialTypes.feelings:
        return t("memorial.sendFeelings");
      default:
        return t("posts.newPost");
    }
  };
  const onClose = (isClosePopup?: boolean) => {
    if (isClosePopup) {
      setIsShowPopup(false);
    } else if (isDirtyNewPost) {
      setIsShowWarningPopup(true);
    } else if (isShowAddPost && onClosePopup) {
      onClosePopup();
    } else {
      setIsShowPopup(false);
    }
  };
  const onCloseWarningPopup = () => {
    setIsShowWarningPopup(false);
    if (isShowAddPost && onClosePopup) {
      onClosePopup();
    } else {
      setIsShowPopup(false);
    }
  };
  return (
    <div className={styles.profileWallContainer}>
      <div className={styles.wallTabs}>{renderTabs(tabs, checkedTab)}</div>

      <div className={styles.wallContent} onScroll={handleScroll} id ='wallScrollableContainer'>
        {checkedTab === MemorialTypes.posts ? (
          <ProfilePosts postsData={postsData}  isWallLoading={isWallLoading}/>
        ) : (
          <ProfileCandles
            checkedTab={checkedTab}
            isWallLoading={isWallLoading}
            candlesData={
              checkedTab === MemorialTypes.candles ? candlesData : feelingsData
            }
          />
        )}
      </div>

      <button className={styles.wallButton} onClick={onButtonClick}>
        {getButtonText()}
      </button>
      {isShowPopup && (
        <ModalWindow
          onClose={() => onClose(false)}
          isShow={isShowPopup}
          headerText={t("posts.newPost")}
          children={
            <AddPostMobile
              onClose={() => onClose(true)}
              setIsDirtyNewPost={(value) => setIsDirtyNewPost(value)}
            />
          }
        ></ModalWindow>
      )}
      {isShowWarningPopup && (
        <ModalWindow
          onClose={() => setIsShowWarningPopup(false)}
          isShow={isShowWarningPopup}
          headerText={t("posts.publishPost")}
          backdropModal={true}
          children={
            <div>
              <p className={styles.popupDescription}>
                {t("posts.publishPostDescription")}
              </p>
              <button
                className={styles.popupButton}
                onClick={() => setIsShowWarningPopup(false)}
              >
                {t("common.cancel")}
              </button>
              <button
                className={styles.popupCloseButton}
                onClick={onCloseWarningPopup}
              >
                {t("common.close")}
              </button>
            </div>
          }
        ></ModalWindow>
      )}
      {isShowNotAuthenticatedModalWindow && (
        <UnAuthenticatedPopup
          onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
        ></UnAuthenticatedPopup>
      )}
    </div>
  );
};
