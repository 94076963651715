/**
 * Populate claims table with appropriate description
 * @param {Object} claims ID token claims
 * @returns claimsObject
 */
 interface IDTokenClaims {
    aud?: string;
    iss?: string;
    iat?: number;
    nbf?: number;
    exp?: number;
    aio?: string;
    email?: string;
    name?: string;
    nonce?: string;
    oid?: string;
    preferred_username?: string;
    rh?: string;
    roles?: string[];
    sub?: string;
    tid?: string;
    uti?: string;
    ver?: string;
    tfp?: string;
    acr?:string;
}

export function compareIssuingPolicy(idTokenClaims: IDTokenClaims, policyToCompare: string) {
    let tfpMatches = idTokenClaims.hasOwnProperty('tfp') && idTokenClaims['tfp']?.toLowerCase() === policyToCompare.toLowerCase();
    let acrMatches = idTokenClaims.hasOwnProperty('acr') && idTokenClaims['acr']?.toLowerCase() === policyToCompare.toLowerCase();
    return tfpMatches || acrMatches;
}
