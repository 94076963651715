import { useState } from "react";
import styles from "./CookieSettings.module.scss";
import { useTranslation } from "react-i18next";
import { SwitchCheckbox } from "../../../shared/components/switchCheckbox/SwitchCheckbox";

type PropsType = {
  onAcceptCookie: () => void;
};

export const CookieSettings = ({ onAcceptCookie }: PropsType) => {
  const { t } = useTranslation();
  const [checkedTab, setCheckedTab] = useState("privacy");
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const tabs = ["privacy", "settings"];

  const switchTabs = (tab: string) => {
    setCheckedTab(tab);
  };

  const renderTabs = (tabs: string[], checkedButton: any) => {
    return tabs.map((tab: string, index: number) => {
      return (
        <button
          key={index}
          className={`${styles.tabContainer} ${
            checkedButton === tab ? styles.activeTab : ""
          } `}
          onClick={() => switchTabs(tab)}
        >
          {t(`cookie.${tab}.header`)}
        </button>
      );
    });
  };

  const onClick = () => {
    onAcceptCookie();
  };

  return (
    <>
      <div className={styles.cookieSettingsContainer}>
        <div className={styles.cookieTabs}>{renderTabs(tabs, checkedTab)}</div>

        <h3 className={styles.cookieSettingsHeader}>
          {t(`cookie.${checkedTab}.header`)}
        </h3>
        <p className={styles.cookieSettingsDescription}>
          {t(`cookie.${checkedTab}.description`)}
        </p>
        {checkedTab === "settings" && (
          <>
            <div className={styles.switchContainer}>
              {" "}
              <SwitchCheckbox
                isChecked={isChecked}
                onChange={(value) => {
                  setIsChecked(value);
                }}
              />{" "}
              <span
                className={
                  isChecked ? styles.switchTextOn : styles.switchTextOff
                }
              >
                {isChecked
                  ? t(`cookie.${checkedTab}.switchOn`)
                  : t(`cookie.${checkedTab}.switchOff`)}
              </span>
            </div>

            {!isChecked && (
              <p className={styles.cookieSettingsDescription}>
                {t(`cookie.${checkedTab}.description2`)}
              </p>
            )}
          </>
        )}
        <div className={styles.buttonsContainer}>
          <button onClick={onClick}>{t(`cookie.enable`)}</button>
          <button onClick={onClick}>{t(`cookie.save`)}</button>
        </div>
      </div>
    </>
  );
};
