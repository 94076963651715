import { profileApi } from "./ProfileService";

const postProfileApi = profileApi.injectEndpoints({
  endpoints: (build) => ({
    getPosts: build.mutation({
      query: ({ profileId, from, limit = 10, beforeLimit }) => {
        return {
          url: `/api/Profile/${profileId}/Post`,
          params: { from, limit, beforeLimit },
        };
      },
    }),
    addPost: build.mutation<any, { profileId: string; text?: {text:string} }>({
      query: ({ profileId, text }) => ({
        url: `/api/Profile/${profileId}/Post`,
        method: "POST",
        body: text,
      }),
    }),
    deletePost: build.mutation<any, { profileId: string; postId: string }>({
      query: ({ profileId, postId }) => ({
        url: `/api/Profile/${profileId}/Post/${postId}`,
        method: "DELETE",
      }),
    }),
    getComments: build.mutation({
      query: ({ profileId, postId, pageNumber = 1, pageSize = 10 }) => {
        return {
          url: `/api/Profile/${profileId}/Post/${postId}/Comment`,
          params: { pageNumber, pageSize },
        };
      },
    }),
    getReplys: build.mutation({
      query: ({
        profileId,
        postId,
        commentId,
        pageNumber = 1,
        pageSize = 10,
      }) => {
        return {
          url: `/api/Profile/${profileId}/Post/${postId}/Comment/${commentId}/Reply`,
          params: { pageNumber, pageSize },
        };
      },
    }),
    addComment: build.mutation({
      query: ({ profileId, postId, body }) => ({
        url: `/api/Profile/${profileId}/Post/${postId}/Comment`,
        method: "POST",
        body: body,
      }),
      transformErrorResponse:(response, meta, arg) =>{
        return response.data
      },
    }),
  
    deleteComment: build.mutation({
      query: ({ profileId, postId, commentId }) => ({
        url: `/api/Profile/${profileId}/Post/${postId}/Comment/${commentId}`,
        method: "DELETE",
      }),
    }),
    updateLikes: build.mutation({
      query: ({ profileId, postId,  state }) => ({
        url: `/api/Profile/${profileId}/Post/${postId}/like?state=${state}`,
        method: "PATCH",
      }),
    }),
  }),
});
export const {
  useGetPostsMutation,
  useAddPostMutation,
  useDeletePostMutation,
  useGetCommentsMutation,
  useGetReplysMutation,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useUpdateLikesMutation
} = postProfileApi;
