import { useForm, FieldValues } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import styles from "./ContactUsForm.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../shared/components/input/Input";
import {
  EMAIL_REGEX,
  MAX_MAIN_SEARCH_LENGTH,
  MAX_MESSAGE_LENGTH,
} from "../../constants/constants";
import { Textarea } from "../../shared/components/textarea/Textarea";
import { useContactUsMutation } from "../../api/ComplaintService";
import { useEffect, useState } from "react";
import { ModalWindow } from "../modalWindow/ModalWindow";
import { setShowSpinner } from "../../store/slices/SpinnerSlice";
import { useDispatch } from "react-redux";


type PropsType = {
  onClose: () => void;
};

export const ContactUsForm = ({ onClose }: PropsType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  const [sendContactUsForm, { isSuccess, isLoading }] = useContactUsMutation();
  useEffect(() => {
    if (isLoading) {
      dispatch(setShowSpinner(true));
    }
    if (isSuccess) {
      setIsShowPopup(true);
      dispatch(setShowSpinner(false));
    }
  }, [isSuccess, isLoading]);

  const schema = yup.object().shape({
    firstName: yup.string().trim().required(t("common.requiredField")),
    lastName: yup.string().trim().required(t("common.requiredField")),
    email: yup
      .string()
      .required(t("common.requiredField"))
      .matches(EMAIL_REGEX, t("userProfile.editProfile.enterValidEmail")),
    phone: yup
      .string()
      .test(
        "tel",
        t("userProfile.editProfile.phoneNumberValidation"),
        (value, context) => {
          if (!value) {
            return true; // phone is not required
          }

          const newValue = (context.parent.countryCode || "") + (value || "");
          return /^[\+]?[0-9]{6,17}$/.test(newValue);
        }
      ),
    message: yup.string().trim().required(t("common.requiredField")),
  }) as yup.ObjectSchema<FieldValues>;
  const resolver = yupResolver(schema);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors},
    watch,
  } = useForm<FieldValues>({
    resolver,
  });

  const onSubmit = (data: FieldValues) => {
    sendContactUsForm(data);
  };
  const onClosePopup = () => {
    setIsShowPopup(false);
    onClose();
  };
  const watchFields = watch();

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <Input
          register={register}
          setValue={setValue}
          name="lastName"
          type="text"
          placeholder={`${t("common.lastName")}`}
          label={`${t("common.lastName")}`}
          error={errors.lastName?.message as string}
          maxLength={MAX_MAIN_SEARCH_LENGTH}
        />
        <Input
          setValue={setValue}
          register={register}
          name="firstName"
          type="text"
          placeholder={`${t("createProfile.firstName")}`}
          label={`${t("createProfile.firstName")}`}
          error={errors.firstName?.message as string}
          maxLength={MAX_MAIN_SEARCH_LENGTH}
        />

        <Input
          register={register}
          setValue={setValue}
          name="email"
          type="text"
          placeholder="name@example.com"
          label={`${t("common.email")}`}
          maxLength={MAX_MAIN_SEARCH_LENGTH}
          error={errors.email?.message as string}
        />
        <Input
          register={register}
          setValue={setValue}
          name="phone"
          type="tel"
          placeholder={t("userProfile.editProfile.phoneNumber")}
          label={t("userProfile.editProfile.phoneNumber")}
          maxLength={MAX_MAIN_SEARCH_LENGTH}
          error={errors.phone?.message as string}
        />

        <Textarea
          register={register}
          setValue={setValue}
          maxLength={MAX_MESSAGE_LENGTH}
          name="message"
          // isInitHeight={!watchFields.message}
          placeholder={`${t("common.text")}`}
          label={`${t("common.text")}`}
          error={errors.message?.message as string}
          isStatic
        />
        <button
          className={styles.formButton}
          type="submit"
        >
          {t("common.send")}
        </button>
        {isShowPopup && (
          <ModalWindow
          onClose={onClosePopup}
          isShow={isShowPopup}
          backdropModal
          headerText={t("common.contactUsHeader")}
          children={
          <div>
          <p className={styles.feedbackText}>{t("common.contactUsText")}</p>
          <button className={styles.feedbackButton} onClick={onClosePopup}>{t('common.ok')}</button>
          </div>
          }
          ></ModalWindow>
        )}
      </div>
    </form>
  );
};
