import styles from "./Memorial.module.scss";
import selectedIcon from "../../image/selectedIcon.svg";

type MemorialProps = {
  onClick: (id: string) => void;
  type?: string;
  selected: boolean;
  id: string;
  img: string;
  price: number;
};

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const MemorialItem = ({
  type,
  selected,
  id,
  onClick,
  img,
  price,
}: MemorialProps) => {

  return (
    <div
        className={`${styles.memorialContainer} ${type ? styles.candle : ""} ${
        selected ? styles.selected : ""
      }`}
      onClick={() => onClick(id)}
    >
      <img
        className={styles.photo}
        src={url + img}
        alt=""
        draggable={false}
      />
      {price !== 0 && (
        <div className={`${styles.monthlyPrice} ${ selected ? styles.monthlyPriceSelected : "" }`}>{price} &euro;</div>
      )}

      {selected && (
        <img className={styles.selectIcon} src={selectedIcon} alt="" />
      )}
    </div>
  );
};
