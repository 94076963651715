import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";

const initialState = {
  photo: "",
  fullPhoto: "",
  isLoaded: false,
  id: "",
  username: "",
};

const baseUrl = process.env.REACT_APP_MEDIA_DNS_NAME as string;

export const fetchPreviewPhoto = async (mediaId: string) => {
  try {
    const response: AxiosResponse<Blob> = await axios.get(
      `${baseUrl}/api/media/${mediaId}/preview`,
      {
        responseType: "blob", // Указываем, что ожидаем Blob в ответе
      }
    );
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Image = reader.result;
      localStorage.setItem("cachedImage", base64Image as string);
    };
    reader.readAsDataURL(response.data);
    return await URL.createObjectURL(response.data as Blob);
  } catch (error) {
    console.error("Error fetching thumbnail:", error);
    throw error;
  }
};

export const fetchFullPhoto = async (mediaId: string) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/media/${mediaId}`,
      {
        responseType: "blob", // Указываем, что ожидаем Blob в ответе
      }
    );

    return await URL.createObjectURL(response.data as Blob);
  } catch (error) {
    console.error("Error fetching full photo:", error);
    throw error;
  }
};
// export const fetchPreviewPhoto = createAsyncThunk(
//   "mediaSlice/fetchPhoto",
//   async (mediaId: string) => {
//     const response = await fetch(
//       `${baseUrl}/api/media/${mediaId}/preview`
//     );

//     const fileData = await response.blob();
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       const base64Image = reader.result;
//       localStorage.setItem("cachedImage", base64Image as string);
//     };
//     reader.readAsDataURL(fileData);
//     return await URL.createObjectURL(fileData);
//   }
// );
export const loadUserPreview: AsyncThunk<string, string, {}> = createAsyncThunk(
  "gallery/loadMedia",
  async (mediaId: string, { dispatch }) => {
    const thumbnail = await fetchPreviewPhoto(mediaId);
    dispatch(setPreviewImage(thumbnail));
    return thumbnail;
  }
);
export const loadUserFullPhoto: AsyncThunk<string, string, {}> =
  createAsyncThunk(
    "gallery/loadMedia",
    async (mediaId: string, { dispatch }) => {
      const photo = await fetchFullPhoto(mediaId);

      dispatch(setFullImage(photo));
      return photo;
    }
  );
export const userPhotoSlice = createSlice({
  name: "userPhotoSlice",
  initialState,
  reducers: {
    deleteImage: (state) => {
      state.photo = "";
    },
    setPreviewImage: (state, action) => {
      state.photo = action.payload;
    },
    setFullImage: (state, action) => {
      state.fullPhoto = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setUserId: (state, action) => {
      state.id = action.payload;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchPreviewPhoto.fulfilled, (state, action) => {
  //       state.photo = action.payload;
  //       state.isLoaded = true;
  //     })
  //     .addCase(fetchPreviewPhoto.pending, (state, action) => {
  //       state.isLoaded = true;
  //     })
  //     .addCase(fetchPreviewPhoto.rejected, (state, action) => {
  //       state.isLoaded = false;
  //     });
  // },
});

export const {
  deleteImage,
  setPreviewImage,
  setFullImage,
  setUsername,
  setUserId,
  setIsLoaded,
} = userPhotoSlice.actions;
export default userPhotoSlice.reducer;
