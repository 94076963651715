import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: null,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});
export const { setIsAuthenticated} =
userSlice.actions;
export default userSlice.reducer;
