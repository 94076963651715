import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import styles from "./EditProfile.module.scss";
import { SettingListItem } from "../../../shared/components/settingListItem/SettingListItem";
import {
  useDeleteUserMutation,
  useGetAuthorizedUserInfoQuery,
  useGetUserContactsQuery,
  usePutContactMutation,
  usePutUserMutation,
} from "../../../api/UserService";
import { ModalWindow } from "../../modalWindow/ModalWindow";
import { EditNameWindow } from "./editForms/EditNameForm";
import { EditEmailForm } from "./editForms/EditEmailForm";
import { PhoneCodeValidation } from "./editForms/EditPhoneForm";
import { EditUserNameForm } from "./editForms/EditUserNameForm";
import { EditPasswordForm } from "./editForms/EditPassword";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { FieldValues } from "react-hook-form";
import { DeleteProfilePopup } from "../../profile/deleteProfilePopup/DeleteProfilePopup";
import { useMsal } from "@azure/msal-react";
import {
  setFullImage,
  setPreviewImage,
} from "../../../store/slices/UserPhotoSlice";
import { setShowSpinner } from "../../../store/slices/SpinnerSlice";
import { setErrorContact } from "../../../store/slices/UserContactErrorSlice";
import { b2cPolicies } from "../../../authConfig";
import { setShowWarningModal } from "../../../store/slices/WarningModalSlice";
import { Avatar } from "../../../shared/components/avatar/Avatar";

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const EditProfile = () => {
  const { instance } = useMsal();
  const { skipUser } = useFetchWithMsal("user", true);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [phoneData, setPhoneData] = useState<any>(null);
  const [emailData, setEmailData] = useState<any>(null);
  const [updateEmailData, setUpdateEmailData] = useState<any>(null);
  const [isShowDeleteModalWindow, setIsShowDeleteModalWindow] =
    useState<boolean>(false);
  const [modalWindow, setModalWindow] = useState<string>("");
  const { data } = useGetAuthorizedUserInfoQuery("", { skip: skipUser });
  const { data: contactData, isSuccess: isSuccessContactData } =
    useGetUserContactsQuery("", {
      skip: skipUser,
      refetchOnMountOrArgChange: true,
    });
  const [deleteUser, { isSuccess: isSuccessDeleteUser }] =
    useDeleteUserMutation();
  const [updateUser, { isError: isErrorUpdateUser, error: errorUpdateUser }] =
    usePutUserMutation();
  const [updateContact, { isSuccess: isSuccessContact }] =
    usePutContactMutation();
  const { language } = useSelector((state: RootState) => state.language);

  useEffect(() => {
    if (
      isErrorUpdateUser &&
      errorUpdateUser &&
      "status" in errorUpdateUser &&
      errorUpdateUser.status === 406
    ) {
      dispatch(setShowSpinner(false));
      dispatch(setShowWarningModal(true));
    }
  }, [isErrorUpdateUser]);

  useEffect(() => {
    if (isSuccessDeleteUser) {
      instance.logoutRedirect();
    }
  }, [isSuccessDeleteUser]);

  useEffect(() => {
    if (contactData) {
      const phoneData = contactData?.find((item: any) => item.type === 2);
      setPhoneData(phoneData);
      const emailData = contactData?.find((item: any) => item.type === 1);
      setEmailData(emailData);
    }
  }, [isSuccessContactData]);

  useEffect(() => {
    if (!skipUser && data?.avatarPreviewUrl) {
      dispatch(setPreviewImage(url + data?.avatarPreviewUrl));
      dispatch(setFullImage(url + data?.avatarUrl));
    }
  }, [data?.avatarPreviewUrl, skipUser]);

  useEffect(() => {
    if (isSuccessContact) {
      setModalWindow("emailCodeWindow");
    }
  }, [isSuccessContact]);

  const editEmail = (data: FieldValues): void => {
    setUpdateEmailData(data);
    updateContact({ contactId: data.id, data });
  };

  const setNewEmailAfterVerification = () => {
    setEmailData(updateEmailData);
    setModalWindow("");
  };

  const handleRedirect = () => {
    const signUpSignInFlowRequest = {
      authority: b2cPolicies.authorities.editPhoneNumber.authority,
      scopes: [],
      extraQueryParameters: { ui_locales: language },
    };
    localStorage.setItem("isEditPhoneNumder", "true");
    instance.loginRedirect(signUpSignInFlowRequest);
  };

  const modalWindowData = () => {
    switch (modalWindow) {
      case "name":
        return {
          headerText: t("common.firstName"),
          children: (
            <EditNameWindow
              onClose={() => setModalWindow("")}
              data={data}
              required
              onSave={updateUser}
            />
          ),
        };
      case "email":
        return {
          headerText: t("userProfile.editProfile.email"),
          children: (
            <EditEmailForm
              onClose={editEmail}
              data={emailData ? emailData : {}}
            />
          ),
        };
      case "userName":
        return {
          headerText: t("userProfile.editProfile.userName"),
          children: (
            <EditUserNameForm onClose={() => setModalWindow("")} data={data} />
          ),
        };
      case "emailCodeWindow":
        return {
          headerText: t("userProfile.editProfile.enterValidationCode"),
          children: (
            <PhoneCodeValidation
              onClose={setNewEmailAfterVerification}
              data={updateEmailData ? updateEmailData : {}}
              type="email"
            />
          ),
        };
      case "password":
        return {
          headerText: t("userProfile.editProfile.editPassword"),
          children: <EditPasswordForm onClose={() => setModalWindow("")} />,
        };
      default:
        return (
          <>
            <input value="name" />
          </>
        );
    }
  };
  const onDeleteProfileClick = () => {
    setIsShowDeleteModalWindow(false);
    deleteUser("");
  };

  const onCloseModalWindow = () => {
    if (modalWindow === "email" || modalWindow === "phone") {
      dispatch(setErrorContact(""));
    }
    setModalWindow("");
  };

  return (
    <div className={styles.profileContainer}>
      <Avatar/>
      <p className={styles.label}>{t("common.firstName")}</p>
      <SettingListItem
        text={`${data?.lastName || ""} ${data?.firstName || ""} ${
          data?.middleName || ""
        }`}
        classNameType={"single"}
        onClick={() => setModalWindow("name")}
      />
      <p className={styles.label}>{t("userProfile.editProfile.contactInfo")}</p>
      <ul className={styles.settingList}>
        <SettingListItem
          text={emailData?.value || ""}
          caption="E-mail"
          wide
          classNameType={""}
          onClick={() => setModalWindow("email")}
        />
        <SettingListItem
          text={
            phoneData ? `${phoneData?.countryCode} ${phoneData?.value}` : ""
          }
          wide
          caption={t("userProfile.editProfile.phone")}
          placeholder={t("userProfile.editProfile.addPhoneNumber")}
          classNameType={""}
          onClick={handleRedirect}
        />
      </ul>
      <p className={styles.label}>{t("userProfile.editProfile.userName")}</p>
      <SettingListItem
        text={data?.username || ""}
        classNameType={"single"}
        onClick={() => setModalWindow("userName")}
      />
      <p className={styles.label}>
        {t("userProfile.editProfile.password")}
        <span onClick={() => setModalWindow("password")}>
          {t("userProfile.editProfile.change")}
        </span>
      </p>
      <SettingListItem
        text="&bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull;"
        classNameType={"single"}
        onClick={() => setModalWindow("password")}
      />
      <div
        className={styles.logoutButtonContainer}
        onClick={() => setIsShowDeleteModalWindow(true)}
      >
        <p className={styles.buttonText}>
          {t("userProfile.editProfile.deleteAccount")}
        </p>
      </div>
  
      {!!modalWindow && (
        <ModalWindow
          onClose={onCloseModalWindow}
          isShow={!!modalWindow}
          {...modalWindowData()}
        ></ModalWindow>
      )}

      {isShowDeleteModalWindow && (
        <DeleteProfilePopup
          type="user"
          onClose={() => setIsShowDeleteModalWindow(false)}
          isShow={isShowDeleteModalWindow}
          onApprove={onDeleteProfileClick}
        ></DeleteProfilePopup>
      )}
    </div>
  );
};
