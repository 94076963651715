import styles from "./EditProfilesPage.module.scss";

import ModalWindowHeader from "../../shared/components/modalWindowHeader/ModalWindowHeader";
import { useTranslation } from "react-i18next";
import {  useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { SettingListItem } from "../../shared/components/settingListItem/SettingListItem";
import mainIcon from '../../shared/image/editProfile/mainIcon.svg'
import photoIcon from '../../shared/image/editProfile/photoIcon.svg'
import internetProfilesIcon from '../../shared/image/editProfile/internetProfilesIcon.svg'
import familyIcon from '../../shared/image/editProfile/familyIcon.svg'
import educationIcon from '../../shared/image/editProfile/educationIcon.svg'
import workIcon from '../../shared/image/editProfile/workIcon.svg'
import hobbyIcon from '../../shared/image/editProfile/hobbyIcon.svg'
import { CommonTab } from "../../components/editProfile/commonTab/CommonTab";
import { MainInfoTab } from "../../components/editProfile/mainInfoTab/MainInfoTab";
import { MediaTab } from "../../components/editProfile/mediaTab/MediaTab";
import smallArrow from "../../shared/image/arrowIcon.svg"
import { LinksTab } from "../../components/editProfile/linksTab/LinksTab";

const iconsMain=[mainIcon,photoIcon,internetProfilesIcon,familyIcon,educationIcon,workIcon, hobbyIcon]


export const EditProfilesPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<number | null>(0);
  const [blockSetting, setBlockSetting] = useState<string>( "");
  const items = t("editProfile.fields", { returnObjects: true }) as string[];

  const handleItemClick = (item: number) => {
    setBlockSetting(items[item]);
    setSelectedItem(item);

  };

  const onClose = () => {
    setSelectedItem(null);
    setBlockSetting("");
  };

  const getClassName = (item: number) => {
    if (item === selectedItem) {
      return "selected";
    } else if (item + 1 === selectedItem) {
      if(item === 5){
        return "between"
      }
      return "beforeSelected";
    } else if (item - 1 === selectedItem) {
      return "afterSelected";
    }
    if (item === 1) {
      return "afterHidden";
    }
  
    return "";
  };
  
  return (
      <div className={styles.profileContainer}>
        <div
          className={`${styles.blockContainer}  ${
            blockSetting && styles.blockSettings
          }`}
        >
          <div className={styles.breadcrumbsContainer} onClick={()=> navigate(-1)}>
               <img src={smallArrow} alt="" />
       <p>{t("common.back")}</p>
         
          </div>
          <div className={styles.profileMainBlock}>
          <ModalWindowHeader title={t("editProfile.editProfile")} onClick={()=> navigate(-1)} />

            <h1 className={styles.header}>{t("editProfile.editProfile")}</h1>
            <ul className={styles.settingList}>
              {items.slice(0, 3).map((item, index) => (
                <SettingListItem
                  key={index}
                  text={item}
                  isStatic
                  icon={iconsMain[index]}
                  classNameType={index ===selectedItem?"selectedSingle":'single'}
                  onClick={() => handleItemClick(index)}
                />
              ))}
            </ul>
            <ul className={styles.settingList}>
              {items.slice(3, 7).map((item, index) => (
                <SettingListItem
                  key={index + 3}
                  text={item}
                  isStatic
                  icon={iconsMain[index + 3]}
                  classNameType={getClassName(index + 3)}
                  onClick={() => handleItemClick(index + 3)}
                />
              ))}
            </ul>
            <div
              className={styles.buttonContainer}
              onClick={()=> navigate(`/profile/${id}`)}
            >
              <p className={styles.buttonText}>{t("editProfile.goToProfile")}</p>
            </div>
          </div>
        </div>
        <div
          className={`${styles.blockContainer} ${styles.blockSettings} ${
            blockSetting && styles.showBlock
          }`}
        >
          <div className={`${styles.profileSettings}`}>
            <ModalWindowHeader title={blockSetting} onClick={onClose} />
            {selectedItem === 0 && <MainInfoTab id={id as string} />}
            {selectedItem === 1 && <MediaTab id={id as string}  />}
            {selectedItem === 2 && <LinksTab id={id as string} />}

            {selectedItem === 3 && <CommonTab id={id as string} type="education" />}
            {selectedItem === 4 && <CommonTab id={id as string} type="job" />}
            {selectedItem === 5 && <CommonTab id={id as string} type="hobbies" />}
          </div>
        </div>
      </div>
  );
};
