import { TailSpin } from "react-loader-spinner";
import styles from "./Loader.module.scss";

type LoaderProps = {
 height?:number
};

export const Loader = ({height}:LoaderProps) => {
  return (
    <TailSpin
    height="80"
    width="80"
    color="#A1A3B2"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{height: `${height}px`}}
    wrapperClass={styles.loader}
    visible={true}
  />
  );
};
