import { useEffect, useState } from "react";

import styles from "./QRModalWindow.module.scss";
import { useTranslation } from "react-i18next";
import {
  useDownloadQRCodeQuery,
  useGetQRCodeQuery,
} from "../../../api/ProfileService";

type PopupProps = {
  onClose: () => void;
  id: string;
};

export const QRModalWindow = ({ onClose, id }: PopupProps) => {
  const { t } = useTranslation();
  const [skip, setSkip] = useState(true);
  const { data: QR } = useGetQRCodeQuery(id);

  const [selectedItem, setSelectedItem] = useState<string>("");
  const { isSuccess, isLoading } = useDownloadQRCodeQuery(
    { id, format: selectedItem },
    { skip }
  );

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const formatData = [
    { id: "A6", name: "A6" },
    { id: "A5", name: "A5" },
    { id: "A4", name: "A4" },
    { id: "A3", name: "A3" },
    { id: "A2", name: "A2" },
    { id: "A1", name: "A1" },
  ];

  return (
    <div>
      <ul className={styles.formatList}>
        {formatData.map((item: { id: string; name: string }) => {
          return (
            <li
              key={item.id}
              className={`${styles.listItem} ${
                item.id === selectedItem ? styles.selected : ""
              }`}
              onClick={() => setSelectedItem(item.id)}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
      <div className={styles.qrcodeContainer}>
        <img src={QR} alt="" loading="lazy"/>
      </div>
      <button
        className={styles.closeButton}
        onClick={() => setSkip(false)}
        disabled={!selectedItem || isLoading}
      >
        {t("common.next")}
      </button>
    </div>
  );
};
