import styles from "./ProfilePage.module.scss";
import { Menu } from "../../components/menu/Menu";
import { useParams, useSearchParams } from "react-router-dom";
import { ProfileInfo } from "../../components/profile/ProfileInfo";
import { ProfileMenu } from "../../components/profile/profileMenu/ProfileMenu";
import { ProfileWall } from "../../components/profile/profileWall/ProfileWall";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { LoadingPage } from "../loadingPage/LoadingPage";
import { UIEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  useGetCandleMutation,
  useGetFeelingsMutation,
} from "../../api/GiftService";
import {
  resetProfileData,
  setCandlesData,
  setCandlesPageNumber,
  setCheckedTab,
  setFeelingsData,
  setFeelingsPageNumber,
  setHasPreviousPosts,
  setIsWallLoading,
  setPostsData,
  setPostsPageNumber,
} from "../../store/slices/ProfileSlice";
import { MemorialTypes } from "../../constants/Memorial.constant";
import { useGetPostsMutation } from "../../api/PostProfileService";
import { deleteState } from "../../store/slices/OrderSlice";
import { PostType } from "../../types/ProfileType";
import { ModalWindow } from "../../components/modalWindow/ModalWindow";
import { useTranslation } from "react-i18next";

export const ProfilePage = () => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };
  const [searchParams] = useSearchParams();
  const postId = searchParams.get("postId");
  const [isShowWall, setIsShowWall] = useState<boolean>(true);
  const [isSharedPost, setIsSharedPost] = useState<boolean>(false);
  const [isPreviousPosts, setPreviousPosts] = useState<boolean>(false);
  const [isShowInfoPopup, setIsShowInfoPopup] = useState<boolean>(false);

  const bottomHeight = useRef(0);

  const { isPaymentLoading } = useSelector(
    (state: RootState) => state.orderSlice
  );
  const {
    checkedTab,
    candlesData,
    feelingsData,
    postsData,
    candlesPageNumber,
    feelingsPageNumber,
    postsPageNumber,
    isWallLoading,
    hasPreviousPosts,
  } = useSelector((state: RootState) => state.profileSlice);

  const [
    getCandles,
    {
      data: candlesFetchData,
      isLoading: isLoadingGetCandle,
      isSuccess: isGetCandleSuccess,
    },
  ] = useGetCandleMutation();
  const [
    getFeelings,
    {
      data: feelingsFetchData,
      isLoading: isGetFeelingsLoading,
      isSuccess: isGetFeelingsSuccess,
    },
  ] = useGetFeelingsMutation();
  const [
    getPosts,
    {
      data: postsFetchData,
      isLoading: isGetPostsLoading,
      isSuccess: isGetPostsSuccess,
    },
  ] = useGetPostsMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (postsData.length < 7 && postsFetchData?.hasNext) {
      getPosts({ profileId: id, from: postsData[postsData.length - 1].id });
      dispatch(setPostsPageNumber(postsPageNumber + 1));
    }
  }, [postsData.length]);

  useEffect(() => {
    dispatch(deleteState());
    getFeelings({ profileId: id, pageNumber: 1 });
    getCandles({ profileId: id, pageNumber: 1 });
    if (!isGetPostsLoading) {
      const body: {
        profileId: string;
        from?: string;
      } = {
        profileId: id,
      };

      if (postId) {
        dispatch(setCheckedTab(MemorialTypes.posts));
        body.from = postId;
        setIsSharedPost(true);
      }
      getPosts(body);
      dispatch(setIsWallLoading(true));
    }
  }, [id]);

  useEffect(() => {
    if (isGetCandleSuccess) {
      if (candlesPageNumber === 1) {
        dispatch(setCandlesData(candlesFetchData.items));
      } else {
        dispatch(setCandlesData([...candlesData, ...candlesFetchData.items]));
      }
      dispatch(setIsWallLoading(false));
    }
  }, [isGetCandleSuccess]);

  useEffect(() => {
    if (isGetFeelingsSuccess) {
      if (feelingsPageNumber === 1) {
        dispatch(setFeelingsData(feelingsFetchData.items));
      } else {
        dispatch(
          setFeelingsData([...feelingsData, ...feelingsFetchData.items])
        );
      }
      dispatch(setIsWallLoading(false));
    }
  }, [isGetFeelingsSuccess]);

  useEffect(() => {
    if (isGetPostsSuccess && postsFetchData) {
      if (!postsFetchData.isFromExistAndNotDeleted && isSharedPost) {
        setIsShowInfoPopup(true);
      }
      if (isPreviousPosts) {
        setPreviousPosts(false);
        const newArr = postsData.reduce(
          (acc: PostType[], eachArr2Elem: PostType) => {
            if (
              postsFetchData.items.findIndex(
                (eachArr1Elem: PostType) => eachArr1Elem.id === eachArr2Elem.id
              ) === -1
            ) {
              acc.push(eachArr2Elem);
            }
            return acc;
          },
          [...postsFetchData.items]
        );
        dispatch(setHasPreviousPosts(postsFetchData.hasPrevious));
        dispatch(setPostsData(newArr));
        const postContainer =
          window.innerWidth > 1150
            ? document.getElementById("pageContainer")
            : document.getElementById("wallScrollableContainer");

        if (postContainer) {
          bottomHeight.current =
            postContainer.scrollHeight - postContainer.scrollTop;
        }
        if (isSharedPost && postId) {
          if (window.innerWidth > 1150) {
            const postContainer = document.getElementById("pageContainer");

            if (postContainer) {
              postContainer.scrollTop = 20;
            }
          } else {
            const wallScrollableContainer = document.getElementById(
              "wallScrollableContainer"
            );
            if (wallScrollableContainer) {
              wallScrollableContainer.scrollTop = 20;
            }
          }
          setTimeout(() => {
            dispatch(setHasPreviousPosts(postsFetchData.hasPrevious));
          }, 0);
        }
      } else if (postsPageNumber === 1) {
        dispatch(setPostsData(postsFetchData.items));
      } else {
        const newArr = postsFetchData.items.reduce(
          (acc: PostType[], eachArr2Elem: PostType) => {
            if (
              postsData.findIndex(
                (eachArr1Elem) => eachArr1Elem.id === eachArr2Elem.id
              ) === -1
            ) {
              acc.push(eachArr2Elem);
            }
            return acc;
          },
          [...postsData]
        );
        dispatch(setPostsData(newArr));
      }
      if (isSharedPost) {
        dispatch(setHasPreviousPosts(postsFetchData.hasPrevious));
      }
      dispatch(setIsWallLoading(false));
    }
  }, [isGetPostsSuccess]);

  const setScrollHeight = () => {
    const postContainer =
      window.innerWidth > 1150
        ? document.getElementById("pageContainer")
        : document.getElementById("wallScrollableContainer");

    if (postContainer && bottomHeight.current) {
      postContainer.scrollTop =
        postContainer.scrollHeight - bottomHeight.current;
        bottomHeight.current = 0
    }
  };

  useLayoutEffect(() => {
    const postContainer =
      window.innerWidth > 1150
        ? document.getElementById("pageContainer")
        : document.getElementById("wallScrollableContainer");

    if (postContainer) {
      const observer = new MutationObserver(() => {
        setScrollHeight();
      });
      observer.observe(postContainer, {
        childList: true,
        subtree: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    setScrollHeight();
  }, [bottomHeight.current]);

  useEffect(() => {
    return () => {
      dispatch(resetProfileData());
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1150) {
        setIsShowWall(false);
      } else {
        setIsShowWall(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const refetch = (type: string) => {
    switch (type) {
      case MemorialTypes.candles:
        dispatch(setCandlesPageNumber(1));
        getCandles({ profileId: id, pageNumber: candlesPageNumber });
        break;
      case MemorialTypes.feelings:
        dispatch(setFeelingsPageNumber(1));
        getFeelings({ profileId: id, pageNumber: 1 });
        break;
      default:
        dispatch(setPostsPageNumber(1));
        getPosts({ profileId: id });
        break;
    }
  };
  const fetchNewPageData = (type: string) => {
    switch (type) {
      case MemorialTypes.candles:
        if (
          !isWallLoading &&
          !isLoadingGetCandle &&
          candlesFetchData?.pageNumber < candlesFetchData?.totalPages
        ) {
          dispatch(setIsWallLoading(true));
          getCandles({ profileId: id, pageNumber: candlesPageNumber + 1 });
          dispatch(setCandlesPageNumber(candlesPageNumber + 1));
        }
        break;
      case MemorialTypes.feelings:
        if (
          !isWallLoading &&
          !isGetFeelingsLoading &&
          feelingsFetchData?.pageNumber < feelingsFetchData.totalPages
        ) {
          dispatch(setIsWallLoading(true));
          getFeelings({ profileId: id, pageNumber: feelingsPageNumber + 1 });
          dispatch(setFeelingsPageNumber(feelingsPageNumber + 1));
        }
        break;
      default:
        if (!isWallLoading && !isGetPostsLoading && postsFetchData?.hasNext) {
          dispatch(setIsWallLoading(true));
          getPosts({ profileId: id, from: postsData[postsData.length - 1].id });
          dispatch(setPostsPageNumber(postsPageNumber + 1));
        }
        break;
    }
  };

  const fetchPreviousPosts = () => {
    if (
      !isWallLoading &&
      !isGetPostsLoading &&
      postsFetchData?.hasPrevious &&
      hasPreviousPosts
    ) {
      
      bottomHeight.current = 0;
      setPreviousPosts(true);
      dispatch(setIsWallLoading(true));
      getPosts({
        profileId: id,
        from: postsData[0].id,
        beforeLimit: 9,
        limit: 0,
      });
      dispatch(setPostsPageNumber(postsPageNumber + 1));
    }
  };
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const divComponent = e.target as HTMLDivElement;
    if (
      divComponent.offsetHeight + divComponent.scrollTop >=
      divComponent.scrollHeight - 200
    ) {
      fetchNewPageData(checkedTab);
    }

    if (divComponent.scrollTop === 0 && hasPreviousPosts) {
      fetchPreviousPosts();
    }
  };

  return (
    <>
      {isPaymentLoading && <LoadingPage type="payment" />}
      <div
        className={styles.profilePageContainer}
        onScroll={handleScroll}
        id="pageContainer"
      >
        <ProfileMenu id={id as string} />
        <div className={styles.profilePageBody}>
          <div></div>
          <div className={styles.profileInfo}>
            <ProfileInfo
              isShowWall={isSharedPost}
              refetchData={refetch}
              fetchNewPageData={fetchNewPageData}
              fetchPreviousPosts={fetchPreviousPosts}
              id={id as string}
            ></ProfileInfo>
          </div>
          {isShowWall && (
            <div className={styles.profileWall}>
              <ProfileWall fetchNewPageData={fetchNewPageData} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.menuContainer}>
        <Menu />
      </div>
      {isShowInfoPopup && (
        <ModalWindow
          backdropModal
          onClose={() => setIsShowInfoPopup(false)}
          isShow={isShowInfoPopup}
          headerText={t("posts.deletedPost")}
          children={
            <button
              className={styles.popupButton}
              onClick={() => setIsShowInfoPopup(false)}
            >
              {t("common.close")}
            </button>
          }
        ></ModalWindow>
      )}
    </>
  );
};
