import styles from "./LoadingPage.module.scss";
import { useTranslation } from "react-i18next";
import payment from '../../shared/image/payment.png'
import { useEffect, useState } from 'react';

type LoadingPageProps = {
    type:string
  };

  
export const LoadingPage = ({type}:LoadingPageProps) => {
  const { t } = useTranslation();
  const [isLoadingPayment, setIsLoadingPayment] = useState(true);

  useEffect(() => {
    if (type === 'payment') {
      const timer = setTimeout(() => {
        setIsLoadingPayment(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, []);

  const generateMessage = () => {
    switch (type) {
      case 'payment':
        return isLoadingPayment ? t("payment.payment") : t("payment.isPaymentTakingLong");
      case 'status':
        return t("common.loading");
      default:
        return t("createProfile.profileCreating");
    }
  };

  return (
    <div className={styles.loadingPageWrapper}>
      <div className={styles.loadingPageContainer} >
        <div  className={styles.loadingPageBackgroundImage} style={{backgroundImage:`url( ${type === 'payment' && payment})`}}></div>
        <div className={styles.loadingPageSpin}></div>
        <p className={styles.description}>
          { generateMessage() }
        </p>
      </div>
    </div>
  );
};
