import styles from "./WizardOneTwoView.module.scss";
import { QrCodeWizardViewProps } from "../QrCodeWizardView";
import { WizardQrBlock } from "../shared/wizardQrBlock/WizardQrBlock";

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const WizardOneTwoView = ({ model, qr, blockForPlate, isBlack }: QrCodeWizardViewProps) => {
    const getTemplateUrl = (): string => {
        return !!model?.templateUrl ? url + model.templateUrl : "";
    }

    return (
      <div className={styles.container}
           ref={blockForPlate}>
          <div className={styles.background} style={{backgroundImage: `url(${getTemplateUrl()})`}}></div>
          <WizardQrBlock isBlack={isBlack} widthAndHeight={"239px"} qr={qr}/>
      </div>
    );
};
