import React, { useEffect, useState } from "react";
import { useGetFullUrlQuery } from "../../api/UtilityService";
import { useParams } from "react-router-dom";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import { useGetProfileQuery } from "../../api/ProfileService";
import useIsAuthenticated from "../../utils/useIsAuthenticated";
import { getLanguageByLocale } from "../../utils/localeUtils";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

function ShortUrlRedirect() {
  const { url } = useParams();
  const {isAuthenticated} = useIsAuthenticated();
  const { skip } = useFetchWithMsal("profile", isAuthenticated);
  const [profileId, setProfileId] = useState("");
  const { data } = useGetFullUrlQuery(url, { skip: !url });
  const { language } = useSelector((state: RootState) => state.language);

  const {  isSuccess } = useGetProfileQuery({id:profileId, lang:getLanguageByLocale(language)}, {
    skip: skip || !data?.id,
  });

  useEffect(() => {
    if (data) {
      const parts = data.targetUrl?.split("/");
      setProfileId(parts[parts.length - 1]);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      window.location.href = data.targetUrl;
    }
  }, [isSuccess]);
  return <></>;
}

export default ShortUrlRedirect;
