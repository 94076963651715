import styles from "./CommonTab.module.scss";
import { useTranslation } from "react-i18next";

import { InfoCard } from "../../../../../shared/components/infoCard/InfoCard";
import {
  useGetEducationsQuery,
  useGetHobbiesQuery,
  useGetWorksQuery,
} from "../../../../../api/ProfileService";

type PropsType = {
  id: string;
  type: string;
};

type InfoType = {
  id: string;
  name: string;
  description: string;
};

export const CommonTab = ({ id, type }: PropsType) => {
  const { data: hobbies } = useGetHobbiesQuery(id, { skip: type !== "hobby" });
  const { data: works } = useGetWorksQuery(id, { skip: type !== "work" });
  const { data: education } = useGetEducationsQuery(id, {
    skip: type !== "education",
  });
  const { t } = useTranslation();

  const getData = () => {
    switch (type) {
      case "hobby":
        return hobbies;
      case "work":
        return works;
      case "education":
        return education;
      default:
        break;
    }
  };
  const getEmptyDescription = () => {
    switch (type) {
      case "hobby":
        return t("profile.emptyHobby");
      case "work":
        return t("profile.emptyJob");
      case "education":
        return t("profile.emptyEducation");
      case "family":
        return t("profile.emptyFamily");
      default:
        break;
    }
  };
  return (
    <div className={styles.commonTabContainer}>
      {getData()?.length ? (
        getData().map((item: InfoType, index: number) => {
          return (
            <InfoCard
              header={item.name}
              description={item.description}
              key={index}
            />
          );
        })
      ) : (
        <p className={styles.emptyDescription}>
          {getEmptyDescription()}<br />
         { type !== 'family' && 
          t("profile.settingInfo")
          }
        </p>
      )}
    </div>
  );
};
