const calculateAgeAndLifeSpan = (
  birthDate: string,
  deathDate: string,
  unknown: string
) => {
  const birth = birthDate ? new Date(birthDate) : null;
  const death = deathDate ? new Date(deathDate) : null;

  const ageInMillis = death && birth ? death.getTime() - birth.getTime() : null;
  const ageInYears = ageInMillis
    ? Math.floor(ageInMillis / (365.25 * 24 * 60 * 60 * 1000))
    : 0;
  const lifeSpan = `${birth ? birth.getFullYear() : unknown} - ${
    death ? death.getFullYear() : unknown
  }`;

  return { ageInYears, lifeSpan };
};
export default calculateAgeAndLifeSpan;
