import styles from './Filter.module.scss';
import stylesRadioButton from './GenderRadioButton/RadioButtonStyles.module.scss';
import React, {useEffect, useState, TouchEvent, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';

import { DateDropdown } from '../../shared/components/dateDropdown/DateDropdown';
import cross from '../../shared/image/cross.svg';

import { GENDER_FEMALE, GENDER_MALE } from '../../constants/constants';
import { SettingListItem } from '../../shared/components/settingListItem/SettingListItem';
import { InputInitial } from '../../shared/components/input/InputInitial';
import { PopupBackground } from '../../shared/components/popupBackground/PopupBackground';
import { FilterByLocationAndReligion } from './FilterByLocationAndReligion/FilterByLocationAndReligion';
import {
  cleaningTheFilter,
  SelectedCountryType,
  setFilterLanguage,
  setOffset, setSearchValue,
  updateFilter,
  updateFilterTrigger,
} from '../../store/slices/FiltersFormSlice';
import { DateType } from '../../types/CommonTypes';
import { GenderRadioButton } from './GenderRadioButton/GenderRadioButton';
import { FilterButton } from './FilterButton/FilterButton';

type FilterProps = {
  onClose: () => void;
  searchTerm: string;
}

export const Filter = ({ onClose, searchTerm }: FilterProps) => {
  const { accounts } = useMsal();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.language);
  const filtersForm = useSelector((state: RootState) => state.filtersFormSlice);
  const { selectedCountryData, selectedReligionData } = filtersForm;
  const isDragging = useRef(false);
  const [filterState, setFiltersState] = useState(filtersForm);
  const [selectedCountry, setSelectedCountry] = useState<SelectedCountryType | null>(null);
  const [isShow, setIsShow] = useState<string>('');
  const [isReset, setIsReset] = useState<boolean>(false);
  const [stateIsChange, setStateIsChange] = useState<boolean>(false);
  const [errorDateOfDeath, setErrorDateOfDeath] = useState("");
  const [errorDateOfBirth, setErrorDateOfBirth] = useState("");
  const [errorAge, setErrorAge] = useState("");
  const [touchStartY, setTouchStartY] = useState<number | null>(null);

  const errorMessage = t("filter.dateValidation");
  const deathBeforeBirthErrorMessage = t("filter.deathBeforeBirthValidation");
  const ageErrorMessage = t("filter.deathBeforeBirthValidation");

  useEffect(() => {
    if (isReset) {
      setFiltersState(filtersForm)
      setIsReset(false);
    }
  }, [isReset])

  useEffect(() => {
    const handleTouchMove: (
      this: Document,
      ev: TouchEventInit | TouchEvent
    ) => any = function (event) {
      if (
        isDragging.current &&
        event.touches &&
        event.touches[0].clientY > 0
      ) {
        (event as TouchEvent).stopPropagation();
        (event as TouchEvent).preventDefault();
      }
    };

    document.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);
  
  const onChangeFilterState = (value: any, name: string) => {
    setFiltersState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'ageTo' || name === 'ageFrom' ) {
      if(errorAge) {
        setErrorAge("")
      }
    }
    if (name === 'birthDate' || name === 'deathDate' ) {
      if (checkDateObject(value)) {
        setStateIsChange(true)
      }
      if (errorDateOfBirth || errorDateOfDeath) {
        setErrorDateOfDeath("");
        setErrorDateOfBirth("");
      }
    } else {
      setStateIsChange(true)
    }
    dispatch(setOffset(0))
  };

  function checkDateObject(dateObj: DateType) {
    if(dateObj) {
      return !Object.values(dateObj).includes(0) && !Object.values(dateObj).includes(-1);
    }
  }

  const validateDateOfDeath = () => {
    const birth = filterState.birthDate as DateType;
    const death = filterState.deathDate as DateType;
    const isCheckDateOfBirth = checkDateObject(filterState.birthDate as DateType);
    const isCheckDateOfDeath = checkDateObject(filterState.deathDate as DateType);

    if (!isCheckDateOfBirth) {
      setErrorDateOfBirth(errorMessage);
    }
    if (!isCheckDateOfDeath) {
      setErrorDateOfDeath(errorMessage);
    }

    if (isCheckDateOfBirth && isCheckDateOfDeath) {
      const dateOfBirthObj = birth && new Date(birth.year, birth.month-1, birth.day);
      const dateOfDeathObj = death && new Date(death.year, death.month-1, death.day);

      if (dateOfBirthObj && dateOfDeathObj) {
        dateOfBirthObj.setFullYear(birth.year);
        dateOfDeathObj.setFullYear(death.year);

        const isCorrectDeathDate = dateOfDeathObj >= dateOfBirthObj && dateOfDeathObj <= new Date();
        const isCorrectBirthDate = dateOfBirthObj <= new Date();

        if (!isCorrectDeathDate) {
          setErrorDateOfDeath(deathBeforeBirthErrorMessage);
        }
        if (!isCorrectBirthDate) {
          setErrorDateOfBirth(deathBeforeBirthErrorMessage);
        }
        return isCorrectBirthDate && isCorrectDeathDate;
      }
    }
    else {
      return isCheckDateOfBirth && isCheckDateOfDeath
    }
  };

  const validationAge = () => {
    const ageFrom = filterState.ageFrom;
    const ageTo = filterState.ageTo;

    if(ageFrom && ageTo) {
      const isCorrectRangeOfAge = Number(ageTo) >= Number(ageFrom)
      if(!isCorrectRangeOfAge) {
        setErrorAge(ageErrorMessage)
      }
      return isCorrectRangeOfAge;
    }
  };
  const onSubmit = () => {
    if (isShow) {
      setIsShow('');
    } else {
      if (filterState.birthDate || filterState.deathDate) {
        const isCheckDateOfBirth = checkDateObject(filterState.birthDate as DateType);
        const isCheckDateOfDeath = checkDateObject(filterState.deathDate as DateType);

        if (!isCheckDateOfBirth && isCheckDateOfBirth !== undefined) {
          setErrorDateOfBirth(errorMessage);
          return;
        }
        if (!isCheckDateOfDeath && isCheckDateOfDeath !== undefined) {
          setErrorDateOfDeath(errorMessage);
          return;
        }
      }
      if (filterState.birthDate && filterState.deathDate && !validateDateOfDeath()) {
        return;
      }
      if (filterState.ageFrom && filterState.ageTo && !validationAge()) {
        return;
      }
      const filterRequestData = {
        ...filterState,
        countryIds: selectedCountryData?.map((country) => country.id),
        cityIds: selectedCountryData?.flatMap(country => country.cities.map(city => city.id)),
        religionIds: selectedReligionData?.map(religion => religion.id)
      }
      dispatch(updateFilter(filterRequestData));
      dispatch(updateFilterTrigger(true));
      dispatch(setFilterLanguage(language));
      if (searchTerm) {
        dispatch(setSearchValue(searchTerm))
      }
      if (accounts.length !== 0) {
        navigate("/");
      } else {
        navigate("/profiles")
      }
      onClose();
    }
  };

  const onCloseFilterBlock = () => {
    setIsShow('');
    dispatch(setFilterLanguage(language));
  };

  const onReset = () => {
    dispatch(cleaningTheFilter());
    dispatch(updateFilterTrigger(false));
    setIsReset(true);
    setIsShow('');
    setErrorDateOfDeath("");
    setErrorDateOfBirth("");
    setErrorAge("");
    setStateIsChange(false);
  };

  const handleClick = (country: SelectedCountryType) => (event?: MouseEvent ): void => {
    if ( country?.id ) {
      setIsShow('city');
      setSelectedCountry(country);
    } else if(event) {
      event.preventDefault();
    }
  };

  const onCloseFilter = () => {
    if (!filtersForm?.filterTrigger) {
      dispatch(cleaningTheFilter())
    }
    if (stateIsChange) {
      onSubmit();
    }
    dispatch(setFilterLanguage(language));
    onClose();
  };
  const handleStartSwipe = (e: TouchEvent<HTMLDivElement>) => {
    setTouchStartY(e.touches[0].clientY);
    isDragging.current = true;
  };
 
  const handleSwipe = (e: TouchEvent) => {
    if (touchStartY) {
      const swipeThreshold = 50;
      const touchEndY = e.changedTouches[0].clientY;
      const deltaY = touchEndY - touchStartY;
      isDragging.current = false;

      if (deltaY > swipeThreshold) {
        dispatch(setFilterLanguage(language));
        onClose();
      }
    }
  };

  return (
    <>
      <PopupBackground onClose={onCloseFilter} />
      <div
        className={isShow ? styles.mobileFilterContainer : styles.filterContainer}
      >
        <div
          className={isShow ? styles.hide : styles.filterTitleMobile}
          onTouchStart={handleStartSwipe}
          onTouchEnd={handleSwipe}
        >
          <span></span>
          <h3>{t("filter.title")}</h3>
        </div>
        <div className={styles.filterMainBlock}>
          <div>
            <div
              className={isShow ? styles.filterTitleMobileHidden : styles.filterTitle}
              onTouchStart={handleStartSwipe}
              onTouchEnd={handleSwipe}
            >
              <span></span>
              <h3>{t("filter.title")}</h3>
              <img className={styles.close} src={cross} alt="" onClick={onCloseFilter} />
            </div>
            <div className={`${isShow ? styles.hide : styles.filterContent}`}>
              <div className={styles.filterBlock}>
                <h3>{t("filter.birthDate")}</h3>
                <DateDropdown
                  value={ filterState.birthDate ? filterState.birthDate : {
                    year: -1,
                    month: -1,
                    day: 0,
                  }}
                  onChange={(value) => onChangeFilterState( value, "birthDate")}
                  error={errorDateOfBirth}
                  smallSize
                />
              </div>
              <div className={styles.filterBlock}>
                <h3>{t("filter.deathDate")}</h3>
                <DateDropdown
                  value={ filterState.deathDate ? filterState.deathDate : {
                    year: -1,
                    month: -1,
                    day: 0,
                  }}
                  onChange={(value) => onChangeFilterState( value, "deathDate")}
                  error={errorDateOfDeath}
                  smallSize
                />
              </div>
              <div className={styles.filterBlock}>
                <h3>{t("filter.age")}</h3>
                <div className={styles.ageBlock}>
                  <InputInitial
                    name="ageFrom"
                    label={t("filter.ageFrom")}
                    value={filterState.ageFrom}
                    setValue={onChangeFilterState}
                    inputStyle={styles.inputStyle}
                  />
                  <InputInitial
                    name="ageTo"
                    label={t("filter.ageTo")}
                    value={filterState.ageTo}
                    setValue={onChangeFilterState}
                    inputStyle={styles.inputStyle}
                  />
                </div>
                {errorAge && <p className={styles.errorMessage}> {errorAge}</p>}
              </div>
              <div className={styles.filterBlock}>
                <h3 className={stylesRadioButton.formHeader}>{t("filter.gender")}</h3>
                <div className={stylesRadioButton.groupContainer}>
                  <GenderRadioButton
                    value={GENDER_MALE}
                    filterState={filterState}
                    onChangeFilterState={onChangeFilterState}
                    label={t("filter.male")}
                  />
                  <GenderRadioButton
                    value={GENDER_FEMALE}
                    filterState={filterState}
                    onChangeFilterState={onChangeFilterState}
                    label={t("filter.female")}
                  />
                </div>
              </div>
              <div className={styles.filterBlock}>
                <h3>{t("filter.additionally")}</h3>
                <ul className={styles.settingList}>
                  <SettingListItem
                    text={t("filter.religion")}
                    smallSize
                    classNameType={selectedReligionData.length ? "filterReligionItems" : "borderBottomNone"}
                    onClick={() => setIsShow('religion')}
                    subText={selectedReligionData?.length ? selectedReligionData?.map((religion) => (religion.name)).join(', ') : t("filter.select")}
                  />

                  {(selectedCountryData.length ? selectedCountryData : filterState.country).map((country, index, array) => {
                    return (
                      <div key={index} className={index === array.length - 1 ? styles.settingBlock : ''}>
                        <SettingListItem
                          text={t("filter.country")}
                          smallSize
                          classNameType={(country.name) ? "filterCountriesItems" : "filterCountriesBorderTopNone"}
                          onClick={() => setIsShow('country')}
                          subText={country.name || t("filter.select")}
                        />
                        <SettingListItem
                          text={t("filter.city")}
                          smallSize
                          classNameType={country && country.cities?.length ? "filterCities" : "borderBottomNone"}
                          onClick={handleClick(country)}
                          subText={country && country.cities?.length
                            ? country.cities.map(city => city.name).join(', ')
                            : t("filter.select")}
                        />
                      </div>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          <FilterButton
            isShow={isShow}
            onReset={onReset}
            onSubmit={onSubmit}
            stateIsChange={stateIsChange}
            styleContainer={isShow ? styles.hide : styles.filterButton}
          />
          {isShow === 'religion' && (
            <FilterByLocationAndReligion
              onClick={() => setStateIsChange(true)}
              onClose={onCloseFilterBlock}
              type={'religion'}
            />
          )}
          {isShow === 'country' && (
            <FilterByLocationAndReligion
              type={'country'}
              onClick={() => setStateIsChange(true)}
              onClose={onCloseFilterBlock}
            />
          )}
          {isShow === 'city' && selectedCountry?.id && (
            <FilterByLocationAndReligion
              type={'city'}
              onClick={() => setStateIsChange(true)}
              onClose={onCloseFilterBlock}
              selectedCountry={selectedCountry}
            />
          )}
        </div>
      </div>
    </>
  )
}