import styles from "../../pages/createProfile/createProfileSteps/EditForm.module.scss";
import { useTranslation } from 'react-i18next';

export type ListType = {
  id: string;
  name: string;
  code?: string;
};
type ReligionFormProps = {
  onClick: (item: ListType) => void;
  data: ListType[];
  selected: string | string[];
  className?: string;
};

export const Religions = ({ onClick, data, selected, className }: ReligionFormProps) => {
  const { t } = useTranslation();

  return (
    <ul className={`${className ? className : styles.religionList}`}>
      {data.map((item: { id: string; name: string }) => {
        return (
          <li
            key={item.id}
            className={`${styles.dropdownItem} ${ Array.isArray(selected) 
              ? selected.includes(item.id) ? styles.selected : "" 
              : item.id === selected ? styles.selected : ""}`}
            onClick={() => onClick(item)}
          >
            {item.name}
          </li>
        );
      })}
      {data && !data.length && (
        <div className={styles.center}>{t("filter.noSearchResults")}</div>
      )}
    </ul>
  );
};
