import styles from "./QrCodeItem.module.scss";
import selectedIcon from "../../image/selectedIcon.svg";
import {useTranslation} from "react-i18next";
import { ReactElement } from "react";

type QrCodeProps = {
  onClick: (id: string) => void;
  type?: string;
  selected: boolean;
  isBlack?: boolean;
  id: string;
  img?: string;
  description?: string;
  price?: number;
  currency?: string;
  big?: boolean;
};

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const QrCodeItem = ({
  type,
  selected,
  isBlack,
  id,
  onClick,
  img,
  description,
  price,
  currency,
  big
}: QrCodeProps) => {
  const { t } = useTranslation();

  const getClassNameByType = (): string => {
    let result = isBlack ? `${styles.black} ` : "";
    switch (type) {
      case 'format':
        result += styles.format;
        break;
      case 'material':
        result += styles.material;
        break;
      case 'mounting':
        result += styles.mounting;
        break
    }
    return result;
  }

  const getPrice = (): ReactElement => {
    if (price == null) {
      return (<></>);
    } else {
      let priceText = ` ${price} ${currency}`;
      let from = '';

      if (type === 'material') {
        from = t("qrCode.from");
      }
      if (price === 0) {
        priceText = t("memorial.free");
      }
      return (<div className={price === 0 ? styles.priceFree : styles.price}>
        {!!from && <span className={styles.from}>{from}</span>}
        {priceText}
      </div>)
    }
  }

  return (
      <div
          className={`
        ${styles.qrCodeContainer}
        ${getClassNameByType()}
        ${selected ? styles.selected : ""}
        ${!!big ? styles.big : ""}
      `}
      onClick={() => onClick(id)}
    >
      <div className={styles.imageContainer}>
        {!!img && <img
            className={styles.photo}
            src={url + img}
            alt=""
        />}
      </div>

      <div className={styles.description}>
        {description}
        {getPrice()}
      </div>

      {selected && (
          <img className={styles.selectIcon} src={selectedIcon} alt="" />
      )}
    </div>
  );
};
