import { ChangeEvent, DragEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./DropArea.module.scss";
import imgIcon from "../../shared/image/imgIcon.png";
import imgIconLight from "../../shared/image/imgIconLight.png";

import video from "../../shared/image/video.png";
import { ErrorImages, ImageItem } from "../../types/CommonTypes";
import { acceptFormats, allowedImageTypes, allowedVideoTypes } from "../../constants/Media.constant";
import { transformImage } from "../../utils/transformImage";

type ImageDropProps = {
  onDrop: (value: ImageItem[], errorValue: ErrorImages) => void;
  type?: string;
  text?: string;
};

const maxImageSize = !!process.env.MAX_IMAGE_SIZE
  ? +process.env.MAX_IMAGE_SIZE
  : 15 * 1024 * 1024;
const maxVideoSize = !!process.env.MAX_VIDEO_SIZE
  ? +process.env.MAX_VIDEO_SIZE
  : 100 * 1024 * 1024;

const ImageDrop = ({ onDrop, type, text }: ImageDropProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };
  const handleDrop = (
    e: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const photoArray = [] as ImageItem[];
    const files = Array.from(
      (e as DragEvent<HTMLDivElement>).dataTransfer?.files ||
        (e as ChangeEvent<HTMLInputElement>).target.files
    );


    const errorProps: ErrorImages = {
      filesName: [],
      showImageError: false,
      showVideoError: false,
      showFormatError: false
    };
    [...files].forEach((file) => {
      
      if (!!file) {
        const fileType = file.type as string;
        if (allowedImageTypes.includes(fileType)) {
          if (file.size < maxImageSize) {
            const newItem: ImageItem = {
              original: file,
              thumbnail: URL.createObjectURL(file as Blob),
              description: "image",
              size: file.size,
              type: fileType,
            };
            transformImage(newItem as ImageItem)
            photoArray.push(newItem);
          } else {
            errorProps.showImageError = true;
            errorProps.filesName.push(file.name);
          }
        } else if (allowedVideoTypes.includes(fileType)) {
          if (file.size < maxVideoSize) {
            const newItem: ImageItem = {
              original: file,
              thumbnail: video,
              description: "video",
              size: file.size,
              type: fileType,
            };
            photoArray.push(newItem);
          } else {
            errorProps.showVideoError = true;
            errorProps.filesName.push(file.name);
          }
        } else {
          errorProps.showFormatError = true;
          errorProps.filesName.push(file.name);
        }
      }
    });
    if (inputRef?.current) {
      inputRef.current.value = "";
    }
    onDrop(photoArray, errorProps);
  };
  const getClassName = () => {
    switch (type) {
      case "all":
        return styles.bigArea;
      case "map":
        return styles.mapArea;
      case "mobile":
        return styles.postsArea;

      default:
        return "";
    }
  };
  return (
    <div
      className={`${styles.dropAreaContainer} ${getClassName()}`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={() => type !== "avatar" && inputRef.current?.click()}
    >
      <img
        src={type === "avatar" ? imgIconLight : imgIcon}
        alt=""
        draggable={false}
        loading="lazy"
        className={
          type !== "mapSmall" ? styles.dropAreaImage : styles.dropAreaImageMap
        }
      />
      {type !== "mapSmall" && (
        <p>{text || t("createProfile.dropAreaDescription")}</p>
      )}
      <input
        accept={acceptFormats}
        id="icon-button-file"
        multiple
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleDrop}
      />
    </div>
  );
};

export default ImageDrop;
