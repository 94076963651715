import { useTranslation } from "react-i18next";
import styles from "./ClipboardNotification.module.scss";

type ClipboardNotificationProps = {
  isShow: boolean
  isQrPopup?:boolean
};

export const ClipboardNotification = ({  isShow ,isQrPopup}: ClipboardNotificationProps) => {
  const { t } = useTranslation();
  
  return (
    <>
    {isShow && (
          <div className={isQrPopup ? styles.clipboardNotificationQR: styles.clipboardNotification}>
            {t("common.linkCopied")}
          </div>
        )}
    </>
  );
};
