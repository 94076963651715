import styles from "./PostsHeader.module.scss";
import { ReactComponent as User } from "../../image/userIcon.svg";
import  dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

type PropsType = {
  img: string | null;
  date: string | Date;
  username:string
};
const url = process.env.REACT_APP_BLOB_DNS_NAME as string;
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const PostsHeader = ({
  date,
  img,
  username
}: PropsType) => {
  const { t } = useTranslation();

  function timeSincePost(postDate: string | Date) {

    const postTime = dayjs.utc(postDate).local().format("YYYY-MM-DD HH:mm:ss");
    const currentTime = dayjs();
    const timeDifference = Math.abs(currentTime.diff(postTime, "seconds"));

    const seconds = Math.floor(timeDifference);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} ${t("order.daysAgo")}`;
    } else if (hours > 0) {
      return `${hours} ${t("order.hoursAgo")}`;
    } else if (minutes > 0) {
      return `${minutes} ${t("order.minutesAgo")}`;
    } else {
      return t("order.justNow");
    }
  }
  return (
    <div className={ styles.postHeaderContainer}>
       <div className={styles.imgContainer}>
         {!!img
           ? (<LazyLoadImage src={`${url}${img}`} alt=""/>)
           : (<User />)
         }
        </div>
        <div className={styles.postsItemHeader}>
          <h3>{username}</h3>
          <p className={styles.time}>{timeSincePost(date)}</p>
        </div>
    </div>
  );
};
