import styles from "./Checkbox.module.scss";

type CheckboxProps = {
  isChecked: boolean;
  onChange: (value: boolean) => void;
  label: string;
  disabled?: boolean;
};

export const Checkbox = ({ onChange, isChecked, label, disabled }: CheckboxProps) => {
  return (
    <label className={`${styles.checkboxLabel} ${isChecked ? styles.checked: ''} ${!!disabled ? styles.disabled : ''}`}>
      <input
        type="checkbox"
        disabled={!!disabled}
        checked={isChecked}
        onChange={() => onChange(!isChecked)}
      />
      {label}
    </label>
  );
};
