import { useTranslation } from "react-i18next";
import styles from "./AddCommentField.module.scss";
import arrowIcon from "../../../../../shared/image/postsIcon/arrowIcon.png";
import { KeyboardEvent, MouseEventHandler, useEffect, useRef, useState } from "react";
import { useAddCommentMutation } from "../../../../../api/PostProfileService";
import { useParams } from "react-router-dom";
import { PopupBackground } from "../../../../../shared/components/popupBackground/PopupBackground";
import { ModalWindow } from "../../../../modalWindow/ModalWindow";
import { SimpleTextarea } from "../../../../../shared/components/simpleTextarea/SimpleTextarea";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { CommentType } from "../../../../../types/ProfileType";
import { setShowSpinner } from "../../../../../store/slices/SpinnerSlice";
import { UnAuthenticatedPopup } from "../../../../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";
import useIsAuthenticated from "../../../../../utils/useIsAuthenticated";
import { tokenRequest } from "../../../../../utils/tokenRequest";
import { useMsal } from "@azure/msal-react";
import { setShowWarningModal } from '../../../../../store/slices/WarningModalSlice';

type AddCommentFieldProps = {
  postId: string;
  replyCommentId?: string;
  replyUserId?: string;
  isFocus?: boolean;
  initValue?: string;
  onBlur?: () => void;
  addNewComment: (value: CommentType) => void;
};

type RquestBodyType = {
  text: string;
  replyCommentId?: string;
  replyUserId?: string;
};

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const AddCommentField = ({
  postId,
  replyCommentId,
  replyUserId,
  isFocus,
  initValue,
  onBlur,
  addNewComment,
}: AddCommentFieldProps) => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();
  const {isAuthenticated} = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [
    isShowNotAuthenticatedModalWindow,
    setIsShowNotAuthenticatedModalWindow,
  ] = useState<boolean>(false);
  const [isOnFocus, setIsOnFocus] = useState<boolean>(false);
  const [text, setText] = useState<string>(initValue || "");
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [showErrorPopup, setShowErrorPopup] = useState<number | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    id: userId,
    username,
    photo,
  } = useSelector((state: RootState) => state.userPhotoSlice);
  const [addComment, { isSuccess, isLoading, data, error, isError }] =
    useAddCommentMutation();
  const dispatch = useDispatch();

  const reset = () => {
    if (onBlur) {
      onBlur();
      preventScroll(false);
    }
    setText("");
    setIsOnFocus(false);
  };

  const preventScroll = (bool: boolean) => {
    const container = document.getElementById("pageContainer");
    if (bool) {
      container && (container.style.overflowY = "hidden");
    } else {
      container && (container.style.overflowY = "auto");
    }
  };
  const handleClickOutside = () => {
    if (text.trim() && text.trim() !== initValue?.trim()) {
      setIsShowPopup(true);
    } else {
      reset();
    }
  };

  const onClose = () => {
    setIsOnFocus(false);
    setIsShowPopup(false);

    reset();
  };
  useEffect(() => {
    setIsOnFocus(!!isFocus);
  }, [isFocus]);

  useEffect(() => {
    preventScroll(isOnFocus);
  }, [isOnFocus]);

  useEffect(() => {
    const errorData = error as { error: number; isSuccess: boolean};
    if (isError && (errorData?.error || errorData?.error === 0)) {
      dispatch(setShowSpinner(false));
      setShowErrorPopup(errorData.error);
    } else if (isError && error &&
      "status" in error &&
      error.status === 406) {
      dispatch(setShowSpinner(false));
      dispatch(setShowWarningModal(true));
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      let newComment = {
        id: data,
        text: text.trim(),
        postId: postId,
        creationDateTime: new Date(),
        owner: {
          id: userId,
          profiles: "",
          username: username,
          avatarUrl: "",
          avatarPreviewUrl: photo.replace(url, ""),
        },
        replyCount: 0,
      } as CommentType;
      if (replyCommentId && replyUserId && initValue) {
        newComment.replyCommentId = replyCommentId;
        newComment.replyUserId = replyUserId;
        newComment.replyUserName = initValue.replace("@", "");
      }
      dispatch(setShowSpinner(false));
      addNewComment(newComment);
      reset();
    }
  }, [isSuccess]);

  const handleAddComment = () => {
    const body = {
      text: text.trim(),
    } as RquestBodyType;

    if (replyUserId && replyCommentId && initValue) {
      body.replyUserId = replyUserId;
      body.replyCommentId = replyCommentId;
    }
    addComment({ profileId: id, postId, body });
    dispatch(setShowSpinner(true));
  };

  const onSubmit = () => {
    if (text.trim()) {
      tokenRequest("profile", instance, accounts, handleAddComment, () =>
      setIsShowNotAuthenticatedModalWindow(true)
    );
    }
  };
  const onFieldClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isAuthenticated) {
      e.stopPropagation();
      setIsShowNotAuthenticatedModalWindow(true);
    }
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const isTouchScreen =window.matchMedia("(pointer: coarse)").matches
    if (e.key === "Enter" && !e.shiftKey && !isTouchScreen) {
      e.preventDefault();
      if (onSubmit) {
        const textarea = e.target as HTMLElement
        onSubmit();
        textarea && textarea.blur();
      }
    } 
  };

  return (
    <div className={isOnFocus ? styles.addCommentWrapperFocus : ""} onKeyDown={handleKeyDown}
    tabIndex={0}>
      {isOnFocus && <PopupBackground onClose={handleClickOutside} />}

      <div
        className={
          isOnFocus
            ? styles.addCommentFieldContainerFocus
            : styles.addCommentFieldContainer
        }
        ref={containerRef}
        onClick={onFieldClick}
      >
        <div className={styles.inputContainer}>
          <SimpleTextarea
            onChange={(value) => setText(value)}
            value={text}
            isInitHeight={!isOnFocus}
            placeholder={t("posts.addComment")}
            id={`${replyCommentId ? replyCommentId : postId}-field`}
            onFocus={() => isAuthenticated && setIsOnFocus(true)}
            isAutoFocus={isFocus}
            maxLength={2048}
            disabled={!isAuthenticated}
          />
          {isOnFocus && (
            <button
              className={styles.button}
              onClick={onSubmit}
              disabled={isLoading || !text.trim().length}
            >
              <img src={arrowIcon} alt="" />
            </button>
          )}
        </div>
      </div>

      {isShowPopup && (
        <ModalWindow
          onClose={() => setIsShowPopup(false)}
          isShow={isShowPopup}
          backdropModal={true}
          headerText={t("posts.publishComment")}
          children={
            <div>
              <p className={styles.popupDescription}>
                {t("posts.publishCommentDescription")}
              </p>
              <button
                className={styles.popupButton}
                onClick={() => setIsShowPopup(false)}
              >
                {t("common.cancel")}
              </button>
              <button className={styles.popupCloseButton} onClick={onClose}>
                {t("common.close")}
              </button>
            </div>
          }
        ></ModalWindow>
      )}
      {showErrorPopup !== null && (
        <ModalWindow
          onClose={() => {
            setShowErrorPopup(null);
            reset();
          }}
          isShow={showErrorPopup !== null}
          backdropModal={true}
          headerText={t(`posts.errorPopup.${showErrorPopup}.popupHeader`)}
          children={
            <div>
              <p className={styles.popupDescription}>
                {t(`posts.errorPopup.${showErrorPopup}.popupDescription`)}
              </p>
              <button
                className={styles.popupButton}
                onClick={() => {
                  setShowErrorPopup(null);
                  reset();
                }}
              >
                {t("common.close")}
              </button>
            </div>
          }
        ></ModalWindow>
      )}
      {isShowNotAuthenticatedModalWindow && (
        <UnAuthenticatedPopup
          onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
        ></UnAuthenticatedPopup>
      )}
    </div>
  );
};
