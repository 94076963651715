import styles from "./CommonTab.module.scss";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { InfoCard } from "../../../shared/components/infoCard/InfoCard";
import {
  useDeleteEducationsMutation,
  useDeleteHobbiesMutation,
  useDeleteWorksMutation,
  useGetEducationsQuery,
  useGetHobbiesQuery,
  useGetWorksQuery,
  useUpdateEducationsMutation,
  useUpdateHobbiesMutation,
  useUpdateWorksMutation,
} from "../../../api/ProfileService";
import { FieldValues, useForm } from "react-hook-form";
import { Input } from "../../../shared/components/input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { Textarea } from "../../../shared/components/textarea/Textarea";
import { useEffect, useState } from "react";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import useUpdateErrors from "../../../utils/useUpdateErrors";
import { DeletePopup } from "../../../shared/components/deletePopup/DeletePopup";

type PropsType = {
  id: string;
  type: string;
};

type InfoType = {
  id: string;
  name: string;
  description: string;
};

export const CommonTab = ({ id, type }: PropsType) => {
  const { skip } = useFetchWithMsal("profile",  true);

  const { data: hobbies } = useGetHobbiesQuery(id, {
    skip: type !== "hobbies",
  });
  const { data: works } = useGetWorksQuery(id, {
    skip: type !== "job" || skip,
  });
  const { data: education } = useGetEducationsQuery(id, {
    skip: type !== "education",
  });
  const [
    updateEducations,
    { data: newEducations, isSuccess: isEducationSuccess },
  ] = useUpdateEducationsMutation();
  const [deleteEducations] = useDeleteEducationsMutation();
  const [updateWorks, { data: newWorks, isSuccess: isWorksSuccess }] =
    useUpdateWorksMutation();
  const [deleteWorks] = useDeleteWorksMutation();
  const [updateHobbies, { data: newHobbies, isSuccess: isHobbiesSuccess }] =
    useUpdateHobbiesMutation();
  const [deleteHobbies] = useDeleteHobbiesMutation();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [savedData, setSavedData] = useState<InfoType[]>([]);
  const [deletedID, setDeletedID] = useState<string>("");
  const [isShowDeletePopup, setIsShowDeletePopup] = useState(false);
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: isChanged
      ? yup.string()
      : yup.string().required(t("editProfile.validateLink")),
  }) as yup.ObjectSchema<FieldValues>;

  const resolver = yupResolver(schema);

  useEffect(() => {
    if (hobbies || works || education) {
      switch (type) {
        case "hobbies":
          setSavedData(hobbies);
          break;
        case "job":
          setSavedData(works);
          break;
        case "education":
          setSavedData(education);
          break;
        default:
          break;
      }
    }
  }, [hobbies, works, education]);

  useEffect(() => {
    if (isEducationSuccess || isWorksSuccess || isHobbiesSuccess) {
      switch (type) {
        case "hobbies":
          setSavedData(newHobbies);
          break;
        case "job":
          setSavedData(newWorks);
          break;
        case "education":
          setSavedData(newEducations);
          break;
        default:
          break;
      }
    }
  }, [isEducationSuccess, isWorksSuccess, isHobbiesSuccess]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, dirtyFields, isDirty, isValid },
    watch,
  } = useForm<FieldValues>({
    resolver,
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const onSubmit = (data: FieldValues) => {
    switch (type) {
      case "hobbies":
        if (data.name) {
          updateHobbies({ profileId: id, body: data });
        }
        break;
      case "job":
        if (data.name) {
          updateWorks({ profileId: id, body: data });
        }
        break;
      case "education":
        if (data.name) {
          updateEducations({ profileId: id, body: data });
        }
        break;
      default:
        break;
    }
    reset({
      name: "",
      description: "",
    });
    setIsChanged(false);
  };
  const onDelete = (value: string) => {
    if(value === "delete") {
      switch (type) {
        case "hobbies":
          deleteHobbies({ profileId: id, id: deletedID });
          break;
        case "job":
          deleteWorks({ profileId: id, id: deletedID });
  
          break;
        case "education":
          deleteEducations({ profileId: id, id: deletedID });
          break;
        default:
          break;
      }
    }
    setIsShowDeletePopup(false);
  };

  const onDeleteButtonClick = (id: string) => {
    setDeletedID(id);
    setIsShowDeletePopup(true);
  };
  const getPlaceholder = () => {
      switch (type) {
        case "hobbies":
        return t("editProfile.hobbies")
        case "job":
          return t("editProfile.nameOfCompany")
        case "education":
          return t("editProfile.nameOfInstitution")
        default:
          break;
      }
  };
  const watchFields = watch();
  useUpdateErrors(handleSubmit(onSubmit), !!Object.keys(dirtyFields).length);
  return (
    <div className={styles.commonTabContainer}>
      <h3>{t(`profile.${type}`)}</h3>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          setValue={setValue}
          maxLength={128}
          required
          name="name"
          type="text"
          autocomplete="off"
          isBold
          placeholder={getPlaceholder()}
          error={errors.header?.message as string}
        />
        <Textarea
          register={register}
          setValue={setValue}
          maxLength={256}
          name="description"
          isInitHeight={!watchFields.description}
          placeholder={`${t("editProfile.moreHetails")}`}
          error={errors.description?.message as string}
        />
        <button
          className={styles.formButton}
          type="submit"
          disabled={!isDirty || !isValid}
        >
          {t("common.add")}
        </button>
      </form>

      {!!savedData?.length && (
        <div className={styles.savedInfoContainer}>
          {savedData.map((item: InfoType, index: number) => {
            return (
              <InfoCard
                id={item.id}
                onClose={onDeleteButtonClick}
                header={item.name}
                description={item.description}
                key={index}
                isEdit
              />
            );
          })}
        </div>
      )}
      {isShowDeletePopup && (
        <DeletePopup onClick={onDelete} text={t("common.deleteNote")} />
      )}
    </div>
  );
};
