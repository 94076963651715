// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ruTranslations from './locales/ru.json';
import enTranslations from './locales/en.json';
import deTranslations from './locales/de.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ru: {
        translation:ruTranslations
      },
      en: {
        translation: enTranslations
      },
      de: {
        translation: deTranslations
      },
    },
    lng: 'en', // язык по умолчанию
    fallbackLng: 'en', // язык, который будет использоваться, если не найдены переводы для текущего
    interpolation: {
      escapeValue: false, // не обязательно: обрабатывать html-теги в переводах
    },
  });

export default i18n;
