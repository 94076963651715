import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { userApi } from "./UserService";
import { profileApi } from "./ProfileService";
const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token-media");
  return token;
};

const baseUrl = process.env.REACT_APP_MEDIA_DNS_NAME as string;

export const mediaApi = createApi({
  reducerPath: "mediaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: (build) => ({
    deleteMediaFile: build.mutation({
      query: (mediaId) => ({
        url: `/api/media/${mediaId}`,
        method: "DELETE",
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          dispatch(
              userApi.util.updateQueryData("getAuthorizedUserInfo", '', (draft) => {
                draft.avatarUrl = "";
                draft.avatarPreviewUrl = "";
              })
          );
        } catch (e) {
          console.error(e);
        }
      }
    }),
    getProfilesPhoto: build.query({
      query: (profileId) => ({
        url: `/api/profile/${profileId}/info/Photos`,
        method: "GET",
      }),
    }),
    postUserMainPhoto: build.mutation({
      query: (body) => ({
        url: `/api/media/user/upload/avatar`,
        method: "POST",
        body
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            userApi.util.updateQueryData("getAuthorizedUserInfo", '', (draft) => {
              draft.avatarUrl = data.avatarUrl
              draft.avatarPreviewUrl = data.avatarPreviewUrl
            })
          );
        } catch (e) {
          console.error(e);
        }
      }
    }),
    postMainPhoto: build.mutation({
      query: ({body}) => ({
        url: `/api/media/profile/upload/avatar`,
        method: "POST",
        body
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            profileApi.util.updateQueryData("getProfile", {id:data.profileId, lang:value.lang}, (draft) => {
              
              draft.avatarUrl = data.avatarUrl
              draft.avatarPreviewUrl = data.avatarPreviewUrl
            })
          );
        } catch (e) {
          console.error(e);
        }
      }
    }),
    getGalleryPhoto: build.query({
      query: (profileId) => ({
        url: `/api/media/profile/${profileId}/gallery`,
        method: "GET",
      }),
    }),
    deleteGalleryPhoto: build.mutation({
      query: (data) => ({
        url: `/api/media/${data.profileId}/gallery/${data.mediaName}`,
        method: "DELETE",
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          const { profileId, mediaName } = value;

          dispatch(
            mediaApi.util.updateQueryData("getGalleryPhoto", profileId, (draft) => {
              return draft.filter((item:any) => item.name !== mediaName);
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getBurialPlacePhoto: build.query({
      query: (profileId) => ({
        url: `/api/media/profile/${profileId}/burial_places`,
        method: "GET",
      }),
    }),
    deleteBurialPlacePhoto: build.mutation({
      query: (data) => ({
        url: `/api/media/${data.profileId}/burial-place/${data.mediaName}`,
        method: "DELETE",
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          const { profileId, mediaName } = value;

          dispatch(
            mediaApi.util.updateQueryData("getBurialPlacePhoto", profileId, (draft) => {
              return draft.filter((item:any) => item.name !== mediaName);

            })
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    postBurialPlacePhoto: build.mutation({
      query: (body) => ({
        url: `/api/media/profile/upload/burial_places`,
        method: "POST",
        body
      }),
    }),
    postGalleryPhoto: build.mutation({
      query: (body) => ({
        url: `/api/media/profile/upload/gallery`,
        method: "POST",
        body
      }),
    }),
    getImage: build.query({
      queryFn: async (id) => {
        try {
          const response = await fetch(
            `${baseUrl}/api/media/${id}/preview`,
            {
              cache: "no-store",
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
            }
          );
          const dataURL = await response.blob();
          return { data: await URL.createObjectURL(dataURL) };
        } catch (e: any) {
          return { error: e.message };
        }
      },
    }),
    getPostsMedia: build.query({
      query: ({profileId, postId}) => ({
        url: `/api/media/profile/${profileId}/post/${postId}`,
        method: "GET",
      }),
    }),
    uploadPostMedia: build.mutation({
      query: ({profileId, postId, body}) => ({
        url: `/api/media/profile/${profileId}/post/${postId}`,
        method: "POST",
        body
      }),
    }),
    editPhoto: build.mutation({
      query: ({url,body}) => ({
        url: `/api/media/image${url}`,
        method: "PUT",
        body
      }),
    }),
  }),
});
export const {
  useDeleteMediaFileMutation,
  useGetImageQuery,
  useGetProfilesPhotoQuery,
  usePostMainPhotoMutation,
  usePostGalleryPhotoMutation,
  usePostBurialPlacePhotoMutation,
  useDeleteBurialPlacePhotoMutation,
  useGetBurialPlacePhotoQuery,
  useGetGalleryPhotoQuery,
  useDeleteGalleryPhotoMutation,
  usePostUserMainPhotoMutation,
  useGetPostsMediaQuery,
  useUploadPostMediaMutation,
  useEditPhotoMutation
} = mediaApi;
