import styles from "./Input.module.scss";
import { ChangeEvent } from 'react';

type InputProps = {
  setValue: (value: string, name: string) => void;
  value: string;
  label?: string;
  placeholder?: string;
  error?: string;
  disabled?:boolean;
  maxlength?: number;
  inputStyle?: string;
  name: string;
};

export const InputInitial = ({
  label,
  placeholder,
  error,
  setValue,
  value,
  disabled,
  maxlength,
  inputStyle,
  name,
}: InputProps) => {

  const OnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (name === 'ageFrom' || name === 'ageTo') {
      if ( /^\d*$/.test(inputValue) && inputValue.length <= 3) {
        setValue(event.target?.value, name)
      }
    } else {
      setValue(event.target?.value, name)
    }
  }

  return (
    <div className={`${inputStyle}`}>
      {label && <label className={styles.labelInitial}>{label}</label>}
      <div>
        <input
          className={`${styles.input} ${error ? styles.error : ''}`}
          type='text'
          name={name}
          placeholder={placeholder ? placeholder : ''}
          autoComplete={"none"}
          value={value}
          disabled={disabled}
          maxLength={maxlength}
          onChange={OnChangeInput}
        />
      </div>

      {error && <p className={styles.errorMessage}> {error}</p>}
    </div>
  );
};
