export const getLocaleByLanguage = (language: string) => {

  switch (language.toLowerCase()) {
    case "ru":
    case "ru-ru":
      return "ru-RU";
    case "en":
    case "en-us":
      return "en-US";
    case "de":
    case "de-de":
      return "de-DE";
    default:
      return "en-US";
  }
};

export const getLanguageByLocale = (locale: string) => {
  switch (locale) {
    case "ru-RU":
      return "ru";
    case "en-US":
      return "en";
    case "de-DE":
      return "de";
    default:
      return "en";
  }
};
