import { useRef } from "react";
import { PopupBackground } from "../popupBackground/PopupBackground";
import styles from "./Popup.module.scss";
import { ListItem } from "../listItem/ListItem";
import { useTranslation } from "react-i18next";
import { PopupData } from "../../../types/CommonTypes";

type PopupProps = {
  data: PopupData[];
  onClose: () => void;
  onClick: (type: string) => void;
  type?: string;
  value?: string;
};

export const Popup = ({ data, onClose, onClick, type, value }: PopupProps) => {
  const popupRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation();

  const getClassName = () => {
    switch (type) {
      case "language":
        return styles.languagePopup;
      case "userProfileAvatar":
        return styles.userProfileAvatar;
      case "userAvatar":
        return styles.userAvatar;
      case "complain":
        return styles.complain;
      case "avatar":
        return styles.avatarPopup;
      case "search":
        return styles.searchPopup;
      case "profileMenu":
        return styles.profileMenu;
      case "languageSwitch":
        return styles.languageSwitch;
      default:
        return styles.popupContainer;
    }
  };

  return (
    <>
      <PopupBackground onClose={onClose} />
      <div className={getClassName()}>
        <ul ref={popupRef} className={`${styles.popupList}`}>
          {data?.map((item, index) => {
            return (
              <ListItem
                item={item}
                key={index}
                type={"popup"}
                onClick={() => onClick(item.type)}
                isSelected={item.type === value}
              />
            );
          })}
        </ul>
        <button className={styles.closeButton} onClick={() => onClose()}>
          {t("common.close")}
        </button>
      </div>
    </>
  );
};
