import { useState } from "react";
import styles from "./PasswordInput.module.scss";
import { UseFormRegister, FieldValues } from "react-hook-form";
import passwordOpen from '../../image/passwordOpen.png'
import passwordClose from '../../image/passwordClose.png'


type PasswordInputProps = {
  register: UseFormRegister<FieldValues>;
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  classType?: string;
  pattern?: RegExp;
  error?: string;
};

export const PasswordInput = ({
  name,
  type,
  label,
  placeholder,
  classType,
  error,
  register,
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={styles.inputContainer}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={styles.inputGroup}>
      <input
        className={`${styles.input} ${classType ? styles.groupItem : ""} ${
          error ? styles.error : error
        }`}
        type={showPassword ? 'text' : 'password'}
        id={name}
        placeholder={placeholder}
        {...register(name)}
        autoComplete='off'
      />
        <span onClick={togglePasswordVisibility}>
    {    <img src={showPassword? passwordOpen: passwordClose} alt=''/>}
      </span>
      </div>
     
      {error && <p className={styles.errorMessage}> {error}</p>}
    </div>
  );
};
