import { ImageItem } from "../types/CommonTypes";

export function transformImage(image: ImageItem) {
  const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = image?.original ? URL.createObjectURL(image.original) : image?.url as string
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;
          const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            if (blob) {
              const fileName =
                image?.original?.name ||
                image?.url?.substring(image?.url.lastIndexOf("/") + 1) ||
                "";
              const newFile = new File([blob], fileName, {
                type: image?.original?.type || image?.type || "image/jpeg",
                lastModified: Date.now(),
              });
              (image as ImageItem).original = newFile;
            }
          }, image?.original?.type || image?.type);
        };
}