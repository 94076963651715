import styles from "./PlatesHistoryTab.module.scss";
import { OrderHistoryPlateType } from "../../../../types/OrderHistoryType";
import { PlateslHistoryItem } from "./platesHistoryItem/PlatesHistoryItem";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { splitIntoParagraphs } from "../../../../utils/splitIntoParagraph";

type PlatesHistoryTabProps = {
  data: OrderHistoryPlateType[];
};

export const PlatesHistoryTab = ({ data }: PlatesHistoryTabProps) => {
  const { t } = useTranslation();
  const { isOrderHistoryLoading } = useSelector(
    (state: RootState) => state.orderHistorySlice
  );

  return (
    <div
      className={
        !data.length ? styles.platesHistoryTabEmpty : styles.platesHistoryTab
      }
    >
      {data?.map((item: OrderHistoryPlateType, index: number) => {
        return (
          <div key={index} className={styles.itemContainer}>
            <PlateslHistoryItem data={item} />
          </div>
        );
      })}
      {!data.length && !isOrderHistoryLoading && (
        <div>
          <div className={styles.text}>
            {splitIntoParagraphs(t("userProfile.orderHistory.emptyPlates")).map(
              (paragraph, index) => (
                <p key={index}>
                  {paragraph}
                  <br />
                </p>
              )
            )}
          </div>
          <Link className={styles.button} to={`/qrCodes`}>
            {t("userProfile.orderHistory.orderPlate")}
          </Link>
        </div>
      )}
    </div>
  );
};
