import { UIEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetReligionsBySearchQuery,
  useSearchCitiesQuery,
  useSearchCountriesQuery,
} from "../../../api/UtilityService";
import { Loader } from "../../../shared/components/loader/Loader";
import { RootState } from "../../../store";
import {
  SelectedCountryType,
  updateFilterCityData,
  updateFilterReligionData,
  updateFilterSelectedCountryData,
  updateIsLoadingNewCities,
  updateOffsetCityData,
} from "../../../store/slices/FiltersFormSlice";
import { SearchInput } from "../../../shared/components/search/SearchInput";
import stylesFilter from "../Filter.module.scss";
import smallArrow from "../../../shared/image/smallArrow.svg";
import {
  AlphabeticalLocationBlock,
  DataType,
  ListType,
} from "./AlphabeticalLocationBlock";
import { CityDataType, CountryType } from "../../../types/CommonTypes";
import { Religions } from "../../religionBlock/Religions";
import {
  LIMIT_SCROLL_CITY,
  MAX_SEARCH_LENGTH,
} from "../../../constants/constants";
import ModalWindowHeader from "../../../shared/components/modalWindowHeader/ModalWindowHeader";
import { getLanguageByLocale } from "../../../utils/localeUtils";
import { FilterButton } from "../FilterButton/FilterButton";

type CountryFilterProps = {
  onClick: () => void;
  onClose: () => void;
  type?: string;
  selectedCountry?: {
    id: string;
    isoCode: string;
    name: string;
    cities: ListType[];
  };
};

type ResultType = CountryType | CityDataType;

type DataByLetter<T> = {
  [letter: string]: T[];
};

type NewDataType = {
  id: string;
  name: string;
  isoCode: string;
};

export const FilterByLocationAndReligion = ({
  onClick,
  onClose,
  type,
  selectedCountry,
}: CountryFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCountryType = type === "country";
  const isCityType = type === "city";
  const isReligionType = type === "religion";
  const { language } = useSelector((state: RootState) => state.language);
  const {
    scrollCityData,
    offset,
    isLoadingNewCities,
    selectedCountryData,
    selectedReligionData,
  } = useSelector((state: RootState) => state.filtersFormSlice);
  const [stateIsChange, setStateIsChange] = useState<boolean>(false);
  const [selectedReligionIds, setSelectedReligionIds] = useState<string[]>(
    selectedReligionData.length
      ? selectedReligionData.map((religion) => religion.id)
      : []
  );
  const [selectedReligionState, setSelectedReligionState] = useState<
    ListType[]
  >(selectedReligionData.length ? selectedReligionData : []);
  const [selectedCountryState, setSelectedCountryState] = useState<
    SelectedCountryType[]
  >(selectedCountryData ? selectedCountryData : []);
  const [selectedIds, setSelectedIds] = useState<string[]>(() => {
    let initialSelected;
    if (isCountryType) {
      initialSelected = selectedCountryData
        ? selectedCountryData.map((country) => country.id)
        : [];
    } else {
      initialSelected = selectedCountryData.map((country) => country.cities)
        ? selectedCountryData.flatMap((country) =>
            country.cities.map((city) => city.id)
          )
        : [];
    }
    return initialSelected;
  });
  const [data, setData] = useState<DataType[]>([]);
  const [searchParam, setSearchParam] = useState("");
  const [isSearch, setIsSearch] = useState(true);
  const [religionsData, setReligionsData] = useState([]);

  const {
    data: religionsDataFromRequest,
    isSuccess: isSuccessGetReligions,
    isLoading: isLoadingReligions,
  } = useGetReligionsBySearchQuery(
    {
      lang: getLanguageByLocale(language),
      search: searchParam ? searchParam : "",
    },
    { skip: !(isReligionType && isSearch) }
  );

  const {
    data: countriesData,
    isSuccess: isSuccessGetCountries,
    isLoading: isLoadingCountries,
  } = useSearchCountriesQuery(
    {
      lang: getLanguageByLocale(language),
      search: searchParam ? searchParam : "",
    },
    { skip: !(isCountryType && isSearch) }
  );

  const {
    data: citiesData,
    isSuccess: isSuccessGetCities,
    isLoading: isLoadingCities,
  } = useSearchCitiesQuery(
    {
      countryId:
        selectedCountry && selectedCountry.isoCode
          ? selectedCountry?.isoCode
          : "",
      lang: getLanguageByLocale(language),
      search: searchParam ? searchParam : "",
      start: offset,
    },
    { skip: !(isCityType && isSearch) }
  );

  const isLoading = isLoadingReligions || isLoadingCities || isLoadingCountries;

  useEffect(() => {
    if (isCityType && isLoadingNewCities) {
      setData(updateDataByLetter(scrollCityData));
      dispatch(updateIsLoadingNewCities(false));
    }
  }, [scrollCityData.length]);

  useEffect(() => {
    if (isSuccessGetReligions && isSearch) {
      setReligionsData(religionsDataFromRequest);
      setIsSearch(false);
    }
  }, [religionsDataFromRequest]);

  useEffect(() => {
    if (isSuccessGetCities && isSearch) {
      if (isLoadingNewCities) {
        dispatch(
          updateFilterCityData([...scrollCityData, ...citiesData.results])
        );
      } else if (
        !isLoadingNewCities &&
        !!scrollCityData.length &&
        !searchParam
      ) {
        const updateDate = updateDataByLetter(scrollCityData);
        setData(updateDate);
        setIsSearch(false);
      } else {
        dispatch(updateFilterCityData(citiesData.results));
        const updateDate = updateDataByLetter(citiesData.results);
        setData(updateDate);
        setIsSearch(false);
      }
    }
  }, [citiesData, offset]);

  useEffect(() => {
    if (isSuccessGetCountries && isSearch) {
      const updateDate = updateDataByLetter(countriesData);
      setData(updateDate);
      setIsSearch(false);
    }
  }, [countriesData]);

  const updateDataByLetter = <T extends ResultType>(results: T[]) => {
    if (!results.length) {
      return [];
    }
    const dataByLetter: DataByLetter<NewDataType> = {};
    let currentLetter = "";

    const updateData = results?.map((item: T) => ({
      name: isCountryType ? item.country : (item as CityDataType).name || "",
      id: item.id,
      isoCode: isCountryType
        ? (item as CountryType).isoCode
        : (item as CityDataType).countryIso,
    })) as NewDataType[];

    updateData.forEach((country) => {
      const firstLetter = country.name[0]?.toUpperCase();
      if (firstLetter && firstLetter !== currentLetter) {
        currentLetter = firstLetter;
        if (!dataByLetter[currentLetter]) {
          dataByLetter[currentLetter] = [];
        }
      }
      if (firstLetter) {
        dataByLetter[currentLetter].push(country);
      }
    });

    return Object.keys(dataByLetter).map((letter) => ({
      letter,
      data: dataByLetter[letter],
    }));
  };

  const onDispatchValues = () => {
    if (isReligionType) {
      dispatch(updateFilterReligionData(selectedReligionState));
    } else if (isCountryType) {
      dispatch(updateFilterSelectedCountryData(selectedCountryState));
    } else if (isCityType && selectedCountry?.id) {
      dispatch(updateFilterSelectedCountryData(selectedCountryState));
      dispatch(updateFilterCityData([]));
      dispatch(updateOffsetCityData(0));
    }
    setStateIsChange(false);
    onClick();
    onClose();
  };

  const updateFilterSelectedCountryDataState = (action: any) => {
    const { selectedCountry, cities, type } = action;

    let updatedState = [...selectedCountryState];

    const existingCountryIndex = updatedState.findIndex(
      (country: any) => country.id === selectedCountry.id
    );
    const citiesArray = Array.isArray(cities) ? cities : [];

    if (existingCountryIndex !== -1) {
      if (type === "country") {
        updatedState = updatedState.filter(
          (country) => country.id !== selectedCountry.id
        );
      } else if (type === "reset") {
        const updatedCountry = { ...updatedState[existingCountryIndex] };
        updatedCountry.cities = [];
        updatedState[existingCountryIndex] = updatedCountry;
      } else {
        const updatedCountry = { ...updatedState[existingCountryIndex] };
        const existingCityIndex = updatedCountry.cities.findIndex((city: any) =>
          cities.some((c: ListType) => c.id === city.id)
        );
        if (existingCityIndex !== -1) {
          updatedCountry.cities = updatedCountry.cities.filter(
            (city, index) => index !== existingCityIndex
          );
        } else {
          updatedCountry.cities = [...updatedCountry.cities, ...citiesArray];
        }
        updatedState[existingCountryIndex] = updatedCountry;
      }
    } else {
      updatedState.push({
        ...selectedCountry,
        cities: citiesArray,
      });
    }

    setSelectedCountryState(updatedState);
  };

  const onItemCountryAndCityClick = (item: ListType) => {
    setStateIsChange(true);
    if (selectedIds.includes(item.id)) {
      if (type === "country") {
        const updatedItems = selectedCountryState.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
        setSelectedIds(updatedItems.map((value) => value.id));
        updateFilterSelectedCountryDataState({
          selectedCountry: item,
          cities: [],
          type: type,
        });
      }
      if (type === "city" && selectedCountry?.id) {
        const updatedItems = selectedCountryState.flatMap((country) =>
          country.cities.filter((city) => city.id !== item.id)
        );
        setSelectedIds(updatedItems.map((value) => value.id));
        updateFilterSelectedCountryDataState({
          selectedCountry,
          cities: [item],
          type: type,
        });
      }
    } else {
      setSelectedIds([...(selectedIds as string[]), item.id]);
      if (type === "country") {
        updateFilterSelectedCountryDataState({
          selectedCountry: item,
          cities: [],
          type: type,
        });
      }
      if (type === "city") {
        if (selectedCountry) {
          updateFilterSelectedCountryDataState({
            selectedCountry,
            cities: [item],
            type: type,
          });
        }
      }
    }
  };

  const onItemReligionClick = (selectedItem: ListType) => {
    setStateIsChange(true);
    if (selectedReligionIds.includes(selectedItem.id)) {
      const updatedItems = selectedReligionState.filter(
        (religion) => religion.id !== selectedItem.id
      );
      setSelectedReligionIds(updatedItems.map((religion) => religion.id));
      setSelectedReligionState(updatedItems);
    } else {
      setSelectedReligionState([...selectedReligionState, selectedItem]);
      setSelectedReligionIds([...selectedReligionIds, selectedItem.id]);
    }
  };

  const onSearchParamChange = (value: string) => {
    setIsSearch(false);
    const updateValue = value.slice(0, MAX_SEARCH_LENGTH).replace(/''+/g, "'");
    if (/^[a-zA-Zа-яА-ЯßäöüÄÖÜßß0-9&'(),/. \-]*$/.test(updateValue)) {
      setSearchParam(updateValue);
      if (updateValue.length >= 3) {
        getDataBySearch();
      }
    }
    if (!value) {
      setIsSearch(true);
      dispatch(updateFilterCityData([]));
      if (isReligionType) {
        setReligionsData(religionsDataFromRequest);
      }
    }
  };

  const getDataBySearch = () => {
    if (offset > 0) {
      dispatch(updateOffsetCityData(0));
    }
    setIsSearch(true);
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (isCityType) {
      const divComponent = e.target as HTMLDivElement;
      if (
        divComponent.offsetHeight + divComponent.scrollTop >=
        divComponent.scrollHeight - 100
      ) {
        if (!isLoadingNewCities && citiesData?.hasNext) {
          dispatch(updateIsLoadingNewCities(true));
          setIsSearch(true);
          dispatch(updateOffsetCityData(offset + LIMIT_SCROLL_CITY));
        }
      }
    }
  };

  const onReset = () => {
    setStateIsChange(true);
    setIsSearch(true);
    setSearchParam("");
    if (isReligionType) {
      setSelectedReligionState([]);
      setSelectedReligionIds([]);
    } else if (isCountryType) {
      setSelectedCountryState([]);
      setSelectedIds([]);
    } else if (isCityType && !!selectedCountry?.cities.length) {
      updateFilterSelectedCountryDataState({
        selectedCountry,
        cities: [...selectedCountry?.cities],
        type: "reset",
      });
      setSelectedIds([]);
    } else if (isCityType && !selectedCountry?.cities.length) {
      setSelectedIds([]);
    }
  };

  const onCloseAdditionBlock = () => {
    setIsSearch(true);
    dispatch(updateFilterCityData([]));
    dispatch(updateOffsetCityData(0));
    onClose();
    setSelectedCountryState([]);
    setSelectedIds([]);
  };

  const setBorder = () => {
    if (data.length && !searchParam) {
      return stylesFilter.border;
    } else if (data.length && searchParam) {
      return stylesFilter.borderNone;
    }
  };

  return (
    <div className={stylesFilter.formContainer}>
      {!isLoading ? (
        <>
          <div className={stylesFilter.mobileContainerFixed}>
            <ModalWindowHeader
              title={t(`filter.${type}`)}
              onClick={onCloseAdditionBlock}
              type={"filter"}
              onReset={onReset}
            />
            <div className={stylesFilter.breadcrumbs}>
              <div
                onClick={onCloseAdditionBlock}
                className={stylesFilter.inactiveLink}
              >
                {t("filter.title")}
              </div>
              <img src={smallArrow} alt={""} />
              <span>{t(`filter.${type}`)}</span>
            </div>
            <SearchInput
              onChange={onSearchParamChange}
              onClick={getDataBySearch}
              value={searchParam}
              placeholder={t("filter.search")}
              type="search"
              isDisabledButton={!searchParam}
            />
          </div>
          {data && (isCountryType || isCityType) ? (
            <AlphabeticalLocationBlock
              data={data}
              selected={selectedIds}
              onClick={onItemCountryAndCityClick}
              handleScroll={handleScroll}
              className={`${stylesFilter.list} ${setBorder()}`}
            />
          ) : religionsDataFromRequest && isReligionType ? (
            <Religions
              className={stylesFilter.list}
              data={religionsData}
              selected={selectedReligionIds}
              onClick={onItemReligionClick}
            />
          ) : (
            <Loader height={220} />
          )}
          <FilterButton
            isShow={type}
            styleContainer={stylesFilter.filterButtonForListing}
            onSubmit={onDispatchValues}
            onReset={onReset}
            stateIsChange={stateIsChange}
            disabledReset={
              isReligionType && !searchParam
                ? !selectedReligionIds.length
                : !searchParam
                ? !selectedIds.length
                : false
            }
          />
        </>
      ) : (
        <Loader height={220} />
      )}
    </div>
  );
};
