import styles from "./WizardImageBlock.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import Draggable, {
  ControlPosition,
  DraggableData,
  DraggableEvent,
} from "react-draggable";
import {
  ChangeEvent,
  RefObject,
  TouchEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ErrorImages, ImageItem } from "../../../../../../types/CommonTypes";
import { FileError } from "../../../../../../shared/components/fileError/FileError";
import { setPlateChangesImage } from "../../../../../../store/slices/OrderSlice";
import { useGetGalleryPhotoQuery } from "../../../../../../api/MediaService";
import { acceptImageFormats, allowedImageTypes } from "../../../../../../constants/Media.constant";

const maxImageSize = !!process.env.MAX_IMAGE_SIZE
  ? +process.env.MAX_IMAGE_SIZE
  : 15 * 1024 * 1024;

const stepSize = 0.01;
const minZoom = 0.1;
const maxZoom = 5;

type WizardImageBlockProps = {
  borderRadius: string;
  width: number;
  height: number;
  isEdit: boolean;
  setImageData: (items: ImageItem[], showSelectButton?: boolean) => void;
  blockForImage: RefObject<HTMLDivElement>;
};

type Point = {
  x: number;
  y: number;
};

export const WizardImageBlock = ({
  borderRadius,
  width,
  height,
  isEdit,
  setImageData,
  blockForImage,
}: WizardImageBlockProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { selectedProfiles, plate } = useSelector(
    (state: RootState) => state.orderSlice
  );

  const imageAvatarRef = useRef<HTMLDivElement>(null);
  const positionStart = useRef<ControlPosition>({ x: 0, y: 0 });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const draggableRef = useRef<Draggable | null>(null);

  const [currentZoom, setCurrentZoom] = useState<number>(1);
  const [showEditContainer, setShowEditContainer] = useState<boolean>(false);
  const [errorProps, setErrorProps] = useState<ErrorImages>({
    showVideoError: false,
    showImageError: false,
    showFormatError: false,
    filesName: [],
  });

  const { data: photos } = useGetGalleryPhotoQuery(
    selectedProfiles[0].profileId
  );

  useEffect(() => {
    if (!plate.changes.image) {
      setShowEditContainer(true);
    }
  }, [isEdit]);

  useEffect(() => {
    draggableRef.current?.setState({ x: 0, y: 0 });
    setCurrentZoom(1);
    setShowEditContainer(false);
  }, [plate.changes.image]);

  const imageContainerStyles = (): string => {
    let result = styles.container;
    if (isEdit) {
      result += ` ${styles.isEdit}`;
    }
    return result;
  };

  const editContainerStyles = (): string => {
    let result = styles.editContainer;
    if (isEdit && showEditContainer) {
      result += ` ${styles.show}`;
    }
    return result;
  };

  const zoomImage = (event: any) => {
    if (isEdit) {
      setShowEditContainer(false);
      const direction = event.deltaY > 0 ? -1 : 1;
      const newZoom = currentZoom + direction * stepSize;

      if (newZoom < minZoom || newZoom > maxZoom) {
        return;
      }

      setCurrentZoom(newZoom);
    }
  };

  const onStart = (e: DraggableEvent, data: DraggableData): void => {
    positionStart.current = {
      x: data.x,
      y: data.y,
    };
  };

  const onDrag = (): void => {
    setShowEditContainer(false);
  };

  const onStop = (e: DraggableEvent, data: DraggableData): void => {
    const { x, y } = positionStart.current;
    const notHaveChange = data.x === x && data.y === y;

    if (notHaveChange) {
      setShowEditContainer(!showEditContainer);
    } else {
      const imageElement = imageAvatarRef.current
        ?.children[0] as HTMLImageElement;
      if (!!imageElement) {
        const borderWidth = 3;
        const maxViewPixels = 100;
        const widthImage = imageElement.width * currentZoom;
        const heightImage = imageElement.height * currentZoom;
        const paddingX = (width - widthImage) / 2 - borderWidth;
        const paddingY = (height - heightImage) / 2 - borderWidth;
        const showWidth = (width - paddingX - maxViewPixels) / currentZoom;
        const showHeight = (height - paddingY - maxViewPixels) / currentZoom;
        let positionX = widthImage + Math.abs(data.x);
        let positionY = heightImage + Math.abs(data.y);
        if (positionX > showWidth + widthImage) {
          draggableRef.current?.setState({
            x: data.x > 0 ? showWidth : -showWidth,
          });
        }
        if (positionY > showHeight + heightImage) {
          draggableRef.current?.setState({
            y: data.y > 0 ? showHeight : -showHeight,
          });
        }
      }
    }
    e.stopPropagation();
  };

  const viewPhoto = (): void => {
    setImageData([
      {
        url: plate.changes.image,
        thumbnail: plate.changes.image,
        description: "image",
      } as ImageItem,
    ]);
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!!file) {
      const fileType = file.type;
      if (allowedImageTypes.includes(fileType)) {
        if (file.size < maxImageSize) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64String = e.target?.result as string;
            if (base64String) {
              dispatch(setPlateChangesImage(base64String));
              draggableRef.current?.setState({ x: 0, y: 0 });
              setCurrentZoom(1);
              setShowEditContainer(false);
            }
          };
          reader.readAsDataURL(file);
          fileInputRef.current!.value = "";
        } else {
          fileInputRef.current!.value = "";
          setErrorProps((prev) => ({
            showImageError: true,
            showVideoError: prev.showVideoError,
            showFormatError: prev.showFormatError,
            filesName: [...prev.filesName, file.name],
          }));
        }
      } else {
        fileInputRef.current!.value = "";
        setErrorProps((prev) => ({
          showImageError: prev.showImageError,
          showVideoError: prev.showVideoError,
          showFormatError: true,
          filesName: [...prev.filesName, file.name],
        }));
      }
    }
  };

  const downloadPhoto = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const selectPhoto = (): void => {
    setImageData(
      photos
        .filter((photo: any) => (photo.contentType as string).includes("image"))
        .map(
          (photo: any) =>
            ({
              url: photo.url,
              thumbnail: photo.previewUrl,
              description: "image",
            } as ImageItem)
        ),
      true
    );
  };

  const mouseUpContainer = (): void => {
    setShowEditContainer(!showEditContainer);
  };

  let lastCenter: Point | null;
  let lastDist = 0;

  const getCenter = (p1: Point, p2: Point) => {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  };

  const getDistance = (p1: Point, p2: Point) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  const touchMove = (e: TouchEvent<HTMLImageElement>) => {
    if (isEdit && e.touches.length === 2) {
      const touchFirst = e.touches[0];
      const touchSecond = e.touches[1];

      const pointFirst: Point = {
        x: touchFirst.clientX,
        y: touchFirst.clientY,
      };
      const pointSecond: Point = {
        x: touchSecond.clientX,
        y: touchSecond.clientY,
      };

      if (!lastCenter) {
        lastCenter = getCenter(pointFirst, pointSecond);
        return;
      }

      const newCenter = getCenter(pointFirst, pointSecond);

      const dist = getDistance(pointFirst, pointSecond);

      if (!lastDist) {
        lastDist = dist;
      }

      const newZoom = currentZoom * (dist / lastDist);

      if (newZoom < minZoom || newZoom > maxZoom) {
        return;
      }

      setCurrentZoom(newZoom);

      lastDist = dist;
      lastCenter = newCenter;

      e.stopPropagation();
    }
  };

  const touchEnd = () => {
    lastDist = 0;
    lastCenter = null;
  };

  return (
    <>
      <input
        accept={acceptImageFormats}
        id="icon-button-file"
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />
      <div
        className={imageContainerStyles()}
        ref={blockForImage}
        onMouseUp={mouseUpContainer}
        style={{ width: width, height: height, borderRadius }}
      >
        <div className={editContainerStyles()}>
          <button
            className={styles.button}
            disabled={!plate.changes.image}
            onClick={viewPhoto}
          >
            {t("common.view")}
          </button>
          <button className={styles.button} onClick={downloadPhoto}>
            {t("common.download")}
          </button>
          <button
            className={styles.button}
            disabled={
              !photos?.filter((photo: any) =>
                (photo.contentType as string).includes("image")
              ).length
            }
            onClick={selectPhoto}
          >
            {t("common.select")}
          </button>
        </div>
        <div
          className={styles.imageContainer}
          style={{ transform: `scale(${currentZoom})` }}
        >
          <Draggable
            scale={currentZoom}
            ref={draggableRef}
            nodeRef={imageAvatarRef}
            onStart={onStart}
            onStop={onStop}
            onDrag={onDrag}
            disabled={!isEdit}
          >
            <div ref={imageAvatarRef}>
              {plate.changes.image && (
                <img
                  className={styles.avatar}
                  onWheel={zoomImage}
                  onTouchEnd={touchEnd}
                  onTouchMove={touchMove}
                  src={plate.changes.image}
                  alt=""
                  draggable={false}
                />
              )}
            </div>
          </Draggable>
        </div>
      </div>

      {(errorProps.showVideoError ||
        errorProps.showImageError ||
        errorProps.showFormatError) && (
        <FileError
          onClose={() =>
            setErrorProps({
              filesName: [],
              showImageError: false,
              showVideoError: false,
              showFormatError: false,
            })
          }
          showVideoError={errorProps.showVideoError}
          showImageError={errorProps.showImageError}
          showFormatError={errorProps.showFormatError}
          errorFileNames={errorProps.filesName}
        />
      )}
    </>
  );
};
