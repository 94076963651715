import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import styles from "./CreateProfile.module.scss";
import smallArrow from "../../shared/image/arrowIcon.svg";
import { NameForm } from "./createProfileSteps/NameForm";
import { EngNameForm } from "./createProfileSteps/EngNameForm";
import { DateForm } from "./createProfileSteps/DateForm";
import { ReligionForm } from "./createProfileSteps/ReligionForm";
import { MediaForm } from "./createProfileSteps/MediaForm";
import ModalWindowHeader from "../../shared/components/modalWindowHeader/ModalWindowHeader";
import { useDispatch, useSelector } from "react-redux";
import { deleteState, setActiveStep } from "../../store/slices/CreateProfileSlice";
import { ProfileCreatedPage } from "./createProfileSteps/profileCreatedPage/ProfileCreatedPage";
import { MapForm } from "./createProfileSteps/MapForm";
import { ProfileCreatingPage } from "./createProfileSteps/profileCreatingPage/ProfileCreatingPage";
import { ImageItem } from "../../types/CommonTypes";
import { WarningModalWindow } from './createProfileSteps/WarningModalWindow';
import { RootState } from "../../store";


export const CreateProfile = () => {
  const [step, setStep] = useState<number>(0);

  const [profileId, setProfileId] = useState<string>("");
  const [burialPlacePhotos, setBurialPlacePhotos] = useState<ImageItem[]>([]);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isShowWarning, setIsShowWarning] = useState<boolean>(false);
  const [indexStep, setIndexStep] = useState<number | null>(null);
  const [isChangeStep, setIsChangeStep] = useState<boolean | undefined>(false);
  const { activeStep } = useSelector((state: RootState) => state.createProfile);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const items = t("createProfile.steps", { returnObjects: true }) as string[];
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(deleteState());
    };
  }, []);

  const onClick = (value?: {
    burialPlacePhotos?: ImageItem[];
    profileId?: string;
  }) => {
    if (activeStep >= step) {
      setStep(step + 1);
    }
    if (value?.profileId) {
      setProfileId(value.profileId);
    }
    if (value?.burialPlacePhotos) {
      setBurialPlacePhotos(value.burialPlacePhotos);
    }
   dispatch(setActiveStep(activeStep + 1)) ;
  };
  const onClickBack = () => {
    if (activeStep === 0) {
      dispatch(deleteState());
      navigate(-1);
    }
    if ((activeStep === 5 || activeStep === 4) && isChangeStep) {
      setIsShowWarning(true);
      setIndexStep(activeStep - 1);
    } else {
      dispatch(setActiveStep(activeStep - 1));
    }
  };
  const onStepClick = (index: number) => {
    if (index <= step) {
      if ((activeStep === 5 || activeStep === 4) && isChangeStep) {
        setIsShowWarning(true);
        setIndexStep(index);
        setIsChangeStep(false);
      } else {
        dispatch(setActiveStep(index));
      }
    }
  };

  const backToPrevStep = () => {
    if (indexStep) {
      dispatch(setActiveStep(indexStep));
      setIsChangeStep(false);
      setIsShowWarning(false);
    }
  };

  const onCloseWarningModalWindow = () => {
    setIsShowWarning(false);
    setIsChangeStep(true);
  };

  return (
    <>
      {activeStep !== 6 && (
        <ModalWindowHeader
          title={t("common.breadcrumbs.createProfile")}
          onClick={onClickBack}
          width={"400px"}
          padding={"15px"}
        />
      )}
      {isShowWarning && (
        <WarningModalWindow
          onClose={onCloseWarningModalWindow}
          isShow={isShowWarning}
          backToPrevStep={backToPrevStep}
        />
      )}
      {isCreating &&  <ProfileCreatingPage />}
      {activeStep === 6 ? (
        <ProfileCreatedPage id={profileId} />
      ) : (
        <div className={styles.createProfileContainer}>
          <div className={styles.blockContainer}>
            <div
              className={styles.breadcrumbsContainer}
              onClick={() => navigate(-1)}
            >
              <img src={smallArrow} alt="" />
              <p>{t("common.back")}</p>
            </div>

            <div className={styles.createProfileMainBlock}>
              <h1 className={styles.header}>
                {t("createProfile.createProfile")}
              </h1>
              <div className={styles.stepper}>
                {items.map((item, index) => {
                  return (
                    <div key={index} className={styles.stepItemContainer}>
                      <div
                        className={`${styles.stepItem} ${
                          step >= index
                            ? step === index
                              ? styles.selectedItem
                              : styles.pastStep
                            : ""
                        }`}
                      >
                        <span
                          className={`${styles.stepButton} ${
                            step === index ? styles.selected : ""
                          }`}
                          onClick={() =>onStepClick(index)}
                        ></span>
                        <div
                          className={
                            activeStep === index
                              ? styles.stepItemContent
                              : styles.hiddenItem
                          }
                        >
                          <div className={styles.stepNumberContainer}>
                            <span className={styles.stepNumber}>
                              {index + 1}
                            </span>
                            <span className={styles.stepNumbers}> / 6</span>
                          </div>
                          <p>{item}</p>
                        </div>
                      </div>
                      <div
                        className={
                          activeStep === index
                            ? styles.stepItemContentMobile
                            : styles.hiddenItem
                        }
                      >
                        <div className={styles.stepNumberContainer}>
                          <span className={styles.stepNumber}>{index + 1}</span>
                          <span className={styles.stepNumbers}> / 6</span>
                        </div>
                        <p>{item}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={`${styles.blockContainer} ${styles.blockSettings}`}>
            <div className={`${styles.profileSettings}`}>
              {activeStep === 0 && <NameForm onClick={onClick} />}
              {activeStep === 1 && <EngNameForm onClick={onClick} />}
              {activeStep === 2 && <DateForm onClick={onClick} />}
              {activeStep === 3 && <ReligionForm onClick={onClick} />}
              {activeStep === 4 && (
                <MapForm
                  onClick={onClick}
                  burialPlacePhotos={burialPlacePhotos}
                  setIsChangeLastStep={setIsChangeStep}
                />
                )
              }
              {activeStep === 5 && (
                <MediaForm
                  onClick={onClick}
                  burialPlacePhotos={burialPlacePhotos}
                  setIsCreating = {(value)=> setIsCreating(value)}
                  setIsChangeLastStep={setIsChangeStep}/>
              )}
            </div>
          </div>
        </div>

      )}
    </>
  );
};
