import { UIEvent, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetchWithMsal from "../../utils/useFetchWithMsal";

import styles from "./UserProfile.module.scss";
import stylesConfirm from "../../components/userProfile/complaints/Complaints.module.scss";

import editIcon from "../../shared/image/Icon_personalAccount/editIcon.svg";
import userIcon from "../../shared/image/Icon_personalAccount/userIcon.svg";
import paymentIcon from "../../shared/image/Icon_personalAccount/paymentIcon.svg";
import historyIcon from "../../shared/image/Icon_personalAccount/historyIcon.svg";
import notificationIcon from "../../shared/image/Icon_personalAccount/notificationIcon.svg";
import infoIcon from "../../shared/image/Icon_personalAccount/InfoIcon.svg";
import blackListIcon from "../../shared/image/Icon_personalAccount/backlistIcon.svg";
import feedbackIcon from "../../shared/image/Icon_personalAccount/feedbackIcon.svg";
import language from "../../shared/image/Icon_personalAccount/languageIcon.svg";
import logout from "../../shared/image/logout.svg";
import smallArrow from "../../shared/image/arrowIcon.svg";
import { ReactComponent as Edit } from "../../shared/image/Icon_personalAccount/editIcon.svg";

import { SettingListItem } from "../../shared/components/settingListItem/SettingListItem";
import { EditProfile } from "../../components/userProfile/editProfile/EditProfile";
import ModalWindowHeader from "../../shared/components/modalWindowHeader/ModalWindowHeader";
import { useGetAuthorizedUserInfoQuery } from "../../api/UserService";
import { Avatar } from "../../shared/components/avatar/Avatar";
import { ProfilesSettingContainer } from "../../components/userProfile/profilesSettingContainer/ProfilesSettingContainer";
import { Menu } from "../../components/menu/Menu";
import { LanguagesSetting } from "../../components/userProfile/editProfile/languagesSetting/LanguagesSetting";
import { RootState } from "../../store";
import { PopupData } from "../../types/CommonTypes";
import {
  useGetOrderHistoryCandleQuery,
  useGetOrderHistoryPlatesQuery,
} from "../../api/OrderService";
import { MemorialTypes } from "../../constants/Memorial.constant";
import {
  resetOrderHistoryData,
  setCandlesHistoryPageNumber,
  setIsOrderHistoryLoading,
  setOrderHistoryCandlesData,
  setOrderHistoryPlatesData,
  setPlatesHistoryPageNumber,
} from "../../store/slices/OrderHistorySlice";
import { OrderHistory } from "../../components/userProfile/orderHistory/OrderHistory";
import { setShowSpinner } from "../../store/slices/SpinnerSlice";
import { PaymentUserProfile } from "../../components/userProfile/paymentUserProfile/PaymentUserProfile";
import useIsAuthenticated from "../../utils/useIsAuthenticated";
import { ModalWithTextarea } from "../../shared/components/ModalWithTextarea/ModalWithTextarea";
import { useFeedbackMutation } from "../../api/ComplaintService";
import { ModalWindow } from "../../components/modalWindow/ModalWindow";
import infoResultIcon from "../../shared/image/info.svg";
import { Info } from "../../components/userProfile/info/Info";

const iconsMain = [
  editIcon,
  userIcon,
  paymentIcon,
  historyIcon,
  notificationIcon,
  language,
  infoIcon,
  blackListIcon,
  feedbackIcon,
];

export const UserProfile = () => {
  const [searchParams] = useSearchParams();
  const selectedItemProps = searchParams.get("selectedItem");
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const { isAuthenticated } = useIsAuthenticated(selectedItem);
  const { skipUser } = useFetchWithMsal("user", true);
  const { skipOrder } = useFetchWithMsal("order", true);
  const { skipComplaint } = useFetchWithMsal("complaint", true);

  const { t } = useTranslation();

  const { instance } = useMsal();
  const [blockSetting, setBlockSetting] = useState<string>("");
  const { data } = useGetAuthorizedUserInfoQuery("", {
    skip: skipUser || !isAuthenticated,
  });
  const { language } = useSelector((state: RootState) => state.language);
  const {
    checkedTab,
    candlesPageNumber,
    candlesData,
    platesData,
    platesPageNumber,
    isOrderHistoryLoading,
  } = useSelector((state: RootState) => state.orderHistorySlice);
  const [isListView, setIsListView] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [num, setNum] = useState(1);
  const [openFeedbackWindow, setOpenFeedbackWindow] = useState<boolean>(false);
  const [feedbackConfirmationModal, setFeedbackConfirmationModal] =
    useState<boolean>(false);

  const {
    data: orderHistoryCandle,
    isSuccess: isCandleHistorySuccess,
    isLoading: isLoadingGetCandle,
    isFetching,
  } = useGetOrderHistoryCandleQuery(
    { pageNumber: num },
    {
      skip:
        skipOrder || !isAuthenticated || checkedTab !== MemorialTypes.candles,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: orderHistoryPlates,
    isSuccess: isPlatesHistorySuccess,
    isLoading: isLoadingGetPlates,
  } = useGetOrderHistoryPlatesQuery(
    { pageNumber: platesPageNumber },
    {
      skip:
        skipOrder || !isAuthenticated || checkedTab !== MemorialTypes.qrCode,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    feedback,
    { isSuccess: isSuccessFeedback, isLoading: isLoadingFeedback },
  ] = useFeedbackMutation();

  const items = t("userProfile.fields", { returnObjects: true }) as string[];

  useEffect(() => {
    if (!!selectedItemProps) {
      handleItemClick(+selectedItemProps);
    }
    return () => {
      dispatch(resetOrderHistoryData());
    };
  }, []);

  useEffect(() => {
    if (isLoadingFeedback) {
      dispatch(setShowSpinner(true));
    }
    if (isSuccessFeedback) {
      setFeedbackConfirmationModal(true);
      dispatch(setShowSpinner(false));
    }
  }, [isSuccessFeedback, isLoadingFeedback]);

  useEffect(() => {
    if (isLoadingGetCandle || isLoadingGetPlates) {
      dispatch(setShowSpinner(true));
      dispatch(setIsOrderHistoryLoading(true));
    } else if (!isLoadingGetCandle && !isLoadingGetCandle) {
      dispatch(setShowSpinner(false));
      dispatch(setIsOrderHistoryLoading(false));
    }
  }, [isLoadingGetCandle, isLoadingGetPlates]);

  useEffect(() => {
    if (isCandleHistorySuccess && !isFetching) {
      if (candlesPageNumber === 1) {
        dispatch(setOrderHistoryCandlesData(orderHistoryCandle.items));
      } else {
        dispatch(
          setOrderHistoryCandlesData([
            ...candlesData,
            ...orderHistoryCandle.items,
          ])
        );
      }
      dispatch(setIsOrderHistoryLoading(false));
    }
  }, [isCandleHistorySuccess, isFetching]);
  useEffect(() => {
    if (isPlatesHistorySuccess) {
      if (platesPageNumber === 1) {
        dispatch(setOrderHistoryPlatesData(orderHistoryPlates.items));
      } else {
        dispatch(
          setOrderHistoryPlatesData([
            ...platesData,
            ...orderHistoryPlates.items,
          ])
        );
      }
      dispatch(setIsOrderHistoryLoading(false));
    }
  }, [isPlatesHistorySuccess]);

  const handleLogoutRedirect = () => {
    localStorage.clear(); 
    localStorage.setItem("lang",language );
    localStorage.setItem('redirectAfterLogout', 'true');
    instance.logoutRedirect();
  };
  const handleItemClick = (item: number) => {
    setSelectedItem(item);
    switch (item) {
      case 0:
        setBlockSetting(t("common.edit"));
        break;
      case 1:
        setBlockSetting(t("userProfile.usersProfiles.ownedProfiles"));
        break;
      case 2:
        setBlockSetting(t("common.payment"));
        break;
      case 3:
        setBlockSetting(t("userProfile.orderHistory.header"));
        break;
      case 5:
        setBlockSetting(t("userProfile.language"));
        break;
      case 6:
        setBlockSetting(t("userProfile.info.header"));
        break;
      case 8:
        setOpenFeedbackWindow(true);
        break;
      default:
        break;
    }
  };
  const getLanguage = (index: number) => {
    if (index === 5) {
      const languages = t("languagePopup", {
        returnObjects: true,
      }) as PopupData[];
      const currentLanguage = languages.find((item) => item.type === language);
      return currentLanguage?.description || "";
    }
    return "";
  };

  const onClose = () => {
    setSelectedItem(0);
    setBlockSetting("");
  };

  const getClassName = (item: number) => {
    if (item === selectedItem) {
      if (item === 4) {
        return "firstSelected";
      }
      return "selected";
    } else if (item - 1 === selectedItem) {
      return "afterSelected";
    }
    if (item === 4) {
      return "afterLanguage";
    }
    return "";
  };
  const fetchNewPageData = (type: string) => {
    switch (type) {
      case MemorialTypes.candles:
        if (
          !isFetching &&
          !isOrderHistoryLoading &&
          !isLoadingGetCandle &&
          orderHistoryCandle?.pageNumber < orderHistoryCandle?.totalPages
        ) {
          dispatch(setIsOrderHistoryLoading(true));
          setNum((prev) => prev + 1);
          dispatch(setCandlesHistoryPageNumber(candlesPageNumber + 1));
        }
        break;
      case MemorialTypes.qrCode:
        if (
          !isOrderHistoryLoading &&
          !isLoadingGetPlates &&
          orderHistoryPlates?.pageNumber < orderHistoryPlates.totalPages
        ) {
          dispatch(setIsOrderHistoryLoading(true));
          dispatch(setPlatesHistoryPageNumber(platesPageNumber + 1));
        }
        break;
      default:
        break;
    }
  };
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const divComponent = e.target as HTMLDivElement;
    if (
      divComponent.offsetHeight + divComponent.scrollTop >=
      divComponent.scrollHeight - 200
    ) {
      fetchNewPageData(checkedTab);
    }
  };

  const onSubmitFeedback = (text: string) => {
    sendFeedback(text);
  };

  const sendFeedback = (text: string) => {
    if (!skipComplaint) {
      feedback({
        body: {
          text: text,
        },
      });
    }
  };

  const onCloseFeedbackConfirmationModal = () => {
    onClose();
    setOpenFeedbackWindow(false);
    setFeedbackConfirmationModal(false);
  };

  const closeFeedbackWindow = () => {
    setOpenFeedbackWindow(false);
    onClose();
  };

  return (
    <>
      {selectedItem === 8 && openFeedbackWindow && (
        <ModalWithTextarea
          onClose={closeFeedbackWindow}
          isShow={openFeedbackWindow}
          headerText={t("userProfile.support")}
          onSubmit={onSubmitFeedback}
          placeholder={t("common.placeholderEnterText")}
          maxLength={2048}
          submitButtonName={t("common.send")}
          idTextarea={"feedback"}
          isFeedback
        />
      )}
      <div className={styles.profileContainer} onScroll={handleScroll}>
        <div className={styles.headerBlock}>
          <Avatar isUserProfile />
          <h3 className={styles.nameUser}>{`${data?.lastName || ""} ${
            data?.firstName || ""
          } ${data?.middleName || ""}`}</h3>
          <p className={styles.username}>@{data?.username || ""}</p>
          <Edit
            className={styles.editIcon}
            onClick={() => setBlockSetting(t("common.edit"))}
          />
        </div>

        <div
          className={`${styles.blockContainer}  ${
            blockSetting && styles.blockSettings
          }`}
        >
          <div
            className={styles.breadcrumbsContainer}
            onClick={() => navigate(-1)}
          >
            <img src={smallArrow} alt="" />
            <p>{t("common.back")}</p>
          </div>

          <div className={styles.profileMainBlock}>
            <h1 className={styles.header}>{t("userProfile.userProfile")}</h1>
            <h3 className={styles.subhead}>{t("userProfile.main")}</h3>
            <ul className={styles.settingList}>
              {items.slice(0, 4).map((item, index) => (
                <SettingListItem
                  isStatic
                  key={index}
                  text={item}
                  isHidden={index === 0}
                  icon={iconsMain[index]}
                  classNameType={getClassName(index)}
                  onClick={() => handleItemClick(index)}
                />
              ))}
            </ul>
            <h3 className={styles.subhead}>{t("userProfile.settings")}</h3>
            <ul className={styles.settingList}>
              {items.slice(4, 9).map((item, index) => (
                <SettingListItem
                  isStatic
                  key={index + 4}
                  text={item}
                  description={getLanguage(index + 4)}
                  icon={iconsMain[index + 4]}
                  classNameType={getClassName(index + 4)}
                  onClick={() => handleItemClick(index + 4)}
                />
              ))}
            </ul>
            <div className={styles.logoutButtonContainer}>
              <SettingListItem
                isStatic
                text={t("userProfile.logout")}
                icon={logout}
                classNameType={"single"}
                onClick={handleLogoutRedirect}
              />
            </div>
          </div>
        </div>
        <div
          className={`${styles.blockContainer} ${styles.blockSettings} ${
            blockSetting && styles.showBlock
          }`}
        >
          {selectedItem !== 8 && (
            <div className={`${styles.profileSettings}`}>
              <ModalWindowHeader
                title={blockSetting}
                onClick={onClose}
                type={selectedItem === 1 ? "ownedProfile" : ""}
                isListView={isListView}
                setIsListView={() => setIsListView(!isListView)}
              />
              <div></div>
              {selectedItem === 0 && <EditProfile />}
              {selectedItem === 1 && (
                <ProfilesSettingContainer
                  listView={isListView}
                  type={"owned"}
                />
              )}
              {selectedItem === 2 && <PaymentUserProfile />}
              {selectedItem === 3 && <OrderHistory />}
              {selectedItem === 5 && (
                <LanguagesSetting
                  changeHeader={() =>
                    setBlockSetting(t("userProfile.language"))
                  }
                />
              )}
              {selectedItem === 6 && <Info />}
            </div>
          )}
        </div>
      </div>
      <div
        className={`${styles.menuContainer} ${
          blockSetting || selectedItem === 8 ? styles.hiddenMenu : ""
        }`}
      >
        <Menu />
      </div>

      {feedbackConfirmationModal && (
        <ModalWindow
          onClose={onCloseFeedbackConfirmationModal}
          isShow={feedbackConfirmationModal}
          headerText={t("userProfile.support")}
          isFeedback
          children={
            <div className={styles.flex}>
              <div>
                <p className={stylesConfirm.description}>
                  {t("userProfile.supportThanks")}
                </p>
              </div>
              <div>
                <img
                  className={styles.infoIcon}
                  src={infoResultIcon}
                  alt={""}
                />
                <button
                  className={stylesConfirm.buttonConfirmModal}
                  onClick={onCloseFeedbackConfirmationModal}
                >
                  {t("common.close")}
                </button>
              </div>
            </div>
          }
        ></ModalWindow>
      )}
    </>
  );
};
