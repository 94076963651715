import styles from './SwipeDownIndicator.module.scss';
import { TouchEvent, useEffect, useRef, useState } from 'react';

type SwipeDownIndicatorProps = {
  title?: string;
  onClose: () => void;
}

export const SwipeDownIndicator = ({onClose, title}: SwipeDownIndicatorProps) => {
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const isDragging = useRef(false);

  useEffect(() => {
    const handleTouchMove: (
      this: Document,
      ev: TouchEventInit | TouchEvent
    ) => any = function (event) {
      if (
        isDragging.current &&
        event.touches &&
        event.touches[0].clientY > 0
      ) {
        (event as TouchEvent).stopPropagation();
        (event as TouchEvent).preventDefault();
      }
    };

    document.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  const handleStartSwipe = (e: TouchEvent<HTMLDivElement>) => {
    setTouchStartY(e.touches[0].clientY);
    isDragging.current = true;
  };
  const handleSwipe = (e: TouchEvent<HTMLDivElement>) => {
    if (touchStartY) {
      const swipeThreshold = 50;
      const touchEndY = e.changedTouches[0].clientY;
      const deltaY = touchEndY - touchStartY;
      isDragging.current = false;

      if (deltaY > swipeThreshold && onClose) {
        onClose();
      }
    }
  };

  return (
    <div
      className={styles.titleMobile}
      onTouchStart={handleStartSwipe}
      onTouchEnd={handleSwipe}
    >
      <span></span>
      {title && <h3>{title}</h3>}
    </div>
  )
}