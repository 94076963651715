import { useState, useEffect } from "react";
import styles from "./ModalWindow.module.scss";
import { ModalWindow } from "../modalWindow/ModalWindow";
import { useTranslation } from "react-i18next";
import useIsAuthenticated from "../../utils/useIsAuthenticated";
import { UnAuthenticatedPopup } from "../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";

type SilentLetterModalProps = {
  isShow: boolean;
  onClose: () => void;
  onSend: (message: string) => void;
};

export const SilentLetterModal: React.FC<SilentLetterModalProps> = ({ isShow, onClose, onSend }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [isShowNotAuthenticatedModalWindow, setIsShowNotAuthenticatedModalWindow] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { isAuthenticated } = useIsAuthenticated();

  useEffect(() => {
    setIsButtonDisabled(message.trim().length === 0);
  }, [message]);

  const handleSend = () => {
    if (message.trim()) {
      if (!isAuthenticated) {
        setIsShowNotAuthenticatedModalWindow(true);
      } else {
        onSend(message);
        setMessage('');
      }
    }
  };

  return (
    <>
      <ModalWindow
        isShow={isShow}
        onClose={onClose}
        headerText={t("profile.sendSilentLetter")}
        isOrder={true}
      >
        <textarea
          className={styles.textarea}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={t("profile.enterMessage")}
        />
        <button
          className={styles.sendButton}
          onClick={handleSend}
          disabled={isButtonDisabled}
        >
          {t("common.send")}
        </button>
      </ModalWindow>
      {isShowNotAuthenticatedModalWindow && (
        <UnAuthenticatedPopup
          onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
        />
      )}
    </>
  );
};
