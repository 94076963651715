import styles from "./UserMenu.module.scss";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { setLanguage } from "../../store/slices/languageSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import i18n from "../../i18n";
import { Popup } from "../../shared/components/popup/Popup";
import { PopupData } from "../../types/CommonTypes";
import { Avatar } from "../../shared/components/avatar/Avatar";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import { useGetAuthorizedUserInfoQuery } from "../../api/UserService";
import {
  setFullImage,
  setPreviewImage,
} from "../../store/slices/UserPhotoSlice";
import { getLanguageByLocale } from "../../utils/localeUtils";
import useIsAuthenticated from "../../utils/useIsAuthenticated";

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const UserMenu = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const {isAuthenticated} = useIsAuthenticated();

  const { skipUser } = useFetchWithMsal("user", isAuthenticated);
  const { data } = useGetAuthorizedUserInfoQuery("", {
    skip: skipUser || !isAuthenticated,
  });

  const { language } = useSelector((state: RootState) => state.language);
  const dispatch = useDispatch();
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  const popupData = t("languagePopup", { returnObjects: true }) as PopupData[];

  useEffect(() => {
    if (!skipUser && data?.avatarPreviewUrl) {
      dispatch(setPreviewImage(url + data?.avatarPreviewUrl));
      dispatch(setFullImage(url + data?.avatarUrl));
    }
  }, [data?.avatarPreviewUrl, skipUser]);

  const handleLoginRedirect = () => {
    loginRequest.extraQueryParameters = { ui_locales: language };
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };
  const onPopupClick = (type: string) => {
    dispatch(setLanguage(type));
    localStorage.setItem("lang", type);
    setIsShowPopup(false);

    i18n.changeLanguage(type.toLocaleLowerCase());
  };
  const onPopupClose = () => {
    setIsShowPopup(false);
  };

  const handleLanguageClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsShowPopup(!isShowPopup);
  };

  return (
    <div className={styles.menuSettings}>
      <div className={styles.languageContainer}>
      <div
        className={`${styles.language} ${isShowPopup ? styles.selected : ""}`}
        onClick={handleLanguageClick}
      >
        <p>{getLanguageByLocale(language).toUpperCase()}</p>
      </div>
      {isShowPopup && (
        <Popup
          data={popupData}
          onClose={onPopupClose}
          onClick={onPopupClick}
          type={"language"}
          value={language}
        ></Popup>
      )}
      </div>
      {isAuthenticated ? (
        <div className={styles.avatarContainer}>
          <Avatar size="small" />
        </div>
      ) : (
        <p className={styles.signIn} onClick={handleLoginRedirect}>
          {t("common.login")}
        </p>
      )}
    </div>
  );
};
