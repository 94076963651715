import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

const baseUrl = process.env.REACT_APP_GIFT_DNS_NAME as string;


export const giftApi = createApi({
  reducerPath: "giftApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  
  endpoints: (build) => ({
    getGifts: build.mutation({
      query: (profileIds) => ({
        url: `/api/gift/?${profileIds}`,
      }),
    }),
    //avatar candle
    getCandleProfile: build.query({
      query: (profileId) => ({
        url: `/api/gift/profile?profileId=${profileId}`,
      }),
    }),
    getCandle: build.mutation<any, { profileId: string; pageNumber?: number; pageSize?: number, orderBy?: string; orderDirection?: number }>({
      query: ({ profileId, pageNumber = 1, pageSize = 10, orderBy = 'PaidAt', orderDirection = 1 }) => {
        return {
          url: `/api/gift/candle`,
          params: { profileId, pageNumber, pageSize, orderBy, orderDirection },
        };
      },
    }),
    getFeelings: build.mutation<any, { profileId: string; pageNumber?: number; pageSize?: number, orderBy?: string; orderDirection?: number }>({
      query: ({ profileId, pageNumber = 1, pageSize = 10, orderBy = 'PaidAt', orderDirection = 1 }) => {
        return {
          url: `/api/gift/feeling`,
          params: { profileId, pageNumber, pageSize, orderBy, orderDirection },
        };
      },
    }),
  }),
});
export const {
  useGetCandleProfileQuery,
  useGetCandleMutation,
  useGetGiftsMutation,
  useGetFeelingsMutation
} = giftApi;
