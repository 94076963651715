import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { Menu } from "../../../components/menu/Menu";
import { UserMenu } from "../../../components/userMenu/UserMenu";

import styles from "./PageLayout.module.scss";
import logo from "../../image/logo.png";
import { Search } from '../../../components/Search/Search';

type PageLayoutProps = {
  type?: string;
};

export const PageLayout = ({type}: PageLayoutProps) => {
  const {accounts} = useMsal();
  const location = useLocation();
  const [hide, setHide] = useState<boolean>(false);

  useEffect(() => {
    const url = location.pathname;
    setHide(!accounts.length && url === '/');
  }, [location, accounts.length]);

  return (
    <>
      { !hide ? (
        <div className={`${type === "editProfile" ? styles.wrapperEditProfile : styles.wrapper}`}>
          <div className={styles.header}>
            <Link
              to={!!accounts.length ? "/" : "/profiles"}
              draggable={false}
              className={styles.logo}
            >
              <img src={logo} alt="logo" draggable={false} loading="lazy"/>
            </Link>
            <div className={styles.headerWithSearch}>
              <Search/>
              <UserMenu/>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.menuContainer}>
              <Menu/>
            </div>
            <div className={styles.mainContainer}><Outlet/></div>
          </div>
        </div> )
        : <Outlet/>
      }
    </>
  );
};
