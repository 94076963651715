import styles from "./ContentEditableDiv.module.scss";
import {
    KeyboardEvent,
    ClipboardEvent,
    FocusEvent
} from 'react';

type ContentEditableDivProps = {
    isEdit: boolean;
    htmlValue: string;
    onBlur: (event: FocusEvent<HTMLDivElement>) => void;
    maxLength: number;
}

export const ContentEditableDiv = ({isEdit, htmlValue, onBlur, maxLength}: ContentEditableDivProps) => {

    const checkMax = (event: KeyboardEvent<HTMLDivElement>): void => {
        const text = (event.target as HTMLDivElement).innerText;
        if (text.length >= maxLength && event.keyCode != 8) {
            event.preventDefault();
        }
    }

    const paste = (e: ClipboardEvent<HTMLDivElement>) => {
        if (e.clipboardData.types.includes('Files')) {
            e.preventDefault();
        }
    }

    return (
            <div className={styles.container}
                 contentEditable={isEdit}
                 onPaste={paste}
                 onKeyDown={checkMax}
                 suppressContentEditableWarning={isEdit}
                 onBlur={onBlur}
                 dangerouslySetInnerHTML={{ __html: htmlValue }}
                 >
            </div>
    );
};
