import styles from "./PersonalPhotosGallery.module.scss";
import { useEffect, useState } from "react";
import { Gallery } from "../gallery/Gallery";
import { useDeleteGalleryPhotoMutation, useGetGalleryPhotoQuery } from "../../../api/MediaService";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { ImageItem } from "../../../types/CommonTypes";
import video from "../../../shared/image/video.png";
import { allowedVideoTypes } from "../../../constants/Media.constant";


type PropsType = {
  id: string;
  isAuthenticated: boolean;
  isCreatedByCurrentUser: boolean;
};

export const PersonalPhotosGallery = ({
  id,
  isAuthenticated,
  isCreatedByCurrentUser,
}: PropsType) => {
  const { skipMedia } = useFetchWithMsal("media", isAuthenticated);

  const { data: photos,isSuccess,isFetching} = useGetGalleryPhotoQuery(id, { skip: skipMedia, refetchOnMountOrArgChange: true });
  const [deletePhoto] = useDeleteGalleryPhotoMutation()
  const [gallery, setGallery] = useState<ImageItem[]>([]);

  useEffect(() => {
    if(photos){
      const galleryPhoto = photos.map((item:any) => {
        return {
          thumbnail: item.previewUrl || video,
          id: item.name,
          url: item.url,
          description: allowedVideoTypes.includes(item.contentType)
          ? "video"
          : "image",
          type: item.contentType
        };
      });
      setGallery(galleryPhoto)
    }
  
  }, [photos]);
  

  const onDelete = (photoId: string, filteredGallery:ImageItem[]) => {
    deletePhoto({ profileId: id, mediaName:photoId });
    setGallery(filteredGallery)
  };
  return (
    <>
      {!!photos?.length && isSuccess && !skipMedia && !isFetching ? (
        <div className={styles.galleryContainer}>
          <Gallery
            gallery={gallery}
            deletePhoto={onDelete}
            isShowDelete={isCreatedByCurrentUser}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
