import { useState } from "react";
import styles from "./PlateslHistoryItem.module.scss";

import { useTranslation } from "react-i18next";
import { OrderHistoryPlateType } from "../../../../../types/OrderHistoryType";
import { PlatesPopup } from "../platesPopup/PlatesPopup";
import { Rating } from "react-simple-star-rating";
import { ReactComponent as RatingIconBorder } from "../../../../../shared/image/rating/ratingIconBorder.svg";
import { ReactComponent as RatingIcon } from "../../../../../shared/image/rating/ratingIcon.svg";
import { OrderHistoryListItem } from "../../OrderHistoryListItem/OrderHistoryListItem";
import { ModalWindow } from "../../../../modalWindow/ModalWindow";

type PlateslHistoryItemProps = {
  data: OrderHistoryPlateType;
};

export const PlateslHistoryItem = ({ data }: PlateslHistoryItemProps) => {
  const { t } = useTranslation();
  const [isShowPlatesWindow, setIsShowPlatesWindow] = useState<boolean>(false);
  const [isShowInfoPopup, setIsShowInfoPopup] = useState<boolean>(false);

  const onSendFeedback = () => {
    setIsShowPlatesWindow(false);
    setIsShowInfoPopup(true);
  };
  return (
    <div className={styles.platesHistoryItemContainer}>
      <div className={styles.platesHistoryItemHeader}>
        <p>{`${t("userProfile.orderHistory.orderNumber")}${data.number}`}</p>
        {!!data.rating && (
          <Rating
            readonly
            allowFraction
            showTooltip={false}
            initialValue={data.rating}
            fillIcon={<RatingIcon />}
            emptyIcon={<RatingIconBorder />}
          ></Rating>
        )}
      </div>
      <OrderHistoryListItem
        classNameType="single"
        data={data}
        handleOpenModal={() => setIsShowPlatesWindow(true)}
      />
      {isShowPlatesWindow && (
        <PlatesPopup
          isShowPlatesWindow={isShowPlatesWindow}
          setIsShowPlatesWindow={setIsShowPlatesWindow}
          onSendFeedback={onSendFeedback}
          id={data.id}
          isRating={!!data.rating}
          orderNumber={data.number}
          orderStatus={data.status}
        />
      )}
      {isShowInfoPopup && (
        <ModalWindow
          onClose={() => setIsShowInfoPopup(false)}
          isShow={isShowInfoPopup}
          headerText={t("userProfile.orderHistory.feedbackSentHeader")}
          children={
          <div>
          <p className={styles.feedbackText}>{t("userProfile.orderHistory.feedbackSentDescription")}</p>
          <button className={styles.feedbackButton} onClick={() => setIsShowInfoPopup(false)}>{t('common.ok')}</button>
          </div>
          }
        ></ModalWindow>
      )}
    </div>
  );
};
