import styles from "./WizardHearthView.module.scss";
import { QrCodeWizardViewProps } from "../QrCodeWizardView";
import { WizardNameBlock } from "../shared/wizardNameBlock/WizardNameBlock";
import { WizardDateBlock } from "../shared/wizardDateBlock/WizardDateBlock";
import { WizardImageBlock } from "../shared/wizardImageBlock/WizardImageBlock";
import { WizardQrBlock } from "../shared/wizardQrBlock/WizardQrBlock";
import { WizardDescriptionBlock } from "../shared/wizardDescriptionBlock/WizardDescriptionBlock";
import { ImageItem } from "../../../../../types/CommonTypes";
import { RefObject } from "react";

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

interface WizardHearthViewProps extends QrCodeWizardViewProps {
  setImageData: (items: ImageItem[], showSelectButton?: boolean) => void;
}

export const WizardHearthView = ({ model, isBlack, qr, blockForImage, blockForPlate, isEdit, setImageData }: WizardHearthViewProps) => {

    const getTemplateUrl = (): string => {
        return !!model?.templateUrl ? url + model.templateUrl : "";
    }

    return (
      <div className={styles.container}
           ref={blockForPlate}>
        <div className={styles.background} style={{backgroundImage: `url(${getTemplateUrl()})`}}></div>
        <div className={styles.upContainer}>
          <WizardImageBlock blockForImage={blockForImage as RefObject<HTMLDivElement>} setImageData={setImageData} isEdit={isEdit} borderRadius={'50%'} height={389} width={389}/>
          <div className={styles.infoContainer}>
            <WizardNameBlock isBlack={isBlack} isEdit={isEdit}/>
            <div className={styles.marginNameDate}></div>
            <WizardDateBlock isBlack={isBlack} isEdit={isEdit}/>
            <div className={styles.marginDateDescription}></div>
            <WizardDescriptionBlock isBlack={isBlack} isEdit={isEdit} height={"68px"}/>
          </div>
        </div>
        <WizardQrBlock isBlack={isBlack} qr={qr} widthAndHeight={"113px"}/>
      </div>
    );
};
