import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";

import styles from "./CandleBurning.module.scss";
import { pluralize } from "../../../utils/pluralizeUtils";
import useIsAuthenticated from '../../../utils/useIsAuthenticated';
import { Dropdown } from "../../../shared/components/dropdown/Dropdown";
import { Checkbox } from '../../../shared/components/checkbox/Checkbox';
import { PriceItemType } from '../../../types/MemorialTypes';

type CandleBurningProps = {
  onClose: (value: {
    burningValue: number;
    isFree: boolean;
    isSubscription: boolean;
  }) => void;
  profileCount: number;
  availableFreeCandle: number;
  cost: number;
};

interface TimeData {
  time: number;
  type: string;
}

const range = (number: number) =>
  Array.from({ length: number }, (_, i) => {
    return { id: 1 + i, name: String(1 + i) };
  });

export const CandleBurning = ({
     onClose,
     cost,
     profileCount,
     availableFreeCandle,
   }: CandleBurningProps) => {
  const {isAuthenticated} =  useIsAuthenticated();
  const { t } = useTranslation();
  const currency = 'EUR';
  const burningTimeType = "Month";
  const count = range(12);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState<number | null>(null);
  const [timePeriods, setTimePeriods] = useState<TimeData[]>([])
  const [timeCount, setTimeCount] = useState<number>(1);
  const [isSubscription, setIsSubscription] = useState<boolean>(false);
  const isSubscriptionType = isAuthenticated && !!selectedTime && selectedTime !== "Hour";
  const radioRefs = useRef<Array<HTMLInputElement | null>>(
    Array(timePeriods?.length + 1).fill(null)
  );

  useEffect(() => {
    if (cost === 0) {
      setTimePeriods( [{time: 3, type: 'Hour'}])
    }
    else if (cost !== 0 && !availableFreeCandle || profileCount > availableFreeCandle) {
      setTimePeriods (
        [
          {time: 1, type: 'Month'},
          {time: 3, type: 'Month'},
          {time: 6, type: 'Month'},
        ]
      )
    } else {
      setTimePeriods ([
        {time: 3, type: 'Hour'},
        {time: 1, type: 'Month'},
        {time: 3, type: 'Month'},
        {time: 6, type: 'Month'},
      ])
    }
  }, [availableFreeCandle])
  const getPrice = (item: PriceItemType) => {
    
    if (item.burningTimeType === "Hour") {
      if (profileCount <= availableFreeCandle) {
        return t(`memorial.free`);
      }
      else if (item.cost === 0) {
        return t(`memorial.free`);
      }
      else {
        return `${item.cost} ${ item.currency }`;
      }
    }
    return `${item.cost * profileCount} ${item.currency}`;
  };

  const getTimeType = (number: number, type: string) => {
    return pluralize(
      number,
      t(`memorial.candleBurningData.${type}.one`),
      t(`memorial.candleBurningData.${type}.few`),
      t(`memorial.candleBurningData.${type}.many`)
    );
  };

  const costObjects = timePeriods?.map(period => ({
    duration: getTimeType(period.time, period.type),
    cost: cost * period.time,
    periodSize: period.type === burningTimeType ? period.time : 0,
    burningTimeType: period.type,
    currency: currency
  }));

  const getManuallyPrice = () => {
    return `${(cost || 0) * timeCount * profileCount} ${currency}`;
  };

  const onClick = () => {
    if (selectedTime === "manually") {
      onClose({
        burningValue: timeCount,
        isFree: !cost,
        isSubscription: isSubscription
      });
    } else {
      onClose({
        burningValue: selectedTime === "Hour" ? 1 : selectedPeriod as number,
        isFree: isAuthenticated ? (selectedTime === "Hour" && availableFreeCandle) ? true : !cost : false,
        isSubscription: isSubscription
      });
    }
  };

  const handleChoice = (time: string, type: string) => {
    if (type === 'Hour' && isSubscription) {
      setIsSubscription(false);
    }
    setSelectedPeriod(Number(time));
    setSelectedTime(type);
  }
 
  return (
    <>
      <div className={styles.formContainer}>
        {costObjects?.map((item, index) => {
          return (
            <div
              className={
                selectedPeriod === item.periodSize && selectedTime !== "manually"
                  ? styles.radioButtonContainerSelected
                  : styles.radioButtonContainer
              }
              key={index}
              onClick={() => radioRefs.current[index]?.click()}
            >
              <label className={styles.radioButtonLabel}>
                <input
                  ref={(el) => (radioRefs.current[index] = el)}
                  type="radio"
                  value={item.periodSize}
                  checked={selectedPeriod === item.periodSize && selectedTime !== "manually"}
                  className={
                    selectedPeriod === item.periodSize && selectedTime !== "manually"
                      ? styles.checkedInput
                      : ""
                  }
                  onChange={(event) => handleChoice(event.target.value, item.burningTimeType)}
                />
                <span className={styles.checkmark}></span>
                <span>{item.duration}</span>
              </label>
              <p className={styles.price}>{getPrice(item)}</p>
            </div>
          );
        })}
        {cost !== 0 && (
          <div
            className={
              selectedTime === "manually"
                ? styles.radioButtonManuallySelected
                : styles.radioButtonManually
            }
            onClick={()=>setSelectedTime("manually")}
          >
            <label className={styles.radioButtonLabel}>
              <input
                type="radio"
                value="manually"
                checked={selectedTime === "manually"}
                className={selectedTime === "manually" ? styles.checkedInput : ""}
              />
              <span className={styles.checkmark}></span>
              <span>{t(`memorial.candleBurningData.manualSelection`)}</span>
            </label>
            {selectedTime === "manually" && (
              <div className={styles.selectContainer}>
                <Dropdown
                  data={count}
                  value={timeCount}
                  onChange={(value) => setTimeCount(value)}
                  type="candle"
                  height="270px"
                />
                <div className={styles.periodManuallyContainer}>
                  {getTimeType(timeCount, burningTimeType).split(' ')[1]}
                </div>
                <p className={styles.manualSelectionText}>{getManuallyPrice()}</p>
              </div>
            )}
          </div>
        )}
      </div>
      <button
        className={styles.candleBurningButton}
        onClick={onClick}
        disabled={!selectedTime}
      >
        {t("common.continue")}
      </button>
      {
        isSubscriptionType && (
          <div className={styles.candleBurningCheckbox}>
            <Checkbox
              isChecked={isSubscription}
              onChange={() => setIsSubscription(!isSubscription)}
              label={t('memorial.candleBurningData.autoPayment')}
            />
          </div>
        )
      }
    </>
  );
};
