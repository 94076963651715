import styles from "./UnAuthenticatedPopup.module.scss";
import cross from "../../../shared/image/cross.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useMsal } from "@azure/msal-react";
import {
  b2cPolicies,
  loginRequest,
  protectedResources,
} from "../../../authConfig";
import { PopupBackground } from "../popupBackground/PopupBackground";
import { splitIntoParagraphs } from "../../../utils/splitIntoParagraph";

type NeedAuthenticatedPopupProps = {
  onClose: () => void;
};

export const UnAuthenticatedPopup = ({
  onClose,
}: NeedAuthenticatedPopupProps) => {
  const { t } = useTranslation();
  const { language } = useSelector((state: RootState) => state.language);
  const { instance } = useMsal();

  const redirectLogin = () => {
    loginRequest.extraQueryParameters = { ui_locales: language };
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleSignUpRedirect = () => {

    let signUpSignInFlowRequest = {
      authority: b2cPolicies.authorities.signUp.authority,
      scopes: protectedResources.api.scopes.read,
      extraQueryParameters: { ui_locales: language },
    };
    instance.loginRedirect(signUpSignInFlowRequest);
  };

  return (
    <div className={styles.modalWindowWrapper}>
      <PopupBackground onClose={onClose} />
      <div className={styles.modalWindowContainer}>
        <div className={styles.modalWindowHeader}>
          <p>{t("common.loginAccount")}</p>
          <img src={cross} alt="" onClick={onClose} />
        </div>
        <div className={`${styles.modalWindowBody} ${styles.description1}`}>
          <div>
            {splitIntoParagraphs(t("common.loginAccountDescription1")).map(
              (paragraph, index) => (
                <p key={index}>
                  {paragraph}
                  <br />
                </p>
              )
            )}
          </div>
          <div className={styles.buttonBlock}>
            <button className={styles.signInButton} onClick={redirectLogin}>
              {t("common.signIn")}
            </button>
            <button className={styles.signUp} onClick={handleSignUpRedirect}>
              {t("common.signUp")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
