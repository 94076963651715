import { useTranslation } from "react-i18next";
import styles from "./ProfileTabsContainer.module.scss";
import { useGetProfileQuery } from "../../../api/ProfileService";
import { useState } from "react";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { ReligionTab } from "./tabs/religionTab/ReligionTab";
import { CommonTab } from "./tabs/commonTab/CommonTab";
import useIsAuthenticated from "../../../utils/useIsAuthenticated";
import { getLanguageByLocale } from "../../../utils/localeUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

type PropsType = {
  id: string;
};

export const ProfileTabsContainer = ({ id }: PropsType) => {
  const {isAuthenticated} = useIsAuthenticated();
  const { skip } = useFetchWithMsal("profile", isAuthenticated);
  const { language } = useSelector((state: RootState) => state.language);

  const { data } = useGetProfileQuery({id, lang:getLanguageByLocale(language)}, {
    skip,
  });

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { t } = useTranslation();
  const getTab = () => {
    switch (selectedTab) {
      case 0:
        return (
          <ReligionTab
            religionId={data?.religionId}
            birthDate={data?.dateOfBirth}
            deathDate={data?.dateOfDeath}
          />
        );
      case 1:
        return <CommonTab type="work" id={id} />;
      case 2:
        return <CommonTab type="education" id={id} />;
      case 3:
        return <CommonTab type="hobby" id={id} />;
      default:
        break;
    }
  };

  const tabs = t("profile.profileTabs", { returnObjects: true }) as string[];
  return (
    <div className={styles.profileTabsContainer}>
      <div className={styles.profileTabs}>
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              className={`${
                selectedTab === index ? styles.activeTab : styles.tab
              }`}
              onClick={() => setSelectedTab(index)}
            >
              {tab}
            </div>
          );
        })}
      </div>
      <div className={styles.profileTabContent}>{getTab()}</div>
    </div>
  );
};
