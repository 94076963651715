import styles from "./ProfileCreatingPage.module.scss";
import { useTranslation } from "react-i18next";

export const ProfileCreatingPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.profileCreatedWrapper}>
      <div className={styles.profileCreatedContainer}>
        <p className={styles.description}>
          {t("createProfile.profileCreating")}
        </p>
      </div>
    </div>
  );
};
