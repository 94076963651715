import styles from "./MemorialMobileGallery.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { MemorialTypes } from "../../../constants/Memorial.constant";
import { UIEvent } from "react";
import { setCheckedTab } from "../../../store/slices/ProfileSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";

type PropsType = {
  onViewAllClick: () => void;
  type: string;
  fetchNewPageData: (type: string) => void;
};
const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const MemorialMobileGallery = ({ onViewAllClick, type, fetchNewPageData }: PropsType) => {
  const { t } = useTranslation();
  const { candlesData, feelingsData } = useSelector(
    (state: RootState) => state.profileSlice
  );
  const dispatch = useDispatch();

  const getData = () => {
    switch (type) {
      case MemorialTypes.candles:
        return candlesData;
      default:
        return feelingsData;
    }
  };
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const divComponent = e.target as HTMLDivElement;
    if (
      divComponent.offsetWidth + divComponent.scrollLeft >=
      divComponent.scrollWidth - 100
    ) {
      fetchNewPageData(type)
    }
  };
  const onViewClick=()=>{
    dispatch(setCheckedTab(type))
    onViewAllClick()
  }
  return (
    <>
      {!!getData()?.length ? (
        <div className={styles.galleryContainer} >
          <div className={styles.headerBlock}>
            <h3 className={styles.header}> {t(`memorial.${type}`)}</h3>
            <p onClick={onViewClick}>{t("profile.viewAll")}</p>
          </div>
          <div className={styles.galleryGrid} onScroll={handleScroll}>
            {getData().map((item, index) => {
              return (
                <div className={styles.imgContainer} key={index}>
                  <LazyLoadImage
                    src={`${url}${item?.mediaUrl}`}
                    alt=""
                    className={styles.galleryImage}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
