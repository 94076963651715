import { useTranslation } from "react-i18next";
import styles from "./LinksInfo.module.scss";
import { useGetLinksQuery } from "../../../api/ProfileService";

type PropsType = {
  id: string;
  skip: boolean;
};
type LinkType = {
  id: string;
  link: string;
  type: number;
};

const baseUrl = process.env.REACT_APP_UTILITIES_DNS_NAME as string;

export const LinksInfo = ({ id, skip }: PropsType) => {
  const { data } = useGetLinksQuery(id, { skip });
  const { t } = useTranslation();

  return (
    <>
      {data?.length ? (
        <div className={styles.linkInfo}>
          <h3 className={styles.header}>{t("profile.internetProfiles")}</h3>
          <div className={styles.linkContainer}>
            {data?.map((item: LinkType, index: number) => {
              let link;
              if (
                !item.link?.startsWith("http://") &&
                !item.link?.startsWith("https://")
              ) {
                link = "https://" + item.link;
              } else {
                link = item.link;
              }

              return (
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.imgContainer}
                  key={index}
                >
                  <img
                    src={`${baseUrl}/api/utility/SocialIcons/${item.type}`}
                    alt=""
                    loading="lazy"
                  />
                </a>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
