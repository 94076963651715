import { useTranslation } from "react-i18next";
import styles from "./PostsMobile.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { MouseEventHandler, useEffect, useState } from "react";
import { useGetPostsMediaQuery } from "../../../api/MediaService";
import { ImageItem } from "../../../types/CommonTypes";
import video from "../../../shared/image/video.png";
import { PostsHeader } from "../../../shared/components/postsHeader/PostsHeader";
import { setCheckedTab } from "../../../store/slices/ProfileSlice";
import { MemorialTypes } from "../../../constants/Memorial.constant";
import newPostIcon from '../../../shared/image/plusIcon.svg'
import { UnAuthenticatedPopup } from "../../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";
import { allowedVideoTypes } from "../../../constants/Media.constant";
import useIsAuthenticated from "../../../utils/useIsAuthenticated";

type PropsType = {
  onViewAllClick: () => void;
  onAddNewPostClick: () => void;
};

export const PostsMobile = ({ onViewAllClick,onAddNewPostClick }: PropsType) => {
  const {isAuthenticated} =  useIsAuthenticated();
  const [
    isShowNotAuthenticatedModalWindow,
    setIsShowNotAuthenticatedModalWindow,
  ] = useState<boolean>(false);
  const { postsData } = useSelector((state: RootState) => state.profileSlice);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [gallery, setGallery] = useState<ImageItem[]>([]);
  const { data: photos } = useGetPostsMediaQuery(
    { profileId: postsData[0]?.profileId, postId: postsData[0]?.id },
    { skip: !postsData.length }
  );

  useEffect(() => {
    if (photos) {
      const galleryPhoto = photos.map((item: any) => {
        return {
          thumbnail: item.previewUrl || video,
          id: item.name,
          url: item.url,
          description: allowedVideoTypes.includes(item.contentType)
            ? "video"
            : "image",
            type: item.contentType
        };
      });
      setGallery(galleryPhoto);
    }
  }, [photos]);
  const splitIntoParagraphs = (text: string) => {
    return text.split("\n");
  };
  const onViewClick = () => {
    dispatch(setCheckedTab(MemorialTypes.posts));
    onViewAllClick();
  };
  const onAddPostClick: MouseEventHandler<HTMLDivElement>  = (e) => {
    if(isAuthenticated){
      dispatch(setCheckedTab(MemorialTypes.posts));
      onAddNewPostClick();
    } else {
      e.stopPropagation()
      setIsShowNotAuthenticatedModalWindow(true)
    }
  };
  return (
    <div className={styles.postContainer}>
      <div className={styles.headerBlock}>
        <h3 className={styles.header}> {t(`posts.wall`)}</h3>
        {!!postsData.length && (
          <p onClick={onViewClick}>{t("profile.viewAll")}</p>
        )}
      </div>
      <div className={styles.mainBlock}>
        {!!postsData[0] ? (
          <>
            <div className={styles.mainContainer} onClick={onViewClick}>
              {!!gallery?.length && (
                <div className={styles.galleryContainer}>
                  {gallery.map((item: ImageItem, index: number) => (
                    <div className={item.description === 'video' ? styles.imgContainerVideo : styles.imgContainer} key={index}>
                    <img
                      src={item.thumbnail}
                      alt=""
                    />
                    </div>
                  ))}
                </div>
              )}
              {postsData[0]?.text && (
                <div className={styles.message}>
                  {splitIntoParagraphs(postsData[0].text).map(
                    (paragraph, index) => (
                      <p className={styles.text} key={index}>
                        {paragraph}<br/>
                      </p>
                    )
                  )}
                </div>
              )}
            </div>
            <PostsHeader
              date={postsData[0].creationDateTime}
              img={postsData[0].owner?.avatarPreviewUrl}
              username={postsData[0].owner?.username}
            />
          </>
        ) : (
          <div className={styles.emptyWall}>
            <p>{t("posts.emptyWall")}</p>
            <div className={styles.addPostContainer} onClick={onAddPostClick}>
              <img alt="" src={newPostIcon}/>
              <p>{t("posts.newPost")}</p>
            </div>
          </div>
        )}
      </div>
      {isShowNotAuthenticatedModalWindow && (
        <UnAuthenticatedPopup
          onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
        ></UnAuthenticatedPopup>
      )}
    </div>
  );
};
