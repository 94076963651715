import React, { MouseEventHandler, TouchEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import i18n from "../../i18n";

import PageControl from "../../shared/components/pageControl/PageControl";

import styles from "./LandingPage.module.scss";
import landing1 from "./../../shared/image/landing1.webp";
import landing2 from "./../../shared/image/landing2.webp";
import landing3 from "./../../shared/image/landing3.webp";
import { ReactComponent as InfoIcon } from "../../shared/image/menu/InfoIcon.svg";
import { ReactComponent as ArrowIcon } from "../../shared/image/Arrow_Icon.svg";
import { Popup } from "../../shared/components/popup/Popup";
import { getLanguageByLocale } from "../../utils/localeUtils";
import { PopupData } from "../../types/CommonTypes";
import { setLanguage } from "../../store/slices/languageSlice";
import { ModalWindow } from "../../components/modalWindow/ModalWindow";
import { ContactUsForm } from "../../components/contactUsForm/ContactUsForm";
import Cookies from "js-cookie";
import { CookieSettings } from "../../components/cookieNotification/cookieSettings/CookieSettings";

type LandingProps = {
  type?: string;
};

const totalPages = 3;

export const LandingPage = ({ type }: LandingProps) => {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [isShowContactUsForm, setIsShowContactUsForm] =
    useState<boolean>(false);
  const { language } = useSelector((state: RootState) => state.language);

  const [currentPage, setCurrentPage] = useState<number>(type ? 2 : 0);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowCookieSettings, setIsShowCookieSettings] =
    useState<boolean>(false);
  const description = t("langing", { returnObjects: true }) as string[];
  const popupData = t("languagePopup", { returnObjects: true }) as PopupData[];
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const hasMoved = useRef(false);

  const handleLoginRedirect = () => {
    const storedConsent = Cookies.get("cookieConsent");
    if (storedConsent) {
      loginRequest.extraQueryParameters = { ui_locales: language };
      instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    } else {
      setIsShowCookieSettings(true);
    }
  };

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    touchStartX.current = 0;
    touchEndX.current = 0;
    if (currentPage === 2) {
      handleLoginRedirect();
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onChangePage = (index: number) => {
    setCurrentPage(index);
  };
  const getClassName = () => {
    switch (currentPage) {
      case 0:
        return `${styles.landingContainer} ${styles.landingContainer1}`;
      case 1:
        return `${styles.landingContainer} ${styles.landingContainer2}`;
      default:
        return `${styles.landingContainer} ${styles.landingContainer3}`;
    }
  };

  const onPopupClick = (type: string) => {
    dispatch(setLanguage(type));
    localStorage.setItem("lang", type);
    setIsShowPopup(false);

    i18n.changeLanguage(type.toLocaleLowerCase());
  };
  const onPopupClose = () => {
    setIsShowPopup(false);
  };

  const handleLanguageClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsShowPopup(!isShowPopup);
  };

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    touchStartX.current = e.targetTouches[0].clientX;
    hasMoved.current = false;
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    touchEndX.current = e.targetTouches[0].clientX;
    hasMoved.current = true;
  };
  const handleTouchEnd = () => {
    if (!hasMoved.current) {
      return;
    }

    if (touchStartX.current - touchEndX.current > 50) {
      setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
    }

    if (touchStartX.current - touchEndX.current < -50) {
      setCurrentPage((prevPage) => (prevPage - 1 + 3) % totalPages);
    }
  };
  const onAcceptCookie = () => {
    Cookies.set("cookieConsent", "true", { expires: 365 });
    handleLoginRedirect();
  };
  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={getClassName()}
    >
      <div
        className={styles.landingBackground}
        style={{
          backgroundImage: `url(${
            [landing1, landing2, landing3][currentPage]
          })`,
        }}
      >
        <div
          className={
            currentPage ? styles.backContainer : styles.backContainerHidden
          }
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          {" "}
          <ArrowIcon className={styles.icon} />
          {t("common.backLanding")}
        </div>
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.languageContainer}>
          <InfoIcon onClick={() => setIsShowContactUsForm(true)} />
          <div
            className={`${styles.language} ${
              isShowPopup ? styles.selected : ""
            }`}
            onClick={handleLanguageClick}
          >
            <p>{getLanguageByLocale(language).toUpperCase()}</p>
          </div>
          {isShowPopup && (
            <Popup
              data={popupData}
              onClose={onPopupClose}
              onClick={onPopupClick}
              type={"languageSwitch"}
              value={language}
            ></Popup>
          )}
        </div>
        <div className={styles.mainBlock}>
          <div className={styles.descriptionBlock}>
            <h1 className={styles.descriptionHeader}>RIPPLANET</h1>
            <p className={styles.description}> {description[currentPage]}</p>
            <PageControl
              totalPages={totalPages}
              currentPage={currentPage}
              onChange={onChangePage}
            />
          </div>
          <button className={styles.landingButton} onClick={handleButtonClick}>
            {t("common.next")}
          </button>
          <p className={styles.skipButton} onClick={handleLoginRedirect}>
            {t("common.skip")}
          </p>
        </div>
        {isShowContactUsForm && (
          <ModalWindow
            onClose={() => setIsShowContactUsForm(false)}
            isShow={isShowContactUsForm}
            isContactUs
            headerText={t("common.contactUs")}
            children={
              <ContactUsForm onClose={() => setIsShowContactUsForm(false)} />
            }
          ></ModalWindow>
        )}
        {isShowCookieSettings && (
          <ModalWindow
            onClose={() => setIsShowCookieSettings(false)}
            isShow={isShowCookieSettings}
            headerText={"Cookie"}
            isOrder
            children={<CookieSettings onAcceptCookie={onAcceptCookie} />}
          ></ModalWindow>
        )}
      </div>
    </div>
  );
};
