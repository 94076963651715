import styles from "./PostItem.module.scss";
import { ReactComponent as InfoIcon } from "../../../../../shared/image/postsIcon/infoIcon.svg";
import { ReactComponent as ShareIcon } from "../../../../../shared/image/postsIcon/shareIcon.svg";
import { PostType } from "../../../../../types/ProfileType";
import { useGetPostsMediaQuery } from "../../../../../api/MediaService";
import { ImageViewer } from "../../../../imageViewer/ImageViewer";
import { MouseEventHandler, useEffect, useState } from "react";
import { ImageItem, PopupData } from "../../../../../types/CommonTypes";
import video from "../../../../../shared/image/video.png";
import { ActionButton } from "./actionButton/ActionButton";
import { PostsHeader } from "../../../../../shared/components/postsHeader/PostsHeader";
import { Comments } from "./comments/Coments";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { Popup } from "../../../../../shared/components/popup/Popup";
import { useTranslation } from "react-i18next";
import { useDeletePostMutation } from "../../../../../api/PostProfileService";
import {
  addNewComment,
  deleteProfilePost,
} from "../../../../../store/slices/ProfileSlice";
import { DeletePopup } from "../../../../../shared/components/deletePopup/DeletePopup";
import { UnAuthenticatedPopup } from "../../../../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";
import { ComplainPopup } from "../../../../userProfile/complaints/ComplainPopup";
import { AddCommentField } from "../addCommentField/AddCommentField";
import { allowedVideoTypes } from "../../../../../constants/Media.constant";
import useIsAuthenticated from "../../../../../utils/useIsAuthenticated";
import { SharePopup } from "../../../../share/SharePopup";
import { LazyLoadImage } from "react-lazy-load-image-component";

type PostItemProps = {
  data: PostType;
};

export const PostItem = ({ data }: PostItemProps) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useIsAuthenticated();
  const { t } = useTranslation();

  const { id } = useSelector((state: RootState) => state.userPhotoSlice);
  const { isCreatedByCurrentUser } = useSelector(
    (state: RootState) => state.profileSlice
  );
  const { language } = useSelector((state: RootState) => state.language);

  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(false);
  const [selectedImg, setSelectedImg] = useState<number>(0);
  const [
    isShowNotAuthenticatedModalWindow,
    setIsShowNotAuthenticatedModalWindow,
  ] = useState<boolean>(false);
  const [isShowInfoPopup, setIsShowInfoPopup] = useState<boolean>(false);
  const [isShowDeletePopup, setIsShowDeletePopup] = useState<boolean>(false);
  const [isShowComments, setIsShowComments] = useState<boolean>(false);
  const [isOnFocus, setIsOnFocus] = useState<boolean>(false);
  const [isShowComplainPopup, setIsShowComplainPopup] =
    useState<boolean>(false);
  const [isShowSharePopup, setIsShowSharePopup] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<PopupData[]>([]);
  const [gallery, setGallery] = useState<ImageItem[]>([]);

  const [deletePost, { isSuccess: isDeleteSuccess }] = useDeletePostMutation();
  const { data: photos } = useGetPostsMediaQuery(
    { profileId: data.profileId, postId: data.id },
    { skip: !data }
  );

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(deleteProfilePost(data.id));
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (data) {
      if (isCreatedByCurrentUser && data.owner.id !== id) {
        setPopupData([
          { name: t("common.complain"), type: "complain" },
          { name: t("common.delete"), type: "delete" },
        ]);
      } else if (data.owner.id === id) {
        setPopupData([{ name: t("common.delete"), type: "delete" }]);
      } else {
        setPopupData([{ name: t("common.complain"), type: "complain" }]);
      }
    }
  }, [data, id, isCreatedByCurrentUser, language]);

  useEffect(() => {
    if (photos) {
      const galleryPhoto = photos.map((item: any) => {
        return {
          thumbnail: item.previewUrl || video,
          id: item.name,
          url: item.url,
          description: allowedVideoTypes.includes(item.contentType)
            ? "video"
            : "image",
          type: item.contentType,
        };
      });
      setGallery(galleryPhoto);
    }
  }, [photos]);

  const onImageClick = (index: number) => {
    setIsGalleryVisible(true);
    setSelectedImg(index);
  };
  const splitIntoParagraphs = (text: string) => {
    return text.split("\n");
  };
  const onPopupClick = (type: string) => {
    if (type === "delete") {
      setIsShowDeletePopup(true);
    }
    if (type === "complain") {
      setIsShowComplainPopup(true);
    }
    setIsShowInfoPopup(false);
  };
  const onPopupClose = () => {
    setIsShowInfoPopup(false);
  };
  const onDeletePopupClick = (value: string) => {
    switch (value) {
      case "delete":
        deletePost({ profileId: data.profileId, postId: data.id });
        setIsShowDeletePopup(false);
        break;
      default:
        setIsShowDeletePopup(false);
    }
  };
  const onReplyClick: MouseEventHandler<HTMLParagraphElement> = (e) => {
    if (isAuthenticated) {
      setIsOnFocus(true);
    } else {
      e.stopPropagation();
      setIsShowNotAuthenticatedModalWindow(true);
    }
  };
  const onShareClick = () => {
    const isMobile =
      /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
        navigator.userAgent
      );
    const isPWA = "standalone" in window.navigator;
    const profileUrl = window.location.href;
    const url = `${profileUrl}?postId=${data.id}`;
    if (isMobile && isPWA && navigator && navigator.canShare({ text: url })) {
      navigator.share({ text: url });
    } else {
      setIsShowSharePopup(true);
    }
  };
  return (
    <div className={styles.postsItemWrapper} id={data?.id}>
      <div className={styles.postsItemContainer}>
        <div className={styles.postsItemHeaderContainer}>
          <PostsHeader
            date={data?.creationDateTime}
            img={data?.owner?.avatarPreviewUrl}
            username={data?.owner?.username}
          />
          <div className={styles.postsItemHeaderTools}>
            {isAuthenticated && (
              <div
                className={
                  isShowInfoPopup
                    ? styles.iconContainerShown
                    : styles.iconContainerHidden
                }
                onClick={() => setIsShowInfoPopup(!isShowInfoPopup)}
              >
                <InfoIcon />
              </div>
            )}
            <div className={styles.iconContainer} onClick={onShareClick}>
              <ShareIcon />
            </div>
            {isShowInfoPopup && (
              <Popup
                data={popupData}
                onClose={onPopupClose}
                onClick={onPopupClick}
                type="complain"
              ></Popup>
            )}
          </div>
        </div>
        <div className={styles.mainContainer}>
          {!!gallery?.length && (
            <div className={styles.photosContainer}>
              {gallery.map((item: ImageItem, index: number) => (
                <div
                  className={
                    item.description === "video"
                      ? styles.videoContainer
                      : styles.imgContainer
                  }
                  key={item.id || item.thumbnail}
                  onClick={() => onImageClick(index)}
                >
                  <LazyLoadImage src={item.thumbnail} alt=""/>
                </div>
              ))}
            </div>
          )}
          {data?.text && (
            <div className={styles.message}>
              {splitIntoParagraphs(data?.text).map((paragraph, index) => (
                <p className={styles.text} key={index}>
                  {paragraph}
                  <br />
                </p>
              ))}
            </div>
          )}
        </div>
        <div className={styles.actionButtonContainet}>
          <ActionButton
            count={data.likesCount}
            isSelected={data.isLikedByUser}
            postId={data.id}
            type="like"
            isAuthenticated={!!isAuthenticated}
            setIsShowWindow={() => setIsShowNotAuthenticatedModalWindow(true)}
          />
          <ActionButton
            count={data.commentsCount}
            isSelected={data.isCommentedByUser}
            postId={data.id}
            type="post"
            onClick={() => setIsShowComments(true)}
          />
          <p onClick={onReplyClick} className={styles.replyText}>{t("common.reply")}</p>
        </div>
        <div className={styles.commentsBlock}>
          <Comments
            postId={data.id}
            commentsCount={data.commentsCount}
            isFocus={isOnFocus}
            onBlur={() => setIsOnFocus(false)}
          />
        </div>
        <div
          className={
            isOnFocus
              ? styles.addCommentFieldWrapper
              : styles.addCommentFieldHidden
          }
        >
          <AddCommentField
            postId={data.id}
            isFocus={isOnFocus}
            onBlur={() => setIsOnFocus(false)}
            addNewComment={() => dispatch(addNewComment(data.id))}
          />
        </div>
      </div>
      {isShowComments && !!data.commentsCount && (
        <div className={styles.commentsMobileBlock}>
          {!!data.commentsCount && (
            <Comments
              postId={data.id}
              commentsCount={data.commentsCount}
              onBlur={() => setIsOnFocus(false)}
              onClose={() => setIsShowComments(false)}
              isMobile
            />
          )}
        </div>
      )}
      {isGalleryVisible && (
        <ImageViewer
          isShow={isGalleryVisible}
          data={gallery}
          onClose={() => setIsGalleryVisible(false)}
          startIndex={selectedImg}
        />
      )}
      {isShowDeletePopup && (
        <DeletePopup
          onClick={onDeletePopupClick}
          text={t("posts.deletePost")}
        />
      )}
      {isShowComplainPopup && (
        <ComplainPopup
          onClose={() => setIsShowComplainPopup(false)}
          entityType={"Post"}
          entityId={data?.id}
          textContent={data?.text}
        />
      )}
      {isShowSharePopup && (
        <SharePopup onClose={() => setIsShowSharePopup(false)} id={data.id} />
      )}
      {isShowNotAuthenticatedModalWindow && (
        <UnAuthenticatedPopup
          onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
        ></UnAuthenticatedPopup>
      )}
    </div>
  );
};
