import { createSlice } from "@reduxjs/toolkit";
import { MemorialTypes } from "../../constants/Memorial.constant";
import { OrderHistoryPlateType, OrderHistoryType } from "../../types/OrderHistoryType";

interface InitialState {
  checkedTab: string;
  candlesData: OrderHistoryType[];
  platesData: OrderHistoryPlateType[];
  platesPageNumber: number;
  candlesPageNumber: number;
  isOrderHistoryLoading: boolean;
}
const initialState: InitialState = {
  checkedTab: "",
  candlesData: [],
  platesData: [],
  platesPageNumber: 1,
  candlesPageNumber: 1,
  isOrderHistoryLoading: false,
};

export const orderHistorySlice = createSlice({
  name: "orderHistorySlice",
  initialState,
  reducers: {
    setCheckedTab: (state, action) => {
      state.checkedTab = action.payload;
    },
    setOrderHistoryCandlesData: (state, action) => {
      state.candlesData = action.payload;
    },

    setOrderHistoryPlatesData: (state, action) => {
      state.platesData = action.payload;
    },
    setCandlesHistoryPageNumber: (state, action) => {
      state.candlesPageNumber = action.payload;
    },
    setPlatesHistoryPageNumber: (state, action) => {
      state.platesPageNumber = action.payload;
    },
    deleteSubscriptionId: (state, action) => {
      const item = state.candlesData.find((item) => item.id === action.payload);
      if (item) {
        item.subscriptionId = null;
      }
    },
    addRating: (state, action) => {
      const item = state.platesData.find((item) => item.id === action.payload.id);
      if (item) {
        item.rating = action.payload.rating;
      }
    },
    setIsOrderHistoryLoading: (state, action) => {
      state.isOrderHistoryLoading = action.payload;
    },
    resetOrderHistoryData: (state) => {
      state.checkedTab = '';
      state.candlesData = [];
      state.platesData = [];
      state.platesPageNumber = 1;
      state.candlesPageNumber = 1;
    },
  },
});
export const {
  setCheckedTab,
  setOrderHistoryCandlesData,
  setOrderHistoryPlatesData,
  setCandlesHistoryPageNumber,
  setPlatesHistoryPageNumber,
  resetOrderHistoryData,
  setIsOrderHistoryLoading,
  deleteSubscriptionId,
  addRating
} = orderHistorySlice.actions;
export default orderHistorySlice.reducer;
