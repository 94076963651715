import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import Marker from "./marker/Marker";
import "./Map.scss";
import CurrentLocationMarker from "./currentLocationMarker/CurrentLocationMarker";
import currentLocation from "../../shared/image/currentLocation.png";
import { MapCenterType, MarkerType } from "../../types/MapTypes";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";

type MapProps = {
  markers?: {
    burialPlace: {
      countryId?: string;
      cityId?: string;
      longitude: number;
      latitude: number;
    };
    firstName?: string;
    lastName?: string;
    id?: string;
    mainPictureId?: string;
    avatarPreviewUrl?: string;
  }[];
  onMarkerClick?: (value: MarkerType) => void;
  centerPoint?: MapCenterType | null;
  type?: string;
};

const key = process.env.REACT_APP_API_GOOGLE_KEY as string;

const Map = ({ markers, onMarkerClick, centerPoint, type }: MapProps) => {
  const { t } = useTranslation();
  const containerStyle = {
    width: "100%",
    height: "100%",
    minHeight: "320px",
    styles: [
      {
        elementType: "all",
        stylers: [
          {
            pointerEvents: "none",
          },
        ],
      },
    ],
  };
  const [center, setCenter] = useState<MapCenterType>({
    lat: 52.52437,
    lng: 13.41053,
  });

  const [zoom, setZoom] = useState(10);
  const mapRef = useRef<google.maps.Map | undefined>(undefined);
  const currentPositionRef = useRef<MapCenterType | null>(null);
  const [currentPosition, setCurrentPosition] = useState<MapCenterType | null>(
    null
  );
  useEffect(() => {
    if (centerPoint) {
      setCenter(centerPoint);
    }
  }, [centerPoint]);

  useEffect(() => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          const newPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentPosition(() => newPosition);
          currentPositionRef.current = newPosition;
        },
        (error) => console.error(error),
        { enableHighAccuracy: true }
      );
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleGetCurrentLocation = () => {
    if (currentPositionRef.current) {
      setCenter(currentPositionRef.current);
      if (onMarkerClick) {
        onMarkerClick({
          burialPlace: {
            latitude: currentPositionRef.current.lat,
            longitude: currentPositionRef.current.lng,
          },
        });
      }
    } else {
      console.error("Current position is null.");
    }
  };

  const Button = () => {
    return (
      <button
        onClick={handleGetCurrentLocation}
        className="currentLocationButton"
        title={t("common.myLocation")}
        type="button"
      >
        <img src={currentLocation} alt="" />
      </button>
    );
  };

  const onLoad = React.useCallback(
    function callback(map: any) {
      mapRef.current = map;
      if (markers && markers.length && markers[0] && !centerPoint) {
        const bounds = new window.google.maps.LatLngBounds();
        markers?.forEach((marker) => {
          if (marker?.burialPlace?.latitude && marker?.burialPlace?.longitude) {
            bounds.extend({
              lat: parseFloat(marker?.burialPlace?.latitude?.toString()),
              lng: parseFloat(marker?.burialPlace?.longitude?.toString()),
            });
          }
        });

        map.fitBounds(bounds);
      }

      if (
        (!markers ||
          !markers?.length ||
          (!markers[0]?.burialPlace.latitude &&
            !markers[0]?.burialPlace.longitude)) &&
        !centerPoint
      ) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            if (latitude && longitude) {
              setCenter({ lat: latitude, lng: longitude });

              if (onMarkerClick) {
                onMarkerClick({
                  burialPlace: {
                    latitude: latitude,
                    longitude: longitude,
                  },
                });
              }
            }
          },
          (error) => {
            if (mapRef.current) {
              mapRef.current.panTo(
                new google.maps.LatLng(center.lat, center.lng)
              );
            }
          }
        );
      }
      const controlDiv = document.createElement("div");
      const button = <Button />;
      createRoot(controlDiv).render(button);

      map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(controlDiv);
    },
    [markers]
  );

  const onUnmount = React.useCallback(function callback(map: any) {
    mapRef.current = map;
  }, []);
  const { isLoaded } = useJsApiLoader({
    id: `google-map-script`,
    googleMapsApiKey: key,
  });
  const handleZoomChanged = () => {
    const newZoom = mapRef.current?.getZoom() as number;
    setZoom(newZoom);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const clickedLatLng = event.latLng as google.maps.LatLng;
    if (onMarkerClick) {
      onMarkerClick({
        burialPlace: {
          latitude: clickedLatLng.lat(),
          longitude: clickedLatLng.lng(),
        },
      });
    }
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onZoomChanged={handleZoomChanged}
          onClick={handleMapClick}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            gestureHandling: "greedy",
          }}
        >
          {markers?.map((marker, index) => (
            <Marker
              key={index}
              position={{
                lat: marker?.burialPlace?.latitude || 0,
                lng: marker?.burialPlace?.longitude || 0,
              }}
              id={marker?.id}
              title={marker?.lastName + " " + marker?.firstName}
              zoom={zoom}
              mainPictureId={marker?.avatarPreviewUrl}
              withLink={type !== "create"}
            />
          ))}
          {currentPosition && (
            <CurrentLocationMarker
              position={currentPosition}
              key={`${currentPosition.lat}-${currentPosition.lng}`}
            />
          )}
          <div className="ttt"></div>
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
