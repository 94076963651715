import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { ImageItem } from "../../types/CommonTypes";


interface PhotoData {
  thumbnail: string;
  url: string;
  id:string
}
interface InitialState {
  gallery: ImageItem[];
  burialPlaceGallery: ImageItem[];
  avatar:ImageItem | null
}
const initialState: InitialState = {
  gallery: [],
  burialPlaceGallery:[],
  avatar:null
};

const baseUrl = process.env.REACT_APP_MEDIA_DNS_NAME as string;

export const fetchThumbnail = async (mediaId: string) => {
  try {
    const response: AxiosResponse<Blob> = await axios.get(
      `${baseUrl}/api/media/${mediaId}/preview`,
      {
        responseType: "blob",
      }
    );

    return await URL.createObjectURL(response.data as Blob);
  } catch (error) {
    console.error("Error fetching thumbnail:", error);
    throw error;
  }
};

export const fetchFullPhoto = async (mediaId: string) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/media/${mediaId}`,
      {
        responseType: "blob", 
      }
    );

    return await URL.createObjectURL(response.data as Blob);
  } catch (error) {
    console.error("Error fetching full photo:", error);
    throw error;
  }
};
export const loadMedia: AsyncThunk<PhotoData, string, {}> = createAsyncThunk(
  "gallery/loadMedia",
  async (mediaId: string, { dispatch }) => {
    const thumbnail = await fetchThumbnail(mediaId);
    const url = await fetchFullPhoto(mediaId);
    dispatch(setMedia({ thumbnail, url,id:mediaId}));
    return { thumbnail, url,id:mediaId };
  }
);
export const loadBurialPlaceMedia: AsyncThunk<PhotoData, string, {}> = createAsyncThunk(
  "gallery/loadMedia",
  async (mediaId: string, { dispatch }) => {
    const thumbnail = await fetchThumbnail(mediaId);
    const url = await fetchFullPhoto(mediaId);
    dispatch(setBurialPlaceMedia({ thumbnail, url,id:mediaId}));
    return { thumbnail, url,id:mediaId };
  }
);
export const loadAvatar: AsyncThunk<PhotoData, string, {}> = createAsyncThunk(
  "gallery/loadMedia",
  async (mediaId: string, { dispatch }) => {
    const thumbnail = await fetchThumbnail(mediaId);
    const url = await fetchFullPhoto(mediaId);

    dispatch(setAvatar({ thumbnail, url,id:mediaId}));
    return { thumbnail, url,id:mediaId };
  }
);
export const profileGallery = createSlice({
  name: "profileGallery",
  initialState,
  reducers: {
    setMedia: (state, action) => {
      const item = state.gallery.find((item) => item.id === action.payload.id);
      if (item) {
        item.thumbnail = action.payload.thumbnail;
        item.url = action.payload.url;
      }
    },
    setBurialPlaceMedia: (state, action) => {
      const item = state.burialPlaceGallery.find((item) => item.id === action.payload.id);
      if (item) {
        item.thumbnail = action.payload.thumbnail;
        item.url = action.payload.url;
      }
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setGallery: (state, action) => {
      state.gallery = action.payload;
    },
    addToGallery:(state, action) => {
      state.gallery.push(action.payload);
    },
    deleteGallery: (state) => {
      state.gallery = [];
    },
    deletePhoto: (state) => {
      state.gallery = [];
    },
    setBurialPlaceGallery: (state, action) => {
      state.burialPlaceGallery = action.payload;
    },
    deleteBurialPlaceGallery: (state) => {
      state.burialPlaceGallery = [];
    },
 
    deleteAvatar: (state) => {
      state.avatar = null;
    },
  },
});

export const { setMedia,setBurialPlaceMedia, setAvatar,setGallery ,addToGallery,deleteGallery, deletePhoto, deleteAvatar, setBurialPlaceGallery, deleteBurialPlaceGallery} = profileGallery.actions;
export default profileGallery.reducer;
