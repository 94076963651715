import styles from "./WizardQrBlock.module.scss";
import ripplanet from "../../../../../../shared/image/ripplanet.svg"

type WizardQrBlockProps = {
  qr?: string;
  widthAndHeight: string;
  isBlack: boolean;
}

export const WizardQrBlock = ({ qr, widthAndHeight, isBlack }: WizardQrBlockProps) => {

  return (
    <div className={styles.container}>
      <img className={styles.qr}
           src={qr} alt=""
           style={{width: widthAndHeight, height: widthAndHeight, filter: isBlack ? "invert()" : "invert(0)"}}/>
      <img src={ripplanet}
           style={{width: widthAndHeight, filter: isBlack ? "invert()" : "invert(0)"}}
           alt=""/>
    </div>
  );
};
