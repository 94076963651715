import React, {MouseEvent, useState} from 'react';
import  dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from "react-i18next";
import { useGetPlateStatusQuery } from '../../../../api/PlateService';

import styles from "./OrderHistoryListItem.module.scss";
import arrowIcon from "../../../../shared/image/arrowIcon.svg";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { LoadingPage } from '../../../../pages/loadingPage/LoadingPage';

type OrderHistoryListItemProps = {
  data: any;
  classNameType: string;
  handleOpenModal?: () => void;
  isDisabled?: boolean;
};
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const OrderHistoryListItem = ({
   data,
   classNameType,
   handleOpenModal,
   isDisabled,
 }: OrderHistoryListItemProps) => {
  const { t } = useTranslation();
  const { language } = useSelector((state: RootState) => state.language);
  const [checkOrderStatus, setCheckOrderStatus] = useState<boolean>(false)
  const {data: orderStatus, refetch, isFetching} = useGetPlateStatusQuery({orderNo: data.number}, {skip: !checkOrderStatus})
  const shortLanguage = language.split('-')[0];

  const checkStatus = (event: MouseEvent<HTMLDivElement>) => {
    if (data?.status === 'Pending') {
      event.stopPropagation();
      setCheckOrderStatus(true);
    } else {
      event.preventDefault()
    }
  };

  const handleClick = () => {
    if (!isDisabled && handleOpenModal) {
      handleOpenModal();
    }
  };

  const getDateCompletedAt = () => {
    if (data && data.completedAt) {
      const formattedDate = dayjs.utc(data.completedAt).local().locale(`${shortLanguage}`).format('D MMMM ');
      let words = formattedDate.split(' ');
      let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedWords.join(' ');
    }
  };

  return (
    <>
      {isFetching && <LoadingPage type={'status'}/>}
      <li
        className={`${styles.orderContainer} ${classNameType} 
        ${styles.fullContainer} 
        ${isDisabled ? styles.disabled : ""}`}
        onClick={handleClick}
      >
        <div className={styles.textContainer}>
          <div
            onClick={(event) => checkStatus(event)}
            className={styles.checkOrder}>
            {
              data?.status !== 'Pending' && (
                <>
                <span className={styles.status}>
                  {t(`order.status.${data?.status}`)}
                </span>
                  {
                    data?.status === 'Shipped' && data?.completedAt && (
                      <span className={styles.completedAt}>
                  {getDateCompletedAt()}
                </span>
                    )
                  }
                </>
              )
            }
            {
              orderStatus?.status === 'Pending' &&
              <span className={styles.statusPending}>
             {t(`order.status.${data?.status}`)}
            </span>
            }
            {(data?.status === 'Pending' && !orderStatus?.status) && (
              <p>{t('order.checkOrderStatus')}</p>
            )}
            {orderStatus?.status === 'Pending' && <p onClick={refetch}>{t('order.updateOrderStatus')}</p>}

          </div>
          <p>{data.profileFullName}</p>
        </div>
        <div className={`${styles.arrowIcon}`}>
          <img src={arrowIcon} alt=""/>
        </div>
      </li>
    </>
  );
};
