import styles from "./WizardOneTwoDescriptionView.module.scss";
import { QrCodeWizardViewProps } from "../QrCodeWizardView";
import { WizardNameBlock } from "../shared/wizardNameBlock/WizardNameBlock";
import { WizardDateBlock } from "../shared/wizardDateBlock/WizardDateBlock";
import { WizardQrBlock } from "../shared/wizardQrBlock/WizardQrBlock";

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const WizardOneTwoDescriptionView = ({ model, isBlack, qr, blockForPlate, isEdit }: QrCodeWizardViewProps) => {
    const getTemplateUrl = (): string => {
        return !!model?.templateUrl ? url + model.templateUrl : "";
    }

    return (
      <div className={styles.container}
           ref={blockForPlate}>
          <div className={styles.background} style={{backgroundImage: `url(${getTemplateUrl()})`}}></div>
          <WizardNameBlock isBlack={isBlack} isEdit={isEdit}/>
          <div className={styles.marginNameDate}></div>
          <WizardDateBlock isBlack={isBlack} isEdit={isEdit}/>
          <div className={styles.marginDateQr}></div>
          <WizardQrBlock isBlack={isBlack} widthAndHeight={"144px"} qr={qr}/>
      </div>
    );
};
