import { useTranslation } from "react-i18next";
import styles from "./ProfileLocation.module.scss";

import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useGetProfileQuery } from "../../../api/ProfileService";
import { useEffect, useState } from "react";
import { Gallery } from "../gallery/Gallery";
import {
  useDeleteBurialPlacePhotoMutation,
  useGetBurialPlacePhotoQuery,
} from "../../../api/MediaService";
import { ImageItem } from "../../../types/CommonTypes";
import video from "../../../shared/image/video.png";
import { allowedVideoTypes } from "../../../constants/Media.constant";
import { getLanguageByLocale } from "../../../utils/localeUtils";

type PropsType = {
  id: string;
  skip: boolean;
  isCreatedByCurrentUser: boolean;
};

export const ProfileLocation = ({
  id,
  skip,
  isCreatedByCurrentUser,
}: PropsType) => {
  const { language } = useSelector((state: RootState) => state.language);
  const { data, isFetching: isProfileFetching } = useGetProfileQuery(
    { id, lang: getLanguageByLocale(language) },
    { skip }
  );

  const {
    data: photos,
    isFetching,
    isSuccess,
  } = useGetBurialPlacePhotoQuery(id, {
    skip: skip,
    refetchOnMountOrArgChange: true,
  });
  const [deletePhoto] = useDeleteBurialPlacePhotoMutation();
  const [gallery, setGallery] = useState<ImageItem[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (photos) {
      const galleryPhoto = photos.map((item: any) => {
        return {
          thumbnail: item.previewUrl || video,
          id: item.name,
          url: item.url,
          description: allowedVideoTypes.includes(item.contentType)
            ? "video"
            : "image",
          type: item.contentType,
        };
      });
      setGallery(galleryPhoto);
    }
  }, [photos]);

  const onDelete = (photoId: string, filteredGallery: ImageItem[]) => {
    deletePhoto({ profileId: id, mediaName: photoId });
    setGallery(filteredGallery);
  };

  function onShowLocationClick() {
    const lat = data?.burialPlace?.latitude;
    const long = data?.burialPlace?.longitude;

    let mapUrl = `https://maps.google.com/maps?q=${lat},${long}`;
    const userAgent = window.navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);

    if (isIOS ) {
      mapUrl = `maps://maps.google.com/maps?q=${lat},${long}`;
      window.open(mapUrl);
    } else if (/Android/.test(navigator.userAgent)) {
      mapUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`;
      window.open(mapUrl);
    } else {
      window.open(mapUrl);
    }
  }

  return (
    <div className={styles.profileInfoLocation}>
      <div className={styles.locationHeader}>
        <h3>{t("profile.location")}</h3>
        <p className={styles.locationCoords}>{`${
          data?.burialPlace?.latitude?.toString() || 0
        }, ${data?.burialPlace?.longitude?.toString() || 0}`}</p>
      </div>

      <p className={styles.locationAddress}>
        {(!isProfileFetching && data?.burialPlace?.formattedAddress) || ""}
      </p>

      <button
        className={styles.mapButton}
        disabled={!data}
        onClick={onShowLocationClick}
      >
        {t("profile.viewOnMap")}
      </button>
      {!!photos?.length && isSuccess && !isFetching && (
        <Gallery
          gallery={gallery}
          deletePhoto={onDelete}
          isBurialPlaceGallery
          isShowDelete={isCreatedByCurrentUser}
        />
      )}
    </div>
  );
};
