import { useEffect, useLayoutEffect } from "react";
import "./App.css";
import {
  AuthenticatedTemplate,
  MsalProvider,
  useMsal,
} from "@azure/msal-react";
import { Routes, Route } from "react-router-dom";
import {
  AuthError,
  AuthenticationResult,
  EventMessage,
  EventType,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { compareIssuingPolicy } from "./utils/claimUtils";
import { b2cPolicies, loginRequest, protectedResources } from "./authConfig";
import { LandingPage } from "./pages/landing/LandingPage";
import { UserProfile } from "./pages/userProfile/UserProfile";
import { PageLayout } from "./shared/components/layout/PageLayout";
import { HomePage } from "./pages/home/HomePage";
import { ProfilesPage } from "./pages/profilesPage/ProfilesPage";
import { CreateProfile } from "./pages/createProfile/CreateProfile";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "./store/slices/languageSlice";
import i18n from "./i18n";
import { RootState } from "./store";
import { ProfilePage } from "./pages/profilePage/ProfilePage";
import { EditProfilesPage } from "./pages/editProfile/EditProfilesPage";
import { SuccessfulPaymentPage } from "./pages/successfulPayment/SuccessfulPayment";
import ShortUrlRedirect from "./pages/shortUrlRedirect/ShortUrlRedirect";
import { OrderPage } from "./pages/candlesPage/OrderPage";
import { SpinnerPage } from "./pages/spinnerPage/SpinnerPage";
import { QrCodeOrder } from "./components/qrCode/qrCodeOrder/QrCodeOrder";
import { OrderStatusPage } from "./pages/orderStatusPage/OrderStatusPage";
import { getLocaleByLanguage } from "./utils/localeUtils";
import { WarningModal } from "./shared/components/warningModal/WarningModal";
import { InfoPage } from "./pages/infoPage/InfoPage";
import { AuthenticatedPage } from "./pages/AuthenticatedPage";
import { CookiePopup } from "./components/cookieNotification/CookiePopup";

type AppProps = {
  instance: IPublicClientApplication;
};

const SignIn = () => {
  const { instance } = useMsal();
  useEffect(() => {
    loginRequest.extraQueryParameters = {
      ui_locales: localStorage.getItem("lang") || navigator.language,
    };
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    // eslint-disable-next-line
  }, [instance]);
  return <></>;
};

const Pages = () => {
  const { instance } = useMsal();
  const isChangindPassword = localStorage.getItem("isChangindPassword");
  const { language } = useSelector((state: RootState) => state.language);
  const { showSpinner } = useSelector((state: RootState) => state.spinnerSlice);
  const { showWarningModal } = useSelector(
    (state: RootState) => state.warningModalSlice
  );

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      const payload = event.payload as AuthenticationResult;

      const redirectAfterLogout = localStorage.getItem("redirectAfterLogout");

      if (
        redirectAfterLogout === "true" &&
        event.eventType === EventType.HANDLE_REDIRECT_END
      ) {
        const lang = localStorage.getItem("lang");

        localStorage.removeItem("redirectAfterLogout");
        loginRequest.extraQueryParameters = { ui_locales: lang || language };
        instance
          .loginRedirect(loginRequest)
          .catch((error) => console.log(error));
      }

      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        payload.account
      ) {
        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */

        if (
          compareIssuingPolicy(
            payload.idTokenClaims,
            b2cPolicies.names.forgotPassword
          )
        ) {
          loginRequest.extraQueryParameters = { ui_locales: language };
          instance
            .loginRedirect(loginRequest)
            .catch((error) => console.log(error));
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          (event.error as AuthError).errorMessage.includes("AADB2C90118")
        ) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
            extraQueryParameters: {
              ui_locales: localStorage.getItem("lang") || navigator.language,
            },
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
      if (event.eventType === EventType.HANDLE_REDIRECT_END) {
        if (isChangindPassword) {
          localStorage.removeItem("isChangindPassword");
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
            extraQueryParameters: {
              ui_locales: localStorage.getItem("lang") || navigator.language,
            },
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  return (
    <div>
      {showSpinner && <SpinnerPage />}
      {showWarningModal && <WarningModal />}
      <Routes>
        {/* <Route path="/userProfile/:id" element={<ProtectedRoute />}> */}

        <Route element={<PageLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/userProfile" element={<UserProfile />} />
          <Route
            path="/profiles"
            element={<ProfilesPage type="search" skip={true} />}
          />
          <Route
            path="/favoriteProfiles"
            element={<ProfilesPage type="profile" />}
          />
          <Route path="/profile/:id" element={<ProfilePage />} />
          <Route path="/editProfile/:id" element={<EditProfilesPage />} />
          <Route path="/s/:url" element={<ShortUrlRedirect />} />
          <Route path="/candles" element={<OrderPage type="candles" />} />
          <Route path="/qrCodes" element={<QrCodeOrder />} />
          <Route path="/feelings" element={<OrderPage type="feelings" />} />
          <Route
            path="/successfulPayment"
            element={<SuccessfulPaymentPage />}
          />
          <Route path="/createProfile" element={<CreateProfile />} />
          <Route path="/paymentStatus/:orderNo" element={<OrderStatusPage />} />
          <Route path="/paymentStatus" element={<OrderStatusPage />} />
          <Route path="/info" element={<InfoPage />} />
        </Route>

        <Route path="/signIn" element={<SignIn />} />
        <Route path="/login" element={<LandingPage type={"login"} />} />
      </Routes>
    </div>
  );
};

function App({ instance }: AppProps) {
  const dispatch = useDispatch();
  const appHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  useEffect(() => {
    appHeight();
    window.addEventListener("resize", function () {
      setTimeout(appHeight, 100);
    });
  }, []);
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      dispatch(setLanguage(lang));
      i18n.changeLanguage(lang.toLocaleLowerCase());
    } else {
      const userLocale = getLocaleByLanguage(navigator.language);
      localStorage.setItem("lang", userLocale);
      dispatch(setLanguage(userLocale));
      i18n.changeLanguage(userLocale.toLocaleLowerCase());
    }
  }, []);

  return (
    <MsalProvider instance={instance}>
      <div className="App">
        <Pages />
        <AuthenticatedTemplate>
          <AuthenticatedPage />
        </AuthenticatedTemplate>
        <CookiePopup />
      </div>
    </MsalProvider>
  );
}

export default App;
