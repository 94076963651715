import {
  createApi,
  fetchBaseQuery
} from "@reduxjs/toolkit/dist/query/react";
import {
  DeliveryTypeRequest,
  DeliveryTypeResponse,
  QrCodeWizard
} from "../types/PlateTypes";

const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token-order");
  return token;
};

const baseUrl = process.env.REACT_APP_ORDER_DNS_NAME as string;

export const plateApi = createApi({
  reducerPath: "plateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        // include token in req header
        headers.set(`authorization`, `Bearer ${token}`);
        // headers.set('Access-Control-Allow-Origin', 'no-cors')
        return headers;
      }
    },
  }),
  endpoints: (build) => ({
    createPlateOrder: build.mutation({
      // query: (body) => ({
      //     url: `/api/order/plate`,
      //     method: "POST",
      //     body:body
      // }),
      queryFn: async (body: any) => {
        try {
          const response = await fetch(
            `${baseUrl}/api/order/plate`,
            {
              cache: "no-store",
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
                Accept: "*/*",
              },
              method: "POST",
              body: body,
            }
          );

          let responseBody;

          if (response.status === 400) {
            responseBody = await response.json();
          } else {
            responseBody = await response.text();
          }
          return { data: responseBody };
        } catch (e: any) {
          return { error: e.message };
        }
      },
    }),
    getPlateWizard: build.query({
      query: (params: { currency?: string }) => ({
        url: `/api/order/plate/wizard`,
        method: "GET",
        params
      }),
      transformResponse: (response: QrCodeWizard) => {
        return response;
      },
    }),
    getPlateOrder: build.query({
      query: (id) => ({
        url: `/api/order/plate/${id}`,
        method: "GET",
      }),
    }),
    getPlateStatus: build.query({
      query: (data) => ({
        url: `/api/order/plate/status?OrderNo=${data.orderNo}`,
        method: "GET",
      }),
    }),
    addFeedback: build.mutation({
        query: (body) => ({
          url: `/api/order/plate/leave_feedback`,
          method: "POST",
          body: body,
        }),
      }),
    getDeliveryPrice: build.query({
      query: (params: DeliveryTypeRequest) => ({
        url: `/api/order/plate/delivery-price`,
        method: "GET",
        params
      }),
      transformResponse: (response: DeliveryTypeResponse) => {
        return response;
      },
    }),
  }),
});
export const {
  useCreatePlateOrderMutation,
  useGetPlateWizardQuery,
  useGetPlateOrderQuery,
  useGetPlateStatusQuery,
  useAddFeedbackMutation,
  useGetDeliveryPriceQuery
} = plateApi;
