export const MAX_USERNAME_LENGTH = 35;
export const MIN_USERNAME_LENGTH = 3;
export const MAX_MAIN_SEARCH_LENGTH = 256;
export const MAX_MESSAGE_LENGTH = 2048;

export const MAX_SEARCH_LENGTH = 128;
export const MAX_NAME_LENGTH = 64;
export const GENDER_MALE = 1;
export const GENDER_FEMALE = 2;
export const GENDER_OTHER = 3;
export const EMAIL_REGEX =
  /^[\w-\.]{3,30}@([\w-]+\.)+[\w-]{2,4}$/;
export const LIMIT_SCROLL_CITY = 40;
export const MAX_RETRY_REQUESTS = 5;