import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const useUpdateErrors = (update, isDirty, isError) => {

  const { t } = useTranslation();
  useEffect(() => {
    if (isDirty && isError) {
      update();
    }
  }, [t]);
  return {};
};

export default useUpdateErrors;
