import styles from "./WizardNameBlock.module.scss";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { RootState } from "../../../../../../store";
import { FocusEvent } from 'react';
import { setPlateChangesName } from "../../../../../../store/slices/OrderSlice";
import { ContentEditableDiv } from "../contentEditableDiv/ContentEditableDiv";

type WizardNameBlock = {
    isEdit: boolean;
    isBlack: boolean;
}

export const WizardNameBlock = ({isEdit, isBlack}: WizardNameBlock) => {
    const dispatch = useDispatch();

    const { plate } = useSelector((state: RootState) => state.orderSlice);

    const getClass = (): string => {
        let result = styles.container;
        if (isEdit) {
            result += ` ${styles.isEdit}`;
        }
        return result;
    }

    const onBlur = (event: FocusEvent<HTMLDivElement>): void => {
        dispatch(setPlateChangesName({
            html: (event.target as HTMLDivElement).innerHTML,
            text: (event.target as HTMLDivElement).innerText
        }));
    }

    return (
        <div className={getClass()} style={{color: isBlack ? "#ffffff" : "#000000"}}>
            <ContentEditableDiv isEdit={isEdit} htmlValue={plate.changes.name.html} onBlur={onBlur} maxLength={256}/>
        </div>
    );
};
