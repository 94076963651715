import styles from "./Input.module.scss";
import {
  UseFormRegister,
  FieldValues,
  UseFormSetValue, UseFormClearErrors,
} from "react-hook-form";
import icon from "../../image/cleanUp.svg";

type InputProps = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  classType?: string;
  isBold?: boolean;
  error?: string;
  autocomplete?: string;
  img?: JSX.Element;
  required?: boolean;
  maxLength?: number;
  clearErrors?: UseFormClearErrors<{ tel?: string | undefined; }>;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const Input = ({
  name,
  type,
  label,
  placeholder,
  classType,
  error,
  register,
  setValue,
  autocomplete,
  isBold,
  required,
  maxLength,
  img,
  clearErrors,
  handleKeyDown,
}: InputProps) => {
  const onMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setValue(name, "");
    if(clearErrors) {
      clearErrors()
    }
  };
  return (
    <div className={styles.inputContainer}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={styles.inputWithCleanUp}>
        {img && <div className={error ? styles.errorIconContainer : styles.iconContainer }>{img}</div>}
        <input
          className={`${isBold ? styles.boldInput : styles.input} ${classType ? styles.groupItem : ""} ${img && styles.inputWithIcon} ${
            error ? styles.error : error
          }`}
          type={type}
          id={name}
          placeholder={placeholder}
          {...register(name, {required})}
          autoComplete={autocomplete}
          maxLength={maxLength}
          onKeyDown={handleKeyDown}
        />
        <span className={styles.icon} onMouseDown={onMouseDown}>
          <img src={icon} alt="" />
        </span>
      </div>

      {error && <p className={styles.errorMessage}> {error}</p>}
    </div>
  );
};
