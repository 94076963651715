import styles from "./ComplainListItem.module.scss";
import arrowIcon from "../../image/arrowIcon.svg";

type SettingListItemProps = {
  text: string;
  onClick?: () => void;
};

export const ComplainListItem = ({
  text,
  onClick,
}: SettingListItemProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <li
      className={`
        ${styles.listItem} 
      `}
      onClick={handleClick}
    >
      <div className={styles.textContainer}>
          <p>
            {text.trim()}
          </p>
      </div>
      <div>
        <img src={arrowIcon} alt="" />
      </div>
    </li>
  );
};
