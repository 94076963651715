import {
  Fragment,
  UIEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm, FieldValues } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Input } from "../../../shared/components/input/Input";
import styles from "./MainInfoTab.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../../api/ProfileService";
import { DateDropdown } from "../../../shared/components/dateDropdown/DateDropdown";
import { Checkbox } from "../../../shared/components/checkbox/Checkbox";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  useGetCityByIdQuery,
  useGetCoordinateInformationQuery,
  useGetCountryByIdQuery,
  useGetReligionsQuery,
  useSearchCitiesQuery,
  useSearchCountriesQuery,
  useSearchRegionsQuery,
  useGetRegionByIdQuery,
} from "../../../api/UtilityService";
import { ReactComponent as ArrowIcon } from "../../../shared/image/arrowIcon.svg";
import { SearchInput } from "../../../shared/components/search/SearchInput";
import Map from "../../map/Map";
import ImageDrop from "../../dropArea/DropArea";
import { ImageViewer } from "../../imageViewer/ImageViewer";
import { setBurialPlaceGallery } from "../../../store/slices/ProfileGallery";
import {
  CommonType,
  CountryType,
  DateType,
  ErrorImages,
  ImageItem,
  LocationDataType,
} from "../../../types/CommonTypes";
import { MapCenterType, MarkerType } from "../../../types/MapTypes";
import {
  useDeleteBurialPlacePhotoMutation,
  useEditPhotoMutation,
  useGetBurialPlacePhotoQuery,
  usePostBurialPlacePhotoMutation,
} from "../../../api/MediaService";
import { FileError } from "../../../shared/components/fileError/FileError";
import { setShowSpinner } from "../../../store/slices/SpinnerSlice";
import video from "../../../shared/image/video.png";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import useUpdateErrors from "../../../utils/useUpdateErrors";
import PhotoForGallery from "../../../shared/components/photoForGallery/PhotoForGallery";
import { ListType, Religions } from "../../religionBlock/Religions";
import {
  GENDER_FEMALE,
  GENDER_MALE,
  LIMIT_SCROLL_CITY,
  MAX_NAME_LENGTH,
} from "../../../constants/constants";
import { handleSpecificCharsKeyDown } from "../../../utils/handleSpecificCharsKeyDown";
import { getLanguageByLocale } from "../../../utils/localeUtils";
import { allowedVideoTypes } from "../../../constants/Media.constant";
import { setShowWarningModal } from "../../../store/slices/WarningModalSlice";
import { PopupLocation } from "../../../pages/createProfile/createProfileSteps/PopupCountries/PopupLocation";
import { updatePhotoHash } from "../../../store/slices/ProfileSlice";

type PropsType = {
  id: string;
};

dayjs.extend(utc);

export const MainInfoTab = ({ id }: PropsType) => {
  const dispatch = useDispatch<AppDispatch>();
  const { skipMedia } = useFetchWithMsal("media", true);
  const { language } = useSelector((state: RootState) => state.language);

  const { data } = useGetProfileQuery({id, lang:getLanguageByLocale(language)});
  const [
    updateProfile,
    { isLoading, isError: isErrorUpdateUser, error: errorUpdateUser,isSuccess},
  ] = useUpdateProfileMutation();
  const { data: religions } = useGetReligionsQuery(
    getLanguageByLocale(language)
  );
  const [editPhoto, {isLoading:isLoadingPhoto}] = useEditPhotoMutation();
  const [isOpenEngName, setIsOpenEngName] = useState(false);
  const [isOpenReligion, setIsOpenReligion] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { t } = useTranslation();
  const [errorDateOfDeath, setErrorDateOfDeath] = useState("");
  const [errorDateOfBirth, setErrorDateOfBirth] = useState("");
  const [isUnknownDateOfDeath, setIsUnknownDateOfDeath] = useState(true);
  const [isUnknownDateOfBirth, setIsUnknownDateOfBirth] = useState(true);
  const [selectedReligion, setSelectedReligion] = useState<string>(
    data?.religionId ? data?.religionId : ""
  );
  const errorMessage = t("createProfile.dateValidation");

  const schema = yup.object().shape({
    firstName: yup.string().trim().required(t("common.requiredField")),
    lastName: yup.string().trim().required(t("common.requiredField")),
    middleName: yup.string().trim(),
    deathReason: yup.string().trim(),
    inEnglish: yup.object({
      firstName: yup
        .string()
        .trim()
        .required(t("common.requiredField"))
        .matches(/^[a-zA-Z0-9\s'.,()-]+$/, t("createProfile.nameValidation")),

      lastName: yup
        .string()
        .trim()
        .required(t("common.requiredField"))
        .matches(/^[a-zA-Z0-9\s'.,()-]+$/, t("createProfile.nameValidation")),
      middleName: yup
        .string()
        .trim()
        .test({
          test: (value) => !value || /^[a-zA-Z0-9\s'.,()-]+$/.test(value),
          message: t("createProfile.nameValidation"),
        }),
    }),
  }) as yup.ObjectSchema<FieldValues>;

  const resolver = yupResolver(schema);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isValid },

    watch,
    reset,
  } = useForm<FieldValues>({
    resolver,
    defaultValues: {
      id: data?.id,
      firstName: data?.firstName,
      lastName: data?.firstName,
      gender: data?.gender,
    },
  });

  const watchFields = watch();

  useEffect(() => {
    if (
      isErrorUpdateUser &&
      errorUpdateUser &&
      "status" in errorUpdateUser &&
      errorUpdateUser.status === 406
    ) {
      dispatch(setShowSpinner(false));
      dispatch(setShowWarningModal(true));
    }
  }, [isErrorUpdateUser]);

  useEffect(() => {
    if (isSuccess && !isPostBurialPlacePhotoLoading) {
      dispatch(setShowSpinner(false));

    }
  }, [isSuccess]);
  useEffect(() => {
    reset(data);
    if (data?.religionId) {
      setSelectedReligion(data?.religionId);
    }
  }, [data]);

  useEffect(() => {
    if (errors.inEnglish) {
      setIsOpenEngName(true);
    }
  }, [errors.inEnglish]);

  const [birthValue, setBirthValue] = useState<DateType>({
    year: -1,
    month: -1,
    day: 0,
  });
  const [deathValue, setDeathValue] = useState<DateType>({
    year: -1,
    month: -1,
    day: 0,
  });

  useEffect(() => {
    if (data?.dateOfBirth) {
      setBirthValue({
        day:  dayjs.utc(data.dateOfBirth, "YYYY-MM-DD").date(),
        month:  dayjs.utc(data.dateOfBirth, "YYYY-MM-DD").month() + 1,
        year:  dayjs.utc(data.dateOfBirth, "YYYY-MM-DD").year(),
      });
      setIsUnknownDateOfBirth(false);
    }
    if (data?.dateOfDeath) {
      setDeathValue({
        day:  dayjs.utc(data.dateOfDeath, "YYYY-MM-DD").date(),
        month:  dayjs.utc(data.dateOfDeath, "YYYY-MM-DD").month() + 1,
        year:  dayjs.utc(data.dateOfDeath, "YYYY-MM-DD").year(),
      });
      setIsUnknownDateOfDeath(false);
    }
  }, [data?.dateOfBirth, data?.dateOfDeath]);

  const validateDateOfDeath = () => {
    const birth = birthValue as DateType;
    const death = deathValue as DateType;

    if (isUnknownDateOfDeath && isUnknownDateOfBirth) {
      setErrorDateOfBirth("");
      setErrorDateOfDeath("");
      return true;
    }

    const emptyBirthDate = Object.values(birth).some((item) => item === -1);
    const emptyDeathDate = Object.values(death).some((item) => item === -1);

    const isDateBirthValid =
      (emptyBirthDate && isUnknownDateOfBirth) ||
      (!emptyBirthDate && !isUnknownDateOfBirth);
    const isDateDeathValid =
      (emptyDeathDate && isUnknownDateOfDeath) ||
      (!emptyDeathDate && !isUnknownDateOfDeath);

    if (!isDateBirthValid) {
      setErrorDateOfBirth(errorMessage);
    }
    if (!isDateDeathValid) {
      setErrorDateOfDeath(errorMessage);
    }
    const dateOfBirthObj = new Date(birth.year, birth.month - 1, birth.day);
    dateOfBirthObj.setFullYear(birth.year);

    const dateOfDeathObj = new Date(death.year, death.month - 1, death.day);
    dateOfDeathObj.setFullYear(death.year);

    const isCorrectDeathDate =
      (dateOfDeathObj >= dateOfBirthObj && dateOfDeathObj <= new Date()) ||
      (isUnknownDateOfBirth && dateOfDeathObj <= new Date()) ||
      isUnknownDateOfDeath;

    const isCorrectBirthDate =
      isUnknownDateOfBirth || dateOfBirthObj <= new Date();

    if (!isCorrectDeathDate) {
      setErrorDateOfBirth(errorMessage);
      setErrorDateOfDeath(errorMessage);
    }

    if (!isCorrectDeathDate) {
      setErrorDateOfBirth(errorMessage);
      setErrorDateOfDeath(errorMessage);
    }
    if (!isCorrectBirthDate) {
      setErrorDateOfBirth(errorMessage);
    }
    const isCorrectDate =
      isCorrectDeathDate &&
      isDateBirthValid &&
      isDateDeathValid &&
      isCorrectBirthDate;

    if (isCorrectDate) {
      setErrorDateOfBirth("");
      setErrorDateOfDeath("");
    }
    return isCorrectDate;
  };

  const onDateChange = useCallback((date: DateType, type: string) => {
    setIsDirty(true);
    if (type === "birth") {
      setBirthValue(date);

      if (date.day > 0 || date.month > 0 || date.year > 0) {
        setIsUnknownDateOfBirth(false);
      }
    } else {
      setDeathValue(date);
      if (date.day > 0 || date.month > 0 || date.year > 0) {
        setIsUnknownDateOfDeath(false);
      }
    }
  }, []);

  const onCheckboxChange = useCallback((value: boolean, type: string) => {
    setIsDirty(true);
    if (type === "birth") {
      if (value) {
        setBirthValue({
          year: -1,
          month: -1,
          day: 0,
        });
      }
      setIsUnknownDateOfBirth(value);
    } else {
      if (value) {
        setDeathValue({
          year: -1,
          month: -1,
          day: 0,
        });
      }
      setIsUnknownDateOfDeath(value);
    }
  }, []);

  const onReligionClick = (selectedItem: ListType) => {
    if (selectedItem.id === selectedReligion) {
      setSelectedReligion("");
      setValue("religionId", "");
    } else {
      setSelectedReligion(selectedItem.id);
      setValue("religionId", selectedItem.id);
    }
    setIsDirty(true);
  };

  const checkGender = (gender: number) => {
    return (Number(watchFields.gender) || data?.gender) === gender;
  };

  const [city, setCity] = useState<CommonType | null>(null);
  const [country, setCountry] = useState<CommonType | null>(null);
  const [region, setRegion] = useState<CommonType | null>(null);

  const [coords, setCoords] = useState("");
  const [marker, setMarker] = useState<MarkerType[]>([]);
  const [center, setCenter] = useState<MapCenterType>({ lat: 52.52437, lng: 13.41053 });
  const [popupData, setPopupData] = useState<LocationDataType[]>([]);
  const [isUnknown, setIsUnknown] = useState(false);
  const [showPopup, setShowPopup] = useState("");
  const [isInvalidCoords, setIsInvalidCoords] = useState(false);
  const [isDirtyCoords, setIsDirtyCoords] = useState(false);

  const [countryInfo, setCountryInfo] = useState<null | {
    isoCode: string;
    countryId: string;
  }>(null);

  const [regionId, setRegionId] = useState("");

  const [errorProps, setErrorProps] = useState<ErrorImages>({
    showVideoError: false,
    showImageError: false,
    showFormatError: false,
    filesName: [],
  });
  const [activeSearchType, setActiveSearchType] = useState("");
  const [skipCoords, setSkipCoords] = useState(true);
  const [images, setImages] = useState<ImageItem[]>([]);
  const { data: photos, refetch } = useGetBurialPlacePhotoQuery(id,{refetchOnMountOrArgChange: true,});
  const [deletedPhotoIds, setDeletedPhotoIds] = useState<string[]>([]);
  const [size, setSize] = useState(0);
  const [selectedImg, setSelectedImg] = useState<number>(0);
  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState(false);
  const [popupCountryData, setPopupCountryData] = useState<
    LocationDataType[] | null
  >(null);
  const [offset, setOffset] = useState<number>(0);
  const isLoadingScroll = useRef<boolean>(false);

  const [
    postBurialPlacePhoto,
    {
      isLoading: isPostBurialPlacePhotoLoading,
      isSuccess: isSiccessBurialPlacePhoto,
    },
  ] = usePostBurialPlacePhotoMutation();
  const [deleteBurialPlacePhoto] = useDeleteBurialPlacePhotoMutation();

  const { data: cityName } = useGetCityByIdQuery(
    {
      cityId: city?.id,
      lang: getLanguageByLocale(language),
    },
    { skip: !city?.id }
  );
  
  const { data: regionName } = useGetRegionByIdQuery(
    {
      regionId: region?.id,
      lang: getLanguageByLocale(language),
    },
    { skip: !region?.id }
  );

  const { data: countryName } = useGetCountryByIdQuery(
    {
      countryId: country?.id,
      lang: getLanguageByLocale(language),
    },
    {
      skip: !country?.id,
    }
  );
  const { data: searchCountriesData, isSuccess: isCountrySuccess } =
    useSearchCountriesQuery(
      { lang: getLanguageByLocale(language), search: country?.name || "" },
      { skip: activeSearchType !== "country" }
    );
  const { data: searchRegionsData,isSuccess: isRegionsSuccess,isFetching: isRegionsLoading } =
    useSearchRegionsQuery(
      {
        lang: getLanguageByLocale(language),
        countryId: countryInfo?.isoCode,
        search: region?.name || "",
        start: offset,
      },
      {
        skip: !isSearch || activeSearchType !== "region",
      }
    );
  const { data: searchCityData, isSuccess:isCitiesSuccess,isFetching: isCitiesLoading } =
    useSearchCitiesQuery(
      {
        lang: getLanguageByLocale(language),
        search: city?.name || "",
        countryId: countryInfo?.isoCode,
        region: regionId,
        start: offset,
      },
      {
        skip: !isSearch || activeSearchType !== "city",
      }
    );
  const {
    data: coordsData,
    isSuccess: isCoordValid,
    isError,
  } = useGetCoordinateInformationQuery(
    { lang: getLanguageByLocale(language), coords: coords },
    {
      skip: skipCoords,
    }
  );

  useEffect(() => {
    if (photos && !isPostBurialPlacePhotoLoading) {
      let totalSize = 0;
      const galleryPhoto = photos.map((item: any) => {
        totalSize += item.size;
        return {
          thumbnail: item.previewUrl || video,
          id: item.name,
          url: item.url,
          description: allowedVideoTypes.includes(item.contentType)
            ? "video"
            : "image",
          size: item.size,
          type: item.contentType,
        };
      });
      dispatch(setBurialPlaceGallery(galleryPhoto));
      setImages(galleryPhoto);
      setSize(totalSize);
    }
  }, [photos]);

  useEffect(() => {
    if (countryName) {
      setCountry({ id: countryName.id, name: countryName.country });
      setCountryInfo({
        isoCode: countryName.isoCode,
        countryId: countryName.id,
      });
    }
  }, [countryName]);

  useEffect(() => {
    if (cityName) {
      if (cityName.region?.id && region?.id !== cityName.region.id) {
        setRegion({ id: cityName.region.id, name: cityName.region.name });
      }

      setCity({
        id: city?.id || data?.burialPlace?.cityId,
        name: cityName.name,
      });
    }
  }, [cityName]);

  useEffect(() => {
    if (regionName) {
      setRegion({ id: region?.id || "", name: regionName.name });
    }
  }, [regionName]);

  useEffect(() => {
    if (showPopup === "city") {

      if (searchCityData?.results?.length && !isCitiesLoading && isCitiesSuccess  && isSearch) {
        
        if (offset) {
          setPopupData([
            ...(popupData as LocationDataType[]),
            ...searchCityData.results,
          ]);
        } else {
          setPopupData([...searchCityData.results]);
        }
        isLoadingScroll.current = false;
        setIsSearch(false);
      }
    }
  }, [isCitiesSuccess,isCitiesLoading, offset, isSearch]);

  useEffect(() => {
    if (showPopup === "region") {
      if (searchRegionsData?.results?.length && !isRegionsLoading && isRegionsSuccess && isSearch) {
        
        if (offset) {
          setPopupData([
            ...(popupData as LocationDataType[]),
            ...searchRegionsData.results,
          ]);
        } else {
          setPopupData([...searchRegionsData.results]);
        }

        setIsSearch(false);
        isLoadingScroll.current = false;
      }
    }
  }, [isRegionsSuccess,isRegionsLoading, offset,isSearch]);

  useEffect(() => {
    if (isCountrySuccess && searchCountriesData?.length && isSearch) {
      const popupData = searchCountriesData.map((item: CountryType) => {
        return {
          name: item.country,
          type: item.id,
          isoCode: item.isoCode,
          id: item.id,
          code: item.code,
          country: item.country,
        };
      });
      setPopupCountryData(popupData);
      setShowPopup("country");
      setIsSearch(false);
    }
  }, [searchCountriesData]);

  useEffect(() => {
    if (isError) {
      setIsInvalidCoords(true);
      setSkipCoords(true);
    }
  }, [isError]);

  useEffect(() => {
    if (isCoordValid && coordsData) {
      deleteSearchState();
      setIsUnknown(false)
      setMarker([
        {
          burialPlace: {
            longitude: coordsData.longitude,
            latitude: coordsData.latitude,
            cityId: coordsData.coordinatesInfo?.cityId,
            countryId: coordsData.coordinatesInfo?.countryId,
          },
        },
      ]);
      setCenter({ lat: coordsData.latitude, lng: coordsData.longitude });
      if (coordsData.coordinatesInfo?.cityName) {
        setCity({
          id: coordsData.coordinatesInfo.cityId,
          name: coordsData.coordinatesInfo.cityName,
        });
      }
      if (coordsData.coordinatesInfo?.countryName) {
        setCountry({
          id: coordsData.coordinatesInfo.countryId,
          name: coordsData.coordinatesInfo.countryName,
        });
      }
      if (coordsData.coordinatesInfo?.regionName) {
        setRegion({
          id: coordsData.coordinatesInfo.regionId,
          name: coordsData.coordinatesInfo.regionName,
        });
      }
      if (coordsData.coordinatesInfo?.regionCode) {
        setRegionId(coordsData.coordinatesInfo.regionCode);
      }
      if (coordsData.coordinatesInfo?.countryId) {
        setCountryInfo({
          countryId: coordsData.coordinatesInfo.countryId,
          isoCode: coordsData.coordinatesInfo.countryIsoCode,
        });
      }
    }

    setIsInvalidCoords(false);

    setSkipCoords(true);
  }, [coordsData]);

  useEffect(() => {
    if (data) {
      if (data?.burialPlace?.latitude && data?.burialPlace?.longitude) {
        setCoords(
          `${data?.burialPlace?.latitude}, ${data?.burialPlace?.longitude}`
        );
        setMarker([data]);
        setCenter({
          lat: data?.burialPlace?.latitude,
          lng: data?.burialPlace?.longitude,
        });
      } else if (!data?.burialPlace.countryId) {
        setIsUnknown(true);
      }
      if (data?.burialPlace?.countryId && country?.id !== data?.burialPlace?.countryId ) {
        setCountry({ id: data?.burialPlace?.countryId, name: "" });
      }
      if (data?.burialPlace?.cityId && city?.id !== data?.burialPlace?.cityId ) {
        setCity({ id: data?.burialPlace?.cityId, name: "" });
      }
    }
  }, [data]);

  useEffect(() => {
    if (isSiccessBurialPlacePhoto) {
      dispatch(setShowSpinner(false));
      refetch();
    }
  }, [isSiccessBurialPlacePhoto]);

  const deleteSearchState = () => {
    setCity(null);
    setCountry(null);
    setRegion(null);
    setRegionId("");
    setCountryInfo(null);
    setIsUnknown(true);

  };
  const onCheckboxClick = () => {
    setIsUnknown(!isUnknown);
    setCoords("");
    deleteSearchState();
    setMarker([]);
    setIsDirty(true);
  };
  const onCityChange = (value: string) => {
    setActiveSearchType("city");
    setCity({ id: "", name: value });
    setCoords("");
    setMarker([]);
    setOffset(0);
    setIsSearch(true);
  };
  const onCountryChange = (value: string) => {
    setActiveSearchType("country");
    setCountry({ id: "", name: value });
    setCountryInfo(null);
    setRegionId("");
    setRegion(null);
    setCity(null);
    setCoords("");
    setMarker([]);
    setIsSearch(true);
    setOffset(0);
  };
  const onRegionChange = (value: string) => {
    setOffset(0);
    setActiveSearchType("region");
    setRegion({ id: "", name: value });
    setRegionId("");
    setCity(null);
    setCoords("");
    setMarker([]);
    setIsSearch(true);
  };
  const onPopupCityClick = (city: LocationDataType) => {
    if (city) {
      setActiveSearchType("");
      setCoords(`${city?.latitude}, ${city?.longitude}`);
      setIsUnknown(false);
      setMarker([
        {
          burialPlace: {
            longitude: city.longitude,
            latitude: city.latitude,
            cityId: city.id,
            countryId: countryInfo?.countryId,
          },
        },
      ]);
      setIsDirty(true);
      setCity({ id: city.id, name: city.name });
      setRegion({ id: city.region?.id, name: city.region?.name || "" });
      setCenter({ lat: city?.latitude, lng: city?.longitude });
      setShowPopup("");
      setOffset(0);
      setPopupData([]);
    }
  };
  const onPopupCountryClick = (item: LocationDataType) => {
    if (item) {
      setActiveSearchType("");
      setIsUnknown(false);
      setIsDirty(true);
      setCountry({ id: item.id, name: item.country as string });
      setCountryInfo({
        isoCode: item.isoCode as string,
        countryId: item.id as string,
      });
      setShowPopup("");
      setPopupCountryData([]);
    }
  };
  const onPopupRegionClick = (region: LocationDataType) => {
    if (region) {
      setActiveSearchType("");
      setIsUnknown(false);

      setIsDirty(true);
      setRegion({ id: region.id, name: region.name });
      setRegionId(region.adminFirstCode);
      setShowPopup("");
      setOffset(0);
      setPopupData([]);
    }
  };
  const onPopupClose = () => {
    if (activeSearchType === 'country') {
      setIsUnknown(true)
    }
    setActiveSearchType("");
    setPopupData([]);
    setShowPopup("");
    setOffset(0);
  };

  const onMarkerClick = (value: MarkerType) => {

    setMarker([value]);
    setIsDirty(true);
    setCoords( `${value?.burialPlace.latitude}, ${value?.burialPlace.longitude}`);
    setSkipCoords(false);
    setIsUnknown(false);
  }
  const searchByCoords = () => {
    if (isDirtyCoords) {
      deleteSearchState();
      setIsUnknown(false);
      setSkipCoords(false);
      setIsDirtyCoords(false);
      setIsDirty(true);
    }
  };
  const onDelete = (value: number) => {
    setIsDirty(true);
    const filteredGallery = images.filter((item, index) => index !== value);

    setSize(() => size - (images[value]?.size as number));
    if (images[value]?.id) {
      setDeletedPhotoIds((prevState) => [
        ...prevState,
        images[value].id as string,
      ]);
    }
    setImages(filteredGallery);
    if (!filteredGallery[value]) {
      if (filteredGallery.length === 0) {
        setIsGalleryVisible(false);
      } else {
        setSelectedImg(value - 1);
      }
    } else {
      setSelectedImg(value);
    }
  };
  const onRotate = (value: number, img: File) => {
    setIsDirty(true)
    const newImages = images.map((item, index) => {
      if (index === value) {
        const newItem = {
          ...item,
          original: img,
          thumbnail: URL.createObjectURL(img as Blob),
          isEdited: item.url ? true : undefined,
        };
        return newItem;
      }
      return item;
    });
  
    setImages(newImages);
  };
  const onImageClick = (index: number) => {
    setIsGalleryVisible(true);
    setSelectedImg(index);
  };

  const onSearchBlur = (type: string) => {
    switch (type) {
      case "country":
        if (!countryInfo?.countryId) {
          setCountry(null);
        }
        break;
      case "region":
        if (!regionId) {
          setRegion(null);
        }
        break;
      case "city":
        if (!coords) {
          setCity(null);
        }
        break;
      default:
        break;
    }
  };
  const setToGallery = (value: ImageItem[], errorValue: ErrorImages) => {
    setErrorProps(errorValue);
    let totalSize = size;
    const remainingSlots = 3 - images.length;
    const filesToAdd = value.slice(0, remainingSlots);

    if (filesToAdd) {
      filesToAdd.forEach((item) => {
        item.isNew = true;
        totalSize += item.original.size;
      });
      if (totalSize <= 200 * 1024 * 1024) {
        setImages([...filesToAdd.reverse(), ...images]);
        setSize(totalSize);
        setIsDirty(true);
      }
    }
  };

  const fieldInFocus = (type: string) => {
    setActiveSearchType(type);
    setIsSearch(true);
    setShowPopup(type);
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (showPopup === "region" || showPopup === "city") {
      const divComponent = e.target as HTMLDivElement;
      if (
        divComponent.offsetHeight + divComponent.scrollTop >=
        divComponent.scrollHeight - 100
      ) {
        if (
          (showPopup === "region" ? !isRegionsLoading : !isCitiesLoading) &&
          (showPopup === "region"
            ? searchRegionsData?.hasNext
            : searchCityData?.hasNext) &&
          !isLoadingScroll.current
        ) {
          isLoadingScroll.current = true;
          setOffset(offset + LIMIT_SCROLL_CITY);
          setIsSearch(true);
        }
      }
    }
  };

  const onSubmit = async (data: any) => {
    if (!validateDateOfDeath()) {
      return;
    }
    dispatch(setShowSpinner(true));
    setIsDirty(false);

    const birth = birthValue as DateType;
    const death = deathValue as DateType;
    let birthDate;
    if (birth.day) {
      birthDate = `${
        birth.year < 100
          ? "00" + birth.year
          : birth.year < 1000
          ? "0" + birth.year
          : birth.year
      }-${birth.month}-${birth.day}`;
    }

    let deathDate;
    if (death.day) {
      deathDate = `${
        death.year < 100
          ? "00" + death.year
          : death.year < 1000
          ? "0" + death.year
          : death.year
      }-${death.month}-${death.day}`;
    }
    data.dateOfBirth = birthDate;
    data.dateOfDeath = deathDate;
    data.burialPlace =
      marker[0]?.burialPlace ||
      (countryInfo?.countryId
        ? {
            countryId: countryInfo?.countryId,
          }
        : {
            longitude: null,
            latitude: null,
            cityId: null,
            countryId: null,
          });
    data.countryId = countryInfo?.countryId;
    data.firstName = data.firstName
      ? data.firstName.replace(/\s+/g, " ").trim()
      : "";
    data.lastName = data.lastName
      ? data.lastName.replace(/\s+/g, " ").trim()
      : "";
    data.middleName = data.middleName
      ? data.middleName.replace(/\s+/g, " ").trim()
      : "";
    data.deathReason = data.deathReason
      ? data.deathReason.replace(/\s+/g, " ").trim()
      : "";
    data.inEnglish.firstName = data.inEnglish?.firstName
      ? data.inEnglish.firstName.trim()
      : "";
    data.inEnglish.lastName = data.inEnglish?.lastName
      ? data.inEnglish.lastName.trim()
      : "";
    data.inEnglish.middleName = data.inEnglish?.middleName
      ? data.inEnglish.middleName.trim()
      : "";
    data.gender = Number(data.gender);
    data.id = id;
    data.religionId = selectedReligion;

    updateProfile({ body: data, profileId: id , lang: getLanguageByLocale(language)});
    const addedPhoto = images.filter((item) => item.isNew);
    if (addedPhoto.length || deletedPhotoIds.length) {
      if (addedPhoto.length && !skipMedia) {
        const formData = new FormData();

        addedPhoto.reverse().forEach((imageItem: ImageItem) => {
          formData.append(`files`, imageItem.original as File);
        });
        formData.append("ProfileId", id);
        postBurialPlacePhoto(formData);
      }
      if (deletedPhotoIds.length) {
        deletedPhotoIds.forEach((mediaName: string) => {
          deleteBurialPlacePhoto({ profileId: id, mediaName });
        });
        setDeletedPhotoIds([]);
      }
      dispatch(updatePhotoHash());
    }
    const editedPhoto = images.filter((item) => item.isEdited);
    if(editedPhoto.length){
      const baseUrl = process.env.REACT_APP_BLOB_DNS_NAME as string;
      editedPhoto.forEach(item => {
        const formImgData = new FormData();

        formImgData.append("file", item.original as File);
        editPhoto({url:item.url?.split(baseUrl)[1], body: formImgData})})
        dispatch(updatePhotoHash());
    }
    reset({ dirtyFields: false });
  };
  useUpdateErrors(
    handleSubmit(onSubmit),
    !!Object.keys(dirtyFields).length || isDirty,
    !isValid
  );

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formContainer}>
          <h3 className={styles.formHeader}>
            {t("createProfile.personalInformation")}
          </h3>
          <Input
            register={register}
            setValue={setValue}
            name="lastName"
            type="text"
            placeholder={`${t("common.lastName")}*`}
            error={errors.lastName?.message as string}
            maxLength={MAX_NAME_LENGTH}
            handleKeyDown={handleSpecificCharsKeyDown}
          />
          <Input
            setValue={setValue}
            register={register}
            name="firstName"
            type="text"
            placeholder={`${t("createProfile.firstName")}*`}
            error={errors.firstName?.message as string}
            maxLength={MAX_NAME_LENGTH}
            handleKeyDown={handleSpecificCharsKeyDown}
          />

          <Input
            register={register}
            setValue={setValue}
            name="middleName"
            type="text"
            placeholder={`${t("common.middleName")}`}
            maxLength={MAX_NAME_LENGTH}
            handleKeyDown={handleSpecificCharsKeyDown}
          />
          <h3 className={styles.formHeader}>{t("createProfile.gender")}</h3>
          <div className={styles.groupContainer}>
            <label
              className={
                checkGender(GENDER_MALE)
                  ? styles.radioButtonLabelSelected
                  : styles.radioButtonLabel
              }
            >
              <input
                type="radio"
                value={GENDER_MALE}
                {...register("gender")}
                checked={checkGender(GENDER_MALE)}
                className={checkGender(GENDER_MALE) ? styles.checkedInput : ""}
              />
              {t("createProfile.male")}
            </label>

            <label
              className={
                checkGender(GENDER_FEMALE)
                  ? styles.radioButtonLabelSelected
                  : styles.radioButtonLabel
              }
            >
              <input
                type="radio"
                value={GENDER_FEMALE}
                checked={checkGender(GENDER_FEMALE)}
                className={
                  checkGender(GENDER_FEMALE) ? styles.checkedInput : ""
                }
                {...register("gender")}
              />
              {t("createProfile.female")}
            </label>
          </div>
          <div
            onClick={() => setIsOpenEngName(!isOpenEngName)}
            className={styles.headerContainer}
          >
            <h3 className={styles.formHeader}>
              {t("editProfile.nameInEnglish")}
            </h3>
            <ArrowIcon
              className={isOpenEngName ? styles.openIcon : styles.icon}
            />
          </div>
          <div
            className={
              isOpenEngName
                ? styles.openInputsContainer
                : styles.inputsContainer
            }
          >
            <Input
              register={register}
              setValue={setValue}
              name="inEnglish.lastName"
              type="text"
              placeholder={`${t("common.lastName")}*`}
              error={(errors.inEnglish as any)?.lastName?.message as string}
              maxLength={MAX_NAME_LENGTH}
              handleKeyDown={handleSpecificCharsKeyDown}
            />
            <Input
              register={register}
              setValue={setValue}
              name="inEnglish.firstName"
              type="text"
              placeholder={`${t("createProfile.firstName")}*`}
              error={(errors.inEnglish as any)?.firstName?.message as string}
              maxLength={MAX_NAME_LENGTH}
              handleKeyDown={handleSpecificCharsKeyDown}
            />

            <Input
              register={register}
              setValue={setValue}
              name="inEnglish.middleName"
              type="text"
              placeholder={`${t("common.middleName")}`}
              error={(errors.inEnglish as any)?.middleName?.message as string}
              maxLength={MAX_NAME_LENGTH}
              handleKeyDown={handleSpecificCharsKeyDown}
            />
          </div>
          <h3 className={styles.formHeader}>{t("createProfile.birthDate")}</h3>
          <DateDropdown
            value={birthValue as DateType}
            onChange={(value) => onDateChange(value, "birth")}
            error={errorDateOfBirth}
            isUnknown={isUnknownDateOfBirth}
          />
          <div className={styles.checkboxContainer}>
            <Checkbox
              isChecked={isUnknownDateOfBirth}
              onChange={(value) => onCheckboxChange(value, "birth")}
              label={t("createProfile.unknown")}
            />
          </div>

          <h3 className={styles.formHeader}>{t("createProfile.deathDate")}</h3>
          <DateDropdown
            value={deathValue as DateType}
            onChange={(value) => onDateChange(value, "death")}
            error={errorDateOfDeath}
            isUnknown={isUnknownDateOfDeath}
          />
          <div className={styles.checkboxContainer}>
            <Checkbox
              isChecked={isUnknownDateOfDeath}
              onChange={(value) => onCheckboxChange(value, "death")}
              label={t("createProfile.unknown")}
            />
          </div>
          <h3 className={styles.formHeader}>
            {t("createProfile.reasonOfDeath")}
          </h3>
          <Input
            register={register}
            setValue={setValue}
            name="deathReason"
            type="text"
            placeholder={`${t("createProfile.reasonOfDeathPlaceholder")}`}
            error={errors.deathReason?.message as string}
            maxLength={64}
          />
          <div
            onClick={() => setIsOpenReligion(!isOpenReligion)}
            className={styles.headerContainer}
          >
            <h3 className={styles.formHeader}>{t("createProfile.religion")}</h3>

            <ArrowIcon
              className={isOpenReligion ? styles.openIcon : styles.icon}
            />
          </div>
          {religions && (
            <Religions
              data={religions}
              className={
                isOpenReligion ? styles.religionList : styles.inputsContainer
              }
              onClick={onReligionClick}
              selected={selectedReligion}
            />
          )}
          <h3 className={styles.formHeader}>{t("createProfile.location")}</h3>

          <div className={styles.searchContainer}>
            <SearchInput
              onChange={onCountryChange}
              value={country?.name || ""}
              placeholder={t("createProfile.country")}
              type="search"
              onBlur={() => onSearchBlur("country")}
              isDisabledButton={!country}
              onFocus={() => fieldInFocus("country")}
            />
            {showPopup === "country" && !!popupCountryData?.length && (
              <PopupLocation
                type="search"
                data={popupCountryData}
                onClose={onPopupClose}
                onClick={onPopupCountryClick}
                handleScroll={handleScroll}
              ></PopupLocation>
            )}
          </div>
          <div className={styles.searchContainer}>
            <SearchInput
              onChange={onRegionChange}
              value={region?.name || ""}
              placeholder={t("createProfile.region")}
              type="search"
              isDisabled={!countryInfo}
              onBlur={() => onSearchBlur("region")}
              isDisabledButton={!region}
              onFocus={() => fieldInFocus("region")}
            />
            {showPopup === "region" && !!popupData?.length && (
              <PopupLocation
                type="search"
                data={popupData}
                onClose={onPopupClose}
                onClick={onPopupRegionClick}
                handleScroll={handleScroll}
              ></PopupLocation>
            )}
          </div>

          <div className={styles.searchContainer}>
            <SearchInput
              onChange={onCityChange}
              value={city?.name || ""}
              placeholder={t("createProfile.city")}
              type="search"
              isDisabled={!countryInfo}
              onBlur={() => onSearchBlur("city")}
              isDisabledButton={!city?.name}
              onFocus={() => fieldInFocus("city")}
            />
            {showPopup === "city" && !!popupData?.length && (
              <PopupLocation
                type="search"
                data={popupData}
                onClose={onPopupClose}
                onClick={onPopupCityClick}
                handleScroll={handleScroll}
              ></PopupLocation>
            )}
          </div>

          <SearchInput
            onChange={(value) => {
              setCoords(value);
              setIsInvalidCoords(false);
              setIsDirtyCoords(true);
            }}
            value={coords}
            placeholder={t("createProfile.coords")}
            type="coords"
            error={isInvalidCoords ? t("createProfile.dateValidation") : ""}
            isDisabledButton={!coords}
            onClick={searchByCoords}
          />
          <div className={styles.mapContainer}>
            <Map
              markers={marker}
              onMarkerClick={onMarkerClick}
              centerPoint={center}
              type="create"
            />
          </div>
          <div className={styles.mapMediaContainer}>
            {images.length ? (
              <div className={styles.scrollContainer}>
                <div className={styles.profileMediaGrid}>
                  {images.map((item, index) => (
                    <Fragment key={index}>
                      <PhotoForGallery
                        item={item}
                        onDelete={() => onDelete(index)}
                        onImageClick={() => onImageClick(index)}
                      />
                    </Fragment>
                  ))}
                  <div className={styles.mapDropContainer}>
                    <ImageDrop
                      onDrop={setToGallery}
                      type="mapSmall"
                      text={t("createProfile.mapPhoto")}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <ImageDrop
                onDrop={setToGallery}
                type="map"
                text={t("createProfile.mapPhoto")}
              />
            )}
          </div>
          {isGalleryVisible && (
            <ImageViewer
              isShow={isGalleryVisible}
              isShowRotate
              data={images}
              isShowDelete
              onClose={() => setIsGalleryVisible(false)}
              onDelete={onDelete}
              onRotate={onRotate}
              startIndex={selectedImg}
            />
          )}

          <div className={styles.checkboxContainer}>
            <Checkbox
              isChecked={isUnknown}
              onChange={onCheckboxClick}
              label={t("createProfile.unknown")}
            />
          </div>
        </div>
        <button
          className={styles.formButton}
          type="submit"
          disabled={(!Object.keys(dirtyFields).length && !isDirty) || isLoading}
        >
          {t("common.save")}
        </button>
      </form>
      {(errorProps.showVideoError ||
        errorProps.showImageError ||
        errorProps.showFormatError) && (
        <FileError
          onClose={() =>
            setErrorProps({
              filesName: [],
              showImageError: false,
              showVideoError: false,
              showFormatError: false,
            })
          }
          showVideoError={errorProps.showVideoError}
          showImageError={errorProps.showImageError}
          showFormatError={errorProps.showFormatError}
          errorFileNames={errorProps.filesName}
        />
      )}
    </>
  );
};
