import styles from './Complaints.module.scss';
import { ComplainListItem } from '../../../shared/components/complainList/ComplainListItem';
import { useTranslation } from "react-i18next";
import { SwipeDownIndicator } from '../../../shared/components/SwipeDownIndicator/SwipeDownIndicator';

type ComplainListProps = {
  handleComplainClick: (complaintType: string) => void;
  onClose: () => void;
}

export const ComplainList = ({handleComplainClick, onClose}: ComplainListProps) => {
  const { t } = useTranslation();
  const complaintsList = t("complaints.complaintsList", { returnObjects: true }) as string[];

  return (
    <div>
      <SwipeDownIndicator
        onClose={onClose}
        title={t("complaints.complain")}
      />
      <div className={styles.title}>
        <p className={styles.subHeader}>{t('complaints.subHeader')}</p>
        <p className={styles.description}>{t('complaints.problems')}</p>
      </div>
      <ul className={styles.settingList}>
        {Object.entries(complaintsList).map(([complaintType, value], index) => (
          <ComplainListItem
            text={value}
            key={index}
            onClick={() => handleComplainClick(complaintType)}
          />
        ))}
      </ul>
      <button className={styles.closeButton} onClick={onClose}>{t("common.close")}</button>
    </div>
  )
}