import styles from "./AlphabeticalLocationBlock.module.scss";
import { useTranslation } from 'react-i18next';
import { UIEvent } from 'react';

export type ListType = {
  id: string;
  name: string;
  isoCode?: string;
};

export type DataType = {
  letter: string;
  data: ListType[];
};

type BlockByAlphabetProps = {
  onClick: (item: ListType) => void;
  data: DataType[];
  selected: string[];
  className?: string;
  handleScroll?: (e: UIEvent<HTMLDivElement>) => void;
};

export const AlphabeticalLocationBlock = ({ onClick, data, selected, className, handleScroll }: BlockByAlphabetProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${className ? className : !data.length ? styles.noResults : styles.list}`} onScroll={handleScroll}>
      {!!data.length && data.map((item: DataType) => {
        return (
          <div key={item.letter} className={styles.countryBlock}>
            <div className={styles.letter}>{item.letter}</div>
              {item.data.map((element: ListType) => {
                return (
                  <div
                    key={element.id}
                    className={`${styles.dropdownItem} ${
                      selected.includes(element.id) ? styles.selected : ""
                    }`}
                    onClick={() => onClick(element)}
                  >
                    {element.name}
                  </div>
                )
              })}
          </div>
        );
      })}
      {data && !data.length && (
        <div className={styles.center}>{t("filter.noSearchResults")}</div>
      )}
    </div>
  );
};
