import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteState,
  setOffset,
  setSearchValue,
  updateFilterTrigger
} from '../../store/slices/FiltersFormSlice';

import { SearchInput } from '../../shared/components/search/SearchInput';
import { Filter } from '../filter/Filter';
import { RootState } from '../../store';
import { MAX_MAIN_SEARCH_LENGTH } from '../../constants/constants';


export const Search = () => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { filterTrigger } = useSelector((state: RootState) => state.filtersFormSlice);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);

  useEffect(() => {
    if((searchTerm || filterTrigger) && url !== '/' && url !== '/profiles') {
      setSearchTerm('');
      dispatch(setSearchValue(''));
      dispatch(deleteState());
      dispatch(updateFilterTrigger(false));
    }
  }, [location, accounts.length]);

  const onChangeSearch = (value: string) => {
    const updateValue = value.slice(0, MAX_MAIN_SEARCH_LENGTH)
      .replace(/''+/g, "'");
    if (/^[a-zA-Zа-яА-ЯßäöüÄÖÜßß0-9.'()\s-]*$/.test(updateValue)) {
      setSearchTerm(updateValue);
    }
  };

  const onSearchClick = () => {
    dispatch(setOffset(0))
    dispatch(setSearchValue(searchTerm))
    if (accounts.length !== 0) {
      navigate("/");
    } else {
      navigate("/profiles")
    }
  };
  const onBlurSearch = () => {
    if(searchTerm === '') {
      dispatch(setSearchValue(searchTerm))
    }
  };

  const toggleFilter = () => {
    setIsShowFilter(!isShowFilter);
  }

  return (
    <>
      <SearchInput
        onClick={onSearchClick}
        toggleFilter={toggleFilter}
        hideBorder
        onChange={onChangeSearch}
        onBlur={onBlurSearch}
        value={searchTerm}
        placeholder={t("common.mainSearch")}
        type="main_search"
        isShowFilter= {isShowFilter}
        filterTrigger= {filterTrigger}
        isDisabledButton={!searchTerm}
      />
      {isShowFilter && (
        <Filter onClose={toggleFilter} searchTerm={searchTerm}/>
      )}
    </>
  );
};
