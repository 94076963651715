import {
  useEffect,
  useRef,
  useState
} from "react";
import { PopupBackground } from "../../../shared/components/popupBackground/PopupBackground";

import styles from "./QRPopup.module.scss";
import { ListItem } from "../../../shared/components/listItem/ListItem";
import { useTranslation } from "react-i18next";
import {
  useGetQRCodeQuery,
  useGetShortLinkQuery
} from "../../../api/ProfileService";
import { useParams } from "react-router-dom";
import { PopupData } from "../../../types/CommonTypes";
import { ClipboardNotification } from "../../../shared/components/clipboardNotification/ClipboardNotification";

type PopupProps = {
  onClose: () => void;
  onClick: (type: string) => void;
  value?: string;
};

export const QRPopup = ({ onClose, onClick, value }: PopupProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: QR } = useGetQRCodeQuery(id);
  const { data: shortLink } = useGetShortLinkQuery(id as string);
  const popupData = t( "profile.qrCodePopup" ,
    {
      returnObjects: true,
    }
  ) as PopupData[];

  const popupRef = useRef<HTMLUListElement>(null);

  const [isCopied, setIsCopied] = useState(false);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(shortLink as string)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
          onClose()
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  
  return (
    <>
      <PopupBackground type='dark'/>
      <div className={styles.qrcodeContainer}>
        <div className={styles.qrBackground}>
          <img src={QR} alt='' loading="lazy"/>
        </div>
         <ClipboardNotification isShow={isCopied} isQrPopup/>
      </div>
      <div className={styles.popupContainer}>
        <ul
          ref={popupRef}
          className={`${
             styles.popupList
          }`}
        >
          {popupData?.map((item, index) => {
            return (
              <ListItem
                item={item}
                key={index}
                type ={'popup'}
                onClick={() => item.type === "copy" ? handleCopyClick() : onClick(item.type)}
                isSelected={item.type === value}
              />
            );
          })}
        </ul>
        <button className={
             styles.closeButton} onClick={()=>  onClose()}>{t('common.close')}</button>
      </div>
    </>
  );
};
