import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  CreateMandateBody,
  InitPaymentBody,
  InitPaymentResponse,
  Mandate
} from "../types/PaymentTypes";
const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token-payment");
  return token;
};

const baseUrl = process.env.REACT_APP_PAYMENT_DNS_NAME as string;

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        // include token in req header
        headers.set(`authorization`, `Bearer ${token}`);
        // headers.set('Access-Control-Allow-Origin', 'no-cors')
        return headers;
      }
    },
  }),
  endpoints: (build) => ({
    initPayment: build.mutation({
      query: (body: InitPaymentBody) => ({
        url: `/api/payment/init`,
        method: "POST",
        body
      }),
      transformResponse: (response: InitPaymentResponse) => {
        return response;
      },
    }),
    getPaymentStatus: build.query({
      query: (id) => ({
        url: `/api/payment/status?transactionId=${id} `,
      }),
    }),
  
    getPaymentMethods: build.query({
      query: () => ({
        url: "/api/mandate",
      }),
      transformResponse: (response: Mandate[]) => {
        return response;
      },
    }),
    setDefaultMandate: build.mutation({
      query: (mandateId: number) => ({
        url: "/api/mandate/set-default",
        method: "PUT",
        body: { id: mandateId }
      }),
      async onQueryStarted(mandateId, { dispatch}) {
        try {
          dispatch(
            paymentApi.util.updateQueryData("getPaymentMethods", "", (draft) => {
              draft.forEach(mandate => {
                mandate.isDefault = mandate.id === mandateId;
              });
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    createMandate: build.mutation({
      query: (body: CreateMandateBody) => ({
        url: "/api/mandate",
        method: "POST",
        body
      })
    }),
    deleteMandate: build.mutation({
      query: (mandateId: number) => ({
        url: "/api/mandate/delete",
        method: "DELETE",
        body: { id: mandateId }
      })
    }),
  })
});
export const {
  useInitPaymentMutation,
  useGetPaymentStatusQuery,
  useGetPaymentMethodsQuery,
  useSetDefaultMandateMutation,
  useCreateMandateMutation,
  useDeleteMandateMutation
} = paymentApi;
