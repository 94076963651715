import styles from "./MemorialTab.module.scss";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { MemorialItem } from "../../../shared/components/candle/Memorial";
import {
  setCandle,
  setCandleMessage,
  setIsAnonymous,
  setIsPaymentLoading,
  setCandlePrice,
  deleteState,
  setIsMemorialLoading,
  setFeelingsPrices,
  setCandlesPrices,
  setCandlesPageNumber,
  setFeelingsPageNumber,
} from "../../../store/slices/OrderSlice";
import { UIEvent, useEffect, useState } from "react";
import { ModalWindow } from "../../modalWindow/ModalWindow";
import { CandleBurning } from "../candleBurning/CandleBurning";
import PaymentForm from "../../paymentForm/PaymentForm";
import { Checkbox } from "../../../shared/components/checkbox/Checkbox";
import {
  useCreateCandleOrderMutation,
  useCreateFeelingsOrderMutation,
  useGetFeelingsMutation,
  useGetFreeCandleCountQuery,
  useGetPriceMutation,
} from "../../../api/OrderService";
import { useInitPaymentMutation } from "../../../api/PaymentService";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { InputWithButton } from "../../../shared/components/input/InputWithButton";
import { PaymentStatus } from "../../../shared/components/paymentStatus/PaymentStatus";
import { SuccessStatusPopup } from "../../../shared/components/successStatusPopup/SuccessStatusPopup";
import { PricesType } from "../../../types/MemorialTypes";
import { useNavigate } from "react-router-dom";
import { MemorialTypes } from "../../../constants/Memorial.constant";
import PaymentMethods from "../../paymentMethods/PaymentMethods";
import { MAX_RETRY_REQUESTS } from "../../../constants/constants";
import { InitPaymentBody, Mandate } from "../../../types/PaymentTypes";
import {
  useGetAuthorizedUserInfoQuery,
  useGetUserContactsQuery,
} from "../../../api/UserService";
import { deleteStatePaymentSessionId } from "../../../store/slices/PaymentSessionSlice";
import useIsAuthenticated from "../../../utils/useIsAuthenticated";
import { UnAuthenticatedPopup } from "../../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";

type MemorialTabProps = {
  isModalWindow?: boolean;
  onClose?: (type: string) => void;
  updateProfiles?: (profileIDs: string[], candleID: string) => void;
  type?: string;
};

export const MemorialTab = ({
  type,
  isModalWindow,
  onClose,
  updateProfiles,
}: MemorialTabProps) => {
  const { isAuthenticated }= useIsAuthenticated();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    candles,
    selectedProfiles,
    isAnonymous,
    candlesPageNumber,
    candlesPrices,
    feelingsPageNumber,
    feelingsPrices,
    isMemorialLoading,
  } = useSelector((state: RootState) => state.orderSlice);
  const { language } = useSelector((state: RootState) => state.language);
  const { paymentSessionId } = useSelector(
    (state: RootState) => state.paymentSessionSlice
  );

  const { data: contactData } = useGetUserContactsQuery("", {
    skip: !isAuthenticated,
  });
  const { data: userData } = useGetAuthorizedUserInfoQuery("", {
    skip: !isAuthenticated,
  });
  const [createCandleOrder, { data, isSuccess, isLoading: isOrderLoading }] =
    useCreateCandleOrderMutation();
  const [
    createFeelingsOrder,
    {
      data: feelingsOrderData,
      isSuccess: isCreateFeelingsOrderSuccess,
      isLoading: isCreateFeelingsOrder,
    },
  ] = useCreateFeelingsOrderMutation();
  const { skipOrder } = useFetchWithMsal("order", isAuthenticated);
  const { data: freeCandlesCount, refetch } = useGetFreeCandleCountQuery("", {
    skip: skipOrder,
  });
  const [
    initPayment,
    { isLoading, isSuccess: isPaymentSuccess, data: paymentData, error },
  ] = useInitPaymentMutation();
  const [
    getCandles,
    {
      data: priceData,
      isLoading: isLoadingGetCandle,
      isSuccess: isGetCandleSuccess,
    },
  ] = useGetPriceMutation();
  const [
    getFeelings,
    {
      data: feelingsData,
      isLoading: isLoadingGetFeelings,
      isSuccess: isGetFeelingsSuccess,
    },
  ] = useGetFeelingsMutation();

  const [isFree, setIsFree] = useState<boolean>(false);

  const [isShowModalWindow, setIsShowModalWindow] = useState<boolean>(false);
  const [isShowPaymentMethod, setIsShowPaymentMethod] =
    useState<boolean>(false);
  const [isShowPaymentForm, setIsShowPaymentForm] = useState<boolean>(false);
  const [checkedTab, setCheckedTab] = useState(type);

  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [initPaymentBody, setInitPaymentBody] = useState<any>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [paymentError, setPaymentError] = useState<boolean>(false);
  const [
    isShowNotAuthenticatedModalWindow,
    setIsShowNotAuthenticatedModalWindow,
  ] = useState<boolean>(false);
  const tabs = [MemorialTypes.candles, MemorialTypes.feelings];

  useEffect(() => {
    if (
      error &&
      "status" in error &&
      error.status === 400 &&
      retryCount < MAX_RETRY_REQUESTS
    ) {
      const newTimerId = setTimeout(() => {
        setRetryCount((prevCount) => prevCount + 1);
        sendInitPayment(initPaymentBody);
      }, 10000);
      if (timerId) {
        clearTimeout(timerId);
      }
      setTimerId(newTimerId);
    } else if (retryCount === MAX_RETRY_REQUESTS && error) {
      setPaymentError(true);
      setRetryCount(0);
      if (timerId) {
        clearTimeout(timerId);
        setTimerId(null);
      }
    }
  }, [error, initPayment]);

  useEffect(() => {
    if (isPaymentSuccess) {
      if (!!paymentData?.checkoutUrl) {
        window.location = paymentData.checkoutUrl as any;
      } else {
        dispatch(setIsPaymentLoading(false));
        setIsPaid(!!paymentData?.isPaid);
        !!onClose && onClose(checkedTab as string);
      }
      if (timerId) {
        clearTimeout(timerId);
        setTimerId(null);
      }
    }
  }, [isPaymentSuccess]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setIsPaymentLoading(isLoading));
    }
  }, [isLoading]);

  useEffect(() => {
    if (checkedTab === MemorialTypes.feelings && !feelingsData) {
      dispatch(setFeelingsPageNumber(1));
      getFeelings({ pageNumber: 1 });
    } else if (!priceData) {
      dispatch(setCandlesPageNumber(1));
      getCandles({ pageNumber: 1 });
    }
    const container = document.getElementById("memorialContainer");
    if (container) {
      container.scrollTo({ top: 0 });
    }
  }, [checkedTab]);

  useEffect(() => {
    if (isGetCandleSuccess) {
      if (candlesPageNumber === 1) {
        dispatch(setCandlesPrices(priceData?.items));
      } else {
        dispatch(setCandlesPrices([...candlesPrices, ...priceData?.items]));
      }
      dispatch(setIsMemorialLoading(false));
    }
  }, [isGetCandleSuccess]);

  useEffect(() => {
    if (isGetFeelingsSuccess) {
      if (feelingsPageNumber === 1) {
        dispatch(setFeelingsPrices(feelingsData?.items));
      } else {
        dispatch(
          setFeelingsPrices([...feelingsPrices, ...feelingsData?.items])
        );
      }
      dispatch(setIsMemorialLoading(false));
    }
  }, [isGetFeelingsSuccess]);

  useEffect(() => {
    if (
      (isSuccess && data) ||
      (isCreateFeelingsOrderSuccess && feelingsOrderData)
    ) {
      if (data?.isFree || feelingsOrderData?.isFree) {
        if (updateProfiles) {
          const profileIDS = selectedProfiles.map((item) => item.profileId);
          updateProfiles(profileIDS, candles.selectedCandle);
        }
        dispatch(deleteState());
        setIsFree(true);
        onClose && onClose(checkedTab as string);
        setTimeout(() => {
          setIsFree(false);
        }, 2000);
        checkedTab === MemorialTypes.candles && refetch();
      } else {
        setIsShowPaymentMethod(true);
      }
    }
  }, [isSuccess, isCreateFeelingsOrderSuccess]);

  const onInputChange = (value: string) => {
    if (value.length <= 150) {
      dispatch(setCandleMessage(value));
    }
  };

  const onCandleClick = (id: string) => {
    dispatch(setCandle(id));
  };

  const onCreateOrderButtonClick = () => {
    if (checkedTab === MemorialTypes.candles) {
      setIsShowModalWindow(true);
    } else {
      createFeelingsOrder({
        recipients: selectedProfiles,
        id: candles.selectedCandle,
        isAnonymous: isAnonymous,
        comment: candles.message,
      });
    }
  };
  const onCandleModalClick = (value: {
    burningValue: number;
    isFree: boolean;
    isSubscription: boolean;
  }) => {
    dispatch(setCandlePrice(value));

    setIsShowModalWindow(false);
    createCandleOrder({
      recipients: selectedProfiles,
      id: candles.selectedCandle,
      isAnonymous: isAnonymous,
      comment: candles.message,
      ...value,
    });
  };

  const onCheckboxClick = () => {
    dispatch(setIsAnonymous(!isAnonymous));
  };

  const sendInitPayment = (body: InitPaymentBody): void => {
    const currentUrl = window.location.href;
    const redirectUrl = `${currentUrl}`;
    const cancelUrl = `${currentUrl}`;
    const email = contactData?.find((item: any) => item.type === 1);
    const customerName = !!userData
      ? `${userData?.lastName || ""} ${userData?.firstName || ""} ${
          userData?.middleName || ""
        }`.trim()
      : undefined;
    const newBody: InitPaymentBody = {
      orderId: data?.orderId,
      redirectUrl,
      cancelUrl,
      locale: language,
      customerName,
      email: email?.value,
      ...body,
    };
    initPayment(newBody);
    if (!initPaymentBody) {
      setInitPaymentBody(body);
    }
  };

  const paymentPaypal = () => {
    sendInitPayment({
      paymentMethod: "paypal",
      sessionId: paymentSessionId,
    });
    setIsShowPaymentMethod(false);
    dispatch(deleteState());
    dispatch(deleteStatePaymentSessionId());
  };

  const onPaymentClick = (
    token: string,
    paymentMethod: string,
    isDefault?: boolean
  ) => {
    sendInitPayment({
      cardToken: token,
      paymentMethod: paymentMethod,
      setDefault: isDefault,
    });
    setIsShowPaymentForm(false);
    dispatch(deleteState());
  };

  const mandatePay = (mandate: Mandate) => {
    sendInitPayment({
      mandateId: mandate.id,
      paymentMethod: mandate.method,
    });
    setIsShowPaymentMethod(false);
    dispatch(deleteState());
  };

  const switchTabs = (tab: string) => {
    !isModalWindow && navigate(`/${tab}`);
    dispatch(setCandle(""));
    setCheckedTab(tab);
  };

  const getPrices = () => {
    if (candlesPrices.length) {
      const selectedItem = candlesPrices.find(
        (item: PricesType) => item.id === candles.selectedCandle
      );
      if (selectedItem) {
        return {
          availableFreeCandle: freeCandlesCount,
          cost: selectedItem.cost,
        };
      }
    }
    return {
      availableFreeCandle: 0,
      cost: 1,
    };
  };
  const getItems = () => {
    if (checkedTab === MemorialTypes.candles) {
      return candlesPrices;
    }
    return feelingsPrices;
  };

  const renderTabs = (tabs: string[], checkedButton: any) => {
    return tabs.map((tab: string, index: number) => {
      return (
        <button
          key={index}
          className={`${styles.tabContainer} ${
            checkedButton === tab ? styles.activeTab : ""
          } `}
          onClick={() => switchTabs(tab)}
        >
          {t(`memorial.${tab}`)}
        </button>
      );
    });
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const divComponent = e.target as HTMLDivElement;
    if (
      divComponent.offsetHeight + divComponent.scrollTop >=
      divComponent.scrollHeight - 200
    ) {
      switch (checkedTab) {
        case MemorialTypes.candles:
          if (
            !isMemorialLoading &&
            !isLoadingGetCandle &&
            priceData?.pageNumber < priceData?.totalPages
          ) {
            getCandles({ pageNumber: candlesPageNumber + 1 });
            dispatch(setCandlesPageNumber(candlesPageNumber + 1));
            dispatch(setIsMemorialLoading(true));
          }
          break;
        case MemorialTypes.feelings:
          if (
            !isMemorialLoading &&
            !isLoadingGetFeelings &&
            feelingsData?.pageNumber < feelingsData?.totalPages
          ) {
            getFeelings({ pageNumber: feelingsPageNumber + 1 });
            dispatch(setFeelingsPageNumber(feelingsPageNumber + 1));
            dispatch(setIsMemorialLoading(true));
          }
          break;
        default:
          break;
      }
    }
  };

  const paymentCreditCard = () => {
    setIsShowPaymentForm(true);
    setIsShowPaymentMethod(false);
  };

  const closePaymentStatus = (): void => {
    setIsPaid(false);
  };

  return (
    <>
      <div className={styles.memorialContainer}>
        <div
          className={
            isModalWindow ? styles.memorialTabs : styles.memorialTabsPage
          }
        >
          {renderTabs(tabs, checkedTab)}
        </div>
        <div
          className={
            isModalWindow ? styles.inputContainerWindow : styles.inputContainer
          }
          onClick={()=> setIsShowNotAuthenticatedModalWindow(!isAuthenticated)}
        >
          <InputWithButton
            value={candles.message}
            setValue={onInputChange}
            placeholder={
              checkedTab === MemorialTypes.candles
                ? t("memorial.candleMessage")
                : t("memorial.feelingsMessage")
            }
           
            disabled={!isAuthenticated}
            maxlength={150}
          />
        </div>

        <div
          className={
            isModalWindow
              ? styles.candlesContainerModalWindow
              : !!selectedProfiles.length && !!candles.selectedCandle
              ? styles.candlesContainerShot
              : styles.candlesContainer
          }
          id="memorialContainer"
          onScroll={handleScroll}
        >
          <div
            className={
              isModalWindow ? styles.candlesGridModalWindow : styles.candlesGrid
            }
          >
            {getItems()?.map((item: PricesType, index: number) => {
              return (
                <MemorialItem
                  key={index}
                  selected={candles.selectedCandle === item.id}
                  type="candle"
                  onClick={onCandleClick}
                  id={item.id}
                  img={item.mediaUrl}
                  price={item?.cost ? item.cost : 0}
                />
              );
            })}
          </div>
        </div>

        {((!!selectedProfiles.length && !!candles.selectedCandle) ||
          isModalWindow) && (
          <>
            { isAuthenticated && (
              <div className={styles.checkboxContainer}>
                <Checkbox
                  isChecked={isAnonymous}
                  disabled={!isAuthenticated}
                  label={t("memorial.sendAnonymously")}
                  onChange={onCheckboxClick}
                />
              </div>
              )
            }
            <div className={styles.buttonContainer}>
              <button
                onClick={onCreateOrderButtonClick}
                disabled={
                  !candles.selectedCandle ||
                  isOrderLoading ||
                  isCreateFeelingsOrder ||
                  !selectedProfiles.length
                }
              >
                {checkedTab === MemorialTypes.candles
                  ? t("common.continue")
                  : t("common.send")}
              </button>
            </div>
          </>
        )}
        {isShowModalWindow && (
          <ModalWindow
            onClose={() => setIsShowModalWindow(false)}
            isShow={isShowModalWindow}
            headerText={t("memorial.candleBurning")}
            children={
              <CandleBurning
                onClose={onCandleModalClick}
                profileCount={selectedProfiles.length}
                {...getPrices()}
              />
            }
          ></ModalWindow>
        )}
        {isShowPaymentMethod && !isShowPaymentForm && (
          <ModalWindow
            onClose={() => setIsShowPaymentMethod(false)}
            isShow={isShowPaymentMethod}
            headerText={t("common.payment")}
            children={
              <PaymentMethods
                onPayPaypal={paymentPaypal}
                onPayCreditCard={paymentCreditCard}
                onPay={mandatePay}
              />
            }
          ></ModalWindow>
        )}
        {isShowPaymentForm && (
          <ModalWindow
            onClose={() => setIsShowPaymentForm(false)}
            isShow={isShowPaymentForm}
            headerText={t("common.payment")}
            children={<PaymentForm onClick={onPaymentClick} disabled={!data} />}
          ></ModalWindow>
        )}
        <PaymentStatus
          paymentError={paymentError}
          isPaid={isPaid}
          onClose={closePaymentStatus}
        />
        {isFree && <SuccessStatusPopup />}
        {isShowNotAuthenticatedModalWindow && (
        <UnAuthenticatedPopup
          onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
        ></UnAuthenticatedPopup>
      )}
      </div>
    </>
  );
};
