import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./SuccessfulPayment.module.scss";
import { useTranslation } from "react-i18next";
import smallArrow from "../../shared/image/arrowIcon.svg";
import close from "../../shared/image/cross.svg";

export const SuccessfulPaymentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParam = searchParams.get('profileId');
  return (
    <div className={styles.profileCreatedWrapper}>
      <Link to="/">
        <img src={close} alt="" className={styles.closeButton} />
      </Link>

      <h3 className={styles.profileCreatedHeader}>
        {t("payment.successfulPayment")}
      </h3>
      <div className={styles.profileCreatedContainer}>
      
      <div  className={styles.spin}><div  className={styles.backgroundImage}></div></div>

      <div
            className={styles.breadcrumbsContainer}
            onClick={() => navigate(queryParam? `/profile/${queryParam}`: '/candles')}
          >
            <img src={smallArrow} alt="" />
            <p>{t("common.back")}</p>
          </div>
        <p className={styles.description}>
          {t("payment.successfulPaymentDescription")}
        </p>
        <p className={styles.description}>
          {t("payment.checkEmail")}
        </p>
      </div>

      <button
        className={styles.button}
         onClick={() => navigate(queryParam? `/profile/${queryParam}`: '/candles')}
      >
        
        {queryParam? t("createProfile.goToProfile") :t("common.back")}
      </button>
    </div>
  );
};
