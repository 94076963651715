import styles from "./ProfilePosts.module.scss";
import { useTranslation } from "react-i18next";
import { PostItem } from "./postItem/PostItem";
import { PostType } from "../../../../types/ProfileType";
import { AddPostField } from "./addPostField/AddPostField";
import { useEffect, useRef, useState } from "react";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import { useGetAuthorizedUserInfoQuery } from "../../../../api/UserService";
import { AppDispatch } from "../../../../store";
import { useDispatch } from "react-redux";
import {
  setFullImage,
  setPreviewImage,
  setUserId,
  setUsername,
} from "../../../../store/slices/UserPhotoSlice";
import useIsAuthenticated from "../../../../utils/useIsAuthenticated";

type ProfilePostsPropsType = {
  postsData: PostType[];
  isWallLoading:boolean
};
const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const ProfilePosts = ({ postsData,isWallLoading }: ProfilePostsPropsType) => {
  const { t } = useTranslation();
  const container = useRef<HTMLDivElement | null>(null);
  const {isAuthenticated }= useIsAuthenticated();
  const { skipUser } = useFetchWithMsal("user", isAuthenticated);
  const { data } = useGetAuthorizedUserInfoQuery("", {
    skip: skipUser || !isAuthenticated,
  });
  const [width, setWidth] = useState<number>(0);
  const [isOnFocus, setIsOnFocus] = useState<boolean>( false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (data) {
      if(data.avatarPreviewUrl){
        dispatch(setPreviewImage(url + data.avatarPreviewUrl));
        dispatch(setFullImage(url + data.avatarUrl));
      }
      dispatch(setUsername(data.username));
      dispatch(setUserId(data.id));
    }
  }, [data]);

  const handleResize = () => {
    setWidth(container?.current?.clientWidth || 0)
  };
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={container}
      className={
        !!postsData.length
          ? styles.profilePostsContainer
          : styles.profilePostsContainerEmpty
      }
    >
      {!!postsData.length ? (
        postsData?.map((item: PostType) => {
          return <PostItem data={item} key={item.id} />;
        })
      ) : (!isWallLoading &&
        <p className={styles.emptyText}>{t("posts.emptyWall")}</p>
      )}
      <div
        className={styles.addPostContainer}
        style={{ width: `${width}px`, zIndex:isOnFocus? 10:0}}
      >
        <AddPostField onFocus={(value)=>setIsOnFocus(value)} />
      </div>
    </div>
  );
};
