import { useTranslation } from "react-i18next";
import styles from "./MediaTab.module.scss";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { ImageViewer } from "../../imageViewer/ImageViewer";
import { Popup } from "../../../shared/components/popup/Popup";
import video from "../../../shared/image/video.png";
import { ReactComponent as AddIcon } from "../../../shared/image/addIcon.svg";

import {
  useGetFrameQuery,
  useGetProfileQuery,
  usePostFrameMutation,
  useUpdateProfileMutation,
} from "../../../api/ProfileService";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { Checkbox } from "../../../shared/components/checkbox/Checkbox";

import { ImageCropper } from "../../imageCropper/ImageCropper";
import ImageDrop from "../../dropArea/DropArea";
import CustomCarousel from "../../carousel/Carousel";

import { DeletePopup } from "../../../shared/components/deletePopup/DeletePopup";
import {
  CoordsType,
  ErrorImages,
  ImageItem,
  PopupData,
} from "../../../types/CommonTypes";
import {
  useDeleteGalleryPhotoMutation,
  useDeleteMediaFileMutation,
  useEditPhotoMutation,
  useGetGalleryPhotoQuery,
  usePostGalleryPhotoMutation,
  usePostMainPhotoMutation,
} from "../../../api/MediaService";
import { FileError } from "../../../shared/components/fileError/FileError";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { setShowSpinner } from "../../../store/slices/SpinnerSlice";
import PhotoForGallery from "../../../shared/components/photoForGallery/PhotoForGallery";
import {
  acceptFormats,
  acceptImageFormats,
  allowedImageTypes,
  allowedVideoTypes,
} from "../../../constants/Media.constant";
import { updatePhotoHash } from "../../../store/slices/ProfileSlice";
import { getLanguageByLocale } from "../../../utils/localeUtils";
import { transformImage } from "../../../utils/transformImage";

type MediaTabProps = {
  id: string;
};
const url = process.env.REACT_APP_BLOB_DNS_NAME as string;
const maxImageSize = !!process.env.MAX_IMAGE_SIZE
  ? +process.env.MAX_IMAGE_SIZE
  : 15 * 1024 * 1024;
const maxVideoSize = !!process.env.MAX_VIDEO_SIZE
  ? +process.env.MAX_VIDEO_SIZE
  : 100 * 1024 * 1024;

export const MediaTab = ({ id }: MediaTabProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { skipMedia } = useFetchWithMsal("media", true);
  const { data: photos, refetch } = useGetGalleryPhotoQuery(id, {
    skip: skipMedia,
  });
  const { language } = useSelector((state: RootState) => state.language);
  const { data: profile } = useGetProfileQuery({
    id,
    lang: getLanguageByLocale(language),
  });
  const { data: currentFrame } = useGetFrameQuery(
    profile?.profilePictureFrameId,
    {
      skip: !profile?.profilePictureFrameId,
    }
  );
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const [
    postMainPhoto,
    { isLoading: isMainProfileLoading, isSuccess: isMainProfileSuccess },
  ] = usePostMainPhotoMutation();
  const [postFrame, { isLoading: isPostFrameLoading }] = usePostFrameMutation();
  const [
    postGalleryPhoto,
    { isLoading: isGalleryLoading, isSuccess: isGallerySuccess },
  ] = usePostGalleryPhotoMutation();
  const [deleteMediaFile] = useDeleteMediaFileMutation();
  const [editPhoto] = useEditPhotoMutation();
  const [deletePhoto, { isSuccess: isDeleteSuccess }] =
    useDeleteGalleryPhotoMutation();
  const [galleryVisible, setGalleryVisible] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [cropperData, setCropperData] = useState<ImageItem | null>(null);
  const [isShowDeletePopup, setIsShowDeletePopup] = useState(false);
  const [cropCoords, setCropCoords] = useState<CoordsType>();
  const [isDirty, setIsDirty] = useState(false);
  const [activTab, setActivTab] = useState("all");
  const [avatar, setAvatar] = useState<ImageItem | null>(null);
  const [images, setImages] = useState<ImageItem[]>([]);
  const [media, setMedia] = useState<ImageItem[]>([]);
  const [videos, setVideos] = useState<ImageItem[]>([]);
  const [selectedImg, setSelectedImg] = useState<number>(0);
  const [frame, setFrame] = useState<string | null>('');
  const [errorProps, setErrorProps] = useState<ErrorImages>({
    showVideoError: false,
    showImageError: false,
    showFormatError: false,
    filesName: [],
  });
  const avatarInputRef = useRef<HTMLInputElement | null>(null);
  const galleryInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const popupData = t("photoPopup", { returnObjects: true }) as PopupData[];

  const [isUserContactForProfile, setIsUserContactForProfile] = useState(
    profile?.isUserContactForProfile
  );
  const [deletedPhotoIds, setDeletedPhotoIds] = useState<string[]>([]);

  useEffect(() => {
    const photosArray = [] as ImageItem[];
    const mediaArray = [] as ImageItem[];
    const videosArray = [] as ImageItem[];

    if (photos && !isGalleryLoading) {
      photos.forEach((item: any) => {
        const mediaItem = {
          thumbnail: item.previewUrl ? item.previewUrl : video,
          id: item.name,
          url: item.url,
          size: item.size,
          description: allowedVideoTypes.includes(item.contentType)
            ? "video"
            : "image",
          type: item.contentType,
        };
        if (allowedVideoTypes.includes(item.contentType)) {
          videosArray.push(mediaItem as ImageItem);
        } else {
          photosArray.push(mediaItem as ImageItem);
        }
        mediaArray.push(mediaItem as ImageItem);
      });
      setImages(photosArray);
      setVideos(videosArray);
      setMedia(mediaArray);
    }
  }, [photos]);

  useEffect(() => {
    if (profile?.avatarPreviewUrl) {
      setAvatar({
        thumbnail: `${url}${profile?.avatarPreviewUrl}`,
        url: `${url}${profile.avatarUrl}`,
        id: "",
      } as ImageItem);
    }
  }, [profile?.avatarPreviewUrl]);
  useEffect(() => {
    if (isDeleteSuccess) {
      setDeletedPhotoIds([]);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (currentFrame) {
      setFrame(currentFrame?.id);
    }
  }, [currentFrame]);

  useEffect(() => {
    if (isGallerySuccess && !isMainProfileLoading) {
      dispatch(setShowSpinner(false));
      refetch();
    }
  }, [isGallerySuccess]);

  useEffect(() => {
    if (isMainProfileSuccess && !isGalleryLoading) {
      dispatch(setShowSpinner(false));
    }
  }, [isMainProfileSuccess]);

  const onSubmit = () => {
    setIsDirty(false);
    if (isUserContactForProfile !== profile?.isUserContactForProfile) {
      const newProfileData = {
        ...profile,
        isUserContactForProfile: isUserContactForProfile,
      };
      updateProfile({ body: newProfileData, profileId: id });
    }

    const addedPhoto = media.filter((item) => item.isNew);
    if (avatar?.isNew || addedPhoto.length || frame || frame === null || deletedPhotoIds.length) {
      if (avatar?.isNew) {
        const formAvatarData = new FormData();

        formAvatarData.append("ProfileId", id);
        formAvatarData.append("file", avatar.original as File);

        if (cropCoords) {
          formAvatarData.append("Left", `${Math.floor(cropCoords.left)}`);
          formAvatarData.append("Top", `${Math.floor(cropCoords.top)}`);
          formAvatarData.append("Right", `${Math.floor(cropCoords?.right)}`);
          formAvatarData.append("Bottom", `${Math.floor(cropCoords?.bottom)}`);
        }

        formAvatarData.append("WallSize", "false");
        postMainPhoto({body:formAvatarData, lang:getLanguageByLocale(language)});
        dispatch(updatePhotoHash());
        dispatch(setShowSpinner(true));
      }
      if (deletedPhotoIds.length) {
        deletedPhotoIds.forEach((mediaName: string) => {
          deletePhoto({ profileId: id, mediaName });
        });
      }

      if ((frame && frame !== currentFrame?.id ) || frame === null) {
        postFrame({
          profileId: id,
          frameGuid: frame,
        });
      }
      if (addedPhoto.length) {
        const formGalleryData = new FormData();
        formGalleryData.append("ProfileId", id);

        addedPhoto.reverse().forEach((imageItem: ImageItem) => {
          formGalleryData.append(`files`, imageItem.original as File);
        });
        postGalleryPhoto(formGalleryData);
        dispatch(setShowSpinner(true));
      }
    }
    if (!avatar && profile?.avatarUrl) {
      deleteMediaFile(profile?.avatarUrl);
    }
    const editedPhoto = media.filter((item) => item.isEdited);
    const baseUrl = process.env.REACT_APP_BLOB_DNS_NAME as string;
    if (editedPhoto.length) {
      editedPhoto.forEach((item) => {
        const formImgData = new FormData();
        formImgData.append("file", item.original as File);
        editPhoto({ url: item.url?.split(baseUrl)[1], body: formImgData });
      });
    }
    if (avatar?.isEdited) {
      const formAvatarData = new FormData();
      formAvatarData.append("file", avatar.original as File);
      editPhoto({ url: avatar.url?.split(baseUrl)[1], body: formAvatarData });
    }
  };

  const setImage = (value: ImageItem[], errorValue: ErrorImages) => {
    setErrorProps(errorValue);
    if (!!value[0]) {
      setIsDirty(true);
      const newAvatar = value[0];
      newAvatar.isNew = true;
      setAvatar(newAvatar);
    }
  };
  const getTabsItem = () => {
    switch (activTab) {
      case "video":
        return videos;
      case "photo":
        return images;
      default:
        return media;
    }
  };

  const setToGallery = (value: ImageItem[], errorValue: ErrorImages) => {
    setErrorProps(errorValue);
    value.forEach((item) => {
      item.isNew = true;
      setMedia((prevImages) => [item, ...prevImages]);

      if (item.description.includes("video")) {
        setIsDirty(true);
        setVideos((prevImages) => [item, ...prevImages]);
      } else {
        setIsDirty(true);
        setImages((prevImages) => [item, ...prevImages]);
      }
    });
  };
  const onPopupClick = (type: string) => {
    switch (type) {
      case "load":
        if (avatarInputRef.current) {
          avatarInputRef.current.click();
        }
        break;
      case "open":
        setGalleryVisible("avatar");
        break;
      case "delete":
        setIsShowDeletePopup(true);
        break;
        case 'edit':
          setCropperData(avatar)
          break
      default:
        break;
    }
    setShowPopup(false);
  };
  const onDeletePopupClick = (value: string) => {
    switch (value) {
      case "delete":
        if (avatar?.id) {
          setDeletedPhotoIds((prevState) => [
            ...prevState,
            avatar?.id as string,
          ]);
        }
        setIsDirty(true);
        setAvatar(null);
        setIsShowDeletePopup(false);
        setFrame(null)
        break;
      default:
        setIsShowDeletePopup(false);
    }
  };
  const onAvatarClick = (event: MouseEvent) => {
    event.stopPropagation();
    setShowPopup(true);
    setSelectedImg(0);
  };
  const onCrop = (value?: CoordsType, img?: ImageItem) => {
    if (!!value && !!img) {
      setAvatar(img);
      setCropCoords(value);
      setIsDirty(true);
    }
    setCropperData(null);
  };
  const onImageClick = (index: number) => {
    setSelectedImg(index);
    setGalleryVisible("gallery");
  };
  const onDelete = (value: number) => {
    setIsDirty(true);
    let filteredGallery;
    switch (activTab) {
      case "photo":
        filteredGallery = images.filter((item, index) => index !== value);
        if (images[value]?.id) {
          setDeletedPhotoIds((prevState) => [
            ...prevState,
            images[value].id as string,
          ]);
        }
        const filteredMedia = media.filter((item) =>
          images[value].isNew
            ? item.original !== images[value].original
            : item.url !== images[value].url
        );
        setMedia(filteredMedia);
        setImages(filteredGallery);
        break;
      case "video":
        filteredGallery = videos.filter((item, index) => index !== value);
        if (videos[value]?.id) {
          setDeletedPhotoIds((prevState) => [
            ...prevState,
            videos[value].id as string,
          ]);
        }
        const filteredAllMedia = media.filter((item) =>
          videos[value].isNew
            ? item.original !== videos[value].original
            : item.url !== videos[value].url
        );
        setMedia(filteredAllMedia);
        setVideos(filteredGallery);
        break;
      default:
        filteredGallery = media.filter((item, index) => index !== value);

        if (media[value]?.id) {
          setDeletedPhotoIds((prevState) => [
            ...prevState,
            media[value].id as string,
          ]);
        }
        if (media[value]?.description === "video") {
          const filteredVideos = videos.filter((item) =>
            media[value].isNew
              ? item.original !== media[value].original
              : item.url !== media[value].url
          );
          setVideos(filteredVideos);
        } else {
          const filteredImages = images.filter((item) =>
            media[value].isNew
              ? item.original !== media[value].original
              : item.url !== media[value].url
          );
          setImages(filteredImages);
        }
        setMedia(filteredGallery);
        break;
    }

    if (!filteredGallery[value]) {
      if (filteredGallery.length === 0) {
        setGalleryVisible("");
      } else {
        setSelectedImg(value - 1);
      }
    } else {
      setSelectedImg(value);
    }
  };
  const onRotate = (value: number, img: File) => {
    setIsDirty(true);

    if (galleryVisible === "avatar") {
      const newAvatar = {
        ...avatar,
        original: img,
        thumbnail: URL.createObjectURL(img as Blob),
        isEdited: avatar?.url ? true : false,
      } as ImageItem;
      setAvatar(newAvatar);
    } else {
      const newImages = images.map((item, index) => {
        if (index === value) {
          item.original = img;
          item.thumbnail = URL.createObjectURL(img as Blob);
          if (item.url) {
            item.isEdited = true;
          }
        }
        return item;
      });
      const newMedia = media.map((item, index) => {
        if (index === value) {
          item.original = img;
          item.thumbnail = URL.createObjectURL(img as Blob);
        }
        return item;
      });
      setMedia(newMedia);
      setImages(newImages);
    }
  };
  const handleImageUpload =
    (type: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (files) {
        const imagesArray = Array.from(files);

        imagesArray.forEach((file) => {
          if (!!file) {
            const fileType = file?.type as string;

            const newItem: ImageItem = {
              original: file,
              thumbnail: allowedVideoTypes.includes(file.type)
                ? video
                : URL.createObjectURL(file as Blob),
              description: allowedVideoTypes.includes(file.type)
                ? "video"
                : "image",
              isNew: true,
            };
            allowedImageTypes.includes(file.type) &&  transformImage(newItem as ImageItem)
            if (allowedImageTypes.includes(fileType)) {
              if (file.size < maxImageSize) {
                if (type === "avatar") {
                  setCropperData(newItem);
                } else {
                  setImages((prevImages) => [newItem, ...prevImages]);
                  setMedia((prevImages) => [newItem, ...prevImages]);
                  setIsDirty(true);
                }
              } else {
                setErrorProps((prev) => ({
                  showImageError: true,
                  showVideoError: prev.showVideoError,
                  showFormatError: prev.showFormatError,
                  filesName: [...prev.filesName, file.name],
                }));
              }
            } else if (allowedVideoTypes.includes(fileType)) {
              if (file.size < maxVideoSize) {
                setVideos((prevImages) => [newItem, ...prevImages]);
                setMedia((prevImages) => [newItem, ...prevImages]);
                setIsDirty(true);
              } else {
                setErrorProps((prev) => ({
                  showImageError: prev.showImageError,
                  showVideoError: true,
                  showFormatError: prev.showFormatError,
                  filesName: [...prev.filesName, file.name],
                }));
              }
            } else {
              setErrorProps((prev) => ({
                showImageError: prev.showImageError,
                showVideoError: prev.showVideoError,
                showFormatError: true,
                filesName: [...prev.filesName, file.name],
              }));
            }
          }
        });
        if (galleryInputRef?.current) {
          galleryInputRef.current.value = "";
        }
        if (avatarInputRef?.current) {
          avatarInputRef.current.value = "";
        }
      }
    };

  return (
    <div className={styles.form}>
      <div className={styles.formContainer}>
        <div className={styles.headerContainer}>
          <h3 className={styles.formHeader}>{t("createProfile.photo")}</h3>

          {!!avatar ? (
            <h3 className={styles.formHeaderDiscription}>
              &nbsp;{t("createProfile.photoDiscription")}
            </h3>
          ) : (
            ""
          )}
        </div>
        <div className={styles.profileAvatarContainer}>
          <CustomCarousel
            setFrame={(value) => {
              setFrame(value);
              setIsDirty(true);
            }}
            value={currentFrame?.id}
            isHidden={!avatar}
          />
          <div
            className={
              !avatar ? styles.photoBackground : styles.photoBackgroundHidden
            }
          ></div>
          <div className={styles.profileAvatarCurrentPhoto}>
            <div className={styles.photoContainer}>
              {avatar ? (
                <img
                  src={avatar.thumbnail}
                  alt="Dropped"
                  className={styles.profileImage}
                  onClick={onAvatarClick}
                />
              ) : (
                <div
                  className={styles.profileImageAddContainer}
                  onClick={() => avatarInputRef.current?.click()}
                >
                  <ImageDrop
                    onDrop={setImage}
                    type="avatar"
                    text={t("createProfile.dropAreaAvatarDescription")}
                  />
                </div>
              )}
              <input
                accept={acceptImageFormats}
                id="icon-button-file"
                type="file"
                ref={avatarInputRef}
                style={{ display: "none" }}
                onChange={handleImageUpload("avatar")}
              />
            </div>
          </div>
        </div>
        <div className={styles.profileMediaContainer}>
          <div className={styles.profileMediaTools}>
            <div
              className={styles.addButton}
              onClick={() => galleryInputRef.current?.click()}
            >
              <AddIcon />
            </div>
            <input
              accept={acceptFormats}
              id="icon-button-file"
              type="file"
              multiple
              ref={galleryInputRef}
              style={{ display: "none" }}
              onChange={handleImageUpload("gallery")}
            />
            <div
              className={`${
                activTab === "all" ? styles.activTab : styles.mediaTab
              }`}
              onClick={() => setActivTab("all")}
            >
              {t("createProfile.all")}
              <span className={styles.count}>
                {images.length + videos.length}
              </span>
            </div>
            <div
              className={`${
                activTab === "photo" ? styles.activTab : styles.mediaTab
              }`}
              onClick={() => setActivTab("photo")}
            >
              {t("createProfile.photo")}
              <span className={styles.count}>{images.length}</span>
            </div>
            <div
              className={`${
                activTab === "video" ? styles.activTab : styles.mediaTab
              }`}
              onClick={() => setActivTab("video")}
            >
              {t("createProfile.video")}
              <span className={styles.count}>{videos.length}</span>
            </div>
          </div>
          {(media.length && activTab === "all") ||
          (images.length && activTab === "photo") ||
          (videos.length && activTab === "video") ? (
            <div className={styles.scrollContainer}>
              <div className={styles.profileMediaGrid}>
                {getTabsItem().map((item, index) => (
                  <PhotoForGallery
                    item={item}
                    onDelete={() => onDelete(index)}
                    onImageClick={() => onImageClick(index)}
                    key={index}
                  />
                ))}
                <div className={styles.dropContainer}>
                  <ImageDrop
                    onDrop={setToGallery}
                    type="mapSmall"
                    text={t("createProfile.mapPhoto")}
                  />
                </div>
              </div>
            </div>
          ) : (
            <ImageDrop onDrop={setToGallery} type="all" />
          )}
        </div>
        <div className={styles.checkboxContainer}>
          <Checkbox
            isChecked={isUserContactForProfile}
            onChange={() => {
              setIsUserContactForProfile(!isUserContactForProfile);
              setIsDirty(true);
            }}
            label={t("createProfile.contactPerson")}
          />
        </div>
      </div>
      {galleryVisible && (
        <ImageViewer
          isShow={!!galleryVisible}
          data={
            galleryVisible === "avatar"
              ? ([avatar] as ImageItem[])
              : getTabsItem()
          }
          isShowDelete={galleryVisible === "gallery"}
          isShowRotate={galleryVisible === "gallery"}
          onClose={() => setGalleryVisible("")}
          onDelete={onDelete}
          onRotate={onRotate}
          startIndex={selectedImg}
        />
      )}
      {!!cropperData && (
        <ImageCropper
          isShow={!!cropperData}
          data={cropperData}
          onClose={onCrop}
        />
      )}
      {showPopup && (
        <Popup
          type="avatar"
          data={popupData}
          onClose={() => setShowPopup(false)}
          onClick={(type) => onPopupClick(type)}
        ></Popup>
      )}
      {isShowDeletePopup && (
        <DeletePopup
          onClick={onDeletePopupClick}
          text={t("common.deletePhoto")}
        />
      )}
      {(errorProps.showVideoError ||
        errorProps.showImageError ||
        errorProps.showFormatError) && (
        <FileError
          onClose={() =>
            setErrorProps({
              filesName: [],
              showImageError: false,
              showVideoError: false,
              showFormatError: false,
            })
          }
          showVideoError={errorProps.showVideoError}
          showImageError={errorProps.showImageError}
          showFormatError={errorProps.showFormatError}
          errorFileNames={errorProps.filesName}
        />
      )}
      <button
        className={styles.createButton}
        onClick={() => onSubmit()}
        disabled={
          isLoading ||
          isPostFrameLoading ||
          isGalleryLoading ||
          isMainProfileLoading ||
          !isDirty
        }
      >
        {t("common.save")}
      </button>
    </div>
  );
};
