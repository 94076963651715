import styles from "./SimpleTextarea.module.scss";
import { ChangeEvent, useEffect, useRef } from "react";

type InputProps = {
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  label?: string;
  placeholder?: string;
  classType?: string;
  error?: string;
  isInitHeight?: boolean;
  maxLength?: number;
  id: string;
  isAutoFocus?: boolean;
  disabled?: boolean;
};

export const SimpleTextarea = ({
  value,
  onChange,
  onFocus,
  onBlur,
  id,
  label,
  placeholder,
  classType,
  error,
  isInitHeight,
  maxLength,
  isAutoFocus,
  disabled,
}: InputProps) => {
  useEffect(() => {
    if (isAutoFocus) {
      const textarea = document.getElementById(id) as HTMLTextAreaElement;
      const cursorPosition = textarea.selectionStart;
      textarea.setSelectionRange(
        cursorPosition + value.length,
        cursorPosition + value.length
      );
      textarea.focus();
    }
  }, [isAutoFocus]);

  useEffect(() => {
    if (isInitHeight) {
      const textarea = document.getElementById(id) as HTMLTextAreaElement;
      setTimeout(() => {
        textarea.style.height = "45px";
        textarea.style.overflowY = "hidden";
      }, 0);
    }
  }, [isInitHeight]);

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = "auto";
    e.currentTarget.style.overflowY = "auto";
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight + 2}px`;
  };
  const handleFocus = (e: ChangeEvent<HTMLTextAreaElement>) => {

    e.stopPropagation();
    e.preventDefault();
    onFocus && onFocus();
  };

  return (
    <div className={styles.inputContainer}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        className={`${styles.input} ${classType ? styles.outline : ""} ${
          error ? styles.error : error
        }`}
        id={id}
        placeholder={placeholder}
        maxLength={maxLength}
        onInput={handleInput}
        value={value}
        onFocus={handleFocus}
        onChange={(e) => onChange(e.target.value)}
        readOnly={disabled}
        onBlur={onBlur}
        style={{ height: isInitHeight ? "45px" : "" }}
        // autoFocus={isAutoFocus}
      />

      {error && <p className={styles.errorMessage}> {error}</p>}
    </div>
  );
};
