import styles from "./ProfilesPage.module.scss";

import { ProfileCard } from "../../shared/components/profileCard/ProfileCard";
import { Menu } from "../../components/menu/Menu";
import { useGetProfilesMutation } from "../../api/SearchService";
import {
  useEffect,
  useRef,
  useState
} from "react";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import {useGetFavoritesIdsProfilesQuery, useGetFavoritesProfilesQuery} from "../../api/ProfileService";
import { ProfileType } from "../../types/ProfileType";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetGiftsMutation } from '../../api/GiftService';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../store';
import {
  SelectedCountryType,
  setOffset,
  updateFilterReligionData,
  updateFilterSelectedCountryData
} from '../../store/slices/FiltersFormSlice';
import  dayjs from "dayjs";
import { Search } from '../../components/Search/Search';
import { NotificationPopup } from '../../shared/components/notificationPopup/NotificationPopup';
import {
  useGetCitiesTranslateByIdQuery,
  useGetCountryTranslateByIdQuery,
  useGetReligionsTranslateByIdQuery
} from '../../api/UtilityService';
import {CityDataType, CountryType} from '../../types/CommonTypes';
import {getLanguageByLocale} from "../../utils/localeUtils";
import useIsAuthenticated from "../../utils/useIsAuthenticated";
import { SearchInput } from '../../shared/components/search/SearchInput';
import { MAX_MAIN_SEARCH_LENGTH } from '../../constants/constants';
import ModalWindowHeader from '../../shared/components/modalWindowHeader/ModalWindowHeader';

type PropsType = {
  type: string;
  skip?: boolean;
};

export type GiftType = {
  mediaUrl: string;
};

type CandleType = {
  profileId: string;
  gifts: GiftType[];
};

export const ProfilesPage = ({type, skip:skipLoading}:PropsType) => {
  const dispatch = useDispatch();
  const {isAuthenticated} =  useIsAuthenticated();
  const { skip: skipProfile } = useFetchWithMsal("profile", isAuthenticated);

  const {
    searchValue,
    offsetSearch,
    filterTrigger,
    gender,
    ageFrom,
    ageTo,
    countryIds,
    cityIds,
    religionIds,
    birthDate,
    deathDate,
    currentLang,
    selectedCountryData,
    selectedReligionData,
  } = useSelector((state: RootState) => state.filtersFormSlice);
  const { language } = useSelector((state: RootState) => state.language);
  
  const { skipSearch, skip } = useFetchWithMsal(type, isAuthenticated);
  const {
    data: favoritesProfiles,
    isSuccess: isSuccessFavoritesProfiles,
    isFetching: isFavoriteLoading,
    
  } = useGetFavoritesProfilesQuery("", {skip: skip || skipLoading || !isAuthenticated, refetchOnMountOrArgChange: true});
  const [profilesData, setProfilesData] = useState<Array<ProfileType>>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [getGifts, {data: candles, isSuccess: isSuccessGetGifts}] = useGetGiftsMutation();
  const {
    data: favoritesIds,
    isSuccess: isSuccessGetFavoritesIds
  } = useGetFavoritesIdsProfilesQuery('', {skip: skipProfile});
  const [getProfiles, {
    data: profilesMutationData,
    isLoading,
  
    isSuccess: isSuccessGetProfiles
  }] = useGetProfilesMutation();
  const count = 15;
  const isLoadingProfiles = useRef<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  let selectedCountryDataIds = selectedCountryData && selectedCountryData.map((country) => `countryIds=${country.id}`).join('&');
  let religionDataIds = selectedReligionData && selectedReligionData.map((religion) => `religionIds=${religion.id}`).join('&');
  let selectedCitiesDataIds = selectedCountryData && selectedCountryData.flatMap(country => country.cities.map(city => `cityIds=${city.id}`)).join('&');

  const {
    data: selectedCountries,
  } = useGetCountryTranslateByIdQuery({
    countryIds: selectedCountryDataIds,
    lang: getLanguageByLocale(language),
  }, {skip: (currentLang === language || !currentLang || !selectedCountryDataIds.length)});

  const {
    data: selectedCities,
  } = useGetCitiesTranslateByIdQuery({
    citiesIds: selectedCitiesDataIds,
    lang: getLanguageByLocale(language),
  },  { skip: (currentLang === language || !currentLang || !selectedCitiesDataIds.length) });

  const {
    data: selectedReligions,
  } = useGetReligionsTranslateByIdQuery({
    religionIds: religionDataIds,
    lang: getLanguageByLocale(language),
  }, {skip: (currentLang === language || !currentLang || !religionDataIds.length)});

  useEffect(() => {
    if (currentLang !== language && (selectedCountries?.length || selectedCities?.length)) {
      if (selectedCitiesDataIds ) {
        if (selectedCountries?.length && selectedCities?.length) {
          const translateLocation: SelectedCountryType[] = [];
          selectedCountries?.forEach((country: CountryType) => {
            const cities = selectedCities?.filter((city: CityDataType) => city.countryIso === country.isoCode);
            translateLocation.push({
              name: country.country,
              id: country.id,
              isoCode: country.isoCode,
              cities: cities.length ?  cities.map((city: CityDataType) => ({
                name: city.name,
                id: city.id,
                isoCode: city.countryIso
              })) : []
            });
          });
          dispatch(updateFilterSelectedCountryData(translateLocation))
        }
      }
      else if (!selectedCitiesDataIds && selectedCountries?.length) {
        const translateLocation: SelectedCountryType[] = [];
        selectedCountries?.forEach((country: CountryType) => {
          translateLocation.push({
            name: country.country,
            id: country.id,
            isoCode: country.isoCode,
            cities: []
          });
        });
        dispatch(updateFilterSelectedCountryData(translateLocation))
      }
    }
  }, [selectedCountries, selectedCities]);

  useEffect(() => {
    if (currentLang !== language && selectedReligions?.length) {
      dispatch(updateFilterReligionData(selectedReligions))
    }
  }, [selectedReligions]);

  useEffect(() => {
    const container = document.getElementsByClassName(styles.profilesPageContainer)[0];
    if (container) {
      container.scrollTo({ top: 0});
    }
    
    if (!isLoading && (isAuthenticated === false ? true  : !skipSearch) && !isLoading) {
      const payload = {
        searchString: searchValue,
        gender: !!gender ? Number(gender) : null,
        birthDate: birthDate ? dayjs().year(birthDate.year).month(birthDate.month - 1).date(birthDate.day).format('YYYY-MM-DD') : null,
        deathDate: deathDate ? dayjs().year(deathDate.year).month(deathDate.month - 1).date(deathDate.day).format('YYYY-MM-DD') : null,
        ageFrom: ageFrom ? Number(ageFrom) : null,
        ageTo: ageTo ? Number(ageTo) : null,
        countryId: countryIds,
        cityId: cityIds,
        religionId: religionIds,
        offset: offsetSearch,
        count
      }
      
      getProfiles({ body: payload });
    }
  }, [isAuthenticated, skipSearch, searchValue, gender, ageFrom, ageTo, countryIds.length, cityIds.length, religionIds, birthDate, deathDate]);

  useEffect(() => {
    if ( !!candles && !!candles.length && isSuccessGetGifts ) {
      mergeGiftArrays();
    }
  }, [isSuccessGetGifts]);

  useEffect(() => {
    if ( !!profilesData.length && isSuccessGetFavoritesIds && !isSuccessFavoritesProfiles) {
      const mergedData = mergeFavoriteArrays();
      setProfilesData(mergedData);
    }

  }, [profilesData.length, favoritesIds]);

  useEffect(() => {
    if ( !!profilesMutationData && !!profilesMutationData.profileList.length && isSuccessGetProfiles && type !== 'profile') {
      const profileIds =  profilesMutationData.profileList.map((profile: ProfileType) => `profilesIds=${profile.id}`).join('&');
      if ( !searchValue && isLoadingProfiles.current || ((!!searchValue || filterTrigger) && isLoadingProfiles.current) ) {
        setProfilesData([...profilesData, ...profilesMutationData.profileList]);
      } else if ( !!searchValue || searchValue === "" ) {
        setProfilesData([...profilesMutationData.profileList]);
      }

      isLoadingProfiles.current = false;
      getGifts(profileIds)
    }
    if (!!profilesMutationData && profilesMutationData.profileList.length === 0 && isSuccessGetProfiles && (!!searchValue || filterTrigger) ) {
      setProfilesData([]);
    }
  }, [isSuccessGetProfiles])

  useEffect(() => {
    if ( !!favoritesProfiles && !!favoritesProfiles.length && isSuccessFavoritesProfiles && !isFavoriteLoading ) {
      const favoritesIds =  favoritesProfiles?.map((profile: ProfileType) => `profilesIds=${profile.id}`).join('&');
      setProfilesData(favoritesProfiles);
      getGifts(favoritesIds)
    } else {
      setProfilesData([]);
    }
  }, [favoritesProfiles])

  useEffect(() => {
    if (type === 'search') {
      return () => {
        dispatch(setOffset(0))
      }
    }
  }, []);


  const mergeGiftArrays = () => {
    const merged = profilesData.map((profile: ProfileType) => {
      const matchingItem = candles.find((candle: CandleType) => candle.profileId === profile.id);

      if ( !!matchingItem ) {
        return {
          ...profile,
          ...matchingItem
        };
      } else {
        return profile
      }

    });

    setProfilesData(merged);
  };

  const mergeFavoriteArrays = () => {
    if ( !!favoritesIds && !!favoritesIds.length ) {
      return profilesData.map((profile: ProfileType) =>  {
        return {
          ...profile,
          isFavorite: favoritesIds.includes(profile.id),
        };
      });
    } else {
      return profilesData;
    }
  };

  const handleScroll = (e: any) => {
    const divComponent: HTMLDivElement = e.target;
    if (divComponent.offsetHeight + divComponent.scrollTop >= divComponent.scrollHeight - 200) {
      if (!skipSearch && !isLoadingProfiles.current && !isLoading && profilesMutationData?.hasNext) {
        isLoadingProfiles.current = true;
        dispatch(setOffset(offsetSearch + count));
        const payload = {
          searchString: searchValue,
          gender: !!gender ? Number(gender) : null,
          birthDate: birthDate ? dayjs().year(birthDate.year).month(birthDate.month - 1).date(birthDate.day).format('YYYY-MM-DD') : null,
          deathDate: deathDate ? dayjs().year(deathDate.year).month(deathDate.month - 1).date(deathDate.day).format('YYYY-MM-DD') : null,
          ageFrom: ageFrom ? Number(ageFrom) : null,
          ageTo: ageTo ? Number(ageTo) : null,
          countryId: countryIds,
          cityId: cityIds,
          religionId: religionIds,
          offset: offsetSearch + count,
          count
        }
        getProfiles({ body: payload });
      }
    }
  }

  const searchByName = (name: string, profiles: any) => {
    const searchResult = profiles.filter((profile: any) => {
      const fullName = `${profile.firstName} ${profile.middleName || ''} ${profile.lastName}`;
      const fullNameEnglish = `${profile.inEnglish.firstName} ${profile.inEnglish.middleName || ''} ${profile.inEnglish.lastName}`;
      return fullName.toLowerCase().includes(name.toLowerCase()) ||
        fullNameEnglish.toLowerCase().includes(name.toLowerCase());
    });
    setProfilesData([...searchResult]);
    const container = document.getElementsByClassName(styles.profilesContainerWithSearch)[0];
      if (container) {
        container.scrollTo({ top: 0});
      }
  }

  const onSearchClick = () => {
    dispatch(setOffset(0))
    searchByName(searchTerm, profilesData)
  };

  const onChangeSearch = (value: string) => {
    const updateValue = value.slice(0, MAX_MAIN_SEARCH_LENGTH)
      .replace(/''+/g, "'");
    if (/^[a-zA-Zа-яА-ЯßäöüÄÖÜßß0-9.'()\s-]*$/.test(updateValue)) {
      setSearchTerm(updateValue);
    }
  };

  const onBlurSearch = () => {
    if(searchTerm === '') {
      setProfilesData([...favoritesProfiles]);
    }
  };

  const backClick = () => {
    navigate('/')
  };

  return (
    <>
      {
        !favoritesProfiles?.length && (
          <div className={styles.mobileMainSearch}>
            <Search/>
          </div>
        )
      }
      <div className={`${!!favoritesProfiles?.length ? styles.profilesContainerWithSearch : styles.profilesPageContainer}`} onScroll={handleScroll}>
        {!!favoritesProfiles?.length && (
          <div className={styles.searchContainer}>
            <div className={styles.modalWindowHeaderContainer}>
              <ModalWindowHeader title={t("userProfile.usersProfiles.favoriteProfiles")} onClick={backClick} />
            </div>
            <SearchInput
              onClick={onSearchClick}
              onChange={onChangeSearch}
              onBlur={onBlurSearch}
              value={searchTerm}
              type="search"
              isDisabledButton={!searchTerm}
            />
          </div>
        )}
        <div className={styles.profilesPageBody}>
          <div className={styles.profilesGrid}>
            {!!profilesData.length && profilesData.map((item: any, index: number) => {
              return (
                <ProfileCard
                  mainPictureId={item.mainPictureId}
                  isCreatedByCurrentUser={item.isCreatedByCurrentUser}
                  id={item.id}
                  key={index}
                  name={`${item.lastName || ""} ${item.firstName || ""} ${
                    item.middleName || ""
                  }`}
                  subName={`${item.inEnglish.lastName || ""} ${
                    item.inEnglish.firstName || ""
                  } ${item.inEnglish.middleName || ""}`}
                  dateOfBirth={item.dateOfBirth}
                  dateOfDeath={item.dateOfDeath}
                  country={item.burialPlace.countryId}
                  isFavorite={item.isFavorite}
                  img={item.avatarPreviewUrl}
                  candles={item.gifts}
                />
              );
            })}
            {isSuccessGetProfiles && (!!searchValue || filterTrigger) && profilesMutationData.profileList.length === 0 && (
              <div className={styles.emptyContainer}>
                <p className={styles.emptyPageText}>{t('profile.emptyProfileSearch')}
                  <br/>{t('profile.createProfileSearch')} </p>
                <button className={styles.createProfileButton}
                        onClick={() => navigate('/createProfile')}>{t('profile.createProfile')}</button>
              </div>
            )}
            {type === 'profile' && !favoritesProfiles?.length && isSuccessFavoritesProfiles && (
              <div className={styles.emptyContainer}>
                <p className={styles.emptyPageText}>{t('profile.emptyProfilePage')}
                  <br/>{t('profile.createProfileDescription')} </p>
                <button className={styles.createProfileButton}
                        onClick={() => navigate('/createProfile')}>{t('profile.createProfile')}</button>
              </div>
            )}
            {!!searchTerm && !profilesData?.length && (
              <div className={styles.emptyContainer}>
                <p className={styles.emptyPageText}>{t('profile.emptyProfileSearch')}
                  <br/>{t('profile.createProfileSearch')} </p>
                <button className={styles.createProfileButton}
                        onClick={() => navigate('/createProfile')}>{t('profile.createProfile')}</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <NotificationPopup/>
      <div className={styles.menuContainer}>
        <Menu />
      </div>
    </>
  );
};
