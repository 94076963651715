export function pluralize(number: number, one: string, few: string, many: string): string {
    if (number === 1) {
        return `${number} ${one}`;
    } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
        return `${number} ${few}`;
    } else if (number % 10 === 0 || (number % 100 >= 11 && number % 100 <= 14)) {
        return `${number} ${many}`;
    } else if (number % 10 === 1 && number % 100 !== 11) {
      return `${number} ${one}`;
    } else {
      return `${number} ${many}`;
    }
}