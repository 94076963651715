import { ModalWindow } from "../../../components/modalWindow/ModalWindow";
import styles from "./DeletePopup.module.scss";
import { useTranslation } from "react-i18next";

type DeletePopupProps = {
  onClick: (value: string) => void;
  text: string;
};

export const DeletePopup = ({ onClick, text }: DeletePopupProps) => {
  const { t } = useTranslation();

  return (
    <ModalWindow
      onClose={() => onClick("cansel")}
      isShow={true}
      backdropModal={true}
      headerText={text}
      children={
        <div className={styles.modalWindowButtonContainer}>
          <p className={styles.deleteButton} onClick={() => onClick("delete")}>
            {t("common.delete")}
          </p>
          <p className={styles.cancelButton} onClick={() => onClick("cansel")}>
            {t("common.cancel")}
          </p>
        </div>
      }
    />
  );
};
