export const allowedImageTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/heif",
  "image/heic",
  "image/webp",
];
export const allowedVideoTypes = [
  "video/mp4",
  "video/quicktime",
  "video/ogg",
  "video/webm",
  "video/avi",
  "video/mpeg",
  "video/x-ms-wmv",
  "video/3gpp2",
  "video/3gpp",
  "video/x-matroska",
  "video/x-ms-asf",
  "video/vnd.dlna.mpeg-tts",
  // "text/plain",
];
export const acceptFormats =
  "image/jpeg, image/jpg, image/png, image/heif, image/heic, image/webp, video/mp4, video/quicktime, video/ogg, video/webm,  video/avi, video/mpeg, video/x-ms-wmv, video/3gpp2, video/3gpp, video/x-matroska, video/x-ms-asf, video/vnd.dlna.mpeg-tts, text/plain";
export const acceptImageFormats =
  "image/jpeg, image/jpg, image/png, image/heif, image/heic, image/webp";
