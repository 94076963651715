import { createSlice } from "@reduxjs/toolkit";
import { ProfileCandlesType } from "../../types/MemorialTypes";
import { MemorialTypes } from "../../constants/Memorial.constant";
import { PostType } from "../../types/ProfileType";

interface InitialState {
  checkedTab: string;
  candlesData: ProfileCandlesType[];
  feelingsData: ProfileCandlesType[];
  postsData: PostType[];
  hasPreviousPosts:boolean;
  feelingsPageNumber: number;
  candlesPageNumber: number;
  postsPageNumber: number;
  isWallLoading: boolean;
  isCreatedByCurrentUser:boolean
  photoHash:number

}
const initialState: InitialState = {
  checkedTab: MemorialTypes.candles,
  candlesData: [],
  feelingsData: [],
  postsData: [],
  feelingsPageNumber: 1,
  candlesPageNumber: 1,
  postsPageNumber: 1,
  hasPreviousPosts:false,
  isWallLoading: false,
  isCreatedByCurrentUser:false,
  photoHash:Math.floor(Math.random() * 9000000)
};

export const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  reducers: {
    setCheckedTab: (state, action) => {
      state.checkedTab = action.payload;
    },
    setCandlesData: (state, action) => {
      state.candlesData = action.payload;
    },
    setPostsData: (state, action) => {
      state.postsData = action.payload;
    },
    setFeelingsData: (state, action) => {
      state.feelingsData = action.payload;
    },
    setCandlesPageNumber: (state, action) => {
      state.candlesPageNumber = action.payload;
    },
    setFeelingsPageNumber: (state, action) => {
      state.feelingsPageNumber = action.payload;
    },
    setPostsPageNumber: (state, action) => {
      state.postsPageNumber = action.payload;
    },
    addProfilePost: (state, action) => {
      state.postsData.unshift(action.payload);
    },
    updateLike: (state, action) => {
      const post = state.postsData.find(
        (item) => item.id === action.payload.id
      );
      if (post) {
        post.isLikedByUser = action.payload.state;
        if (action.payload.state) {
          post.likesCount += 1;
        } else {
          post.likesCount -= 1;
        }
      }
    },
    deleteComment: (state, action) => {
      const post = state.postsData.find(
        (item) => item.id === action.payload
      );
      if (post) {
        post.commentsCount -= 1;
      }
    },
    addNewComment: (state, action) => {
      const post = state.postsData.find(
        (item) => item.id === action.payload
      );
      
      if (post) {
        post.commentsCount += 1;
        post.isCommentedByUser=true
      }
    },
    deleteProfilePost: (state, action) => {
      const posts = state.postsData.filter(
        (item) => item.id !== action.payload
      );
      
      state.postsData = posts
    },
    setIsWallLoading: (state, action) => {
      state.isWallLoading = action.payload;
    },
    setHasPreviousPosts: (state, action) => {
      state.hasPreviousPosts = action.payload;
    },
    setIsCreatedByCurrentUser: (state, action) => {
      state.isCreatedByCurrentUser = action.payload;
    },
    resetProfileData: (state) => {
      state.checkedTab = MemorialTypes.candles;
      state.candlesData = [];
      state.feelingsData = [];
      state.postsData = [];
      state.feelingsPageNumber = 1;
      state.candlesPageNumber = 1;
      state.postsPageNumber = 1;
    },
    updatePhotoHash: (state) => {
      state.photoHash = Math.floor(Math.random() * 9000000)
    },
  },
});
export const {
  setCheckedTab,
  setCandlesData,
  setPostsData,
  setFeelingsData,
  setCandlesPageNumber,
  setFeelingsPageNumber,
  setPostsPageNumber,
  addProfilePost,
  updateLike,
  deleteComment,
  addNewComment,
  deleteProfilePost,
  setIsWallLoading,
  setHasPreviousPosts,
  resetProfileData,
  setIsCreatedByCurrentUser,
  updatePhotoHash
} = profileSlice.actions;
export default profileSlice.reducer;
