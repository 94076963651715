import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token-profile");
  return token;
};

const baseUrl = process.env.REACT_APP_PROFILE_DNS_NAME as string;

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),

  endpoints: (build) => ({
    getOwnedProfiles: build.query({
      query: () => ({
        url: `/api/Profiles/Owned/Me`,
      }),
    }),
    getFavoritesProfiles: build.query({
      query: () => ({
        url: `api/Profiles/Favorites/Me`,
      }),
    }),
    getFavoritesIdsProfiles: build.query({
      query: () => ({
        url: `api/profiles/favorites`,
      }),
    }),
    getAllProfiles: build.query({
      query: () => ({
        url: `api/Profiles/All`,
      }),
    }),
    getProfile: build.query({
      query: ({id, lang}) => ({
        url: `/api/Profiles/${id}?languageCode=${lang}`,
      }),
    }),
    updateProfile: build.mutation({
      query: (data) => ({
        url: `/api/Profiles/${data.profileId}`,
        method: "PUT",
        body: data.body,
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            profileApi.util.upsertQueryData("getProfile", {id:value.profileId, lang:value.lang}, data)
           
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getLinks: build.query({
      query: (id) => ({
        url: `/api/profile/${id}/info/Links`,
      }),
    }),
    updateLinks: build.mutation({
      query: (data) => ({
        url: `/api/profile/${data.profileId}/info/Links`,
        method: "POST",
        body: data.body,
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            profileApi.util.upsertQueryData("getLinks", value.profileId, data)
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getWorks: build.query({
      query: (id) => ({
        url: `/api/profile/${id}/info/Works`,
      }),
    }),
    updateWorks: build.mutation({
      query: (data) => ({
        url: `/api/profile/${data.profileId}/info/Works`,
        method: "POST",
        body: data.body,
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            profileApi.util.updateQueryData(
              "getWorks",
              value.profileId,
              (draft) => {
                draft.unshift(data);
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deleteWorks: build.mutation({
      query: (data) => ({
        url: `/api/profile/${data.profileId}/info/Works/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            profileApi.util.updateQueryData(
              "getWorks",
              value.profileId,
              (draft) => {
                return(draft as any[]).filter((item) => item.id !== value.id);
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getHobbies: build.query({
      query: (id) => ({
        url: `/api/profile/${id}/info/Hobbies`,
      }),
    }),
    updateHobbies: build.mutation({
      query: (data) => ({
        url: `/api/profile/${data.profileId}/info/Hobbies`,
        method: "POST",
        body: data.body,
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            profileApi.util.updateQueryData(
              "getHobbies",
              value.profileId,
              (draft) => {
                draft.unshift(data);
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deleteHobbies: build.mutation({
      query: (data) => ({
        url: `/api/profile/${data.profileId}/info/Hobbies/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            profileApi.util.updateQueryData(
              "getHobbies",
              value.profileId,
              (draft) => {
                return(draft as any[]).filter((item) => item.id !== value.id);
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getEducations: build.query({
      query: (id) => ({
        url: `/api/profile/${id}/info/Educations`,
      }),
    }),
    updateEducations: build.mutation({
      query: (data) => ({
        url: `/api/profile/${data.profileId}/info/Educations`,
        method: "POST",
        body: data.body,
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            profileApi.util.updateQueryData(
              "getEducations",
              value.profileId,
              (draft) => {
                draft.unshift(data);
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deleteEducations: build.mutation({
      query: (data) => ({
        url: `/api/profile/${data.profileId}/info/Educations/${data.id}`,
        method: "DELETE",
        body: data,
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            profileApi.util.updateQueryData(
              "getEducations",
              value.profileId,
              (draft) => {
                return (draft as any[]).filter((item) => item.id !== value.id);
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getFrame: build.query({
      query: (id) => ({
        url: `/api/profile/PhotoFrame/${id}`,
      }),
    }),
    postFrame: build.mutation({
      query: (body) => ({
        url: `/api/profile/photoframe`,
        method: "POST",
        body: body,
      }),
    }),
    getAllFrames: build.query({
      query: () => ({
        url: `/api/profile/PhotoFrame`,
      }),
    }),

    putFavoriteProfile: build.mutation({
      query: (profileId) => ({
        url: `/api/Profiles/Favorites/Me/${profileId}`,
        method: "PUT",
      }),
      async onQueryStarted(profileId, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            profileApi.util.updateQueryData(
              "getFavoritesIdsProfiles",
              "",
              (draft) => {
                draft.push(profileId);
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deleteFavoriteProfile: build.mutation({
      query: (profileId) => ({
        url: `/api/Profiles/Favorites/Me/${profileId}`,
        method: "DELETE",
      }),
      async onQueryStarted(profileId, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            profileApi.util.updateQueryData(
              "getFavoritesProfiles",
              "",
              (draft) => {
                draft = (draft as any[]).filter(
                  (item) => item.id !== profileId
                );
              }
            )
          );
          dispatch(
            profileApi.util.updateQueryData(
              "getFavoritesIdsProfiles",
              "",
              (draft) => {
                return (draft as any[]).filter(
                  (item) => item !== profileId
                );
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deleteProfile: build.mutation({
      query: (profileId) => ({
        url: `/api/Profiles/${profileId}`,
        method: "DELETE",
      }),
      async onQueryStarted(profileId, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            profileApi.util.updateQueryData(
              "getFavoritesProfiles",
              "",
              (draft) => {
                draft = (draft as any[]).filter(
                  (item) => item.id !== profileId
                );
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    createProfile: build.mutation({
      query: (body) => ({
        url: `/api/Profiles/Create`,
        method: "POST",
        body,
      }),
    }),
    getShortLink: build.query({
      queryFn: async (id: string) => {
        try {
          const response = await fetch(
            `${baseUrl}/api/Profiles/${id}/info/short-link`,
            {
              cache: "no-store",
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
            }
          );
          return { data: await response.text() };
        } catch (e: any) {
          return { error: e.message };
        }
      },
    }),
    getQRCode: build.query({
      queryFn: async (id) => {
        try {
          const response = await fetch(
            `${baseUrl}/api/profile/${id}/info/qr`,
            {
              cache: "no-store",
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
            }
          );
          const dataURL = await response.blob();
          return { data: await URL.createObjectURL(dataURL) };
        } catch (e: any) {
          return { error: e.message };
        }
      },
    }),
    downloadQRCode: build.query({
      queryFn: async ({ id, format }) => {
        try {
          const response = await fetch(
            `${baseUrl}/api/profile/${id}/info/qr/${format}`,
            {
              cache: "no-store",
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
            }
          );
          const dataURL = await response.blob();
          const url = URL.createObjectURL(dataURL);
          const a = document.createElement("a");
          a.href = url;
          a.download = "file_QR.pdf";
          a.click();
          return { data: await URL.createObjectURL(dataURL) };
        } catch (e: any) {
          return { error: e.message };
        }
      },
    }),
  }),
});
export const {
  useGetOwnedProfilesQuery,
  useGetFavoritesProfilesQuery,
  useGetFavoritesIdsProfilesQuery,
  useGetAllProfilesQuery,
  useGetProfileQuery,
  useDeleteProfileMutation,
  useUpdateProfileMutation,
  useDeleteFavoriteProfileMutation,
  usePutFavoriteProfileMutation,
  useCreateProfileMutation,
  usePostFrameMutation,
  useGetFrameQuery,
  useGetAllFramesQuery,
  useGetWorksQuery,
  useUpdateWorksMutation,
  useDeleteWorksMutation,
  useGetEducationsQuery,
  useUpdateEducationsMutation,
  useDeleteEducationsMutation,
  useGetHobbiesQuery,
  useUpdateHobbiesMutation,
  useDeleteHobbiesMutation,
  useGetLinksQuery,
  useUpdateLinksMutation,
  useGetShortLinkQuery,
  useGetQRCodeQuery,
  useDownloadQRCodeQuery,
} = profileApi;
