import { useTranslation } from "react-i18next";
import styles from "./ProfileCard.module.scss";
import { ReactComponent as UserIcon } from "../../image/userIcon.svg";
import { ReactComponent as FavoriteIcon } from "../../image/favoriteFilled.svg";
import { ReactComponent as EditIcon } from "../../image/editIcon.svg";
import { ReactComponent as SelectedIcon } from "../../image/selectedIcon.svg";
import ellipsisIcon from "../../image/points.svg";
import { MouseEventHandler, useEffect, useState } from "react";
import {
  useDeleteFavoriteProfileMutation,
  usePutFavoriteProfileMutation,
} from "../../../api/ProfileService";
import { Link } from "react-router-dom";
import calculateAgeAndLifeSpan from "../../../utils/calculateAgeAndLifeSpan";
import { useGetCountryByIdQuery } from "../../../api/UtilityService";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { GiftType } from "../../../pages/profilesPage/ProfilesPage";
import { getLanguageByLocale } from "../../../utils/localeUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";

type ProfileCardProps = {
  name: string;
  subName: string;
  candles?: GiftType[];
  isCreatedByCurrentUser: boolean;
  img: string;
  isSelectable?: boolean;
  isSelected?: boolean;
  id: string;
  mainPictureId: string | null;
  country?: string;
  dateOfBirth: string;
  dateOfDeath: string;
  isFavorite: boolean;
  onProfileClick?: (value: {
    profileId: string;
    profileFullName: string;
    profileFullNameEn: string;
    dateOfBirth: string;
    dateOfDeath: string;
    avatarUrl: string;
  }) => void;
};
const url = process.env.REACT_APP_BLOB_DNS_NAME as string;
const MAX_DISPLAY_CANDLES = 3;

export const ProfileCard = ({
  name,
  candles,
  subName,
  country,
  dateOfBirth,
  dateOfDeath,
  isCreatedByCurrentUser,
  isSelectable,
  isSelected,
  isFavorite,
  id,
  img,
  mainPictureId,
  onProfileClick,
}: ProfileCardProps) => {
  const [isFavoriteProfile, setIsFavoriteProfile] =
    useState<boolean>(isFavorite);
  const [deleteFavorite, { isLoading }] = useDeleteFavoriteProfileMutation();
  const [putFavorite, { isLoading: isPutLoading }] =
    usePutFavoriteProfileMutation();
  const { t } = useTranslation();
  const { language } = useSelector((state: RootState) => state.language);
  const { photoHash } = useSelector((state: RootState) => state.profileSlice);

  const { data: countryName } = useGetCountryByIdQuery(
    { countryId: country, lang: getLanguageByLocale(language) },
    {
      skip: !country,
    }
  );
  const currentCandles =
    candles?.length && candles?.length > MAX_DISPLAY_CANDLES
      ? [...candles?.slice(0, MAX_DISPLAY_CANDLES)]
      : candles;

  useEffect(() => {
    setIsFavoriteProfile(isFavorite);
  }, [isFavorite]);

  const { ageInYears, lifeSpan } = calculateAgeAndLifeSpan(
    dateOfBirth,
    dateOfDeath,
    t("common.unknown")
  );

  const onClick: MouseEventHandler<SVGSVGElement> = (event) => {
    event.stopPropagation();

    if (!isPutLoading && !isLoading) {
      if (isFavoriteProfile) {
        deleteFavorite(id);
      } else {
        putFavorite(id);
      }
      setIsFavoriteProfile(!isFavoriteProfile);
    }
  };

  const onCardClick = () => {
    if (onProfileClick) {
      onProfileClick({
        profileId: id,
        profileFullName: name,
        profileFullNameEn: subName,
        avatarUrl: mainPictureId || "",
        dateOfBirth,
        dateOfDeath,
      });
    }
  };

  return (
    <div
      className={
        isSelectable
          ? isSelected
            ? styles.profileCardContainerSelected
            : styles.profileCardContainerSelectable
          : styles.profileCardContainer
      }
      onClick={onCardClick}
    >
      <div className={styles.profileCardImgContainer}>
        <Link className={styles.link} to={`/profile/${id}`}>
          {!!img ? (
            <LazyLoadImage
              alt={""}
              src={`${url}${img}?${photoHash}`} 
            />
          ) : (
            <div className={styles.imgContainer}>
              <UserIcon />
            </div>
          )}
        </Link>
      </div>
      <div className={styles.profileCardBody}>
        <div className={styles.profileCardName}>
          <Link className={styles.link} to={`/profile/${id}`}>
            <h3>{name}</h3>
          </Link>
          <p>{subName}</p>
        </div>
        <div className={styles.profileCardDescription}>
          <p>
            <span>{lifeSpan}</span>{" "}
            <span className={styles.ageInfo}>{`(${ageInYears} ${t(
              "common.years"
            )})`}</span>
            {country && countryName?.country && (
              <span className={styles.countryInfo}>
                {" "}
                &middot; {countryName?.country || ""}
              </span>
            )}
          </p>
        </div>
        <div className={styles.profileCardCandles}>
          {!!currentCandles?.length &&
            currentCandles.map((candle: GiftType, index: number) => {
              return (
                <div key={index} className={styles.candleContainer}>
                  <LazyLoadImage src={`${url}${candle?.mediaUrl}`} alt="" />
                </div>
              );
            })}
          {!!candles?.length && candles.length > MAX_DISPLAY_CANDLES && (
            <Link className={styles.link} to={`/profile/${id}`}>
              <div className={styles.ellipsisIcon}>
                <img src={ellipsisIcon} alt="" />
              </div>
            </Link>
          )}
        </div>

        <>
          <AuthenticatedTemplate>
            <FavoriteIcon
              className={`${styles.profileCardIcon} ${
                isFavoriteProfile ? styles.favorite : ""
              }`}
              onClick={onClick}
            />
          </AuthenticatedTemplate>
        </>
        {isSelected && <SelectedIcon className={styles.selectIcon} />}
        {isCreatedByCurrentUser && (
            <Link to={`/editProfile/${id}`}>
              <EditIcon className={styles.editIcon} />
            </Link>
        )}
      </div>
    </div>
  );
};
