import { useEffect, useRef, useState } from "react";
import styles from "./ModalWithTextarea.module.scss";
import { SimpleTextarea } from "../simpleTextarea/SimpleTextarea";
import { ModalWindow } from "../../../components/modalWindow/ModalWindow";
import { SwipeDownIndicator } from "../SwipeDownIndicator/SwipeDownIndicator";

type ModalWithTextareaType = {
  onClose: () => void;
  isShow: boolean;
  headerText: string;
  onSubmit: (text: string) => void;
  placeholder?: string;
  maxLength?: number;
  submitButtonName?: string;
  idTextarea: string;
  isFeedback?: boolean;
  isMobile?: boolean;
};

export const ModalWithTextarea = ({
  onClose,
  isShow,
  headerText,
  onSubmit,
  placeholder,
  maxLength,
  submitButtonName,
  idTextarea,
  isMobile,
}: ModalWithTextareaType) => {
  const [text, setText] = useState<string>("");
  const [isHiddenOverflow, setIsHiddenOverflow] = useState<boolean>(false);
  const startY = useRef<number>(0);

  useEffect(() => {
    const container = document.getElementById("hiddenOverflowContainer");
    const textarea = document.getElementById(idTextarea);
    const handleTouchStart = (e: TouchEvent) => {
      startY.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (isHiddenOverflow && textarea) {
        const deltaY = e.touches[0].clientY - startY.current;
        const isAtTop = textarea.scrollTop === 0 && deltaY > 0;
        const isAtBottom =
          textarea.scrollHeight - textarea.scrollTop ===
            textarea.clientHeight && deltaY < 0;
        const target = e.target as HTMLButtonElement; 
        if (isAtTop || isAtBottom || target.id !== idTextarea) {
          e.preventDefault();
        }
      }
    };
    container?.addEventListener("touchstart", handleTouchStart);
    container?.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    return () => {
      container?.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isHiddenOverflow]);

  const onSubmitText = () => {
    onSubmit(text);
  };

  return (
    <ModalWindow
      onClose={onClose}
      isShow={isShow}
      headerText={headerText}
      isMobile={isMobile}
      isFeedback
      isHiddenOverflow={isHiddenOverflow}
      children={
        <>
          {isMobile && (
            <SwipeDownIndicator onClose={onClose} title={headerText} />
          )}
          <div className={styles.textarea}>
            <SimpleTextarea
              onChange={(value) => setText(value)}
              value={text}
              placeholder={placeholder}
              id={idTextarea}
              maxLength={maxLength}
              isAutoFocus
              onFocus={() => setIsHiddenOverflow(true)}
              onBlur={() => setIsHiddenOverflow(false)}
            />
          </div>
          <button
            className={styles.buttonConfirmModal}
            onClick={onSubmitText}
            disabled={!text}
          >
            {submitButtonName}
          </button>
        </>
      }
    ></ModalWindow>
  );
};
