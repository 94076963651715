import { useForm, FieldValues } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Input } from "../../../shared/components/input/Input";
import styles from "./EditForm.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { setNameData } from "../../../store/slices/CreateProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import useUpdateErrors from "../../../utils/useUpdateErrors";
import { handleSpecificCharsKeyDown } from '../../../utils/handleSpecificCharsKeyDown';
import { GENDER_FEMALE, GENDER_MALE, MAX_NAME_LENGTH } from '../../../constants/constants';

type NameFormProps = {
  onClick: () => void;
};

export const NameForm = ({ onClick }: NameFormProps) => {
  const { firstName, middleName, lastName, gender } = useSelector(
    (state: RootState) => state.createProfile.mainInfo
  );
  const { t } = useTranslation();

  const schema =  yup.object().shape({
      firstName: yup.string().trim().required(t("common.requiredField")),
      lastName: yup.string().trim().required(t("common.requiredField")),
      middleName: yup.string().trim(),
    }) as yup.ObjectSchema<FieldValues>;
  const resolver = yupResolver(schema);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    watch,
  } = useForm<FieldValues>({
    resolver,
    defaultValues: {
      firstName,
      middleName,
      lastName,
      gender,
    },
  });
  const dispatch = useDispatch();

  const onSubmit = (data: FieldValues) => {
    const correctData = data;
    correctData.gender = Number(data.gender);
    dispatch(setNameData(correctData));
    onClick();
  };
  const watchFields = watch();

  useUpdateErrors(handleSubmit(onSubmit), !!Object.keys(dirtyFields).length);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <h3 className={styles.formHeader}>
          {t("createProfile.personalInformation")}
        </h3>
        <Input
          register={register}
          setValue={setValue}
          name="lastName"
          type="text"
          placeholder={`${t("common.lastName")}*`}
          error={errors.lastName?.message as string}
          maxLength={MAX_NAME_LENGTH}
          handleKeyDown={handleSpecificCharsKeyDown}
        />
        <Input
          setValue={setValue}
          register={register}
          name="firstName"
          type="text"
          placeholder={`${t("createProfile.firstName")}*`}
          error={errors.firstName?.message as string}
          maxLength={MAX_NAME_LENGTH}
          handleKeyDown={handleSpecificCharsKeyDown}
        />

        <Input
          register={register}
          setValue={setValue}
          name="middleName"
          type="text"
          placeholder={`${t("common.middleName")}`}
          maxLength={MAX_NAME_LENGTH}
          handleKeyDown={handleSpecificCharsKeyDown}
        />
        <h3 className={styles.formHeader}>{t("createProfile.gender")}</h3>
        <div className={styles.groupContainer}>
          <label
            className={
            watchFields.gender == GENDER_MALE
              ? styles.radioButtonLabelSelected
              : styles.radioButtonLabel
            }
          >
            <input
              type="radio"
              value={GENDER_MALE}
              {...register("gender")}
              checked={watchFields.gender == GENDER_MALE}
              className={watchFields.gender == GENDER_MALE ? styles.checkedInput : ""}
            />
            {t("createProfile.male")}
          </label>

          <label
            className={
            Number(watchFields.gender) === GENDER_FEMALE
              ? styles.radioButtonLabelSelected
              : styles.radioButtonLabel
            }
          >
            <input
              type="radio"
              value={GENDER_FEMALE}
              checked={watchFields.gender == GENDER_FEMALE}
              className={watchFields.gender == GENDER_FEMALE ? styles.checkedInput : ""}
              {...register("gender")}
            />
            {t("createProfile.female")}
          </label>
        </div>
        <button
          className={styles.formButton}
          type="submit"
          disabled={
            !watchFields.firstName &&
            !watchFields.lastName &&
            !watchFields.middleName
          }
        >
          {t("common.next")}
        </button>
      </div>
    </form>
  );
};
