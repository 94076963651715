import styles from "./Comments.module.scss";
import { useGetCommentsMutation } from "../../../../../../api/PostProfileService";
import { useParams } from "react-router-dom";
import React, { TouchEvent, useEffect, useRef, useState } from "react";
import { CommentWrapper } from "../commentWrapper/CommentWrapper";
import { CommentType } from "../../../../../../types/ProfileType";
import { useDispatch } from "react-redux";
import {
  addNewComment,
  deleteComment,
} from "../../../../../../store/slices/ProfileSlice";
import { useTranslation } from "react-i18next";
import { AddCommentField } from "../../addCommentField/AddCommentField";

type CommentsProps = {
  commentsCount: number;
  postId: string;
  onBlur: () => void;
  onClose?: () => void;
  isMobile?: boolean;
  isFocus?: boolean;
};

export const Comments = React.memo(
  ({
    commentsCount,
    postId,
    isMobile,
    onBlur,
    isFocus,
    onClose,
  }: CommentsProps) => {
    const { id } = useParams() as { id: string };
    const [getComments, { data }] = useGetCommentsMutation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isDragging = useRef(false);
    const [comments, setComments] = useState<CommentType[]>([]);
    const [addedComments, setAddedComments] = useState<CommentType[]>([]);
    const [showPageCount, setShowPageCount] = useState<number>(
      isMobile ? 1 : 0
    );
    const [loadedPageCount, setloadedPageCount] = useState<number>(1);
    const [touchStartY, setTouchStartY] = useState<number | null>(null);
    const { t } = useTranslation();
    const commentRef = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();

    useEffect(() => {
      if (loadedPageCount && commentsCount) {
        setIsLoading(true);
        getComments({ profileId: id, postId, pageNumber: loadedPageCount });
      }
    }, [loadedPageCount]);

    useEffect(() => {
      if (data) {
        if (loadedPageCount === 1) {
          setComments([...data.items]);
        } else {
          setComments([...comments, ...data.items]);
          if (addedComments.length) {
            const filteredArray = addedComments.filter(
              (obj1) =>
                !data.items.some((obj2: CommentType) => obj1.id === obj2.id)
            );
            setAddedComments(filteredArray);
          }
        }
        setIsLoading(false);
      }
    }, [data]);
    
    useEffect(() => {
      const handleTouchMove: (
        this: Document,
        ev: TouchEventInit | TouchEvent
      ) => any = function (event) {
        if (
          isDragging.current &&
          event.touches &&
          event.touches[0].clientY > 0
        ) {
          (event as TouchEvent).stopPropagation();
          (event as TouchEvent).preventDefault();
        }
      };

      document.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });

      return () => {
        document.removeEventListener("touchmove", handleTouchMove);
      };
    }, []);

    const addComment = (value: CommentType) => {
      dispatch(addNewComment(postId));

      if (data?.totalPages === showPageCount || (!data?.totalPages && !comments.length) || ( comments.length-showPageCount * 10 <=0)) {
        setComments([...comments, value]);
      } else if (comments.length === 1 || !data?.totalPages ) {
        setComments([...comments, value]);
        setShowPageCount(1);
      } else {
        setAddedComments([...addedComments, value]);
      }
    };
    const onShowButtonClick = () => {
      if (!isLoading) {
        if (
          showPageCount + 1 === data?.totalPages &&
          showPageCount + 1 === loadedPageCount &&
          addedComments.length
        ) {
          setComments([...comments, ...addedComments]);
          setAddedComments([]);
        }

        if (loadedPageCount <= showPageCount) {
          setloadedPageCount((prev) => prev + 1);
        }
        setShowPageCount((prev) => prev + 1);
      }
    };
    const onCommentDelete = (id: string) => {
      const filteredComments = comments.filter((item) => item.id !== id);
      setComments(filteredComments);
      dispatch(deleteComment(postId));
    };
    const onAddedCommentDelete = (id: string) => {
      const filteredComments = addedComments.filter((item) => item.id !== id);
      setAddedComments(filteredComments);
      dispatch(deleteComment(postId));
    };
    const onReplyDelete = (id: string) => {
      const updatedComments = comments.map((comment) => {
        if (comment.id === id) {
          return {
            ...comment,
            replyCount: comment.replyCount ? comment.replyCount - 1 : 0,
          };
        }
        return comment;
      });
      setComments(updatedComments);
    };
    const onAddReply = (id: string, count?:number) => {
      const updatedComments = comments.map((comment) => {
        if (comment.id === id) {
          return {
            ...comment,
            replyCount:count? count : comment.replyCount ? comment.replyCount + 1 : 1,
          };
        }
        return comment;
      });
      setComments(updatedComments);
    };
    const handleStartSwipe = (e: TouchEvent<HTMLDivElement>) => {
      setTouchStartY(e.touches[0].clientY);
      isDragging.current = true;
    };
    const handleSwipe = (e: TouchEvent<HTMLDivElement>) => {
      if (touchStartY) {
        const swipeThreshold = 50;
        const touchEndY = e.changedTouches[0].clientY;
        const deltaY = touchEndY - touchStartY;
        isDragging.current = false;

        if (deltaY > swipeThreshold && onClose) {
          onClose();
        }
      }
    };
    const hideComments = () => {
      setShowPageCount(isMobile ? 1 : 0);
      commentRef?.current?.scrollIntoView({ behavior: "smooth" });
    };
    const hideAddedComments = () => {
      if (data?.totalPages === loadedPageCount || !data) {
        setComments([...comments, ...addedComments]);
      }
      setAddedComments([]);
      setShowPageCount(0);
      commentRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
      <div className={styles.commentsWrapper} ref={commentRef}>
        <div
          className={styles.commentsTitleMobile}
          onTouchStart={handleStartSwipe}
          onTouchEnd={handleSwipe}
        >
          <span></span>
          <h3>{t("posts.comments")}</h3>
        </div>

        <div className={styles.commentsContainer}>
          <CommentWrapper
            data={comments?.[0]}
            postId={postId}
            onCommentDelete={onCommentDelete}
            onReplyDelete={onReplyDelete}
            onAddReply={onAddReply}
          />
          {!!showPageCount &&
            comments
              .slice(1, 10 * showPageCount)
              .map((comment: CommentType) => (
                <CommentWrapper
                  data={comment}
                  postId={postId}
                  onCommentDelete={onCommentDelete}
                  onReplyDelete={onReplyDelete}
                  key={comment.id}
                  onAddReply={onAddReply}
                />
              ))}

          {!!commentsCount && (
            <div className={styles.actionContainer}>
              {(commentsCount - addedComments.length > 1 && !showPageCount) ||
              (!!showPageCount &&
                commentsCount - addedComments.length > showPageCount * 10) ? (
                <p onClick={onShowButtonClick}>{`${t("posts.showComments")} (${
                  !!showPageCount
                    ? Math.min(
                        commentsCount -
                          addedComments.length -
                          Math.min(showPageCount * 10, comments.length),
                        10
                      )
                    : commentsCount - addedComments.length - 1
                })`}</p>
              ) : (
                !isMobile &&
                commentsCount - addedComments.length > 1 && (
                  <p onClick={hideComments}>{t("posts.hideComments")}</p>
                )
              )}
            </div>
          )}
          {!!addedComments.length &&
            addedComments.map((comment: CommentType) => (
              <CommentWrapper
                data={comment}
                postId={postId}
                onCommentDelete={onAddedCommentDelete}
                onReplyDelete={onReplyDelete}
                onAddReply={onAddReply}
                key={comment.id}
              />
            ))}
          {!!addedComments.length && commentsCount > 1 && (
            <div className={styles.actionContainer}>
              <p onClick={hideAddedComments}>{t("posts.hideComments")}</p>
            </div>
          )}
        </div>
        <div className={styles.fieldContainer}>
          <AddCommentField
            postId={postId}
            addNewComment={addComment}
            isFocus={isFocus}
            onBlur={onBlur}
          />
        </div>
      </div>
    );
  }
);
