export type WizardModel = {
    modelId: string;
    aliasName: QrCodeModelAliasName;
    mediaUrl?: string;
    templateUrl?: string;
    cost?: number;
    currency?: string;
    big: boolean;
}

export enum QrCodeModelAliasName {
    TWO_THREE = 'model_1',
    HEARTH = 'model_2',
    THREE_TWO = 'model_3',
    ONE_TWO_DESCRIPTION = 'model_4',
    ONE_TWO = 'model_5',
}

export const modelsPlateWizardConstant: Array<WizardModel> = [
    {
        aliasName: QrCodeModelAliasName.TWO_THREE,
        modelId: QrCodeModelAliasName.TWO_THREE,
        big: false
    },
    {
        aliasName: QrCodeModelAliasName.THREE_TWO,
        modelId: QrCodeModelAliasName.THREE_TWO,
        big: true
    },
    {
        aliasName: QrCodeModelAliasName.ONE_TWO_DESCRIPTION,
        modelId: QrCodeModelAliasName.ONE_TWO_DESCRIPTION,
        big: false
    },
    {
        aliasName: QrCodeModelAliasName.HEARTH,
        modelId: QrCodeModelAliasName.HEARTH,
        big: true
    },
    {
        aliasName: QrCodeModelAliasName.ONE_TWO,
        modelId: QrCodeModelAliasName.ONE_TWO,
        big: false
    }
]
