import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { b2cPolicies, protectedResources } from "../authConfig";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated } from "../store/slices/UserSlice";

const useIsAuthenticated = (type) => {
  const { instance, accounts,inProgress} = useMsal();
const dispatch=useDispatch()
  const account = instance.getActiveAccount();
  const expirationTime = account?.idTokenClaims?.exp * 1000;
  const currentTime = new Date().getTime();
  const { isAuthenticated} = useSelector((state) => state.userSlice);
  const { language } = useSelector((state) => state.language);
  useEffect(() => {
    if (!account && inProgress === 'none') {
      dispatch(setIsAuthenticated(false));
    } else if (currentTime > expirationTime) {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: [...protectedResources.api.scopes.read],
          authority: b2cPolicies.authorities.signUpSignIn.authority,
        })
        .then((response) => {
          localStorage.setItem(`token-user`, response?.accessToken);
          dispatch(setIsAuthenticated(true));
        })
        .catch(() => {
          localStorage.clear(); 
          localStorage.setItem("lang",language );
          dispatch(setIsAuthenticated(false));
        });
    } else if( inProgress === 'none'){
      dispatch(setIsAuthenticated(true));
    }
  }, [type, inProgress]);
  return {isAuthenticated}

};

export default useIsAuthenticated;
