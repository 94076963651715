import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token-search");
  return token;
};

const baseUrl = process.env.REACT_APP_SEARCH_DNS_NAME as string;

export const searchApi = createApi({
  reducerPath: "searchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        // include token in req header
        headers.set(`authorization`, `Bearer ${token}`);
        // headers.set('Access-Control-Allow-Origin', 'no-cors')
        return headers;
      }
    },
  }),
  endpoints: (build) => ({
    getProfiles: build.mutation({
      query: ({ body }) => ({
        url: `api/search/profiles`,
        method: "POST",
        body
      }),
    }),
    
  }),
});
export const {
  useGetProfilesMutation
} = searchApi;
