import { createSlice } from "@reduxjs/toolkit";
import { PricesType } from "../../types/MemorialTypes";

type selectedPriceType = {
  burningType: string;
  burningValue: number;
  isFree: boolean;
  isSubscription: boolean;
  priceGroupId: string;
}

interface InitialState {
  candlesPrices: PricesType[];
  feelingsPrices: PricesType[];
  feelingsPageNumber: number;
  candlesPageNumber: number;
  isMemorialLoading: boolean;
  selectedProfiles: {
    profileId: string;
    profileFullName: string;
    profileFullNameEn: string;
    dateOfBirth: string;
    dateOfDeath: string;
    avatarUrl: string;
  }[];
  candles: {
    message: string;
    selectedCandle: string;
    selectedPrice: selectedPriceType;
  };
  plate: {
    selectedModel: string;
    selectedMaterial: string;
    selectedMounting: string;
    selectedColor: string;
    showWizard: boolean;
    count: number;
    emailContact: string;
    userData: {
      firstName: string;
      lastName: string;
      middleName: string;
    };
    phoneData: {
      countryCode: string;
      value: string;
    };
    userCountry: {
      isoCode: string;
      countryId: string;
      value: string;
    };
    userCity: {
      id: string;
      value: string;
    };
    address:  "",
    index: "",
    changes: {
      nameOriginal: string;
      name: {
        html: string;
        text: string;
      };
      date: {
        html: string;
        text: string;
      };
      descriptionOriginal: string;
      description: {
        html: string;
        text: string;
      };
      image: string;
    };
  };
  isPaymentLoading: boolean;
  isAnonymous: boolean;
}
const initialState: InitialState = {
  candlesPrices: [],
  feelingsPrices: [],
  feelingsPageNumber: 1,
  candlesPageNumber: 1,
  selectedProfiles: [],
  isMemorialLoading: false,
  candles: {
    message: "",
    selectedCandle: "",
    selectedPrice: {
      burningType: '',
      burningValue: 0,
      isFree: false,
      isSubscription: false,
      priceGroupId: ''
    },
  },
  plate: {
    selectedModel: "",
    selectedMaterial: "",
    selectedMounting: "",
    selectedColor: "",
    count: 1,
    showWizard: false,
    emailContact: "",
    userData: {
      firstName: "",
      lastName: "",
      middleName: ""
    },
    phoneData: {
      value: "",
      countryCode: ""
    },
    userCountry: {
      isoCode: "",
      countryId: "",
      value: ""
    },
    userCity: {
      id: "",
      value: ""
    },
    address: '',
    index:'',
    changes: {
      date: {
        html: "",
        text: "",
      },
      nameOriginal: "",
      name: {
        html: "",
        text: "",
      },
      descriptionOriginal: "",
      description: {
        html: "",
        text: "",
      },
      image: "",
    },
  },
  isAnonymous: false,
  isPaymentLoading: false,
};

const OrderSlice = createSlice({
  name: "memorialSlice",
  initialState,
  reducers: {
    setCandlesPrices: (state, action) => {
      state.candlesPrices = action.payload;
    },
    setCandlesPageNumber: (state, action) => {
      state.candlesPageNumber = action.payload;
    },
    setFeelingsPrices: (state, action) => {
      state.feelingsPrices = action.payload;
    },
    setFeelingsPageNumber: (state, action) => {
      state.feelingsPageNumber = action.payload;
    },
    setIsMemorialLoading: (state, action) => {
      state.isMemorialLoading = action.payload;
    },
    setProfiles: (state, action) => {
      if (
        state.selectedProfiles.some(
          (item) => item.profileId === action.payload.profileId
        )
      ) {
        state.selectedProfiles = state.selectedProfiles.filter(
          (item) => item.profileId !== action.payload.profileId
        );
      } else {
        state.selectedProfiles.push(action.payload);
      }
    },
    setPlateProfile: (state, action) => {
      state.selectedProfiles.length = 0;
      state.selectedProfiles.push(action.payload);
    },
    setPlateMounting: (state, action) => {
      state.plate.selectedMounting = action.payload;
    },
    setPlateCount: (state, action) => {
      state.plate.count = action.payload;
    },
    setPlateWizard: (state, action) => {
      state.plate.showWizard = action.payload;
    },
    deleteState: (state) => {
      state.selectedProfiles = [];
      state.candles = {
        message: "",
        selectedCandle: "",
        selectedPrice: {
          burningType: '',
          burningValue: 0,
          isFree: false,
          isSubscription: false,
          priceGroupId: ''
        },
      };
      state.isAnonymous = false;
      state.plate = {
        selectedModel: "",
        selectedMaterial: "",
        selectedMounting: "",
        selectedColor: "",
        showWizard: false,
        count: 1,
        emailContact: "",
        userData: {
          firstName: "",
          lastName: "",
          middleName: ""
        },
        phoneData: {
          value: "",
          countryCode: ""
        },
        userCountry: {
          isoCode: "",
          countryId: "",
          value: ""
        },
        userCity: {
          id: "",
          value: ""
        },
        address: "",
        index:"",
        changes: {
          nameOriginal: "",
          name: {
            html: "",
            text: "",
          },
          date: {
            html: "",
            text: "",
          },
          descriptionOriginal: "",
          description: {
            html: "",
            text: "",
          },
          image: "",
        },
      };
    },
    setCandleMessage: (state, action) => {
      state.candles.message = action.payload;
    },
    setIsAnonymous: (state, action) => {
      state.isAnonymous = action.payload;
    },
    setIsPaymentLoading: (state, action) => {
      state.isPaymentLoading = action.payload;
    },
    setCandle: (state, action) => {
      state.candles.selectedCandle = action.payload;
    },
    setCandlePrice: (state, action) => {
      state.candles.selectedPrice = action.payload;
    },
    setPlateModel: (state, action) => {
      state.plate.selectedModel = action.payload;
    },
    setPlateMaterial: (state, action) => {
      state.plate.selectedMaterial = action.payload;
    },
    setPlateChangesName: (state, action) => {
      state.plate.changes.name = action.payload;
    },
    setPlateChangesDescription: (state, action) => {
      state.plate.changes.description = action.payload;
    },
    setPlateChangesDescriptionOriginal: (state, action) => {
      state.plate.changes.descriptionOriginal = action.payload;
    },
    setPlateChangesNameOriginal: (state, action) => {
      state.plate.changes.nameOriginal = action.payload;
    },
    setPlateChangesDate: (state, action) => {
      state.plate.changes.date = action.payload;
    },
    setPlateChangesImage: (state, action) => {
      state.plate.changes.image = action.payload;
    },
    setPlateColor: (state, action) => {
      state.plate.selectedColor = action.payload;
    },
    setUserData: (state, action) => {
      state.plate.userData = action.payload;
    },
    setEmailContact: (state, action) => {
      state.plate.emailContact = action.payload;
    },
    setPhoneData: (state, action) => {
      state.plate.phoneData = action.payload;
    },
    setUserCountry: (state, action) => {
      state.plate.userCountry = action.payload;
    },
    setUserCity: (state, action) => {
      state.plate.userCity = action.payload;
    },
    setPlateAddress: (state, action) => {
      state.plate.address = action.payload;
    },
    setPlateIndex: (state, action) => {
      state.plate.index = action.payload;
    }
  },
});

export const {
  setProfiles,
  setCandleMessage,
  setCandle,
  setCandlePrice,
  setPlateModel,
  setPlateMaterial,
  setIsAnonymous,
  deleteState,
  setIsPaymentLoading,
  setPlateProfile,
  setPlateMounting,
  setPlateWizard,
  setPlateCount,
  setPlateChangesName,
  setPlateChangesDescription,
  setPlateChangesDescriptionOriginal,
  setPlateChangesNameOriginal,
  setPlateChangesDate,
  setPlateChangesImage,
  setCandlesPageNumber,
  setCandlesPrices,
  setFeelingsPageNumber,
  setFeelingsPrices,
  setIsMemorialLoading,
  setPlateColor,
  setUserData,
  setEmailContact,
  setPhoneData,
  setUserCountry,
  setUserCity,
  setPlateAddress,
  setPlateIndex
} = OrderSlice.actions;
export default OrderSlice.reducer;
