import { useTranslation } from "react-i18next";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import styles from "./ImageCropper.module.scss";

import React, { createRef, useEffect, useState } from "react";
import { CoordsType, ImageItem } from "../../types/CommonTypes";
import cross from "../../shared/image/cross.svg";
import rotate from "../../shared/image/rotate.svg";
import { transformImage } from "../../utils/transformImage";

type ImageViewerProps = {
  isShow: boolean;
  data: ImageItem;
  onClose: (value?: CoordsType, img?: ImageItem) => void;
};

export const ImageCropper = ({ isShow, onClose, data }: ImageViewerProps) => {
  const { t } = useTranslation();
  const [image, setImage] = useState<ImageItem | null>();
  const cropperRef = createRef<ReactCropperElement>();

  useEffect(() => {
    setImage(data);
  }, [data]);

  const getCropData = () => {
    const cropData = cropperRef.current?.cropper.getCropBoxData();
    const canvasData = cropperRef.current?.cropper.getCanvasData();
    if (canvasData && cropData) {
      const scale = canvasData.naturalHeight / canvasData.height;

      const left = (cropData.left - canvasData.left) * scale;
      const top = (cropData.top - canvasData.top) * scale ;
      const bottom = top + cropData.height * scale;
      const right = left + cropData.width * scale;

      (image as ImageItem).thumbnail = cropperRef.current?.cropper
        .getCroppedCanvas()?.toDataURL() as string;
      (image as ImageItem).isNew = true;
      transformImage(image as ImageItem)
      onClose(
        {
          left,
          bottom,
          top,
          right,
        },
        image as ImageItem
      );
    }
  };

  const rotateImage = (
    imageItem: ImageItem,
    angle: number,
    callback: (newItem: File) => void
  ) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imageItem.original
      ? URL.createObjectURL(imageItem.original)
      : (imageItem.url as string);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

      canvas.width = img.height;
      canvas.height = img.width;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((angle * Math.PI) / 180);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
      const fileName =
        imageItem.original?.name ||
        imageItem.url?.substring(imageItem.url.lastIndexOf("/") + 1) ||
        "";
      canvas.toBlob((blob) => {
        if (blob) {
          const newFile = new File([blob], fileName, {
            type: imageItem.original?.type || imageItem.type || "image/jpeg",
            lastModified: Date.now(),
          });
          callback(newFile);
        }
      }, imageItem.original?.type || imageItem.type);
    };
  };
  const handleRotateButtonClick = () => {
    rotateImage(image as ImageItem, 90, (newItem) => {
      setImage({ ...(image as ImageItem), original: newItem });
    });
  };
  return (
    <div className={`${!isShow ? styles.hidden : styles.imageViewerWrapper}`}>
      <div className={styles.imageViewerContainer}>
        <div className={styles.closeButton} onClick={() => onClose()}>
          <img src={cross} alt="" />
        </div>
        <div className={styles.rotateButton} onClick={handleRotateButtonClick}>
          <img src={rotate} alt="" />
        </div>
        <div className={styles.description}>
          <p>{t("createProfile.cropPhoto")}</p>
        </div>
        <Cropper
          ref={cropperRef}
          className={styles.cropper}
          style={{ height: "calc(var(--vh, 1vh) * 100 - 220px)" }}
          initialAspectRatio={1}
          src={
            image?.original ? URL.createObjectURL(image.original) : image?.url
          }
          aspectRatio={1 / 1}
          autoCropArea={1}
          viewMode={1}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          background={false}
          responsive={true}
          checkOrientation={false}
          guides={true}
          zoomable={false}
        />
        <div className={styles.nextButtonMobile} onClick={() => onClose()}>
          {t("common.close")}
        </div>
        <div className={styles.nextButton} onClick={getCropData}>
          {t("common.next")}
        </div>
      </div>
    </div>
  );
};
