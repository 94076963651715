import styles from "./QrCodeWizardView.module.scss";
import { Model } from "../../../../types/PlateTypes";
import {
    ReactElement,
    RefObject,
    useEffect,
    useRef,
    useState
} from "react";
import { QrCodeModelAliasName } from "../../../../constants/ModelsPlateWizard.constant";
import { WizardTwoThreeView } from "./wizardTwoThreeView/WizardTwoThreeView";
import { WizardThreeTwoView } from "./wizardThreeTwoView/WizardThreeTwoView";
import { WizardHearthView } from "./wizardHearthView/WizardHearthView";
import { WizardOneTwoDescriptionView } from "./wizardOneTwoDescriptionView/WizardOneTwoDescriptionView";
import { WizardOneTwoView } from "./wizardOneTwoView/WizardOneTwoView";
import { useTranslation } from "react-i18next";
import { ImageViewer } from "../../../imageViewer/ImageViewer";
import { ImageItem } from "../../../../types/CommonTypes";
import { setPlateChangesImage } from "../../../../store/slices/OrderSlice";
import { useDispatch } from "react-redux";

export type QrCodeWizardViewProps = {
    model?: Model;
    qr?: string;
    blockForImage?: RefObject<HTMLDivElement>;
    blockForPlate: RefObject<HTMLDivElement>;
    isEdit: boolean;
    isBlack: boolean;
}

export const QrCodeWizardView = ({model, qr, blockForImage, blockForPlate, isEdit, isBlack}: QrCodeWizardViewProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const wizardContainer = useRef<HTMLDivElement>(null);

    const [isShowGallery, setIsShowGallery] = useState<boolean>(false);
    const [imageData, setImageData] = useState<ImageItem[]>([]);
    const [showSelectButton, setShowSelectButton] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        setTimeout(() => {
            updateSize();
        },100);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const updateSize = () => {
        if (!!wizardContainer.current) {
            const container = wizardContainer.current;
            const parentNode = container.parentNode as HTMLDivElement;

            const containerWidth = container.clientWidth;
            const parentWidth = parentNode.clientWidth;

            const scale = parentWidth / containerWidth;

            if (scale < 1) {
                container.style.transform = `scale(${scale})`;
            } else {
                container.style.transform = 'unset';
            }
        }
    }

    const getClassContainer = (): string => {
        let result = styles.container;
        if (isBlack) {
            result += ` ${styles.black}`
        }

        return result;
    }

    const setImageDataProp = (items: ImageItem[], showSelectButton?: boolean): void => {
        setImageData(items);
        setIsShowGallery(true);
        setShowSelectButton(!!showSelectButton);
    }

    const getElement = (): ReactElement => {
        switch (model?.aliasName) {
            case QrCodeModelAliasName.TWO_THREE:
                return (<WizardTwoThreeView setImageData={setImageDataProp}
                                            isBlack={isBlack}
                                            model={model}
                                            isEdit={isEdit}
                                            qr={qr}
                                            blockForImage={blockForImage}
                                            blockForPlate={blockForPlate}/>)
            case QrCodeModelAliasName.THREE_TWO:
                return (<WizardThreeTwoView setImageData={setImageDataProp}
                                            isBlack={isBlack}
                                            model={model}
                                            isEdit={isEdit}
                                            qr={qr}
                                            blockForImage={blockForImage}
                                            blockForPlate={blockForPlate}/>)
            case QrCodeModelAliasName.HEARTH:
                return (<WizardHearthView setImageData={setImageDataProp}
                                          isBlack={isBlack}
                                          model={model}
                                          isEdit={isEdit}
                                          qr={qr}
                                          blockForImage={blockForImage}
                                          blockForPlate={blockForPlate}
                />)
            case QrCodeModelAliasName.ONE_TWO_DESCRIPTION:
                return (<WizardOneTwoDescriptionView isBlack={isBlack}
                                                     model={model}
                                                     isEdit={isEdit}
                                                     qr={qr}
                                                     blockForPlate={blockForPlate}/>)
            case QrCodeModelAliasName.ONE_TWO:
                return (<WizardOneTwoView isBlack={isBlack}
                                          model={model}
                                          isEdit={isEdit}
                                          qr={qr}
                                          blockForPlate={blockForPlate}/>)
            default:
                return (<></>);
        }
    }

    const closeImageViewer = (selectedImage?: ImageItem): void => {
        if (!!selectedImage) {
            dispatch(setPlateChangesImage(selectedImage.url));
        }
        setIsShowGallery(false);
    }
    const onRotate = (value: number, img: File) => {
        const newImage = {...imageData[0], original:img}
        setImageData([newImage]);
        dispatch(setPlateChangesImage(URL.createObjectURL(img as Blob)));
      };
    return (
      <>
          <div className={getClassContainer()}>
              <div className={styles.wizardContainer}
                   ref={wizardContainer}>
                  {getElement()}
              </div>
              <div className={styles.inner}>
                  <div className={styles.description}>{t("qrCode.size")}: {model?.width} cm x {model?.height} cm</div>
              </div>
          </div>
          {isShowGallery && (
            <ImageViewer
              isShow={isShowGallery}
              isShowRotate ={!showSelectButton}
              onRotate={onRotate}
              data={imageData}
              onClose={closeImageViewer}
              showSelectButton={showSelectButton}
              startIndex={0}
            />
          )}
      </>

    );
};
