import styles from "./PopupBackground.module.scss";

type PropsType = {
  type?: string;
  onClose?: () => void;
};


export const PopupBackground = ({type, onClose}: PropsType) => {
  const onClick = () => {
    if(onClose) {
      onClose();
    }
  };

  return (
    <div className={type?  styles.popupBackgroundDark  : styles.popupBackground} onClick={onClick} >
  </div>
  );
};
