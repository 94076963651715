import { useTranslation } from 'react-i18next';
import styles from '../Filter.module.scss';


type FilterButtonProps = {
  isShow?: string;
  onSubmit: () => void;
  onReset?: () => void;
  stateIsChange?: boolean;
  styleContainer: string;
  disabledReset?: boolean
}

export const FilterButton = ({ isShow, onSubmit, onReset, stateIsChange, styleContainer, disabledReset }: FilterButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className={styleContainer}>
      {onReset && (
        <button
          className={`${isShow ? styles.subFilterResetButton : styles.resetButton}`}
          onClick={onReset}
          disabled={disabledReset}
        >
          {t("filter.reset")}
        </button>
      )}
      <button
        className={`${isShow ? styles.subFilterSubmitButton : styles.formButton}`}
        type="submit"
        onClick={onSubmit}
        disabled={!stateIsChange }
      >
        {t("filter.search")}
      </button>
    </div>
  );
};