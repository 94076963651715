import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Info.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getLanguageByLocale } from "../../../utils/localeUtils";

const baseUrl = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const Info = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state: RootState) => state.language);

  const getLink = useCallback(() => {
    const lang = getLanguageByLocale(language);
    switch (lang) {
      case "ru":
        return `${baseUrl}/signup/src/policy.ru.pdf`;
      case "de":
        return `${baseUrl}/signup/src/policy.pdf`;
      default:
        return `${baseUrl}/signup/src/policy.en.pdf`;
    }
  }, [language]);

  return (
    <div style={{ position: 'fixed' }} className={styles.infoContainer}>
      <div className={styles.infoHeader}>
        <h3>{t("userProfile.info.header")}</h3>
      </div>
      <h3 className={styles.infoTitle}>
        {t("userProfile.info.сontactInformation")}
      </h3>
      <p>
        RIPPLANET SARL
        <br />
        12, rue du Château d'Eau
        <br />
        L - 3364 Leudelange
        <br />
        Luxembourg
        <br />
        Handelsregisternummer: B262158
        <br />
        USt-IdNr LU34464638
        <br />
        <a href="mailto:info@ripplanet.com" className={styles.link}>
          info@ripplanet.com
        </a>
      </p>
      <h3 className={styles.infoTitle}>
        {t("userProfile.info.privacyPolicy")}
      </h3>
      <p>
        <a
          href={getLink()}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          {t("userProfile.info.privacyPolicy")}
        </a>
      </p>
    </div>
  );
};
