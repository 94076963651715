import { useCallback, useEffect, useState } from "react";
import styles from "./PlatesPopup.module.scss";
import { useTranslation } from "react-i18next";
import { ModalWindow } from "../../../../modalWindow/ModalWindow";
import { QrCodeRequest } from "../../../../qrCode/qrCodeRequest/QrCodeRequest";
import {
  useAddFeedbackMutation,
  useGetPlateOrderQuery,
} from "../../../../../api/PlateService";
import { OrderHistoryPlateInfoType } from "../../../../../types/OrderHistoryType";
import { Rating } from "react-simple-star-rating";
import { ReactComponent as RatingIconBorder } from "../../../../../shared/image/rating/ratingIconBorder.svg";
import { ReactComponent as RatingIcon } from "../../../../../shared/image/rating/ratingIcon.svg";
import { SimpleTextarea } from "../../../../../shared/components/simpleTextarea/SimpleTextarea";
import { useDispatch } from "react-redux";
import { setShowSpinner } from "../../../../../store/slices/SpinnerSlice";
import { addRating } from "../../../../../store/slices/OrderHistorySlice";
import { splitIntoParagraphs } from "../../../../../utils/splitIntoParagraph";

type PlatesPopupProps = {
  isShowPlatesWindow: boolean;
  setIsShowPlatesWindow: (value: boolean) => void;
  onSendFeedback: () => void;
  id: string;
  isRating: boolean;
  orderNumber: number;
  orderStatus: string;
};

export const PlatesPopup = ({
  isShowPlatesWindow,
  setIsShowPlatesWindow,
  onSendFeedback,
  id,
  isRating,
  orderNumber,
  orderStatus,
}: PlatesPopupProps) => {
  const { t } = useTranslation();
  const [isShowFeedbackWindow, setIsShowFeedbackWindow] =
    useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [rating, setRating] = useState<number>(5);
  const [addFeedback, { isSuccess, isLoading }] = useAddFeedbackMutation();
  const { data } = useGetPlateOrderQuery(id);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowSpinner(!data));
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(addRating({ id, rating }));
      dispatch(setShowSpinner(false));
      setIsShowFeedbackWindow(false);
      onSendFeedback();
    }
  }, [isSuccess]);
  
  const getHistoryData = useCallback(() => {
    if (data) {
      const plateInfo = {
        profileName: data.profileFullName,
        recipient: `${data.recipient.lastName} ${data.recipient.firstName} ${
          data.recipient.parentName || ""
        }`,
        email: data.recipient.email,
        phone: data.recipient.phone,
        country: data.recipient.country,
        city: data.recipient.city,
        address: data.recipient.address,
        index:data.recipient.zip,
        isRating,
        deliveryPrice: data.deliveryPrice
      } as OrderHistoryPlateInfoType;

      return plateInfo;
    }
    return null;
  }, [data]);

  const onSubmit = () => {
    dispatch(setShowSpinner(true));
    addFeedback({
      id: data.id,
      rating: rating,
      comment: text.trim(),
    });
    setIsShowFeedbackWindow(false)
  };
  return (
    <>
      {isShowPlatesWindow && !isLoading && !isSuccess && data && (
        <ModalWindow
          onClose={() => setIsShowPlatesWindow(false)}
          isShow={isShowPlatesWindow}
          headerText={`${t(
            "userProfile.orderHistory.orderNumber"
          )}${orderNumber}`}
          children={
            <QrCodeRequest
              onSendFeedback={() => setIsShowFeedbackWindow(true)}
              price={data?.price}
              isHistory
              historyData={getHistoryData()}
              orderStatus={orderStatus}
            />
          }
        ></ModalWindow>
      )}
      {isShowFeedbackWindow && (
        <ModalWindow
          onClose={() => setIsShowFeedbackWindow(false)}
          isShow={isShowPlatesWindow}
          headerText={t("userProfile.orderHistory.feedback")}
          children={
            <div className={styles.popupContainer}>
            <div className={styles.ratingContainer}>

              <Rating
                size={50}
                transition
                allowFraction
                showTooltip={false}
                initialValue={rating}
                onClick={(value) => setRating(value)}
                fillIcon={<RatingIcon width="38" height="38" />}
                emptyIcon={<RatingIconBorder width="38" height="38" />}
              ></Rating>
              </div>
              <div>
              {splitIntoParagraphs(t("userProfile.orderHistory.feedbackDescription")).map(
                (paragraph, index) => (
                  <p key={index}>
                    {paragraph}<br/>
                  </p>
                )
              )}
              </div>
              <SimpleTextarea
                onChange={(value) => setText(value)}
                value={text}
                classType="outline"
                placeholder={t("userProfile.orderHistory.feedbackPlaceholder")}
                maxLength={2048}
                id="feedback"
              />
              <button className={styles.button} onClick={onSubmit}>
                {t("common.send")}
              </button>
            </div>
          }
        ></ModalWindow>
      )}
    </>
  );
};
