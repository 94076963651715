import styles from "./InfoCard.module.scss";
import cross from "../../image/cross.svg";
type PropsType = {
  header: string;
  description: string;
  isEdit?: boolean;
  onClose?: (id: string) => void;
  id?: string;
};

export const InfoCard = ({
  header,
  description,
  isEdit,
  onClose,
  id,
}: PropsType) => {
  return (
    <div className={isEdit ? styles.infoCardEdit : styles.infoCard}>
      {isEdit && (
        <img
          src={cross}
          alt=""
          onClick={() => onClose && onClose(id as string)}
        />
      )}
      <p className={styles.infoCardHeader}>{header}</p>
      {description && (
        <>
          <span className={styles.divider} />
          <p className={styles.infoCardDescription}>
            {description}
          </p>
        </>
      )}
    </div>
  );
};
