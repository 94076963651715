import styles from "./DeleteProfilePopup.module.scss";
import cross from "../../../shared/image/cross.svg";
import { useTranslation } from "react-i18next";

type ModalWindowProps = {
  isShow: boolean;
  type?:string
  onClose: () => void;
  onApprove: () => void;
};

export const DeleteProfilePopup = ({
  isShow,
  onClose,
  onApprove,
  type
}: ModalWindowProps) => {

  const { t } = useTranslation();

  return (
      <div className={`${styles.modalWindowWrapper} ${!isShow && styles.hidden}`}>
        <div className={styles.modalWindowContainer}>
          <div className={styles.modalWindowHeader}>
            <p>{type === 'user'? t("userProfile.editProfile.deleteАccount"): t("profile.deleteProfile")}</p>
            <img src={cross} alt="" onClick={onClose}/>
          </div>
          <div className={styles.modalWindowBody}>
            <div>
              <p className={styles.deleteProfileQuestion}>
                {t("profile.deleteProfileQuestion")}
              </p>
              <p className={styles.deleteProfileDescription}>
                {t("profile.deleteProfileDescription")}
              </p>

              <button className={styles.cancelButton}
                      onClick={() => onClose()}>{t("common.cancel")}</button>

              <p className={styles.deleteButton} onClick={() => onApprove()}>{t("common.delete")}</p>
            </div>
          </div>
        </div>
        <button className={styles.cancelButtonMobile}
                onClick={() => onClose()}>{t("common.cancel")}</button>
      </div>
  );
};
