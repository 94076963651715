import styles from "./OrderPage.module.scss";
import { SearchInput } from "../../shared/components/search/SearchInput";
import { ProfileCard } from "../../shared/components/profileCard/ProfileCard";
import { useGetProfilesMutation } from "../../api/SearchService";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import { useEffect, useRef, useState } from "react";
import { MemorialTab } from "../../components/memorial/memorialTab/MemorialTab";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteState,
  setCandle,
  setCandleMessage,
  setPlateProfile,
  setProfiles,
} from "../../store/slices/OrderSlice";
import { LoadingPage } from "../loadingPage/LoadingPage";
import { ProfileType } from "../../types/ProfileType";
import { QrCodeTab } from "../../components/qrCode/qrCodeTab/QrCodeTab";
import ModalWindowHeader from "../../shared/components/modalWindowHeader/ModalWindowHeader";
import { useTranslation } from "react-i18next";
import useIsAuthenticated from "../../utils/useIsAuthenticated";
import { useLocation, useNavigate } from "react-router-dom";
import { MemorialTypes } from "../../constants/Memorial.constant";
import { InputWithButton } from "../../shared/components/input/InputWithButton";
import {MAX_MAIN_SEARCH_LENGTH} from '../../constants/constants';
import { setIsOrderHistoryLoading } from "../../store/slices/OrderHistorySlice";
import { useGetFavoritesIdsProfilesQuery } from "../../api/ProfileService";

type OrderPageProps = {
  type: string;
  notNeedDeleteState?: boolean;
};

export const OrderPage = ({ type, notNeedDeleteState }: OrderPageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {isAuthenticated} = useIsAuthenticated();
  const { skipSearch } = useFetchWithMsal("search", isAuthenticated);
  const { skip: skipProfile } = useFetchWithMsal("profile", isAuthenticated);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isAfterSearch, setIsAfterSearch] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement|null>(null)
  const { selectedProfiles, isPaymentLoading,candles } = useSelector(
    (state: RootState) => state.orderSlice
  );

  const isLoadingProfiles = useRef<boolean>(false);

  const [profilesData, setProfilesData] = useState<Array<ProfileType>>([]);
  const [offset, setOffset] = useState<number>(0);
  const count = 15;
  const {
    data: favoritesIds,
    isSuccess: isSuccessGetFavoritesIds
  } = useGetFavoritesIdsProfilesQuery('', {skip: skipProfile});
  const [getProfiles, { data, isLoading, isSuccess: isSuccessGetProfiles }] = useGetProfilesMutation();

  useEffect(() => {
    return () => {
      if (!notNeedDeleteState) {
        dispatch(deleteState());
      }
    };
  }, [type]);

  useEffect(() => {
    if (!isLoading && (isAuthenticated === false ? true: !skipSearch )) {
      getProfiles({
        body: {
          offset,
          count,
        },
      });
    }
  }, [isAuthenticated, skipSearch]);

  useEffect(() => {
    if (!!data) {
      if (!!data?.profileList.length && !searchTerm && !isAfterSearch ) {
        setProfilesData([...profilesData, ...data.profileList]);
        isLoadingProfiles.current = false;
      } else if(!!data?.profileList.length && isAfterSearch && !searchTerm) {
        
        setIsAfterSearch(false)
        setProfilesData([...data.profileList]);
        isLoadingProfiles.current = false;
      }else if (!!searchTerm && !offset) {
        setIsAfterSearch(true)
        setProfilesData([...data.profileList]);
        isLoadingProfiles.current = false;
      } else if (!!searchTerm && !!offset) {
        setProfilesData([...profilesData, ...data.profileList]);
        isLoadingProfiles.current = false;
      }
    }
  }, [data]);

  useEffect(() => {
    if (offset !== 0) {
      getProfiles({
        body: {
          searchString: searchTerm,
          offset: offset,
          count,
        },
      });
    }
  }, [offset]);

  const onProfileClick = (value: {
    profileId: string;
    profileFullName: string;
    profileFullNameEn: string;
    dateOfBirth: string;
    dateOfDeath: string;
    avatarUrl: string;
  }) => {
    if (type === "plate") {
      dispatch(setPlateProfile(value));
    } else {
      dispatch(setProfiles(value));
    }
  };

  const handleScroll = (e: any) => {
    const divComponent: HTMLDivElement = e.target;
    if (
      divComponent.offsetHeight + divComponent.scrollTop >=
      divComponent.scrollHeight - 200
    ) {
      if (
        !skipSearch &&
        !isLoadingProfiles.current &&
        !isLoading &&
        data?.hasNext
      ) {
        isLoadingProfiles.current = true;
        setOffset(offset + count);
      }
    }
  };

  const updateProfiles = (profilesIDs: string[], candleId: string) => {
    const profiles = profilesData.map((item) => {
      if (profilesIDs.includes(item.id)) {
        const profile = { ...item };
        profile.mainCandles = [{ id: candleId, mediaId: candleId }];
        return profile;
      }
      return item;
    });
    setProfilesData(profiles);
  };

  const backClick = () => {navigate(-1)};
  const switchTabs = (tab: string) => {
    navigate(`/${tab}`);
    dispatch(setCandle(""));
  };
  const renderTabs = () => {
    const tabs = [MemorialTypes.candles, MemorialTypes.feelings];
    return tabs.map((tab: string, index: number) => {
      return (
        <button
          key={index}
          className={`${styles.tabContainer} ${
            location.pathname.replace("/", "") === tab ? styles.activeTab : ""
          } `}
          onClick={() => switchTabs(tab)}
        >
          {t(`memorial.${tab}`)}
        </button>
      );
    });
  };
  const onInputChange = (value: string) => {
    if (value.length <= 150) {
      dispatch(setCandleMessage(value));
    }
  };

  const onSearchClick = () => {
    setOffset(0)

    const payload = {
      searchString: searchTerm,
      offset: 0,
      count
    }
    getProfiles({ body: payload });
    if(containerRef.current) {
      containerRef.current.scrollTop = 0
     } 
  };

  const onChangeSearch = (value: string) => {
    const updateValue = value.slice(0, MAX_MAIN_SEARCH_LENGTH)
      .replace(/''+/g, "'");
    if (/^[a-zA-Zа-яА-ЯßäöüÄÖÜßß0-9.'()\s-]*$/.test(updateValue)) {
      setSearchTerm(updateValue);
    }
  };

  const onBlurSearch = () => {
    if(searchTerm === '') {
      const payload = {
        searchString: searchTerm,
        offset,
        count
      }
      getProfiles({ body: payload });
    }
  };

  return (
    <>
      {isPaymentLoading ? <LoadingPage type="payment" /> : <></>}
      <div
        className={type === "plate" ? styles.plate : styles.ordersPageContainer}
      >
        <div className={styles.modalWindowHeaderContainer}>
          <ModalWindowHeader title={type === "plate" ? t("qrCode.title") : t("profile.addCandle")} onClick={backClick} />
        </div>
        <div className={styles.profilesContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              onClick={onSearchClick}
              onChange={onChangeSearch}
              onBlur={onBlurSearch}
              value={searchTerm}
              type="search"
              isDisabledButton={!searchTerm}
            />
          </div>
          <div className={styles.memorialTabs}>{renderTabs()}</div>
          <div className={styles.inputContainer}> <InputWithButton
          value={candles.message}
          setValue={onInputChange}
          placeholder={
            location.pathname.replace("/", "") === MemorialTypes.candles
              ? t("memorial.candleMessage")
              : t("memorial.feelingsMessage")
          }
          disabled={!isAuthenticated}
          maxlength={150}
        /></div>
          <div className={styles.scrollContainer} onScroll={handleScroll} ref={containerRef}>
            <div className={styles.profilesGrid}>
              {profilesData.map((item: any, index: number) => {
                return (
                  <ProfileCard
                    mainPictureId={item.avatarUrl}
                    isCreatedByCurrentUser={item.isCreatedByCurrentUser}
                    id={item.id}
                    key={index}
                    name={`${item.lastName || ""} ${item.firstName || ""} ${
                      item.middleName || ""
                    }`}
                    subName={`${item.inEnglish.lastName || ""} ${
                      item.inEnglish.firstName || ""
                    } ${item.inEnglish.middleName || ""}`}
                    dateOfBirth={item.dateOfBirth}
                    dateOfDeath={item.dateOfDeath}
                    country={item.burialPlace.countryId}
                    isFavorite={favoritesIds?.includes(item.id)}
                    isSelected={selectedProfiles.some(
                      (i) => i.profileId === item.id
                    )}
                    isSelectable
                    onProfileClick={onProfileClick}
                    img={item.avatarPreviewUrl}
                  />
                );
              })}
              {!!searchTerm && isSuccessGetProfiles && !profilesData?.length && (
                <div className={styles.emptyContainer}>
                  <p className={styles.emptyPageText}>{t('profile.emptyProfileSearch')}
                    <br/>{t('profile.createProfileSearch')} </p>
                  <button className={styles.createProfileButton}
                          onClick={() => navigate('/createProfile')}>{t('profile.createProfile')}</button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.opacityBottom}></div>
        </div>
        <div className={styles.ordersContainer}>
          {type === "candles" && (
            <MemorialTab type="candles" updateProfiles={updateProfiles} />
          )}
          {type === "feelings" && (
            <MemorialTab type="feelings" updateProfiles={updateProfiles} />
          )}
          {type === "plate" && <QrCodeTab />}
        </div>
      </div>
    </>
  );
};
