import "./PageControl.css";

type PageControlProps = {
    totalPages: number;
    currentPage: number;
    onChange:(index:number)=> void;
  };

const PageControl = ({totalPages, currentPage, onChange}: PageControlProps) => {

  return (
    <div className="page-control">
      <div className="dot-indicators">
      {Array.from({ length: totalPages }, (_, index) => (
          <span
            key={index}
            className={`dot ${currentPage === index ? 'active' : ''}`}
            onClick={()=>onChange(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default PageControl;
