import { useTranslation } from "react-i18next";
import styles from "./QrCodeRequest.module.scss";
import { UIEvent, useEffect, useRef, useState } from "react";
import {
  useGetAuthorizedUserInfoQuery,
  useGetUserContactsQuery,
} from "../../../api/UserService";
import { SettingListItem } from "../../../shared/components/settingListItem/SettingListItem";
import { ModalWindow } from "../../modalWindow/ModalWindow";
import { EditNameWindow } from "../../userProfile/editProfile/editForms/EditNameForm";
import { EditEmailForm } from "../../userProfile/editProfile/editForms/EditEmailForm";
import { EditPhoneForm } from "../../userProfile/editProfile/editForms/EditPhoneForm";
import { FieldValues } from "react-hook-form";
import {
  useGetCountryCodesQuery,
  useSearchCitiesQuery,
  useSearchCountriesQuery,
} from "../../../api/UtilityService";
import { SearchInput } from "../../../shared/components/search/SearchInput";
import { CountryType, LocationDataType } from "../../../types/CommonTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import PaymentForm from "../../paymentForm/PaymentForm";
import {
  useCreatePlateOrderMutation,
  useGetDeliveryPriceQuery,
} from "../../../api/PlateService";
import { setShowSpinner } from "../../../store/slices/SpinnerSlice";
import PaymentMethods from "../../paymentMethods/PaymentMethods";
import { OrderHistoryPlateInfoType } from "../../../types/OrderHistoryType";
import { Mandate } from "../../../types/PaymentTypes";
import { getLanguageByLocale } from "../../../utils/localeUtils";
import completedIcon from "../../../shared/image/completedIcon.svg";
import canceledIcon from "../../../shared/image/canceledIcon.svg";
import useIsAuthenticated from "../../../utils/useIsAuthenticated";
import {
  setEmailContact,
  setPhoneData,
  setPlateAddress,
  setPlateIndex,
  setUserCity,
  setUserCountry,
  setUserData,
} from "../../../store/slices/OrderSlice";
import { PopupLocation } from "../../../pages/createProfile/createProfileSteps/PopupCountries/PopupLocation";
import { LIMIT_SCROLL_CITY } from "../../../constants/constants";
import { useGetQRCodeQuery } from "../../../api/ProfileService";

type QrCodeRequestProps = {
  plateFile?: string;
  avatarFile?: Blob;
  price: string;
  onClose?: (
    token: string,
    paymentMethod: string,
    orderId: string,
    isDefault?: boolean,
    mandate?: Mandate
  ) => void;
  onSendFeedback?: () => void;
  isHistory?: boolean;
  historyData?: OrderHistoryPlateInfoType | null;
  orderStatus?: string;
};

export const QrCodeRequest = ({
  avatarFile,
  plateFile,
  price,
  onClose,
  onSendFeedback,
  isHistory,
  historyData,
  orderStatus,
}: QrCodeRequestProps) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useIsAuthenticated();
  const { t } = useTranslation();

  const { language } = useSelector((state: RootState) => state.language);
  const { plate, selectedProfiles } = useSelector(
    (state: RootState) => state.orderSlice
  );

  const [modalWindow, setModalWindow] = useState<string>("");

  const [activeSearchType, setActiveSearchType] = useState("");
  const [showPopup, setShowPopup] = useState("");
  const [popupData, setPopupData] = useState<LocationDataType[] | null>(null);
  const [isShowPaymentModalWindow, setIsShowPaymentModalWindow] =
    useState<boolean>(false);
  const [isShowPaymentForm, setIsShowPaymentForm] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState(false);
  const [offset, setOffset] = useState<number>(0);
  const isLoadingScroll = useRef<boolean>(false);

  const { data: userData } = useGetAuthorizedUserInfoQuery("", {
    skip: !isAuthenticated,
  });
  const { data: contactData } = useGetUserContactsQuery("", {
    skip: !isAuthenticated,
  });
  const { data: countryCodes } = useGetCountryCodesQuery(
    getLanguageByLocale(language)
  );
  const { data: qr } = useGetQRCodeQuery(selectedProfiles[0]?.profileId, {
    skip: !plateFile,
  });

  const { data: searchCountriesData, isSuccess: isCountrySuccess } =
    useSearchCountriesQuery(
      { lang: getLanguageByLocale(language), search: plate.userCountry.value },
      { skip: !isSearch || activeSearchType !== "country" }
    );
  const {
    data: searchCityData,
    isSuccess: isCitiesSuccess,
    isLoading: isCitiesLoading,
  } = useSearchCitiesQuery(
    {
      lang: getLanguageByLocale(language),
      search: plate.userCity.value,
      countryId: plate.userCountry.isoCode,
      region: "",
      start: offset,
    },
    {
      skip: !isSearch || activeSearchType !== "city",
    }
  );
  const [createPlateOrder, { data: orderId, isSuccess: isSuccessOrder }] =
    useCreatePlateOrderMutation();

  const { data: deliveryPrice } = useGetDeliveryPriceQuery(
    {
      countryCode: plate.userCountry.isoCode,
      currency: "EUR",
    },
    {
      skip: !plate.userCountry.isoCode,
    }
  );

  useEffect(() => {
    if (showPopup === "city") {
      if (
        isCitiesSuccess &&
        searchCityData?.results?.length &&
        isSearch &&
        !isLoadingScroll.current
      ) {
        setPopupData([...searchCityData.results]);
        setIsSearch(false);
      } else if (
        isCitiesSuccess &&
        searchCityData?.results?.length === 0 &&
        isSearch
      ) {
        isLoadingScroll.current = false;
        setPopupData([]);
        setIsSearch(false);
      } else if (
        isCitiesSuccess &&
        searchCityData?.results?.length &&
        isSearch &&
        isLoadingScroll.current
      ) {
        isLoadingScroll.current = false;
        setPopupData([
          ...(popupData as LocationDataType[]),
          ...searchCityData.results,
        ]);
        setIsSearch(false);
      }
    }
  }, [searchCityData, isSearch]);

  useEffect(() => {
    if (showPopup === "country") {
      if (isCountrySuccess && searchCountriesData?.length && isSearch) {
        const popupData = searchCountriesData.map((item: CountryType) => {
          return {
            name: item.country,
            type: item.id,
            isoCode: item.isoCode,
            id: item.id,
            code: item.code,
            country: item.country,
          };
        });
        setPopupData(popupData);
        setIsSearch(false);
      } else if (
        isCountrySuccess &&
        searchCountriesData?.length === 0 &&
        isSearch
      ) {
        setPopupData([]);
        setIsSearch(false);
      }
    }
  }, [searchCountriesData, isSearch]);

  useEffect(() => {
    if (contactData && !isHistory) {
      if (!plate.phoneData.value) {
        const phoneData = contactData?.find((item: any) => item.type === 2);
        if (!!phoneData) {
          dispatch(
            setPhoneData({
              value: phoneData.value,
              countryCode: phoneData.countryCode,
            })
          );
        }
      }

      if (!plate.emailContact) {
        const emailData = contactData?.find((item: any) => item.type === 1);
        dispatch(setEmailContact(emailData.value));
      }
    }
  }, [contactData]);

  useEffect(() => {
    if (userData && !isHistory) {
      if (!plate.userData.firstName) {
        dispatch(
          setUserData({
            firstName: userData.firstName,
            lastName: userData.lastName,
            middleName: userData.middleName,
          })
        );
      }
    }
  }, [userData]);

  useEffect(() => {
    if (isSuccessOrder) {
      if (!!orderId) {
        dispatch(setShowSpinner(false));
        if (!!orderId.errors) {
          //TODO: need add validation
        } else {
          setIsShowPaymentModalWindow(true);
        }
      }
    }
  }, [isSuccessOrder]);

  const getPhoneNumber = () => {
    if (historyData?.phone) {
      return `+${historyData?.phone}`;
    }
    const code = countryCodes?.find(
      (item) => item.code === plate.phoneData.countryCode
    );
    return plate.phoneData.value
      ? `${code?.code || ""} ${plate.phoneData.value || ""}`
      : "";
  };

  const saveName = (data: FieldValues) => {
    dispatch(
      setUserData({
        lastName: data.lastName,
        firstName: data.firstName,
        middleName: data.middleName,
      })
    );
  };

  const onEditPhone = (value: any) => {
    dispatch(
      setPhoneData({
        value: value.value,
        countryCode: value.countryCode,
      })
    );
    setModalWindow("");
  };

  const editEmail = (data: FieldValues) => {
    setModalWindow("");
    dispatch(setEmailContact(data.value));
  };

  const onAddressChange = (value: string) => {
    dispatch(setPlateAddress(value));
  };

  const onIndexChange = (value: string) => {
    dispatch(setPlateIndex(value));
  };
  const modalWindowData = () => {
    switch (modalWindow) {
      case "name":
        return {
          headerText: t("common.firstName"),
          children: (
            <EditNameWindow
              onClose={() => setModalWindow("")}
              data={plate.userData}
              required
              onSave={saveName}
            />
          ),
        };
      case "email":
        return {
          headerText: t("userProfile.editProfile.email"),
          children: (
            <EditEmailForm
              onClose={editEmail}
              data={{ value: plate.emailContact }}
            />
          ),
        };
      case "phone":
        return {
          headerText: t("userProfile.editProfile.phoneNumber"),
          // description: t("userProfile.editProfile.phoneDescription"),
          children: (
            <EditPhoneForm onClose={onEditPhone} data={plate.phoneData} />
          ),
        };
      default:
        return (
          <>
            <input value="name" />
          </>
        );
    }
  };

  const onCountryChange = (value: string) => {
    setActiveSearchType("country");
    dispatch(
      setUserCountry({
        isoCode: "",
        countryId: "",
        value,
      })
    );
    dispatch(
      setUserCity({
        id: "",
        value: "",
      })
    );
    dispatch(setPlateAddress(""));
    dispatch(setPlateIndex(""));
    setIsSearch(true);
    setOffset(0);
  };

  const onSearchBlur = (type: string) => {
    if (!plate.userCountry.countryId) {
      dispatch(
        setUserCountry({
          isoCode: "",
          countryId: "",
          value: "",
        })
      );
    }
  };

  const onPopupClose = () => {
    setActiveSearchType("");
    setPopupData([]);
    setShowPopup("");
  };

  const onPopupCountryClick = (country: LocationDataType) => {
    if (country) {
      setActiveSearchType("");
      dispatch(
        setUserCountry({
          isoCode: country.isoCode,
          countryId: country.id,
          value: country.country,
        })
      );
      setShowPopup("");
      setPopupData([]);
    }
  };

  const onCityChange = (value: string) => {
    setActiveSearchType("city");
    dispatch(
      setUserCity({
        id: "",
        value: value,
      })
    );
    setOffset(0);
    setIsSearch(true);
  };

  const onPopupCityClick = (city: LocationDataType) => {
    if (city) {
      setActiveSearchType("");
      dispatch(
        setUserCity({
          id: city.id,
          value: city.region.name
            ? city.name + ", " + city.region.name
            : city.name,
        })
      );
      setShowPopup("");
      setPopupData([]);
    }
  };

  const onPaymentClick = async () => {
    const formData = new FormData();
    formData.append("MaterialId", plate.selectedMaterial);
    formData.append("ModelId", plate.selectedModel);
    formData.append("MountingTypeId", plate.selectedMounting);
    formData.append("ColorId", plate.selectedColor);
    formData.append("Count", plate.count as any);

    if (!!avatarFile) {
      formData.append("AvatarFile", avatarFile);
    }
    if (!!qr) {
      const blob = await fetch(qr).then((response) => response.blob());
      formData.append("QrFile", blob);
    }

    formData.append("FullName", plate.changes.name.text);
    formData.append("AdditionalText", plate.changes.description.text);
    formData.append("LifeDates", plate.changes.date.text);
    formData.append("ProfileInfo.ProfileId", selectedProfiles[0].profileId);
    formData.append(
      "ProfileInfo.ProfileFullName",
      selectedProfiles[0].profileFullName
    );
    formData.append(
      "ProfileInfo.ProfileFullNameEn",
      selectedProfiles[0].profileFullNameEn
    );
    formData.append("RecipientInfo.FirstName", plate.userData.firstName);
    formData.append("RecipientInfo.LastName", plate.userData.lastName);
    if (plate.userData.middleName) {
      formData.append("RecipientInfo.ParentName", plate.userData.middleName);
    }
    formData.append("RecipientInfo.Email", plate.emailContact);
    formData.append("RecipientInfo.Phone", getPhoneNumber());
    formData.append("RecipientInfo.Country", plate.userCountry.value);
    formData.append("RecipientInfo.CountryCode", plate.userCountry.isoCode);
    formData.append("RecipientInfo.City", plate.userCity.value);
    formData.append("RecipientInfo.Address", plate.address);
    formData.append("RecipientInfo.Zip", plate.index);
    formData.append("Locale", getLanguageByLocale(language));
    formData.append("Currency", "EUR");

    createPlateOrder(formData);
    dispatch(setShowSpinner(true));
  };

  const onPaymentCardClick = (
    token: string,
    paymentMethod: string,
    isDefault?: boolean
  ) => {
    setIsShowPaymentModalWindow(false);
    onClose && onClose(token, paymentMethod, orderId, isDefault);
  };

  const disablePaymentForm = (): boolean => {
    return !orderId;
  };

  const disablePay = (): boolean => {
    const nameValid =
      !!plate.userData.lastName ||
      !!plate.userData.firstName ||
      !!plate.userData.middleName;
    const emailValid = !!plate.emailContact;
    const numberPhoneValid = !!getPhoneNumber();
    const addressValid =
      (!!plate.index || !deliveryPrice?.isHaveZipCode) && !!plate.address;
    return !(nameValid && emailValid && numberPhoneValid && addressValid);
  };

  const paymentCreditCard = () => {
    setIsShowPaymentForm(true);
    setIsShowPaymentModalWindow(false);
  };

  const paymentPaypal = () => {
    onPaymentCardClick("", "paypal");
  };

  const mandatePay = (mandate: Mandate): void => {
    setIsShowPaymentModalWindow(false);
    onClose && onClose("", mandate.method, orderId, mandate.isDefault, mandate);
  };

  const getDeliveryPrice = (): number => {
    if (!!deliveryPrice) {
      return deliveryPrice.cost;
    } else if (historyData?.deliveryPrice) {
      return historyData?.deliveryPrice;
    } else {
      return 0;
    }
  };

  const getTotalPrice = (): string => {
    return (+price + getDeliveryPrice()).toFixed(2);
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (showPopup === "city") {
      const divComponent = e.target as HTMLDivElement;
      if (
        divComponent.offsetHeight + divComponent.scrollTop >=
        divComponent.scrollHeight - 100
      ) {
        if (
          !isCitiesLoading &&
          searchCityData?.hasNext &&
          !isLoadingScroll.current
        ) {
          isLoadingScroll.current = true;
          setIsSearch(true);
          setOffset(offset + LIMIT_SCROLL_CITY);
        }
      }
    }
  };

  const fieldInFocus = (type: string) => {
    setActiveSearchType(type);
    setIsSearch(true);
    setShowPopup(type);
  };

  return (
    <div className={styles.container}>
      {(orderStatus === "Shipped" || orderStatus === "Cancelled") && (
        <div className={styles.icon}>
          <img
            src={orderStatus === "Shipped" ? completedIcon : canceledIcon}
            alt={""}
          />
        </div>
      )}

      {isHistory ? (
        <SettingListItem
          text={historyData?.profileName || ""}
          wide
          classNameType={"request"}
          isDisabled={isHistory}
        />
      ) : (
        <>
          <div className={styles.imageContainer}>
            <img
              className={styles.photo}
              src={plateFile}
              alt=""
              draggable={false}
            />
          </div>

          <div className={styles.price}>{price} €</div>
        </>
      )}

      <div className={styles.title}>{t("qrCode.payment.recipientOrder")}</div>

      <p className={styles.label}>{t("common.firstName")}</p>
      <ul className={styles.settingList}>
        <SettingListItem
          text={
            historyData?.recipient ||
            `${plate.userData.lastName || ""}
             ${plate.userData.firstName || ""}
             ${plate.userData.middleName || ""}`
          }
          placeholder={t("qrCode.payment.namePlaceholder")}
          classNameType={"request"}
          onClick={() => setModalWindow("name")}
          isDisabled={isHistory}
        />
      </ul>
      <p className={styles.label}>{t("userProfile.editProfile.contactInfo")}</p>
      <ul className={styles.settingList}>
        <SettingListItem
          text={historyData?.email || plate.emailContact || ""}
          caption="E-mail"
          wide
          classNameType={"request"}
          onClick={() => setModalWindow("email")}
          isDisabled={isHistory}
        />
        <SettingListItem
          text={getPhoneNumber()}
          wide
          caption={t("userProfile.editProfile.phone")}
          placeholder={t("userProfile.editProfile.addPhoneNumber")}
          classNameType={"request"}
          onClick={() => setModalWindow("phone")}
          isDisabled={isHistory}
        />
      </ul>
      <p className={styles.label}>{t("qrCode.payment.address")}</p>
      <div className={styles.borderSettingList}>
        <div className={styles.searchContainer}>
          <SearchInput
            hideBorder
            caption={t("filter.country")}
            onChange={onCountryChange}
            value={historyData?.country || plate.userCountry.value}
            placeholder={t("qrCode.payment.selectCountry")}
            type="arrow"
            onBlur={() => onSearchBlur("country")}
            isHistory={isHistory}
            onFocus={() => fieldInFocus("country")}
            positionStyle={showPopup === "country" && !!popupData?.length}
          />
          {showPopup === "country" && !!popupData?.length && (
            <PopupLocation
              type="search"
              data={popupData}
              onClose={onPopupClose}
              onClick={onPopupCountryClick}
            ></PopupLocation>
          )}
        </div>
        <div className={styles.searchContainer}>
          <SearchInput
            hideBorder
            onChange={onCityChange}
            caption={t("filter.city")}
            value={historyData?.city || plate.userCity.value}
            placeholder={t("qrCode.payment.selectCity")}
            type="arrow"
            isHistory={isHistory}
            isDisabled={!plate.userCountry.countryId && !isHistory}
            onFocus={() => fieldInFocus("city")}
            positionStyle={showPopup === "city" && !!popupData?.length}
          />
          {showPopup === "city" && !!popupData?.length && (
            <PopupLocation
              type="searchCity"
              data={popupData}
              onClose={onPopupClose}
              onClick={onPopupCityClick}
              handleScroll={handleScroll}
            ></PopupLocation>
          )}
        </div>
        <div className={styles.searchContainer}>
          <SearchInput
            hideBorder
            onChange={onAddressChange}
            caption={t("qrCode.payment.addAddress")}
            value={historyData?.address || plate.address}
            placeholder={t("qrCode.payment.addAddress")}
            type="empty"
            isHistory={isHistory}
            maxLength={512}
          />
          <SearchInput
            hideBorder
            onChange={onIndexChange}
            caption={t("qrCode.payment.index")}
            value={historyData?.index || plate.index}
            placeholder={t("qrCode.payment.index")}
            type="empty"
            isDisabled={
              (!deliveryPrice?.isHaveZipCode || !plate.userCountry.value) &&
              !isHistory
            }
            isHistory={isHistory}
            maxLength={10}
          />
        </div>
      </div>
      <div className={styles.total}>
        <div className={styles.row}>
          <div className={styles.title}>{t("qrCode.payment.delivery")}</div>
          <div className={styles.price}>{getDeliveryPrice()} €</div>
        </div>
        <div className={styles.row + " " + styles.second}>
          <div className={styles.title}>{t("qrCode.payment.total")}</div>
          <div className={styles.price}>{getTotalPrice()} €</div>
        </div>
      </div>
      {orderStatus ? (
        <div className={styles.orderStatus}>
          {" "}
          {t(`order.status.${orderStatus}`)}.
        </div>
      ) : (
        ""
      )}
      {isHistory ? (
        <button
          className={styles.qrCodeRequestButton}
          onClick={onSendFeedback}
          disabled={historyData?.isRating}
        >
          {historyData?.isRating
            ? t("userProfile.orderHistory.feedbackSent")
            : t("userProfile.orderHistory.leaveFeedback")}
        </button>
      ) : (
        <button
          className={styles.qrCodeRequestButton}
          onClick={onPaymentClick}
          disabled={disablePay()}
        >
          {t("common.continue")}
        </button>
      )}

      {!!modalWindow && (
        <ModalWindow
          onClose={() => setModalWindow("")}
          isShow={!!modalWindow}
          {...modalWindowData()}
        ></ModalWindow>
      )}
      {isShowPaymentModalWindow && !isShowPaymentForm && (
        <ModalWindow
          onClose={() => setIsShowPaymentModalWindow(false)}
          isShow={isShowPaymentModalWindow}
          headerText={t("common.payment")}
          children={
            <PaymentMethods
              onPayPaypal={paymentPaypal}
              onPayCreditCard={paymentCreditCard}
              onPay={mandatePay}
            />
          }
        ></ModalWindow>
      )}
      {isShowPaymentForm && (
        <ModalWindow
          onClose={() => setIsShowPaymentForm(false)}
          isShow={isShowPaymentForm}
          headerText={t("common.payment")}
          children={
            <PaymentForm
              onClick={onPaymentCardClick}
              disabled={disablePaymentForm()}
            />
          }
        ></ModalWindow>
      )}
    </div>
  );
};
