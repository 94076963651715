import styles from "./MemorialCard.module.scss";
import { ProfileCandlesType } from "../../../../types/MemorialTypes";
import { PostsHeader } from "../../../../shared/components/postsHeader/PostsHeader";
import { LazyLoadImage } from "react-lazy-load-image-component";


type MemorialCardProps = {
  data: ProfileCandlesType;
};

const url = process.env.REACT_APP_BLOB_DNS_NAME as string;

export const MemorialCard = ({ data }: MemorialCardProps) => {

  return (
    <div className={styles.memorialItemContainer}>
      <div
        className={
          !data?.userInfo
            ? styles.memorialItemHeaderContainerAnonymous
            : styles.memorialItemHeaderContainer
        }
      >
        {(data?.userInfo || data?.createdAt) && (
          <PostsHeader
            date={data?.createdAt}
            img={data?.userInfo?.avatarPreviewUrl}
            username={data?.userInfo?.userName}
          />
        )}
      </div>
      <div className={styles.mainContainer}>
        <LazyLoadImage src={`${url}${data?.mediaUrl}`} alt="" />
      </div>
      {data?.comment && <p className={styles.message}>{data.comment}</p>}
    </div>
  );
};
