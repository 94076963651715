import React, {useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./LanguagesSetting.module.scss";
import { ListItem } from "../../../../shared/components/listItem/ListItem";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../../store/slices/languageSlice";
import i18n from "../../../../i18n";
import { SearchInput } from "../../../../shared/components/search/SearchInput";
import { PopupData } from "../../../../types/CommonTypes";

type LanguagesSettingProps = {
  changeHeader: ()=>void;

};

export const LanguagesSetting = ({changeHeader}:LanguagesSettingProps) => {
  const { language } = useSelector((state: any) => state.language);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const languagesData = t("languagePopup", {
    returnObjects: true,
  }) as PopupData[];

  const [languages, setLanguages] = useState(languagesData);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    const newLanguagesData = t("languagePopup", {
      returnObjects: true,
    }) as PopupData[];
    setLanguages(newLanguagesData);
  }, [t]);

  const onClick = (type: string) => {
    dispatch(setLanguage(type));
    localStorage.setItem("lang", type);
    i18n.changeLanguage(type.toLocaleLowerCase());
    changeHeader()
    if (!!selectedLanguage) {
      setSelectedLanguage('')
    }
  };

  const onChange = (term: string) => {
    setSearchTerm(term);

    const filtered = languagesData.filter((language: PopupData) => {
      return (
        language.description?.toLowerCase().includes(term.toLowerCase()) ||
        language.name.toLowerCase().includes(term.toLowerCase())
      );
    });
    setLanguages(filtered);
  };

  const onSelectItem = (type: string) => {
    setSelectedLanguage(type)
  }

  return (
    <>
      <div className={styles.languagesBlock}>
        <h3 className={styles.title}>{t('userProfile.language')}</h3>
        <ul className={`${styles.languagesList}`}>
          {languages?.map((item, index) => {
            return (
              <ListItem
                item={item}
                key={index}
                onClick={onSelectItem}
                isSelected={!!selectedLanguage ? item.type === selectedLanguage : item.type === language}
                isWide
              />
            );
          })}
        </ul>
        <button onClick={() => onClick(selectedLanguage)} disabled={!selectedLanguage}>{t('common.save')}</button>
      </div>

      <div className={styles.languagesBlockMobile}>
        <SearchInput onChange={onChange} value={searchTerm} />
        <ul className={`${styles.languagesList}`}>
          {languages?.map((item, index) => {
            return (
              <ListItem
                item={item}
                key={index}
                onClick={onClick}
                isSelected={item.type === language}
                isWide
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};
