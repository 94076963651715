import {
  useDispatch,
  useSelector
} from "react-redux";
import { RootState } from "../../../store";
import { OrderPage } from "../../../pages/candlesPage/OrderPage";
import { QrCodeWizardComponent } from "../qrCodeWizard/QrCodeWizardComponent";
import {
  useEffect,
  useState
} from "react";
import {
  deleteState,
  setIsPaymentLoading
} from "../../../store/slices/OrderSlice";
import { PaymentStatus } from "../../../shared/components/paymentStatus/PaymentStatus";
import { useInitPaymentMutation } from "../../../api/PaymentService";
import { MAX_RETRY_REQUESTS } from '../../../constants/constants';
import {
  InitPaymentBody,
  Mandate
} from "../../../types/PaymentTypes";
import {
  useGetAuthorizedUserInfoQuery,
  useGetUserContactsQuery
} from "../../../api/UserService";
import { deleteStatePaymentSessionId } from '../../../store/slices/PaymentSessionSlice';
import useIsAuthenticated from "../../../utils/useIsAuthenticated";

export const QrCodeOrder = () => {
  const dispatch = useDispatch();
  const {isAuthenticated} =  useIsAuthenticated();

  const { plate } = useSelector((state: RootState) => state.orderSlice);
  const { language } = useSelector((state: RootState) => state.language);
  const { paymentSessionId } = useSelector((state: RootState) => state.paymentSessionSlice);

  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [initPaymentBody, setInitPaymentBody] = useState<any>(null)
  const [retryCount, setRetryCount] = useState(0);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [paymentError, setPaymentError] = useState<boolean>(false);

  const { data: contactData } = useGetUserContactsQuery("", { skip: !isAuthenticated });
  const { data: userData } = useGetAuthorizedUserInfoQuery("", { skip: !isAuthenticated });

  const [
    initPayment,
    { isLoading, isSuccess: isPaymentSuccess, data: paymentData, error },
  ] = useInitPaymentMutation();

  useEffect(() => {
    if (error && 'status' in error && error.status === 400 && retryCount < MAX_RETRY_REQUESTS) {
      const newTimerId = setTimeout(() => {
        setRetryCount(prevCount => prevCount + 1);
        sendInitPayment(initPaymentBody);
      }, 10000);
      if (timerId) {
        clearTimeout(timerId);
      }
      setTimerId(newTimerId);
    } else if (retryCount === MAX_RETRY_REQUESTS && error) {
      setPaymentError(true);
      setRetryCount(0);
      setTimerId(null);
      if (timerId) {
        clearTimeout(timerId);
      }
    }
  }, [ error, initPayment ]);

  useEffect(() => {
    return () => {
      if (!plate.showWizard) {
        dispatch(deleteState());
      }
    };
  }, []);

  useEffect(() => {
    if (isPaymentSuccess) {
      if (!!paymentData?.checkoutUrl) {
        window.location = paymentData.checkoutUrl as any;
      } else {
        dispatch(setIsPaymentLoading(isLoading))
        setIsPaid(!!paymentData?.isPaid)
      }
      if (timerId) {
        clearTimeout(timerId);
        setTimerId(null);
      }
    }
  }, [isPaymentSuccess]);

  useEffect(() => {
    if(isLoading) {
      dispatch(setIsPaymentLoading(isLoading));
    }
  }, [isLoading]);

  const sendInitPayment = (body: InitPaymentBody): void => {
    const currentUrl = window.location.href;
    const redirectUrl = `${currentUrl}`;
    const cancelUrl = `${currentUrl}`;
    const email = contactData?.find((item: any) => item.type === 1)
    const customerName = !!userData ? `${userData?.lastName || ""} ${userData?.firstName || ""} ${userData?.middleName || ""}`.trim() : undefined;
    const newBody: InitPaymentBody = {
      redirectUrl,
      cancelUrl,
      locale: language,
      customerName,
      email: email?.value,
      ...body
    }
    initPayment(newBody);
    if (!initPaymentBody) {
      setInitPaymentBody(body)
    }
  }

  const mandatePayment = (orderId: string, mandate: Mandate): void => {
    sendInitPayment({
      orderId,
      paymentMethod: mandate.method,
      mandateId: mandate.id
    });
    dispatch(deleteState());
  }

  const paypalPayment = (orderId: string): void => {
    sendInitPayment({
      orderId,
      paymentMethod: 'paypal',
      sessionId: paymentSessionId,
    });
    dispatch(deleteState());
    dispatch(deleteStatePaymentSessionId());
  }

  const newCardPay = (orderId: string, cardToken: string, paymentMethod: string, isDefault?: boolean): void => {
    sendInitPayment({
      orderId,
      cardToken,
      paymentMethod,
      setDefault: isDefault
    });
    dispatch(deleteState());
  }

  const onPaymentClick = (token: string, paymentMethod: string, orderId: string, isDefault?: boolean, mandate?: Mandate): void => {
    if (!!mandate) {
      mandatePayment(orderId, mandate);
    } else {
      if (paymentMethod === 'paypal') {
        paypalPayment(orderId);
      } else {
        newCardPay(orderId, token, paymentMethod, isDefault);
      }
    }
  }

  const closePaymentStatus = (): void => {
    setIsPaid(false);
  }

  return (
    <>
      {!plate.showWizard && <OrderPage notNeedDeleteState={true} type={'plate'} /> }
      {plate.showWizard && <QrCodeWizardComponent onClose={onPaymentClick} />}
      <PaymentStatus type={"plate"} paymentError={paymentError} isPaid={isPaid} onClose={closePaymentStatus}/>
    </>
  );
};
