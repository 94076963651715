import styles from "./ProfileCandles.module.scss";
import Masonry from "react-responsive-masonry";
import { MemorialCard } from "../memorialCard/MemorialCard";
import { ProfileCandlesType } from "../../../../types/MemorialTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MemorialTypes } from "../../../../constants/Memorial.constant";


type ProfileCandlesPropsType = {
  candlesData: ProfileCandlesType[];
  checkedTab: string;
  isWallLoading:boolean
};

  export const ProfileCandles = ({ candlesData, checkedTab ,isWallLoading }: ProfileCandlesPropsType) => {
  const [columnsCount, setColumnsCount] = useState(2);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      const container = document.getElementsByClassName(
        styles.profileCandleContainer
      ) as HTMLCollectionOf<HTMLElement>;
     
      if (container.length) {
        const elementWidth = container[0]?.offsetWidth;
        if (elementWidth < 620 || window.innerWidth < 1150) {
          setColumnsCount(1);
        } else {
          setColumnsCount(2);
        }
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={!!candlesData.length ? styles.profileCandleContainer : styles.profileCandleContainerEmpty}>
      {!!candlesData.length ? (
        <Masonry columnsCount={columnsCount} gutter="8px">
          {candlesData?.map((item: ProfileCandlesType, index: number) => {
            return <MemorialCard data={item} key={index} />;
          })}
        </Masonry>
      ) : !isWallLoading && <p className={styles.emptyText}>{checkedTab === MemorialTypes.candles ? t("profile.emptyCandles") :  t("profile.emptyFeelings")}</p>}
    </div>
  );
};
