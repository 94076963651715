import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { b2cPolicies, protectedResources } from "../authConfig";

export function tokenRequest(type: string, instance: IPublicClientApplication, accounts:AccountInfo[], callback: ()=>void, reject: ()=>void) {
  let scope = [...protectedResources.api.scopes.profile];
  switch (type) {
    case "profile":
      scope = [...protectedResources.api.scopes.profile];
      break;
    case "search":
      scope = [...protectedResources.api.scopes.search];
      break;
    case "payment":
      scope = [...protectedResources.api.scopes.payment];
      break;
    case "order":
      scope = [...protectedResources.api.scopes.order];
      break;
    case "media":
      scope = [...protectedResources.api.scopes.media];
      break;
    case "complaint":
      scope = [...protectedResources.api.scopes.complaint];
      break;
    default:
      scope = [...protectedResources.api.scopes.read];
  }

    instance
      .acquireTokenSilent({
        account: accounts[0],
        scopes: scope,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
      })
      .then((response) => {
        localStorage.setItem(`token-${type}`, response?.accessToken);
        callback()
      })
      .catch((e) => {
        reject()
      });
}
