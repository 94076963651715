import {
  useForm,
  FieldValues
} from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import { Input } from "../../../../shared/components/input/Input";
import styles from "./EditForm.module.scss";
import { Dropdown } from "../../../../shared/components/dropdown/Dropdown";
import {
  useCheckCodeMutation,
  useSendVerificationCodeMutation,
} from "../../../../api/UserService";
import {
  useEffect,
  useState
} from "react";
import { ModalWindowError } from "../../../modalWindowError/ModalWindowError";
import { CodeValidationInput } from "../../../../shared/components/codeValidationInput/CodeValidationInput";
import { useGetCountryCodesQuery } from "../../../../api/UtilityService";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { RootState } from "../../../../store";
import useUpdateErrors from "../../../../utils/useUpdateErrors";
import { setErrorContact } from '../../../../store/slices/UserContactErrorSlice';
import {getLanguageByLocale} from "../../../../utils/localeUtils";

type EditPhoneFormProps = {
  onClose: (data: FieldValues) => void;
  data: FieldValues;
  type?: string;
};
type EditPhoneValidationFormProps = {
  onClose: () => void;
  data: FieldValues;
  type?: string;
  number?: string;
};

export const EditPhoneForm = ({ onClose, data }: EditPhoneFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = useSelector((state: RootState) => state.language);
  const { errorContact } = useSelector((state: any) => state.userContactErrorSlice);
  const [ countryCode, setCountryCode ] = useState(data?.countryCode);
  const { data: countryCodes } = useGetCountryCodesQuery(getLanguageByLocale(language));

  const schema = yup.object().shape({
    tel: yup.string().test(
      'tel',
      t('userProfile.editProfile.phoneNumberValidation'),
      (value, context) => {
        const newValue = (context.parent.countryCode || "") + (value || "")
        return /^[\+]?[0-9]{6,17}$/.test(newValue);
      }
    ),
    countryCode: yup.string().test(
      'countryCode',
      t('userProfile.editProfile.phoneNumberValidation'),
      (value) => {
        return !!value
      }
    )
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
    trigger,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    defaultValues: { ...data, tel: data?.value ? data.value : '', countryCode: data?.countryCode ? data.countryCode : '' },
    resolver: yupResolver(schema),
    mode: "onChange"
  });

  const telValue = watch('tel');

  useEffect(() => {
    trigger('tel');
    if (!telValue && !!errorContact) {
      dispatch(setErrorContact(''));
      reset({
        ...data,
        tel: ''
      });
    }
  }, [telValue]);

  const onSubmit = (value: FieldValues) => {
    const newData = {
      type: 2,
      value: value.tel,
      countryCode: countryCode || data?.countryCode,
      id: data?.id
    }
    onClose(newData);
  };
  useUpdateErrors(handleSubmit(onSubmit), !!Object.keys(dirtyFields).length);

  const onChange = (data: string) => {
    setCountryCode(data);
    setValue("countryCode", data);
    trigger('tel');
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.phoneFormContainer}>
        <Dropdown
          onChange={onChange}
          value={countryCode}
          data={countryCodes as { id: number; name: string, code: string }[]}
          placeholder={t('userProfile.editProfile.selectCountryCode')}
        />
        <Input
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          required
          placeholder={t('userProfile.editProfile.phoneNumber')}
          name="tel"
          type="number"
          error={errors.tel?.message as string || (errorContact === "Contact is already in use" ? t("userProfile.editProfile.editContactError") : '')}
          autocomplete="tel-local"
        />
      </div>
      <button className={styles.formButton} type="submit" disabled={!isValid}>
      { t('common.save')}
      </button>
    </form>
  );
};

export const PhoneCodeValidation = ({ onClose, data , type, number }: EditPhoneValidationFormProps) => {
  const [sendCode] = useSendVerificationCodeMutation();
  const [checkCode, { isSuccess, data: res, isLoading }] = useCheckCodeMutation();
  const [showErrorModalWinndow, setShowErrorModalWinndow] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [isCodeComplete, setIsCodeComplete] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess) {
      if (!res?.code) {
        onClose();
      } else {
        setShowErrorModalWinndow(true);
      }
    }
  }, [isSuccess]);

 
  const onCheckCode = () => {
    checkCode({ contactId: data.id, code });
  };

  return (
    <>
      <div className={styles.phoneVarificationContainer}>
        <p
          className={styles.textPhoneVarification}
        >{t('userProfile.editProfile.sendOn')} <span>{type === 'email' ? data.value : number}</span></p>
        <CodeValidationInput onSend={(value) => setCode(value)} setIsCodeComplete={setIsCodeComplete}/>
        <button className={styles.formButton} onClick={onCheckCode} disabled={isLoading || !isCodeComplete}>
          {t('userProfile.editProfile.checkValidationCode')}
        </button>
        <p
          className={styles.resendCodeButton}
          onClick={() => sendCode(data.id)}
        >
          {t('userProfile.editProfile.sendNewCode')}
        </p>
      </div>
      {showErrorModalWinndow && (
        <ModalWindowError
          isShow={showErrorModalWinndow}
          onClose={() => setShowErrorModalWinndow(false)}
          headerText={t('userProfile.editProfile.invalidCode')}
          description={t('userProfile.editProfile.invalidCodeDescription')}
        />
      )}
    </>
  );
};
