import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentSessionId: '',
};

export const paymentSessionSlice = createSlice({
  name: "paymentSessionSlice",
  initialState,
  reducers: {
    setPaymentSessionId: (state, action) => {
      state.paymentSessionId = action.payload;
    },
    deleteStatePaymentSessionId: (state) => {
      return initialState;
    },
  },
});
export const { setPaymentSessionId, deleteStatePaymentSessionId } =
  paymentSessionSlice.actions;
export default paymentSessionSlice.reducer;
