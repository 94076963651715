import React from "react";
import { useTranslation } from 'react-i18next';
import { useGetOwnedProfilesQuery } from "../../../api/ProfileService";
import { ProfilesSetting } from "./profilesSetting/ProfilesSetting";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";

type ProfilesSettingContainerProps = {
  type: string;
  listView:boolean
};

 export const ProfilesSettingContainer = React.memo( ({type, listView}:ProfilesSettingContainerProps) => {
  const { skip } = useFetchWithMsal('profile',  true);
  const { data: ownedProfiles} = useGetOwnedProfilesQuery("",{skip: skip || type === 'favorite', refetchOnMountOrArgChange: true,});
  const { t } = useTranslation();

  return (
    <ProfilesSetting data={ownedProfiles} header={t('userProfile.usersProfiles.ownedProfiles')} listView={listView}/>
  );
  })

