import { useTranslation } from "react-i18next";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "./ImageViewer.scss";
import cross from "../../shared/image/cross.svg";
import rotate from "../../shared/image/rotate.svg";

import ReactImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { useEffect, useState } from "react";
import deleteIcon from "../../shared/image/delete.svg";
import { ImageItem } from "../../types/CommonTypes";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import { generateRandomString } from "../../utils/generateRandomString";
import { allowedImageTypes } from "../../constants/Media.constant";

type ImageViewerProps = {
  isShow: boolean;
  isShowDelete?: boolean;
  isShowRotate?: boolean;
  data: ImageItem[];
  startIndex: number;
  onClose: (currentData?: ImageItem) => void;
  onDelete?: (index: number) => void;
  onRotate?: (index: number, img: File) => void;
  showSelectButton?: boolean;
};

export const ImageViewer = ({
  isShow,
  isShowDelete,
  isShowRotate,
  onClose,
  data,
  startIndex,
  onDelete,
  onRotate,
  showSelectButton,
}: ImageViewerProps) => {
  const [images, setImages] = useState<ReactImageGalleryItem[]>([]);
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState<number>(startIndex || 0);

  const [ids, setIds] = useState<number[]>([]);

  const [showButtonSelect, setShowButtonSelect] = useState<boolean>(true);

  useEffect(() => {
    const indices = Array.from({ length: data?.length }, () =>
      generateRandomString()
    );
    setIds(indices);
    const imgData = data.map((mediaItem, i) => {
      if (mediaItem.description === "video") {
        return {
          original: mediaItem.original
            ? URL.createObjectURL(mediaItem.original as Blob)
            : (mediaItem.url as string),
          thumbnail: mediaItem.thumbnail,
          description: mediaItem.description,
          renderItem: function (item: any) {
            return (
              <LazyLoadComponent>
                <video
                  width="100%"
                  height="100%"
                  preload="auto"
                  controls
                  src={item.original}
                  className={
                    !(
                      mediaItem.type === "video/mp4" ||
                      mediaItem.type === "video/quicktime"
                    )
                      ? "errorVideo"
                      : ""
                  }
                  id={`video_${indices[i]}`}
                ></video>
                {!(
                  mediaItem.type === "video/mp4" ||
                  mediaItem.type === "video/quicktime"
                ) && <p className="errorText">{t("common.videoError")}</p>}
              </LazyLoadComponent>
            );
          },
        };
      } else {
        return {
          original: mediaItem.original
            ? URL.createObjectURL(mediaItem.original as Blob)
            : (mediaItem.url as string),
          thumbnail: mediaItem.thumbnail,
          renderItem: function (item: any) {
            return (
              <div className="image-gallery-slides">
                <LazyLoadImage
                  src={item.original as string}
                  alt={item.description}
                  effect="blur"
                />
                <p>{item.description}</p>
              </div>
            );
          },
        };
      }
    });

    setImages(imgData);
  }, []);

  const renderThumbInner = (item: ReactImageGalleryItem) => {
    return (
      <div
        className={
          item.description === "video"
            ? "thumbInnerContainer-video"
            : "thumbInnerContainer"
        }
      >
        <img
          src={item.thumbnail}
          alt={item.description}
          className="thumbInnerImg"
        />
      </div>
    );
  };

  const onClick = () => {
    const filteredGallery = images.filter(
      (item, index) => index !== currentIndex
    );
    const filteredIds = ids.filter((item, index) => index !== currentIndex);
    setIds(filteredIds);

    setImages(filteredGallery);
    if (onDelete) {
      onDelete(currentIndex);
      if (!filteredGallery[currentIndex]) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  const handleSlide = (index: number) => {
    const video = document.getElementById(
      `video_${ids[currentIndex]}`
    ) as HTMLVideoElement;
    video && video.pause();
    setCurrentIndex(index);
  };

  const rotateImage = (
    imageItem: ImageItem,
    angle: number,
    callback: (newItem: File) => void
  ) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imageItem.original
      ? URL.createObjectURL(imageItem.original)
      : (imageItem.url as string);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

      canvas.width = img.height;
      canvas.height = img.width;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((angle * Math.PI) / 180);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
      const fileName =
        imageItem.original?.name ||
        imageItem.url?.substring(imageItem.url.lastIndexOf("/") + 1) || '';
      canvas.toBlob((blob) => {
        if (blob) {
          const newFile = new File([blob], fileName, {
            type: imageItem.original?.type || imageItem.type || 'image/jpeg',
            lastModified: Date.now(),
          });
          callback(newFile);
        }
      }, imageItem.original?.type || imageItem.type);
    };
  };

  const handleRotateButtonClick = () => {
    rotateImage(data[currentIndex], 90, (newItem) => {
      const newMedia = images.map((item, index) => {
        if (index === currentIndex) {
          item.original = URL.createObjectURL(newItem as Blob);
          item.thumbnail= URL.createObjectURL(newItem as Blob);
        }
        return item;
      });
      setImages(newMedia);
      onRotate && onRotate(currentIndex, newItem);
    });
  };

  return (
    <div className={`imageViewerWrapper ${!isShow && "hidden"}`}>
      <div className="imageViewerContainer">
        <div className="closeButton" onClick={() => onClose()}>
          <img src={cross} alt="" />
        </div>
        {isShowRotate && (allowedImageTypes.includes(data[currentIndex].type || '') || !data[currentIndex].type ) && <div className="rotateButton" onClick={handleRotateButtonClick}>
          <img src={rotate} alt="" />
        </div>}
        <p
          className={isShowDelete ? "deleteButton" : "hidden"}
          onClick={onClick}
        >
          <img src={deleteIcon} alt="" />
        </p>
        <ReactImageGallery
          items={images}
          onMouseOver={() => setShowButtonSelect(true)}
          onMouseLeave={() => setShowButtonSelect(false)}
          showFullscreenButton={false}
          showPlayButton={false}
          startIndex={startIndex}
          showThumbnails={data.length > 1}
          renderThumbInner={renderThumbInner}
          onSlide={handleSlide}
        />
      </div>
      <div className="closeButtonMobile" onClick={() => onClose()}>
        {t("common.close")}
      </div>
      {showSelectButton && showButtonSelect && (
        <button
          onClick={() => onClose(data[currentIndex])}
          onMouseOver={() => setShowButtonSelect(true)}
          className={"selectButton"}
        >
          {t("common.applyPhoto")}
        </button>
      )}
    </div>
  );
};
