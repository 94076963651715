import { useTranslation } from "react-i18next";
import { ModalWindow } from '../../../components/modalWindow/ModalWindow';

import styles from '../CreateProfile.module.scss';
import { splitIntoParagraphs } from '../../../utils/splitIntoParagraph';

type WarningModalWindowProps = {
  onClose: () => void;
  isShow: boolean;
  backToPrevStep: () => void;
}

export const WarningModalWindow = ({onClose, isShow, backToPrevStep}: WarningModalWindowProps) => {
  const { t } = useTranslation();

  return (
    <ModalWindow
      onClose={onClose}
      isShow={isShow}
      headerText={t('profile.warning')}
      children={
        <div>
          <div className={styles.popupDescription}>
            {splitIntoParagraphs(t('profile.warningLoadPhoto')).map(
              (paragraph, index) => (
                <p key={index}>
                  {paragraph}<br/>
                </p>
              )
            )}
          </div>
          <button
            className={styles.popupButton}
            onClick={backToPrevStep}
          >
            {t("common.back")}
          </button>
          <button
            className={styles.popupCloseButton}
            onClick={onClose}
          >
            {t("common.cancel")}
          </button>
        </div>
      }
    />
  )
}