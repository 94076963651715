import styles from './RadioButtonStyles.module.scss';
import {initialStateFilterType} from '../../../store/slices/FiltersFormSlice';

type GenderRadioButtonPropsType = {
  value: number;
  filterState: initialStateFilterType;
  onChangeFilterState: (event: string, value: string) => void;
  label: string;
}

export const GenderRadioButton = ({ value, filterState, onChangeFilterState, label}: GenderRadioButtonPropsType) => {
  return (
    <div className={`${Number(filterState.gender) === value ? styles.radioWrapperSelected : styles.radioWrapper}`}>
      <label className={styles.radioContainer}>
        <input
          type="radio"
          value={value}
          onChange={(event) => onChangeFilterState(event.target.value, "gender")}
          checked={Number(filterState.gender) === value}
        />
        {label}
        <span className={styles.checkmark}></span>
      </label>
    </div>
  );
}