import { useTranslation } from "react-i18next";
import { ModalWindow } from '../../../components/modalWindow/ModalWindow';

import styles from '../../../components/userProfile/complaints/Complaints.module.scss';
import infoResultIcon from '../../image/info.svg';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {setShowWarningModal} from '../../../store/slices/WarningModalSlice';

export const WarningModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showWarningModal } = useSelector(
    (state: RootState) => state.warningModalSlice
  );

  const onClose = () => {
    dispatch(setShowWarningModal(false));
  }

  return (
    <ModalWindow
      isShow={showWarningModal}
      onClose={onClose}
      headerText={t('warningModal.header')}
      children={
        <div className={styles.flex}>
          <div>
            <p className={styles.description}>{t("warningModal.description")}</p>
          </div>
          <div>
            <img className={styles.infoIcon} src={infoResultIcon} alt={""}/>
            <button className={styles.buttonConfirmModal} onClick={onClose}>{t("common.close")}</button>
          </div>
        </div>
      }
    />
  )
}