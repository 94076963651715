import { OverlayView } from "@react-google-maps/api";
import styles from "./CurrentLocationMarker.module.scss";

interface CustomMarkerProps {
  position: google.maps.LatLngLiteral;
}

const CurrentLocationMarker = ({ position }: CustomMarkerProps) => {

  return (
    <>
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: -width / 2,
          y: -height,
        })}
      >
        <div className={styles.marker} ></div>
      </OverlayView>
    </>
  );
};

export default CurrentLocationMarker;
