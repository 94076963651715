import { useForm, FieldValues } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../shared/components/input/Input";
import styles from "./EditForm.module.scss";
import { useEffect, useState } from "react";
import { ModalWindowError } from "../../../modalWindowError/ModalWindowError";
import useUpdateErrors from "../../../../utils/useUpdateErrors";
import { EMAIL_REGEX } from '../../../../constants/constants';
import {useDispatch, useSelector} from 'react-redux';
import {setErrorContact} from '../../../../store/slices/UserContactErrorSlice';

type EditEmailFormProps = {
  onClose: (data: FieldValues) => void;
  data: FieldValues;
};

export const EditEmailForm = ({ onClose, data }: EditEmailFormProps) => {
  const dispatch = useDispatch();
  const { errorContact } = useSelector((state: any) => state.userContactErrorSlice);

  const { t } = useTranslation();
  const schemaEmail = yup
    .object({
      email: yup
        .string()
        .matches(EMAIL_REGEX, t("userProfile.editProfile.enterValidEmail")),
    })
    .required();
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({ defaultValues: { ...data, email: data?.value ?  data.value : '' }, resolver: yupResolver(schemaEmail) });
  const [isModalWindowShow, setIsModalWindowShow] = useState<boolean>(false);
  const emailField = watch('email');
  const getValuesEmailField = getValues('email');

  useEffect(() => {
    if (errors.email?.message) {
      setIsModalWindowShow(true);
    }
  }, [errors.email]);

  useEffect(() => {
    if (!emailField && !!errorContact) {
      dispatch(setErrorContact(''));
      reset({
        ...data,
        email: ''
      });
    }
  }, [emailField]);

  const onSubmit = (data: FieldValues) => {
    const newData = {
      id: data.id,
      isMain: data.isMain,
      isVerified: data.isVerified,
      ownerId :data.ownerId,
      type: 1,
      value: data.email
    }
    onClose(newData);
  };

  const onCloseErrorModal = () => {
    reset({
      ...data,
      email: getValuesEmailField
    });
    setIsModalWindowShow(false)
  };

  useUpdateErrors(handleSubmit(onSubmit), !!Object.keys(dirtyFields).length);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <Input
          register={register}
          setValue={setValue}
          name="email"
          type="text"
          placeholder="name@example.com"
          autocomplete="email"
          error={errorContact === "Contact is already in use" ? t("userProfile.editProfile.editContactError") : ''}
        />
      </div>
      <button className={styles.formButton} type="submit">
        {t("common.save")}
      </button>
      {isModalWindowShow && (
        <ModalWindowError
          isShow={isModalWindowShow}
          onClose={onCloseErrorModal}
          headerText={t("userProfile.editProfile.invalidEmail")}
          description={(errors.email?.message as string) || ""}
        />
      )}
    </form>
  );
};
