import styles from "./QrCodeTab.module.scss";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";

import { useGetPlateWizardQuery } from "../../../api/PlateService";
import {
  setPlateMounting,
  setPlateModel,
  setPlateMaterial,
  setPlateWizard,
  setPlateCount,
  setPlateColor,
} from "../../../store/slices/OrderSlice";
import { QrCodeItem } from "../../../shared/components/qrCodeItem/QrCodeItem";
import { Color, Material, Mounting } from "../../../types/PlateTypes";
import {
  modelsPlateWizardConstant,
  WizardModel,
} from "../../../constants/ModelsPlateWizard.constant";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "../../../shared/components/dropdown/Dropdown";
import { DropdownData } from "../../../types/CommonTypes";

type QrCodeTabProps = {
  isModalWindow?: boolean;
};

export const QrCodeTab = ({ isModalWindow }: QrCodeTabProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { plate, selectedProfiles } = useSelector(
    (state: RootState) => state.orderSlice
  );

  const [models, setModels] = useState<Array<WizardModel>>([]);
  const [mountings, setMountings] = useState<Array<Mounting>>([]);
  const [colors, setColors] = useState<Array<Color>>([]);

  const { data: plateWizard, isSuccess: isSuccessWizard } =
    useGetPlateWizardQuery({});

  useEffect(() => {
    if (isSuccessWizard) {
      const newModels: Array<WizardModel> = [];
      let material = plateWizard.materials[0];
      if (!!plate.selectedMaterial) {
        const findItem = plateWizard.materials.find(
          (item) => item.id === plate.selectedMaterial
        );
        if (!!findItem) {
          material = findItem;
        }
      }
      let color = material.colors[0];
      if (!!plate.selectedColor) {
        const findItem = material.colors.find(
          (item) => item.id === plate.selectedColor
        );
        if (!!findItem) {
          color = findItem;
        }
      }
      modelsPlateWizardConstant.forEach((editFormat) => {
        const format = color.models.find(
          (item) => item.aliasName === editFormat.aliasName
        );
        if (!!format) {
          editFormat.modelId = format.modelId;
          editFormat.mediaUrl = format.mediaUrl;
          editFormat.templateUrl = format.templateUrl;
          newModels.push(editFormat);
        }
      });

      setMountings(material.mountingTypes);
      setModels(newModels);
    }
  }, [isSuccessWizard]);

  useEffect(() => {
    if (!!mountings.length) {
      const index = mountings.findIndex(
        (item) => item.id === plate.selectedMounting
      );
      if (index === -1) {
        dispatch(setPlateMounting(mountings[0].id));
      }
    }
  }, [mountings]);

  useEffect(() => {
    if (!!colors.length) {
      const index = colors.findIndex((item) => item.id === plate.selectedColor);
      if (index === -1) {
        dispatch(setPlateColor(colors[0].id));
      } else {
        fillModels(colors[index]);
      }
    }
  }, [colors]);

  useEffect(() => {
    if (plate.selectedMaterial) {
      const selectedMaterial = plateWizard?.materials.find(
        (material) => material.id === plate.selectedMaterial
      );
      if (selectedMaterial) {
        setMountings(selectedMaterial.mountingTypes);
        setColors(selectedMaterial.colors);
      }
    }
  }, [plate.selectedMaterial]);

  useEffect(() => {
    if (!!plate.selectedColor) {
      const selectedColor = colors.find(
        (color) => color.id === plate.selectedColor
      );
      if (!!selectedColor) {
        fillModels(selectedColor);
      }
    }
  }, [plate.selectedColor]);

  const fillModels = (color: Color): void => {
    color.models.forEach((model) => {
      const editFormat = models.find(
        (format) => format.modelId === model.modelId
      );
      if (!!editFormat) {
        editFormat.cost = model.cost;
        editFormat.mediaUrl = model.mediaUrl;
        editFormat.templateUrl = model.templateUrl;
      }
    });
    setModels([...models]);
  };

  const onFormatsClick = (id: string) => {
    dispatch(setPlateModel(id));
  };

  const onMaterialClick = (id: string) => {
    dispatch(setPlateMaterial(id));
  };

  const onMountingClick = (id: string) => {
    dispatch(setPlateMounting(id));
  };

  const onColorClick = (id: string) => {
    dispatch(setPlateColor(id));
  };

  const addCountPlate = (): void => {
    if (plate.count < 20) {
      dispatch(setPlateCount(plate.count + 1));
    }
  };

  const removeCountPlate = (): void => {
    if (plate.count > 1) {
      dispatch(setPlateCount(plate.count - 1));
    }
  };

  const getMinMaterialPrice = (material: Material): number => {
    const prices: Array<number> = [];
    material.colors.forEach((color) => {
      color.models.forEach((model) => prices.push(model.cost));
    });
    return Math.min(...prices);
  };

  const orderClick = (): void => {
    dispatch(setPlateWizard(true));
    if (isModalWindow) {
      navigate("/qrCodes");
    }
  };

  const modelIsBlack = (): boolean => {
    const color = colors.find((item) => item.id === plate.selectedColor);
    if (!!color) {
      return color.name === "Black";
    } else {
      return false;
    }
  };

  const changeCountMobile = (value: number): void => {
    dispatch(setPlateCount(value));
  };

  const getPlateDropdownData = (): DropdownData[] => {
    const result: Array<DropdownData> = [];
    for (let i = 1; i <= 20; i++) {
      result.push({ id: i, name: i.toString() });
    }
    return result;
  };

  return (
    <>
      {!isModalWindow && (
        <div className={styles.title}>{t("qrCode.title")}</div>
      )}
      <div className={styles.container}>
        <div
          className={
            isModalWindow
              ? styles.qrCodesContainerModalWindow
              : styles.qrCodesContainer
          }
        >
          <div className={styles.subContainer}>
            <div className={styles.subTitle}>{t("qrCode.material")}</div>
            <div className={!isModalWindow ? styles.scrollContainer : ""}>
              <div className={`${styles.qrCodesGrid} ${styles.material}`}>
                {plateWizard?.materials?.map((item: Material) => {
                  return (
                    <QrCodeItem
                      key={item.id}
                      selected={plate.selectedMaterial === item.id}
                      description={t(`qrCode.materials.${item.name}`)}
                      type="material"
                      onClick={onMaterialClick}
                      id={item.id}
                      img={item.mediaUrl}
                      price={getMinMaterialPrice(item)}
                      currency="€"
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles.subTitle}>{t("qrCode.mounting")}</div>
            <div className={`${styles.qrCodesGrid} ${styles.mounting}`}>
              {mountings.map((mounting) => (
                <QrCodeItem
                  key={mounting.id}
                  selected={plate.selectedMounting === mounting.id}
                  description={t(`qrCode.glues.${mounting.name}`)}
                  type="mounting"
                  onClick={onMountingClick}
                  id={mounting.id}
                  price={mounting.cost}
                  currency="€"
                />
              ))}
            </div>
            {colors.length > 1 && (
              <>
                <div className={styles.subTitle}>{t("qrCode.color")}</div>
                <div className={`${styles.qrCodesGrid} ${styles.mounting}`}>
                  {colors.map((color) => (
                    <QrCodeItem
                      key={color.id}
                      selected={plate.selectedColor === color.id}
                      description={t(`qrCode.colors.${color.name}`)}
                      type="mounting"
                      onClick={onColorClick}
                      id={color.id}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
          <div className={styles.title}>{t("qrCode.additionally")}</div>
          <div className={styles.subContainer}>
            <div className={styles.subTitle}>{t("qrCode.model")}</div>
            <div
              className={`${styles.qrCodesGrid} ${styles.formats} ${
                isModalWindow ? styles.modal : ""
              }`}
            >
              {models.map((item) => {
                return (
                  <QrCodeItem
                    key={item.modelId}
                    selected={plate.selectedModel === item.modelId}
                    description={t(`qrCode.models.${item.aliasName}`)}
                    big={item.big}
                    type="format"
                    onClick={onFormatsClick}
                    id={item.modelId}
                    img={item.mediaUrl}
                    price={item.cost}
                    isBlack={modelIsBlack()}
                    currency="€"
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={`${styles.buttonContainer} ${
            isModalWindow ? styles.modal : ""
          }`}
        >
          <div className={styles.mobileCountContainer}>
            <div className={styles.description}>{t("qrCode.count")}:</div>
            <div className={styles.dropdown}>
              <Dropdown
                isTop
                data={getPlateDropdownData()}
                value={plate.count}
                onChange={changeCountMobile}
                type="date"
                height="200px"
              />
            </div>
          </div>
          <div className={styles.countContainer}>
            <div className={styles.description}>{t("qrCode.count")}:</div>
            <div className={styles.value}>{plate.count}</div>
            <div className={styles.buttons}>
              <div className={styles.customBut} onClick={addCountPlate}>
                +
              </div>
              <div className={styles.line}></div>
              <div className={styles.customBut} onClick={removeCountPlate}>
                -
              </div>
            </div>
          </div>
          <button
            onClick={orderClick}
            disabled={
              !selectedProfiles.length ||
              !plate.selectedModel ||
              !plate.selectedMaterial
            }
          >
            {t("qrCode.order")}
          </button>
        </div>
      </div>
    </>
  );
};
