import { useTranslation } from "react-i18next";
import styles from "./PhotoForGallery.module.scss";
import { ImageItem } from "../../../types/CommonTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

type PhotoForGalleryProps = {
  onImageClick: () => void;
  onDelete: () => void;
  item: ImageItem;
  isBig?:boolean
};

const PhotoForGallery = ({
  onImageClick,
  onDelete,
  item,
  isBig
}: PhotoForGalleryProps) => {
  const { t } = useTranslation();
  const { photoHash } = useSelector((state: RootState) => state.profileSlice);
  
  return (
    <div className={`${isBig ? styles.imgContainerBig :styles.imgContainer} ${item.description === 'video'?  styles.videoContainer : ''}`}>
      <img
        src={item.isNew || item.isEdited || item.description === 'video' ? item.thumbnail :`${item.thumbnail}?${photoHash || 0}`}
        alt=""
        className={styles.galleryImage}
        onClick={onImageClick}
        loading="lazy"
      />
      <div className={styles.imgButtonContainer}>
        <button type="button" onClick={onImageClick}>
          {t("common.view")}
        </button>
        <button
          type="button"
          className={styles.imgDeleteButton}
          onClick={onDelete}
        >
          {t("common.delete")}
        </button>
      </div>
    </div>
  );
};

export default PhotoForGallery;
