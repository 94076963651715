import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { userApi } from "../api/UserService";
import { profileApi } from "../api/ProfileService";
import { utilityApi } from "../api/UtilityService";
import { mediaApi } from "../api/MediaService";
import { searchApi } from "../api/SearchService";
import { orderApi } from "../api/OrderService";
import { paymentApi } from "../api/PaymentService";
import { plateApi } from "../api/PlateService";
import { giftApi } from "../api/GiftService";
import {complaintApi} from '../api/ComplaintService';

import language from "./slices/languageSlice";
import createProfile from "./slices/CreateProfileSlice";
import mediaSlice from "./slices/MediaSlice";
import userPhotoSlice from "./slices/UserPhotoSlice";
import profileGallerySlice from "./slices/ProfileGallery";
import orderSlice from "./slices/OrderSlice";
import spinnerSlice from "./slices/SpinnerSlice";
import profileSlice from "./slices/ProfileSlice";
import orderHistorySlice from "./slices/OrderHistorySlice";
import warningModalSlice from "./slices/WarningModalSlice";

import filtersFormSlice from './slices/FiltersFormSlice';
import userContactErrorSlice from './slices/UserContactErrorSlice';
import paymentSessionSlice from './slices/PaymentSessionSlice';
import userSlice from './slices/UserSlice';


export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [utilityApi.reducerPath]: utilityApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [plateApi.reducerPath]: plateApi.reducer,
    [giftApi.reducerPath]: giftApi.reducer,
    [complaintApi.reducerPath]: complaintApi.reducer,

    language,
    createProfile,
    mediaSlice,
    userPhotoSlice,
    profileGallerySlice,
    orderSlice,
    spinnerSlice,
    profileSlice,
    filtersFormSlice,
    userContactErrorSlice,
    paymentSessionSlice,
    orderHistorySlice,
    userSlice,
    warningModalSlice
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false }).concat(
      userApi.middleware,
      profileApi.middleware,
      utilityApi.middleware,
      mediaApi.middleware,
      searchApi.middleware,
      orderApi.middleware,
      paymentApi.middleware,
      plateApi.middleware,
      giftApi.middleware,
      complaintApi.middleware,
    ),
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
