import styles from "./ActionButton.module.scss";
import { ReactComponent as FavoriteIcon } from "../../../../../../shared/image/postsIcon/likeIcon.svg";
import { ReactComponent as CommentsIcon } from "../../../../../../shared/image/postsIcon/commentsIcon.svg";

import { useUpdateLikesMutation } from "../../../../../../api/PostProfileService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLike } from "../../../../../../store/slices/ProfileSlice";
import { MouseEventHandler, useEffect, useState } from "react";
import { ModalWindow } from "../../../../../modalWindow/ModalWindow";
import { useTranslation } from "react-i18next";

type ActionButtonProps = {
  count: number;
  isSelected: boolean;
  postId: string;
  type: string;
  onClick?: () => void;
  isAuthenticated?: boolean;
  setIsShowWindow?: () => void;
};

export const ActionButton = ({
  count,
  isSelected,
  postId,
  type,
  onClick,
  isAuthenticated,
  setIsShowWindow,
}: ActionButtonProps) => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [isShowErrorPopup, setIsShowErrorPopup] = useState<boolean>(false);

  const [updateLikes, { isSuccess, isError }] =
    useUpdateLikesMutation();

  const onImageClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (type === "like") {
      if (isAuthenticated) {
        updateLikes({ profileId: id, postId, state: !isSelected });
      } else if (setIsShowWindow) {
        e.stopPropagation();
        setIsShowWindow();
      }
    } else if (onClick) {
      onClick();
    }
  };
  useEffect(() => {
    if (isError) {
      setIsShowErrorPopup(true);
    }
  }, [isError]);
  useEffect(() => {
    if (isSuccess) {
      dispatch(updateLike({ id: postId, state: !isSelected }));
    }
  }, [isSuccess]);

  return (
    <>
      <div className={styles.likeContainer}>
        <div
          className={
            type !== "like" && isSelected
              ? styles.iconContainerSelected
              : styles.iconContainer
          }
          onClick={onImageClick}
        >
          {type === "like" ? (
            <FavoriteIcon
              className={`${styles.favoriteIcon}  ${
                isSelected ? styles.selectedIcon : ""
              }`}
            />
          ) : (
            <CommentsIcon
              className={`${styles.favoriteIcon}  ${
                isSelected ? styles.selectedCommentsIcon : ""
              }`}
            />
          )}
        </div>

        <div className={styles.countContainer}>
          <p>{count}</p>
        </div>
      </div>
      {isShowErrorPopup !== null && (
        <ModalWindow
          onClose={() => {
            setIsShowErrorPopup(false);
          }}
          isShow={isShowErrorPopup}
          backdropModal={true}
          headerText={t(`posts.errorPopup.1.popupHeader`)}
          children={
            <div>
              <p className={styles.popupDescription}>
                {t(`posts.errorPopup.1.popupDescription`)}
              </p>
              <button
                className={styles.popupButton}
                onClick={() => {
                  setIsShowErrorPopup(false);
                }}
              >
                {t("common.close")}
              </button>
            </div>
          }
        ></ModalWindow>
      )}
    </>
  );
};
