import styles from "./PaymentUserProfile.module.scss";
import { useTranslation } from "react-i18next";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import {
  useCreateMandateMutation,
  useDeleteMandateMutation,
  useGetPaymentMethodsQuery,
  useSetDefaultMandateMutation
} from "../../../api/PaymentService";
import { getCardImage } from "../../../utils/paymentUtils";
import cardIcon from '../../../shared/image/IconCard.svg'
import { Mandate } from "../../../types/PaymentTypes";
import { ModalWindow } from "../../modalWindow/ModalWindow";
import PaymentForm from "../../paymentForm/PaymentForm";
import {
  useEffect,
  useState
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { RootState } from "../../../store";
import {
  useGetAuthorizedUserInfoQuery,
  useGetUserContactsQuery
} from "../../../api/UserService";
import { setShowSpinner } from "../../../store/slices/SpinnerSlice";
import { PaymentStatus } from "../../../shared/components/paymentStatus/PaymentStatus";
import { ReactComponent as Cross } from "../../../shared/image/cross.svg";
import useIsAuthenticated from "../../../utils/useIsAuthenticated";
import { MobileInfoButton } from "../../../shared/components/mobileInfoButton/MobileInfoButton";
import { DeletePopup } from "../../../shared/components/deletePopup/DeletePopup";

export const PaymentUserProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {isAuthenticated} = useIsAuthenticated();
  const { skipPayment } = useFetchWithMsal("payment", isAuthenticated);

  const { language } = useSelector((state: RootState) => state.language);

  const [isShowPaymentForm, setIsShowPaymentForm] = useState<boolean>(false);

  const { data: contactData } = useGetUserContactsQuery("", { skip: !isAuthenticated });
  const { data: userData } = useGetAuthorizedUserInfoQuery("", { skip: !isAuthenticated });

  const { data: mandates, refetch: refetchMandates, isFetching: isFetchingMandates } = useGetPaymentMethodsQuery("", { skip: skipPayment });
  const [setDefaultMandate] = useSetDefaultMandateMutation();
  const [createMandate, { isSuccess: isCreateMandateSuccess, data: createMandateResponse, isLoading: isLoadingCreateMandate }] = useCreateMandateMutation()
  const [deleteMandate, { isSuccess: isDeleteMandateSuccess, isLoading: isLoadingDeleteMandate }] = useDeleteMandateMutation()
  const [deleteMandateItem, setDeleteMandateItem] = useState<Mandate | null>(null)

  useEffect(() => {
    if (isCreateMandateSuccess) {
      window.location = createMandateResponse.checkoutUrl;
    }
  }, [isCreateMandateSuccess]);

  useEffect(() => {
    dispatch(setShowSpinner(isLoadingCreateMandate));
  }, [isLoadingCreateMandate]);

  useEffect(() => {
    if (isDeleteMandateSuccess) {
      refetchMandates();
    }
  }, [isDeleteMandateSuccess, isLoadingDeleteMandate]);

  useEffect(() => {
    dispatch(setShowSpinner(isFetchingMandates));
  }, [isFetchingMandates]);

  const setDefault = (mandate: Mandate): void => {
    if (!mandate.isDefault) {
      setDefaultMandate(mandate.id);
    }
  }

  const onPaymentClick = (token: string, paymentMethod: string, isDefault?: boolean) => {
    const currentUrl = window.location.href;
    const redirectUrl = `${currentUrl}?selectedItem=2`;
    setIsShowPaymentForm(false);
    const email = contactData?.find((item: any) => item.type === 1);

    createMandate({
      paymentMethod,
      setDefault: !!isDefault,
      email: email?.value,
      locale: language,
      customerName: `${userData?.lastName || ""} ${userData?.firstName || ""} ${userData?.middleName || ""}`,
      redirectUrl,
      cardToken: token
    })
  };

  const onClickRemoveIcon = (mandate: Mandate): void => {
    setDeleteMandateItem(mandate);
  }

  const onDeleteMandate = (value:string): void => {
    switch (value) {
      case "delete":
        if (!!deleteMandateItem) {
          deleteMandate(deleteMandateItem.id);
          dispatch(setShowSpinner(true));
          setDeleteMandateItem(null);
        }
        break;
      default:
        setDeleteMandateItem(null)
    }
   
  }

  const transformDate = (date: string): string => {
    const newDate = new Date(date);
    const year = newDate.getFullYear().toString();
    return `${newDate.getMonth() + 1}/${year.substring(year.length - 2)}`;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{t("paymentMethods.paymentMethod")}</div>
        {mandates?.map(mandate => (
          <MobileInfoButton
            childrenHaveBorder
            key={mandate.id}
            isShowDelete={true}
            isShowInfo={false}
            onDelete={() => onClickRemoveIcon(mandate)}
            onComplain={() => {}}
            children={(
            <div className={`${styles.paymentMethod} ${mandate.isDefault ? styles.selected : ""}`}>
              <div className={styles.actions}>
                <div className={styles.setDefault} onClick={() => setDefault(mandate)}>
                  {mandate.isDefault ? t('payment.mainCard') : t('payment.setDefault')}
                </div>
                <Cross className={styles.removeIcon} onClick={() => onClickRemoveIcon(mandate)}/>
              </div>
              <div className={styles.info}>
                <div className={styles.descriptionContainer}>
                  <div className={styles.description}>
                    <span className={styles.cardLabel}>{mandate.cardLabel}</span>
                    {` **** ${mandate.cardNumber}`}
                  </div>
                  <div className={styles.expireDate}>{transformDate(mandate.cardExpiryDate)}</div>
                </div>
                <img alt="" src={getCardImage(mandate.cardLabel)}/>
              </div>
            </div>
          )}/>
        ))}
        <div className={`${styles.paymentMethod} ${styles.new}`}
             onClick={() => setIsShowPaymentForm(true)}>
          <div className={styles.info}>
            <img alt="" src={cardIcon}/>
            <div className={styles.description}>{t("paymentMethods.card")}</div>
          </div>
        </div>
      </div>
      {isShowPaymentForm && (
        <ModalWindow
          onClose={() => setIsShowPaymentForm(false)}
          isShow={isShowPaymentForm}
          headerText={t("paymentMethods.card")}
          newCard
          children={<PaymentForm onClick={onPaymentClick} buttonText={t("common.save")} disabled={false}/>}
        ></ModalWindow>
      )}
      {!!deleteMandateItem && (
        <DeletePopup text={t("paymentMethods.deletePaymentMethod")} onClick={onDeleteMandate}/>
      )}
      <PaymentStatus type="card"/>
    </>

  )
}