import styles from "./CommentItem.module.scss";
import { useDeleteCommentMutation } from "../../../../../../api/PostProfileService";
import { useParams } from "react-router-dom";
import { PostsHeader } from "../../../../../../shared/components/postsHeader/PostsHeader";
import { CommentType } from "../../../../../../types/ProfileType";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { AddCommentField } from "../../addCommentField/AddCommentField";
import { DeletePopup } from "../../../../../../shared/components/deletePopup/DeletePopup";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { MobileInfoButton } from "../../../../../../shared/components/mobileInfoButton/MobileInfoButton";
import { UnAuthenticatedPopup } from "../../../../../../shared/components/unAuthenticatedPopup/UnAuthenticatedPopup";
import { ComplainPopup } from "../../../../../userProfile/complaints/ComplainPopup";
import useIsAuthenticated from "../../../../../../utils/useIsAuthenticated";
import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../../../../../utils/tokenRequest";

type CommentItemProps = {
  data: CommentType;
  postId: string;
  isShowRepliesButton?: boolean;
  commentsCount?: number;
  setIsShowReplies?: () => void;
  onItemDelete: (id: string) => void;
  addNewComment: (value: CommentType) => void;
};

export const CommentItem = ({
  data,
  postId,
  isShowRepliesButton,
  commentsCount,
  setIsShowReplies,
  onItemDelete,
  addNewComment,
}: CommentItemProps) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [isReplyFieldShow, setIsReplyFieldShow] = useState<boolean>(false);
  const [isShowDeletePopup, setIsShowDeletePopup] = useState<boolean>(false);
  const [
    isShowNotAuthenticatedModalWindow,
    setIsShowNotAuthenticatedModalWindow,
  ] = useState<boolean>(false);
  const [isShowComplainPopup, setIsShowComplainPopup] =
    useState<boolean>(false);

  const { id: userId } = useSelector(
    (state: RootState) => state.userPhotoSlice
  );
  const { isCreatedByCurrentUser } = useSelector(
    (state: RootState) => state.profileSlice
  );
  const [deleteComment] = useDeleteCommentMutation();

  useEffect(() => {
    if (textRef.current && data.text) {
      setIsOverflowing(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  }, [data.text]);

  const splitIntoParagraphs = (text: string) => {
    return text.split("\n");
  };
  const handleDelete = () => {
    deleteComment({ profileId: id, postId, commentId: data.id });
    onItemDelete(data.id);
    setIsShowDeletePopup(false);
  };
  const onDelete = (value: string) => {
    switch (value) {
      case "delete":
        tokenRequest("profile", instance, accounts, handleDelete, () =>
          setIsShowNotAuthenticatedModalWindow(true)
        );
        break;
      default:
        setIsShowDeletePopup(false);
    }
  };
  const onReplyClick: MouseEventHandler<HTMLParagraphElement> = (e) => {
    if (isAuthenticated) {
      e.stopPropagation();
      setIsReplyFieldShow(true);
      const container = document.getElementById("pageContainer") as HTMLElement;
      if (containerRef.current && container) {
        const containerRect = container.getBoundingClientRect();
        const blockRect = containerRef.current.getBoundingClientRect();

        const isNeedToScroll = containerRect.bottom - blockRect.bottom < 140;
        if (isNeedToScroll) {
          container?.scrollTo(
            0,
            container.scrollTop +
              (blockRect.bottom - containerRect.bottom + 110)
          );
        }
      }
    } else {
      e.stopPropagation();
      setIsShowNotAuthenticatedModalWindow(true);
    }
  };
  function HighlightNamesInLine({ line }: { line: string }) {
    const highlightedText = line.split(/(@\w+)/g).map((part, index) => {
      if (part.startsWith("@")) {
        return (
          <span key={index} className={styles.highlight}>
            {part}
          </span>
        );
      }
      return part;
    });

    return <>{highlightedText}</>;
  }

  return (
    <div ref={containerRef}>
      <MobileInfoButton
        isShowDelete={isCreatedByCurrentUser || data.owner.id === userId}
        isShowInfo={data.owner.id !== userId && !!isAuthenticated}
        onDelete={() => setIsShowDeletePopup(true)}
        onComplain={() => setIsShowComplainPopup(true)}
      >
        <div className={styles.commentContainer}>
          <div className={styles.commentHeaderContainer}>
            <PostsHeader
              date={data?.creationDateTime}
              img={data?.owner?.avatarPreviewUrl}
              username={data?.owner?.username}
            />
            <div className={styles.postsItemHeaderTools}>
              {(userId === data?.owner?.id || isCreatedByCurrentUser) && (
                <p
                  className={styles.toolsHidden}
                  onClick={() => setIsShowDeletePopup(true)}
                >
                  {t("common.delete")}
                </p>
              )}
              {userId !== data?.owner?.id && isAuthenticated && (
                <p
                  onClick={() => setIsShowComplainPopup(true)}
                  className={styles.toolsHidden}
                >
                  {t("common.complain")}
                </p>
              )}

              <p className={styles.tools} onClick={onReplyClick}>
                {t("common.reply")}
              </p>
            </div>
          </div>

          <div className={styles.textContainer}>
            <div
              ref={textRef}
              className={
                isExpanded
                  ? styles.textContentExpanded
                  : isOverflowing
                  ? styles.textContentOverflowing
                  : styles.textContent
              }
            >
              {splitIntoParagraphs(data.text).map((paragraph, index) => (
                <p className={styles.text} key={index}>
                  <HighlightNamesInLine line={paragraph} />
                  <br />
                </p>
              ))}
            </div>
            {isOverflowing && !isExpanded && (
              <div className={styles.buttonContainer}>
                <span> ... </span>
                <button
                  onClick={() => setIsExpanded(true)}
                  className={styles.readMoreButton}
                >
                  {t("posts.readMore")}
                </button>
              </div>
            )}
          </div>
          {isShowRepliesButton && (
            <p
              className={styles.showRepliesButton}
              onClick={setIsShowReplies}
            >{`${t("posts.showReplies")} (${commentsCount})`}</p>
          )}
        </div>
      </MobileInfoButton>

      {isReplyFieldShow && (
        <div className={styles.fieldContainer}>
          <AddCommentField
            postId={postId}
            replyCommentId={data.replyCommentId || data.id}
            replyUserId={data?.owner?.id}
            initValue={`@${data?.owner?.username} `}
            isFocus
            onBlur={() => setIsReplyFieldShow(false)}
            addNewComment={addNewComment}
          />
        </div>
      )}
      {isShowDeletePopup && (
        <DeletePopup onClick={onDelete} text={t("posts.deleteComment")} />
      )}
      {isShowNotAuthenticatedModalWindow && (
        <UnAuthenticatedPopup
          onClose={() => setIsShowNotAuthenticatedModalWindow(false)}
        ></UnAuthenticatedPopup>
      )}
      {isShowComplainPopup && (
        <ComplainPopup
          onClose={() => setIsShowComplainPopup(false)}
          entityType={"Comment"}
          entityId={data.replyCommentId || data.id}
          textContent={data.text}
        />
      )}
    </div>
  );
};
