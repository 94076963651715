import styles from "./ModalWindow.module.scss";
import cross from "../../shared/image/cross.svg";
import ModalWindowHeader from "../../shared/components/modalWindowHeader/ModalWindowHeader";
import { ClipboardNotification } from "../../shared/components/clipboardNotification/ClipboardNotification";

type ModalWindowProps = {
  isShow: boolean;
  children?: string | JSX.Element | JSX.Element[];
  headerText?: string;
  description?: string;
  onClose: () => void;
  isOrder?: boolean;
  isError?: boolean;
  backdropModal?: boolean;
  isComplain?: boolean;
  isFeedback?: boolean;
  newCard?: boolean;
  isMobile?: boolean;
  isContactUs?: boolean;
  isHiddenOverflow?: boolean;
  isShowClipboard?: boolean;
};

export const ModalWindow = ({
  isShow,
  onClose,
  headerText,
  description,
  children,
  isOrder,
  isError,
  backdropModal,
  isComplain,
  isFeedback,
  isContactUs,
  newCard,
  isMobile,
  isHiddenOverflow,
  isShowClipboard
}: ModalWindowProps) => {

  const getClassName = () => {
    if (isOrder) {
      return styles.modalWindowContainerWide
    }
    if (isContactUs) {
      return styles.modalWindowContainerContactUs
    }
    if (isComplain || isFeedback || newCard) {
      return styles.modalWindowContainerHeight
    }
    return styles.modalWindowContainer
  }

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={`${styles.modalWindowWrapper} ${!isShow && styles.hidden} 
      ${backdropModal ? styles.modalWithBackdrop : ''}`}
      onClick={handleOverlayClick}
    >
      <div className={`${getClassName()} ${isHiddenOverflow? styles.modalContainerHiddenOverflow: ''}
        ${backdropModal ? styles.modalWithBackdropContainer : ''} ${isMobile ? styles.modalWrapperMobile : ''}`}
     id={isHiddenOverflow? 'hiddenOverflowContainer':''}
     >
        <div className={`${isError ? styles.modalWindowHeaderError : styles.modalWindowHeader} 
          ${backdropModal ? styles.modalWithBackdropHeader : ''}`}
        >
          <p>{headerText}</p>
          <img src={cross} alt="" onClick={onClose}/>
        </div>
        {
          !isMobile && (
            <div className={backdropModal ? styles.hidden : styles.modalWindowHeaderContainer}>
              <ModalWindowHeader title={headerText || ""} onClick={onClose}/>
            </div>
          )
        }
        <div style={{ overflowY: 'auto' }} className={isFeedback ? styles.modalWindowBodyFeedback : styles.modalWindowBody}>{children}</div>
        {description && <p className={styles.description}>{description}</p>}
      </div>
      <ClipboardNotification isShow={!!isShowClipboard}/>
    </div>
  );
};
