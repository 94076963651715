import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ModalWindow } from "../../../components/modalWindow/ModalWindow";
import { SuccessStatusPopup } from "../successStatusPopup/SuccessStatusPopup";
import { PaymentStatusBody } from "./paymentStatusBody/PaymentStatusBody";
import { useGetPaymentStatusQuery } from "../../../api/PaymentService";
import { setIsPaymentLoading } from '../../../store/slices/OrderSlice';
import { useDispatch } from 'react-redux';

type PaymentStatusProps = {
  isFree?: boolean,
  type?: string,
  paymentError?: boolean;
  isPaid?: boolean;
  onClose?: () => void;
}

export const PaymentStatus = ({ isFree, type, paymentError, isPaid, onClose }: PaymentStatusProps) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [paymentStatus, setPaymentStatus] = useState<string>("");
  const transactionId = searchParams.get("transactionId");
  const { data } = useGetPaymentStatusQuery(transactionId,{skip: !transactionId})

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {pathname, hash} = useLocation();

  useEffect(() => {
    if(paymentError) {
      setPaymentStatus('failed');
      dispatch(setIsPaymentLoading(false));
    }
  }, [paymentError])

  useEffect(() => {
    if (data) {
      setPaymentStatus(data.status);
      dispatch(setIsPaymentLoading(false));
    }
  }, [data]);

  useEffect(() => {
    if (isFree) {
      setPaymentStatus("free");
      setTimeout(() => {
        setPaymentStatus("");
      }, 2000);
    }
  }, [isFree]);

  useEffect(() => {
    if (!!isPaid) {
      setPaymentStatus('paid');
    }
  }, [isPaid]);

  const onCloseStatusPopup = () => {
    window.history.replaceState(null, '', window.location.pathname);
    setPaymentStatus("");
    navigate(pathname + hash);
    !!onClose && onClose();
  };

  const getHeaderText = (): string => {
    switch (type) {
      case 'plate':
        return t("qrCode.payment.acceptanceOrder");
      case 'card':
        return t("paymentMethods.paymentMethodAdd");
      default:
        return t("payment.candleSent");
    }
  }

  return (
    <>
      {paymentStatus && paymentStatus !== "free" && (
        <ModalWindow
          onClose={onCloseStatusPopup}
          isShow={!!paymentStatus && paymentStatus !== "free"}
          isError={paymentStatus !== "paid"}
          backdropModal = {type=== 'card'}
          headerText={
            paymentStatus !== "paid"
              ? t("common.error")
              : getHeaderText()
          }
          children={
            <PaymentStatusBody onClick={onCloseStatusPopup} status={paymentStatus} type={type} />
          }
        ></ModalWindow>
      )}
      {paymentStatus === "free" && <SuccessStatusPopup />}
    </>
  );
};
